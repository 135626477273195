import { combineReducers } from '@reduxjs/toolkit';
import groups from './groups.slice';
import teams from './teams.slice';
import users from './users.slice';

export const budgetPin = combineReducers({
  teams,
  groups,
  users
});

export default budgetPin;
