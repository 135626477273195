import { combineReducers, PayloadAction } from '@reduxjs/toolkit';
import quickActionDrawer from 'components/QuickActionsDrawer/quickActionsDrawer.slice';
import activities from 'features/activities/activities.slice';
import authPin from 'features/auth-pin/auth-pin.slice';
import basket from 'features/basket/basket.slice';
import checkout from 'features/checkout/checkout.slice';
import contract from 'features/contract/contract.slice';
import defineGPsByCCG from 'features/contract/defineGPsByCCG.slice';
import event from 'features/events/event.slice';
import feedback from 'features/feedback/feedback.slice';
import news from 'features/news/news.slice';
import peripheralStores from 'features/peripheralStores/peripheralStores.slice';
import budgetPin from 'features/pinManager/budgetPin.slice';
import productsCatalogue from 'features/products/productsCatalogue.slice';
import contractProfessionalAssessments from 'features/professionalAssessments/contractProfessionalAssessments.slice';
import quotes from 'features/quotes/quotes.slice';
import search from 'features/search/search.slice';
import serviceCentres from 'features/service-centres/serviceCentres.slice';
import serviceHub from 'features/service-hub/service-hub.slice';
import serviceUsers from 'features/service-users/serviceUsers.slice';
import specialOrder from 'features/service-users/specialOrder.slice';
import suppliers from 'features/service-users/suppliers.slice';
import telecare from 'features/telecare/telecare.slice';
import menu from 'features/ui/menu.slice';
import userSettings from 'features/ui/userSettings.slice';
import { baseRootReducers, clearAllCache } from 'millbrook-core';

export const RESET_APP_ACTION_TYPE = 'RESET_APP';
export const resetAppAction = () => ({
  type: RESET_APP_ACTION_TYPE
});

const allReducers = combineReducers({
  ...baseRootReducers,
  authPin,
  userSettings,
  menu,
  productsCatalogue,
  serviceCentres,
  serviceUsers,
  specialOrder,
  search,
  activities,
  news,
  checkout,
  basket,
  quotes,
  quickActionDrawer,
  contract,
  feedback,
  peripheralStores,
  suppliers,
  telecare,
  event,
  serviceHub,
  contractProfessionalAssessments,
  defineGPsByCCG,
  budgetPin
});

// TODO: work out the state here
const createRootReducer = (state: any, action: PayloadAction<any>) => {
  if (action.type === RESET_APP_ACTION_TYPE) {
    // If we are switching, we can pass the PIN user straight in here, rather than setting again in the getPinUserToken method
    // list of excluded reducers (Auth is required for pin select, authPin is needed for the pins, activities and service users are required for CSA switching contracts)
    const { router, auth, authPin, activityShared, activities, serviceUsers, contract } = state;
    const { selectedPinUser, pinToken, ...authPinRest } = authPin!;
    state = { router, activityShared, activities, serviceUsers, auth, authPin: authPinRest, contract };

    // clear the session api cache
    clearAllCache();
  }
  if (action.type === 'hydrate') {
    state = action.payload;
  }
  return allReducers(state, action);
};

export const rootReducer = createRootReducer;
