import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import {
  ApiId,
  mapApiErrors,
  ServiceUser,
  ServiceUserSearchCriteria,
  ServiceUserSummaryAlternative,
  snackbarUtils
} from 'millbrook-core';
import qs from 'query-string';
import { getItem, getItems, postItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { ServiceUserMergeSelectState } from './service-users.types';
import { removeServiceUserClientAlert, ServiceUserResponse, setSelectedServiceUser } from './serviceUser.slice';
import { ServiceUsersListResponse } from './serviceUsersData.slice';

interface MergingState {
  serviceUserSummaries: ServiceUserSummaryAlternative[];
  serviceUsers: ServiceUser[];
}

const initialState: MergingState = {
  serviceUsers: [],
  serviceUserSummaries: []
};

const serviceUserMergingSlice = createSlice({
  name: 'merging',
  initialState,
  reducers: {
    setMergeServiceUser(state, action: PayloadAction<{ serviceUser: ServiceUser; type: ServiceUserMergeSelectState }>) {
      state.serviceUsers[action.payload.type] = action.payload.serviceUser;
    },
    setMergeServiceUserSummary(
      state,
      action: PayloadAction<{ serviceUser: ServiceUserSummaryAlternative; type: ServiceUserMergeSelectState }>
    ) {
      state.serviceUserSummaries[action.payload.type] = action.payload.serviceUser;
    },
    clearMergeServiceUser(state) {
      state.serviceUserSummaries = [];
      state.serviceUsers = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setSelectedServiceUser, (state, action) => {
        // rebuild the merged SU and summary if the service user details updates
        // slight overkill
        const payloadSuId = action.payload?.serviceUserId;

        // check to see if any service users match the payload
        const suIndex = state.serviceUsers.findIndex((x) => x.serviceUserId === payloadSuId);
        if (suIndex === -1) return;

        state.serviceUsers[suIndex] = action.payload!;

        const {
          details,
          additionalInformation,
          serviceHub,
          serviceUserId: id,
          serviceUserName: name
        } = action.payload!;

        state.serviceUserSummaries[suIndex] = {
          ...state.serviceUserSummaries[suIndex],
          name,
          id,
          address: details.primaryAddress.address,
          dob: details.dob,
          firstName: details.firstName,
          landline: additionalInformation.contactDetails.landline,
          mobile: additionalInformation.contactDetails.mobile,
          nhsNumber: additionalInformation.additionalDetails.nhsNumber,
          hasShubAccount: !!serviceHub,
          title: details.title,
          socialCareNumber: additionalInformation.additionalDetails.socialCareNumber
        };
      })
      .addCase(removeServiceUserClientAlert, (state, action) => {
        // see if the alert id is in one of the merging service users.
        // We only have the id, not the service user, but it is unique
        // "add" is taken care of in the setSelectedServiceUser extraReducer
        const suIndex = state.serviceUsers.findIndex((su) =>
          su.serviceUserClientAlerts.some((x) => x.id === action.payload)
        );

        if (suIndex >= 0) {
          state.serviceUsers[suIndex].serviceUserClientAlerts = state.serviceUsers[
            suIndex
          ].serviceUserClientAlerts.filter((x) => x.id !== action.payload);
        }
      });
  }
});

/**
 * actions
 */
export const { setMergeServiceUser, setMergeServiceUserSummary, clearMergeServiceUser } =
  serviceUserMergingSlice.actions;

/**
 * thunks
 */

export const fetchMergeLookupServiceUsers =
  (filter?: ServiceUserSearchCriteria): AppThunk =>
  async (dispatch, getState) => {
    const app = getState();
    const primarySuContract =
      selectMergeServiceUserSummaries(app)[ServiceUserMergeSelectState.Active]?.contractDetails[0];

    const URL = qs.stringifyUrl(
      {
        url: ENDPOINTS.SERVICE_USERS.SERVICE_USERS_MERGE_SEARCH,
        query: { ...filter, showAssociatedShubAccount: true }
      },
      { skipEmptyString: true }
    );

    return getItems<ServiceUsersListResponse>(URL).then(
      (response) => {
        const serviceUsers = (response.result || []).reduce<ServiceUserSummaryAlternative[]>(
          (users, masterServiceUser) => {
            if (masterServiceUser.serviceUsers.length) {
              users = [
                // get the existing users in the reduce accumulator
                ...users,

                // add the service users from the MSUR,
                // if we are comparing the primary contract
                // - don't include the primary service user
                // - only include contracts that match the primary. If there are no contracts, we can display a message
                ...masterServiceUser.serviceUsers
                  .filter((x) => (!primarySuContract || x.id !== primarySuContract.serviceUserId) && x.isLiveAccount)
                  .map((x) => ({
                    ...x,
                    contractDetails: x.contractDetails.filter(
                      (y) => !primarySuContract || y.contractId === primarySuContract.contractId
                    )
                  }))
              ];
            }

            return users;
          },
          []
        );

        return serviceUsers.sort((a, b) => b.contractDetails.length - a.contractDetails.length);
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchMergeServiceUser =
  (serviceUserId: ApiId, contractId: ApiId, type: ServiceUserMergeSelectState): AppThunk =>
  async (dispatch, getState) => {
    return getItem<ApiId, ServiceUserResponse>(
      ENDPOINTS.SERVICE_USERS.SERVICE_USERS_MERGE_DETAILS(serviceUserId, contractId),
      '',
      {
        ignoreDefaultAuthRedirect: true,
        disableFullPageLoader: true
      }
    ).then(
      (response) => {
        response.result && dispatch(setMergeServiceUser({ serviceUser: response.result, type }));
      },
      (response) => {
        const error = mapApiErrors(response);
        snackbarUtils.error('Error retrieving service user details: ' + error);
      }
    );
  };

export const createMergeServiceUser = (): AppThunk => async (dispatch, getState) => {
  const [serviceUserActive, serviceUserArchive] = selectMergeServiceUsers(getState());

  // endpoint is considering the "current" service user is the one to archive
  // and "future" service user is the one to remain active. So all "current" will be replaced with "future"
  return postItems<string, null>(
    ENDPOINTS.SERVICE_USERS.SERVICE_USERS_MERGE_COMPLETE +
      `?currentServiceUserId=${serviceUserArchive.serviceUserId}&futureServiceUserId=${serviceUserActive.serviceUserId}`,
    '',
    {
      disableFullPageLoader: true
    }
  ).then(
    () => {
      return serviceUserActive.serviceUserId;
    },
    (response) => {
      const error = mapApiErrors(response);
      throw new Error(error);
    }
  );
};

/**
 * selectors
 */

export const selectMergeServiceUsers = (state: RootState) => state.serviceUsers.merging.serviceUsers;

export const selectMergeServiceUserSummaries = (state: RootState) => state.serviceUsers.merging.serviceUserSummaries;

export default serviceUserMergingSlice.reducer;
