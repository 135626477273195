import { Box, Collapse } from '@material-ui/core';
import { ENDPOINTS } from 'constants/api';
import { ServiceUserResponse } from 'features/service-users/serviceUser.slice';
import { ApiId, ColProp, DataTable, EosProductSummary, StyledButton } from 'millbrook-core';
import { useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { getItem } from 'services/api.service';

const eosTableColumns: ColProp[] = [
  { headerName: 'Product name', field: 'name' },
  { headerName: 'Delivery date', field: 'deliveryDate', formatAsDate: true },
  { headerName: 'Maintenance due', field: 'maintenanceDue', formatAsDate: true }
];

export const AddToBasketEosList: React.FC<{ serviceUserId: ApiId }> = ({ serviceUserId }) => {
  const [eos, setEos] = useState<EosProductSummary[]>([]);
  const [showEos, setShowEos] = useState(false);

  const handleShowEos = async () => {
    if (!eos.length) {
      const response = await getItem<ApiId, ServiceUserResponse>(
        ENDPOINTS.SERVICE_USERS.SERVICE_USER_DETAILS,
        serviceUserId,
        {
          ignoreDefaultAuthRedirect: true
        }
      );
      setEos(response?.result?.equipmentOnSite || []);
      setShowEos(true);
    }
  };

  return (
    <TransitionGroup>
      {!showEos && (
        <Collapse>
          <Box mt={3}>
            <StyledButton variant="secondary" size="small" onClick={handleShowEos}>
              Review Equipment on Site
            </StyledButton>
          </Box>
        </Collapse>
      )}
      {showEos && (
        <Collapse>
          <DataTable
            title="Service user EOS list"
            slimColumns
            rows={eos}
            columns={eosTableColumns}
            spacingTop={3}
            paging
          />
        </Collapse>
      )}
    </TransitionGroup>
  );
};
