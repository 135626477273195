import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { selectIsInternalPin, selectPinUsers, switchContract } from 'features/auth-pin/auth-pin.slice';
import { selectBasket } from 'features/basket/basket.slice';
import { selectContractId } from 'features/contract/contract.slice';
import { EosProductExtendReviewRequest, ServiceUserCardData } from 'features/service-users/service-users.types';
import {
    ApiId,
    EosProduct,
    IApiResponse,
    ISelectOption,
    mapApiErrors,
    MasterReviewPeriod,
    ServiceTypeCodeEnum,
    ServiceUser,
    ServiceUserContractDetailsModel,
    CollectedEquipmentModel,
    ActivityBaseSummaryModel,
    EosRemovalReasonsEnum
} from 'millbrook-core';
import { deleteItem, getItem, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';

/* types */
export type ServiceUserResponse = IApiResponse<ServiceUser>;
export type EosProductResponse = IApiResponse<EosProduct>;
export type CollectedEquipmentResponse = IApiResponse<CollectedEquipmentModel[]>;
export type ContractInactiveReasonsResponse = IApiResponse<InactiveReason[]>;

interface PCIItemAddRequest {
    contractProductId: ApiId;
    serviceUserId: ApiId;
}

interface ServiceUserAlertNotesAddRequest {
    serviceUserActiveAlertId: ApiId;
    note?: string;
}

interface ServiceUserAlertNotesUpdateRequest {
    id: ApiId;
    note?: string;
}

interface AddClientAlertRequest {
    contractServiceUserAlertId: ApiId;
    serviceUserId: ApiId;
    note?: string;
}

interface UpdateServiceUserInactiveStatus {
    serviceUserId: ApiId;
    serviceUserInactiveReasonId?: ApiId;
}

/* state */
interface ServiceUserState {
    selectedServiceUserDetails?: ServiceUser;
    showAssociatedRecordsModal: boolean;
    serviceUserCollectedEquipment?: CollectedEquipmentModel[];
    reasons?: InactiveReason[];
}

export interface InactiveReason {
    id: ApiId;
    contractId: ApiId;
    masterName: string;
    inactiveReasonId: ApiId;
    onContract: boolean;
}

const initialState: ServiceUserState = {
    showAssociatedRecordsModal: false
};

export type EosRemovalFormData = {
    serviceUserId: ApiId,
    eosItemId: ApiId,
    removalReason: EosRemovalReasonsEnum
}

/* slice */
const serviceUsersSlice = createSlice({
    name: 'serviceUser',
    initialState,
    reducers: {
        setSelectedServiceUser(state, action: PayloadAction<ServiceUser | undefined>) {
            // extraReducer in merging.slice is listening to this
            state.selectedServiceUserDetails = action.payload;
        },
        clearServiceUserDetails(state) {
            return initialState;
        },
        removeServiceUserClientAlert(state, action: PayloadAction<ApiId>) {
            // extraReducer in merging.slice is listening to this
            if (state.selectedServiceUserDetails) {
                state.selectedServiceUserDetails.serviceUserClientAlerts =
                    state.selectedServiceUserDetails.serviceUserClientAlerts.filter((x) => x.id !== action.payload);
            }
        },
        setServiceUserCollectedEquipment(state, action: PayloadAction<CollectedEquipmentModel[]>) {
            state.serviceUserCollectedEquipment = action.payload;
        },
        setContractInactiveReasons(state, action: PayloadAction<InactiveReason[]>) {
            state.reasons = action.payload;
        }
    }
});

/* thunks */
export const fetchServiceUser =
    (id: ApiId, clearServiceUser: boolean = true): AppThunk =>
        async (dispatch, getState) => {
            if (clearServiceUser) {
                await dispatch<any>(clearServiceUserDetails());
            }

            const app = getState();
            const contractId = selectContractId(app);

            try {
                const response = await getItem<ApiId, ServiceUserResponse>(ENDPOINTS.SERVICE_USERS.SERVICE_USER_DETAILS, id, {
                    ignoreDefaultAuthRedirect: true
                });

                const suContractId = response.result?.contractId;

                if (suContractId && contractId && suContractId !== contractId) {
                    await dispatch<any>(switchContract({ contractId: suContractId }));
                }

                dispatch(setSelectedServiceUser(response.result));

                return response.result;
            } catch (response) {
                const error = mapApiErrors(response);
                throw new Error(error);
            }
        };

export const markServiceUserAsDeceased =
    (id: ApiId): AppThunk =>
        async (dispatch) => {
            return putItem<null, IApiResponse<boolean>>(
                ENDPOINTS.SERVICE_USERS.SERVICE_USER_MARK_AS_DECEASED(id),
                undefined,
                undefined,
                {
                    enableGlobalErrorDialog: true
                }
            ).then((response) => {
                // If service user has been successfully marked as deceased, update details
                if (response.result === true) {
                    dispatch(fetchServiceUser(id));
                }

                return response.result;
            });
        };

export type UpdateServiceUserInactiveStatusRequest = UpdateServiceUserInactiveStatus;

export const markServiceUserAsInactive =
    (updateServiceUserInactiveStatus: UpdateServiceUserInactiveStatus): AppThunk =>
        async (dispatch) => {
            return putItem<UpdateServiceUserInactiveStatusRequest, IApiResponse<boolean>>(
                ENDPOINTS.SERVICE_USERS.SERVICE_USER_MARK_AS_INACTIVE(updateServiceUserInactiveStatus.serviceUserId),
                updateServiceUserInactiveStatus,
                undefined,
                {
                    enableGlobalErrorDialog: true
                }
            ).then((response) => {
                // If service user has been successfully marked as inactive, update details
                if (response.result === true) {
                    dispatch(fetchServiceUser(updateServiceUserInactiveStatus.serviceUserId));
                }

                return response.result;
            });
        };

export const fetchEosProduct =
    (serviceUserId: ApiId, id: ApiId): AppThunk =>
        async (dispatch) => {
            return getItem<ApiId, EosProductResponse>(
                ENDPOINTS.SERVICE_USERS.SERVICE_USERS_EOS_PRODUCT(serviceUserId),
                id
            ).then(
                (response) => {
                    if (response.result) {
                        return response.result;
                    }
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const fetchOpenActivities =
    (stockId: ApiId): AppThunk =>
        async () => {
            return getItems<IApiResponse<ActivityBaseSummaryModel[]>>(
                `${ENDPOINTS.ACTIVITIES.OPEN_ACTIVITIES}?stockId={${stockId}}`
            ).then(
                (response) => {
                    if (response.result) {
                        return response.result;
                    }
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const fetchCollectedEquipment =
    (serviceUserId: ApiId): AppThunk =>
        async (dispatch) => {
            return getItem<ApiId, CollectedEquipmentResponse>(
                ENDPOINTS.SERVICE_USERS.SERVICE_USERS_COLLECTED_EQUIPMENT(serviceUserId)
            ).then(
                (response) => {
                    if (response.result) {
                        dispatch(setServiceUserCollectedEquipment(response.result));
                        return response.result;
                    }
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

// SERVICE USER CLIENT ALERTS

export const addClientAlert =
    (contractServiceUserAlertId: ApiId, serviceUserId: ApiId, note?: string): AppThunk =>
        async (dispatch, getState) => {
            return postItems<AddClientAlertRequest, ServiceUserResponse>(
                ENDPOINTS.SERVICE_USERS.SERVICE_USERS_CLIENT_ALERTS,
                { contractServiceUserAlertId, serviceUserId, note },
                {
                    enableGlobalErrorDialog: true
                }
            ).then((response) => {
                // todo
            });
        };

export const removeClientAlert =
    (clientAlertId: ApiId): AppThunk =>
        async (dispatch) => {
            return deleteItem<ApiId, ServiceUserResponse>(ENDPOINTS.SERVICE_USERS.SERVICE_USERS_CLIENT_ALERTS, clientAlertId, {
                enableGlobalErrorDialog: true
            }).then((response) => {
                dispatch(removeServiceUserClientAlert(clientAlertId));
            });
        };

export const updateClientAlertNote =
    (data: ServiceUserAlertNotesUpdateRequest): AppThunk =>
        async (dispatch) => {
            return putItem<ServiceUserAlertNotesUpdateRequest, any>(
                ENDPOINTS.SERVICE_USERS.SERVICE_USERS_CLIENT_ALERT_NOTE,
                data,
                data.id
            );
        };

export const addClientAlertNote =
    (data: ServiceUserAlertNotesAddRequest): AppThunk =>
        async (dispatch) => {
            return postItems<ServiceUserAlertNotesAddRequest, any>(
                ENDPOINTS.SERVICE_USERS.SERVICE_USERS_CLIENT_ALERT_NOTE,
                data
            );
        };

// REVIEW PERIODS
export const fetchReviewPeriods = (): AppThunk => async (dispatch, getState) => {
    return getItems<IApiResponse<MasterReviewPeriod[]>>(ENDPOINTS.SERVICE_USERS.REVIEW_PERIODS, {
        enableGlobalErrorDialog: true,
        cacheName: 'portal-review-periods'
    }).then((response) => {
        return response.result || [];
    });
};

export const updateEosReviewPeriod =
    (stockId: ApiId, reviewPeriodId: ApiId, serviceUserId: ApiId): AppThunk =>
        async (dispatch) => {
            const eosExtendRequest = {
                reviewPeriodId
            };

            return postItems<EosProductExtendReviewRequest, ServiceUserResponse>(
                ENDPOINTS.SERVICE_USERS.SERVICE_USERS_EOS_REVIEW_PERIOD(serviceUserId, stockId),
                eosExtendRequest
            ).then(
                (response) => {
                    dispatch(setSelectedServiceUser(response.result));
                    return response.result;
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const fetchRecentServiceUsers = (): AppThunk => async () => {
    return getItems<IApiResponse<ServiceUserCardData[]>>(ENDPOINTS.SERVICE_USERS.RECENT_SERVICE_USERS, {
        disableFullPageLoader: true
    }).then(
        (response) => {
            return response.result || [];
        },
        (response) => {
            const error = mapApiErrors(response);
            throw new Error(error);
        }
    );
};

export const addPCIItem =
    (data: PCIItemAddRequest): AppThunk =>
        async (dispatch) => {
            return postItems<PCIItemAddRequest, any>(
                ENDPOINTS.PRODUCTS.ADD_PCI_ITEM,
                data
            );
        };

export const fetchContractInactiveReasons =
    (contractId: ApiId): AppThunk =>
        async (dispatch, getState) => {
            return getItems<ContractInactiveReasonsResponse>(
                `${ENDPOINTS.SERVICE_USERS.CONTRACT_SERVICE_USER_INACTIVE_REASONS}?contractId=${contractId}&includeMasterList=false`
            ).then((response) => {
                dispatch(setContractInactiveReasons(response.result || []));
            });
        };

export const removeEosItem =
    (eosRemovalFormData: EosRemovalFormData): AppThunk =>
        async () => {
            return putItem<EosRemovalFormData, IApiResponse<boolean>>(
                ENDPOINTS.SERVICE_USERS.REMOVE_EOS(eosRemovalFormData.serviceUserId, eosRemovalFormData.eosItemId),
                eosRemovalFormData
            ).then(
                (response) => {
                    return response.result;
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

/* actions */
export const {
    setSelectedServiceUser,
    clearServiceUserDetails,
    removeServiceUserClientAlert,
    setServiceUserCollectedEquipment,
    setContractInactiveReasons
} = serviceUsersSlice.actions;

/* selectors */
const selectedServiceUser = (state: RootState) => state.serviceUsers.serviceUser.selectedServiceUserDetails;

export const selectServiceUser = createSelector(
    [selectedServiceUser, selectIsInternalPin, selectPinUsers, selectContractId],
    (serviceUser, isInternalPin, pinUsers, contractId) => {
        // need to work out the contract listing if this is an internal pin
        if (isInternalPin && serviceUser) {
            const otherContracts = serviceUser.otherContracts.map<ServiceUserContractDetailsModel>((cd) => ({
                ...cd,
                switchState: pinUsers.some((pu) => pu.contractId === cd.contractId) ? 'contractSwitch' : 'noLink'
            }));

            return { ...serviceUser, otherContracts };
        }

        return serviceUser;
    }
);

export const selectServiceUserId = (state: RootState) =>
    state.serviceUsers.serviceUser.selectedServiceUserDetails?.serviceUserId;
export const selectShowAssociatedRecordsModal = (state: RootState) =>
    state.serviceUsers.serviceUser.showAssociatedRecordsModal;
export const selectServiceUserIsLiveAccount = (state: RootState) =>
    state.serviceUsers.serviceUser.selectedServiceUserDetails?.isLiveAccount;

export const selectEquipmentOnSite = (state: RootState) =>
    state.serviceUsers.serviceUser.selectedServiceUserDetails?.equipmentOnSite;

export const selectCollectedEquipment = (state: RootState) =>
    state.serviceUsers.serviceUser.serviceUserCollectedEquipment;

export const selectEosCollectionRepairInBasket = createSelector([selectBasket, selectServiceUser], (basket, user) => {
    if (!basket || basket.serviceUserId !== user?.serviceUserId) {
        return undefined;
    }

    // you can only have a repair or collections. Just find the first one and return the type
    const repairCollectionItem = basket.basketItems.find(
        (x) => x.serviceTypeCode === ServiceTypeCodeEnum.Repair || x.serviceTypeCode === ServiceTypeCodeEnum.Collection
    );

    return repairCollectionItem?.serviceTypeCode;
});

// export const selectEquipmentOnSite = createSelector(
//   [equipmentOnSite, selectUserEosCollectionRepairIds],
//   (eos, { collectionIds, repairIds }) => {
//     if (!(collectionIds.length || repairIds.length)) {
//       return eos;
//     }

//     return (eos || []).map<EosProductSummary>((e) => ({
//       ...e,
//       collectionInBasket: collectionIds.includes(e.id),
//       repairInBasket: repairIds.includes(e.id)
//     }));
//   }
// );

export const selectUserHasEosProducts = createSelector([selectEquipmentOnSite], (equipmentOnSite) => {
    return !!equipmentOnSite && equipmentOnSite.length > 0;
});

export const selectUserHasOrders = createSelector([selectServiceUser], (serviceUserDetails) => {
    return serviceUserDetails?.history?.orderHistory && serviceUserDetails?.history?.orderHistory?.length > 0;
});

export const selectServiceUserClientAlerts = createSelector([selectServiceUser], (serviceUserDetails) => {
    return serviceUserDetails?.serviceUserClientAlerts || [];
});

export const selectContractClientAlerts = createSelector(
    [selectServiceUser, selectServiceUserClientAlerts],
    (serviceUserDetails, suAlerts): ISelectOption[] => {
        const suAlertIds = suAlerts.map((x) => x.contractServiceUserAlert.id);
        const contractAlerts = (serviceUserDetails?.contractClientAlerts || []).filter(
            (cca) => !suAlertIds.some((sua) => sua === cca.id)
        );

        return contractAlerts.map((alert) => ({
            value: alert.id,
            label: alert.name
        }));
    }
);

const serviceUserHistory = (state: RootState) => state.serviceUsers.serviceUser.selectedServiceUserDetails?.history;

export const selectServiceUserHistory = createSelector(
    [serviceUserHistory, selectServiceUserIsLiveAccount],
    (history, isLiveAccount) => {
        // Don't show any history if this is the merged user and all records are empty. Needs to be more definitive than an object with empty arrays
        const hasAnyHistory =
            history && history.accountHistory.length && history.communicationHistory.length && history.orderHistory.length;

        // live and history = history
        // live and no history = history
        // archive and history = history
        // archive and no history = undefined
        return !isLiveAccount && !hasAnyHistory ? undefined : history;
    }
);

export const selectServiceUserHubDetails = (state: RootState) =>
    state.serviceUsers.serviceUser.selectedServiceUserDetails?.serviceHub;

export const selectInactiveReasonsList = (state: RootState) => state.serviceUsers.serviceUser.reasons;

/* reducer */
export default serviceUsersSlice.reducer;
