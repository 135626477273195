import { ENDPOINTS } from 'constants/api';
import { BASE_ACTIVITIES_SEARCH } from 'constants/pagination';
import { selectIsInternalPin } from 'features/auth-pin/auth-pin.slice';
import {
  ActivityBaseSummaryModel,
  ActivityFiltersResponseModel,
  ActivityReferenceLookupResponseModel,
  ActivitySearch,
  IApiResponse,
  mapApiErrors,
  PagedActivitiesResponse,
  selectActivityMasterServiceTypes,
  ServiceTypeCodeEnum
} from 'millbrook-core';
import qs from 'query-string';
import { getItem, getItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';

/* thunks */
const generateActivityParamsUrl = (getState: () => RootState, searchFilters: ActivitySearch, baseUrl: string) => {
  const appState = getState();

  const isInternalPin = selectIsInternalPin(appState);

  // Endpoint filters on pinUserId. Add current PIN user if required. Double check for CSA user that they will never be able to search by their PIN. The CSA shouldn't ever have filterByPin set here because they don't have an option to do that
  const searchByPin = searchFilters?.filterByPin && !isInternalPin;

  const formattedSearchFilters = {
    ...searchFilters,
    pinUserId: searchByPin ? appState.authPin.selectedPinUser : undefined
  };

  return qs.stringifyUrl(
    {
      url: baseUrl,
      query: {
        ...BASE_ACTIVITIES_SEARCH,
        ...formattedSearchFilters
      }
    },
    { skipEmptyString: true }
  );
};

export const fetchActivities =
  (searchFilters: ActivitySearch = {}): AppThunk =>
  async (dispatch, getState) => {
      const URL = generateActivityParamsUrl(getState, JSON.parse(JSON.stringify(searchFilters, (k, v) => v ?? undefined)), ENDPOINTS.ACTIVITIES.ACTIVITY_SEARCH);
      //JSON.stringify PB to remove nulls sent over in saved searches causing 400 errors
    return getItems<PagedActivitiesResponse>(URL).then((response) => {
      return response.result;
    });
  };

export const fetchActivitiesFilters =
  (searchFilters: ActivitySearch = {}): AppThunk =>
  async (dispatch, getState) => {
    const URL = generateActivityParamsUrl(getState, searchFilters, ENDPOINTS.ACTIVITIES.ACTIVITY_SEARCH_FILTERS);

    return getItems<IApiResponse<ActivityFiltersResponseModel>>(URL, {
      cacheName: 'activity-filters',
      cacheExpires: 2,
      disableFullPageLoader: true
    }).then((response) => {
      return response.result;
    });
  };

export const fetchOpenActivities = (): AppThunk => async (dispatch, getState) => {
  const appState = getState();

  const pinUserId = appState.authPin?.selectedPinUser;

  // CP2-2920 - PPMs are excluded from open activities
  const serviceTypes = selectActivityMasterServiceTypes(appState);
  const ppmServiceType = serviceTypes.find((x) => x.serviceTypeCode == ServiceTypeCodeEnum.PPM);

  if (pinUserId) {
    const URL = `${ENDPOINTS.ACTIVITIES.ACTIVITY_SEARCH}?${qs.stringify(
      {
        ...BASE_ACTIVITIES_SEARCH,
        pinUserId,
        openOrdersOnly: true,
        fetchAll: true,
        ...(ppmServiceType && { ignoredServiceTypeIds: [ppmServiceType.id] })
      },
      { skipEmptyString: true }
    )}`;

    return getItems<PagedActivitiesResponse>(URL, { disableFullPageLoader: true }).then(
      (response) => {
        return response.result?.data || [];
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  }
};

export const fetchActivityGuidByReference =
  (activityReference: string): AppThunk =>
  async (dispatch, getState) => {
    // this is used for exact matches of activity reference. Only called from the activity search panel.
    // return a guid which can be used to go straight to the activity details page
    return getItem<string, IApiResponse<ActivityReferenceLookupResponseModel>>(
      ENDPOINTS.ACTIVITIES.LOOKUP_BY_REFERENCE,
      activityReference
    ).then(
      (response) => {
        return response.result;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchMostRecentOrders = (): AppThunk => async () => {
  return getItems<IApiResponse<ActivityBaseSummaryModel[]>>(ENDPOINTS.ACTIVITIES.MOST_RECENT_ORDERS).then(
    (response) => {
      return response.result || [];
    }
  );
};
