import { ProductReview } from 'features/basket/basket.types';
import { ApiId, DeliveryTypeEnum, ServiceUserAddressFormData, YesNo } from 'millbrook-core';
import { AdditionalContactFormData } from 'pages/service-users/components/ContactForm/ContactForm';

export type CheckoutStepName = 'step0' | 'step1' | 'step2' | 'step3' | 'step4' | 'step5' | 'step6' | 'step7';

export enum CheckoutStep {
  BasketSummary = 0,
  Budget = 1,
  Contact = 2,
  Delivery = 3,
  Cte = 4,
  DeliverySpeed = 5,
  AdditionalDetails = 6,
  OrderReview = 7
}

export type StepStatus = 'completed' | 'in-progress' | 'not-started';

export interface CheckoutStepState<T> {
  status: StepStatus;
  notRequired?: boolean;
  data: T;
}

export interface Step0FormDataState {}

export interface Step1FormDataState {}

export interface Step2FormDataState {
  addedClientAlerts: ApiId[];
  removedClientAlerts: ApiId[];
  selectedAlternativeContact?: ApiId | null;
  newAlternativeContact?: AdditionalContactFormData;
  mobile?: string;
  landline?: string;
  emailAddress?: string;
  contactPreferences?: string[];
  alternativeContactNote?: string;
}

export interface Step3FormDataState {
  selectedAddress?: ApiId | null;
  newAddress?: ServiceUserAddressFormData;
  dogOnPremise?: YesNo;
  stepsLeadingUpToTheProperty?: string;
  parkingAvailable?: string;
  accessIssues?: string;
  keySafe?: YesNo;
  propertyTypeId?: string | null; // API might give null
  generalNotes?: string;
  // Files are handled independently as part of the activity medias in the initial form data.
  arrangeBooking?: YesNo;
}

export interface Step4FormDataState {
  acceptedProductCTEs: string[];
}

export interface Step5FormDataState {
  deliverStandardAndSpecialTogether?: YesNo;
  isJointVisitRequired?: YesNo;
  isTwoPersonDelivery?: string;

  deliveryType?: DeliveryTypeEnum;
  deliverySpeed?: string;
  deliveryDate?: string | null;
  contractServiceTimeSlotId?: string;

  specialActivityDeliveryType?: DeliveryTypeEnum;
  specialDeliverySpeed?: ApiId;
  specialDeliveryDate?: string | null;

  sameDeliveryAreaAccepted?: boolean;
}

export interface Step6FormDataState {
  authorisers?: ApiId[];
  careOutcomeId: ApiId;
  careOutcomeAlternativeId: ApiId;
  currentWeeklyCareCost?: string; // This is converted to number for the api request
  newWeeklyCareCost?: string; // This is converted to number for the api request
  // REFACTOR: there are two product reviews, one in basket types. Work out why they are different
  standardProductReviews: ProductReview[];
  specialProductReviews: ProductReview[];
  clinicalNotes?: string;
  authorisationNotes?: string;
  riskAssessmentNotes?: string;

  // Files are handled independently as part of the activity medias in the initial form data.

  /* special activity */
  specialActivityCareOutcomeId: ApiId;
  specialActivityCareOutcomeAlternativeId: ApiId;
  specialActivityCurrentWeeklyCareCost?: string; // This is converted to number for the api request
  specialActivityNewWeeklyCareCost?: string; // This is converted to number for the api request
  specialActivityAuthorisationNotes?: string;
  specialActivityAuthorisers?: ApiId[];
}

export interface Step7FormDataState {
  activityReference?: ApiId;
}
