import { yupResolver } from '@hookform/resolvers';
import { FormControl } from '@material-ui/core';
import { selectIsInternalPin } from 'features/auth-pin/auth-pin.slice';
import { selectOrderFormData } from 'features/search/search.slice';
import {
  activitySearchDateTypeDisplayNames,
  ActivitySearchDateTypeEnum,
  CheckboxField,
  DateField,
  ErrorBox,
  InputField,
  mapEnumToSelectOptions,
  mergeFormData,
  RadioField,
  StyledDivider,
  StyledGrid
} from 'millbrook-core';
import { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  defaultOrderSearchFormData,
  OrderSearchFormData,
  orderSearchFormValidation
} from './OrdersSearchForm.validation';

interface OrdersSearchFormProps {
  onSubmit: (data: OrderSearchFormData) => void;
}

export const ORDER_SEARCH_FORM_ID = 'order-search-form';

export const OrdersSearchForm: React.FC<OrdersSearchFormProps> = ({ onSubmit }) => {
  const orderFormData = useSelector(selectOrderFormData);
  const isInternalPin = useSelector(selectIsInternalPin);

  // Default to existing form values if available
  const { control, handleSubmit, errors, watch } = useForm<OrderSearchFormData>({
    defaultValues: mergeFormData(defaultOrderSearchFormData, orderFormData),
    resolver: yupResolver(orderSearchFormValidation)
  });

  const handleOrderSearch = (data: OrderSearchFormData) => {
    const { activityReference, activityReferenceExact } = data;

    if (activityReference && activityReferenceExact) {
      // ignore everything else if order is exact match
      onSubmit({ activityReference, activityReferenceExact });
    } else {
      onSubmit(data);
    }
  };

  const { activityReferenceExact, dateSearchType } = watch(['activityReferenceExact', 'dateSearchType']);

  return (
    <form id={ORDER_SEARCH_FORM_ID} onSubmit={handleSubmit(handleOrderSearch)} data-testid="order-form" noValidate>
      <ErrorBox error={(errors as any)?.atLeastOne} />

      <FormControl>
        <StyledGrid container>
          <StyledGrid item xs={12} sm={6}>
            <InputField
              name="activityReference"
              error={errors?.activityReference}
              control={control}
              label="Order number"
              placeholder="Please enter order number"
            />
          </StyledGrid>
          <StyledGrid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'end' }}>
            <CheckboxField
              control={control}
              label="Match exact reference"
              error={errors?.activityReferenceExact}
              name="activityReferenceExact"
            />
          </StyledGrid>
        </StyledGrid>
      </FormControl>

      {activityReferenceExact && <StyledDivider text="or" color="regalBlue" spacing={8} />}

      <FormControl>
        <StyledGrid container>
          {!isInternalPin && (
            <StyledGrid item xs={12}>
              <CheckboxField
                control={control}
                label="Show my orders only"
                error={errors?.filterByPin}
                name="filterByPin"
              />
            </StyledGrid>
          )}

          <StyledGrid item xs={12} sm={6}>
            <InputField
              name="serviceUserFirstName"
              error={errors?.serviceUserFirstName}
              control={control}
              label="Service user's first name"
              placeholder="Please enter first name"
            />
          </StyledGrid>

          <StyledGrid item xs={12} sm={6}>
            <InputField
              name="serviceUserLastName"
              error={errors?.serviceUserLastName}
              control={control}
              label="Service user's surname"
              placeholder="Please enter surname"
            />
          </StyledGrid>
          <StyledGrid item xs={12}>
            <RadioField
              name="dateSearchType"
              label="Dates"
              hideLegend
              options={mapEnumToSelectOptions(ActivitySearchDateTypeEnum, activitySearchDateTypeDisplayNames)}
              control={control}
              row
            />
          </StyledGrid>
          {dateSearchType !== ActivitySearchDateTypeEnum.NoDate && (
            <Fragment>
              <StyledGrid item xs={12} sm={6}>
                <DateField name="fromDate" error={errors?.fromDate} control={control} label="Date from" disableFuture />
              </StyledGrid>
              <StyledGrid item xs={12} sm={6}>
                <DateField name="toDate" error={errors?.toDate} control={control} label="Date to" disableFuture />
              </StyledGrid>
            </Fragment>
          )}
        </StyledGrid>
      </FormControl>
    </form>
  );
};
