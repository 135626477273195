import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import clsx from 'clsx';
import { MAIN_MENU } from 'constants/menu';
import { selectIsInternalPin } from 'features/auth-pin/auth-pin.slice';
import { selectProductsCatalogueSubMenu } from 'features/ui/menu.slice';
import {
  BaseMenuItem,
  BodyText,
  hasPermissions,
  Heading,
  MainMenuItem,
  StyledDrawer,
  StyledDrawerContent
} from 'millbrook-core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { mainMenuDrawerStyles } from './MainMenuDrawer.styles';

interface MainMenuDrawerProps {
  open: boolean;
  onClose: () => void;
}

const MainMenuDrawer = ({ open, onClose }: MainMenuDrawerProps) => {
  /* hooks */
  const productsCatalogueSubmenu = useSelector(selectProductsCatalogueSubMenu);
  const isInternalPin = useSelector(selectIsInternalPin);

  /* state */
  const [mainMenu, setMainMenu] = useState<MainMenuItem[]>([]);
  const [showSub, setShowSub] = useState(false);
  const [subMenuData, setSubMenuData] = useState<BaseMenuItem[]>();

  useEffect(() => {
    setShowSub(subMenuData ? true : false);
  }, [subMenuData]);

  useEffect(() => {
    const fullMenu = MAIN_MENU(isInternalPin, productsCatalogueSubmenu);
    const menu = fullMenu.filter((x) => hasPermissions(x.permissions));

    setMainMenu(menu);

    return () => {
      // NOTE: This is to attempt to reset the submenu when the pin user is switched.
      // This component will only become unmounted when the whole page changes, which seems to happen when the pin select is hit.
      // If this menu changes e.g. always want the menu showing with limited options, then something different will need to happen.
      setSubMenuData(undefined);
    };
  }, [productsCatalogueSubmenu, isInternalPin]);

  /* events */
  const closeSubMenu = () => {
    setShowSub(false);
    setTimeout(() => setSubMenuData(undefined), 600);
  };

  const handleMenuClick = (e: React.MouseEvent, menuItem: MainMenuItem) => {
    menuItem.onClick && menuItem.onClick();
    if (menuItem.subMenu) {
      e.preventDefault();
      // push the topLevel on the sub
      let subMenu: BaseMenuItem[] = [{ label: menuItem.label, href: menuItem.href }].concat(menuItem.subMenu);
      setSubMenuData(subMenu);
    } else {
      // there is no submenu, so it is assumed to be a straight link, so just close the menu
      onClose();
    }
  };

  /* values */
  const classes = mainMenuDrawerStyles();

  return (
    <StyledDrawer open={open} onClose={onClose}>
      <StyledDrawerContent>
        <Box className={clsx(classes.slidingMenu, showSub && classes.slidingMenuSub)}>
          <Box className={clsx(classes.drawerContentSlide, classes.drawerContentSlideMain)}>
            <List>
              {mainMenu.map((listItem) => (
                <ListItem
                  button
                  className={clsx(classes.menuItem, classes.mainMenuListItem)}
                  key={listItem.label}
                  component={RouterLink}
                  to={listItem.href}
                  onClick={(e: React.MouseEvent) => handleMenuClick(e, listItem)}
                >
                  <Heading component="span" type="h3" color="inherit">
                    {listItem.label}
                  </Heading>
                  <ArrowRightAltIcon fontSize="large" />
                </ListItem>
              ))}
            </List>
          </Box>
          {subMenuData && (
            <Box className={clsx(classes.drawerContentSlide, classes.drawerContentSlideSub)}>
              <List>
                <ListItem button onClick={closeSubMenu}>
                  <IconButton aria-label="back to menu" className={classes.subMenuClose} onClick={closeSubMenu}>
                    <ArrowRightAltIcon fontSize="large" />
                  </IconButton>
                  <Heading component="div" type="h3">
                    Back to menu
                  </Heading>
                </ListItem>
                {/* This is the top item that is the parent link of the sub-section */}
                {/* <ListItem
                  className={clsx(classes.menuItem, classes.subMenuItem, classes.subMenuItemParent)}
                  button
                  component={RouterLink}
                  to={subMenuData.href}
                  onClick={onClose}
                >
                  <BodyText color="inherit">{subMenuData.label}</BodyText>

                  <ArrowRightAltIcon />
                </ListItem> */}
                {/* The following items are the sub category children */}
                {subMenuData.map((item, index) => (
                  <ListItem
                    key={item.label}
                    className={clsx(
                      classes.menuItem,
                      classes.subMenuItem,
                      index ? classes.subMenuItemChild : classes.subMenuItemParent
                    )}
                    button
                    component={RouterLink}
                    to={item.href}
                    onClick={onClose}
                  >
                    <BodyText color="inherit">{item.label}</BodyText>
                    <ArrowRightAltIcon />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Box>
      </StyledDrawerContent>
    </StyledDrawer>
  );
};

export default MainMenuDrawer;
