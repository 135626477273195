import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { NUMBER_OF_NEWS_ADMIN_PER_PAGE } from 'constants/pagination';
import {
  ApiId,
  DATE_TIME_MIN,
  IApiPagination,
  IApiResponse,
  IDeleteRequest,
  IDocumentModel,
  ISearchPagination,
  IsoDate,
  mapApiErrors
} from 'millbrook-core';
import { serialize } from 'object-to-formdata';
import qs from 'query-string';
import { deleteItems, getItem, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';

export enum NewsStatusEnum {
  //[Description("Urgent")]
  Urgent = 1,
  //[Description("Non-Urgent")]
  NonUrgent = 2,
  //[Description("All")]
  All = Urgent | NonUrgent
}

export interface NewsFilters {
  newsStatusEnum?: NewsStatusEnum;
}

export interface NewsItem {
  id: ApiId;
  title: string;
  body: string;
  created?: IsoDate;
  modified?: IsoDate;
  createdByUser: string;
  updatedByUser: string;
  publishDate?: IsoDate;
  expires?: IsoDate;
  isUrgent: boolean;
  isUrgentUnread?: boolean;
  newsDocuments: IDocumentModel[];
}

export type NewsResults = IApiPagination<NewsItem[]>;

export type NewsResultsResponse = IApiResponse<NewsResults>;

export interface NewsItemRequest {
  id?: ApiId;
  title: string;
  body: string;
  publishDate: IsoDate;
  expires: IsoDate;
  isUrgent: boolean;
  files: FileList;
  filesToDelete?: ApiId[];
}

export const INITIAL_NEWS_PAGINATION = {
  pageIndex: 1,
  pageSize: NUMBER_OF_NEWS_ADMIN_PER_PAGE
};

interface NewsState {
  newsPanelList: NewsItem[];
  newsFlashList: NewsItem[];
}

const initialState: NewsState = {
  newsPanelList: [],
  newsFlashList: []
};

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNewsFlashList(state, action: PayloadAction<NewsItem[]>) {
      state.newsFlashList = action.payload;
    },
    setNewsPanelList(state, action: PayloadAction<NewsItem[]>) {
      state.newsPanelList = action.payload;
    },
    clearNewsFlashItems(state, action: PayloadAction<ApiId[]>) {
      state.newsFlashList = state.newsFlashList.filter((x) => !action.payload.includes(x.id));
    }
  }
});

export const { setNewsPanelList, setNewsFlashList, clearNewsFlashItems } = newsSlice.actions;

export const selectNewsPanelList = (state: RootState) => state.news.newsPanelList;
export const selectNewsFlashList = (state: RootState) => state.news.newsFlashList;

// thunks
export const fetchNewsItems =
  (
    searchFilters: NewsFilters & ISearchPagination = { ...INITIAL_NEWS_PAGINATION },
    isAdmin: boolean = false
  ): AppThunk =>
  async (dispatch) => {
    return getItems<NewsResultsResponse>(
      `${ENDPOINTS.NEWS[isAdmin ? 'NewsAdmin' : 'News']}?${qs.stringify(searchFilters, { skipEmptyString: true })}`
    ).then(
      (response) => {
        return response.result;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchNewsPanelItems =
  (pageSize: number = 3): AppThunk =>
  async (dispatch) => {
    return dispatch<any>(fetchNewsItems({ pageSize, pageIndex: 1 })).then((newssResults: NewsResults) => {
      dispatch(setNewsPanelList(newssResults.data));
    });
  };

export const fetchNewsFlashItems = (): AppThunk => async (dispatch) => {
  return getItems<IApiResponse<NewsItem[]>>(ENDPOINTS.NEWS.Urgent).then(
    (response) => {
      dispatch(setNewsFlashList(response?.result || []));
    },
    (response) => {
      const error = mapApiErrors(response);
      throw new Error(error);
    }
  );
};

export const deleteNewsFlash =
  (ids: ApiId[]): AppThunk =>
  async (dispatch) => {
    return postItems<ApiId[], null>(ENDPOINTS.NEWS.UrgentNewsRead, ids, {
      enableGlobalErrorDialog: true,
      disableFullPageLoader: true
    })
      .then(() => {
        return dispatch(clearNewsFlashItems(ids));
      })
      .catch(() => () => {
        // handled with global error handler
      });
  };

export const fetchNewsItem =
  (id: ApiId, isAdmin: boolean = false): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, IApiResponse<NewsItem>>(ENDPOINTS.NEWS[isAdmin ? 'NewsAdmin' : 'News'], id).then(
      (response) => {
        const { expires, ...rest } = (response.result || {}) as NewsItem;

        return { ...rest, expires: expires === DATE_TIME_MIN ? '' : expires };
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

// this handles post and put
export const updateNewsItem =
  (data: NewsItemRequest): AppThunk =>
  async (dispatch) => {
    const { files, ...rest } = data;

    const formData = serialize(rest, { indices: false, allowEmptyArrays: false });

    for (let i = 0; i < files.length; i++) {
      formData.append('formFileCollection', files[i]);
    }

    const request = data.id
      ? putItem<FormData, IApiResponse<NewsItem>>(ENDPOINTS.NEWS.News, formData, '')
      : postItems<FormData, IApiResponse<NewsItem>>(ENDPOINTS.NEWS.News, formData);

    return request.then(
      (response) => {
        //dispatch<any>(fetchHelpSections());
        return response.result;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const deleteNewsItem =
  (data: NewsItem): AppThunk =>
  async (dispatch) => {
    const { id } = data;

    return deleteItems<IDeleteRequest, null>(ENDPOINTS.NEWS.News, [id], { enableGlobalErrorDialog: true }).then(
      () => {
        // refetch handled on the page
      },
      () => {
        // handled with global error handler
      }
    );
  };

export default newsSlice.reducer;
