import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiId, IApiResponse, mapApiErrors, ServiceUser, Preferred2FaEnum } from 'millbrook-core';
import { AppThunk, RootState } from 'store/store';
import { ENDPOINTS } from 'constants/api';
import { postItems, getItem } from 'services/api.service';
import { ServiceHubAccessFormType } from 'features/service-hub/service-hub.validation';
import { ServiceUserResponse } from 'features/service-users/serviceUser.slice';

export interface ServiceHubUserRequest {
  id: ApiId;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  twoFaEnabled: boolean;
  preferred2FaMedium: Preferred2FaEnum;
}

interface ServiceHubState {
  available2FaMediums?: string[];
}

const initialState: ServiceHubState = {};

const serviceHubSlice = createSlice({
  name: 'serviceHub',
  initialState,
  reducers: {
    setAvailable2FaMediums(state, action: PayloadAction<string[] | undefined>) {
      return { ...state, available2FaMediums: action.payload };
    },
    clearServiceHubState() {
      return initialState;
    }
  }
});

const { setAvailable2FaMediums } = serviceHubSlice.actions;

export const mapServiceHubFormToRequest = (serviceUser: ServiceUser, form: ServiceHubAccessFormType) => {
  const { email, telephoneNumber, activate2Fa, preferred2Fa } = form;
  const { serviceUserId, details } = serviceUser;
  const { firstName, surname } = details;
  return {
    id: serviceUserId,
    firstName: firstName,
    lastName: surname,
    email: email,
    phoneNumber: telephoneNumber,
    twoFaEnabled: activate2Fa,
    preferred2FaMedium: preferred2Fa,
    isEnabled: true
  };
};

export const createServiceHubUser =
  (serviceUser: ServiceUser, formData: ServiceHubAccessFormType): AppThunk =>
  async (dispatch) => {
    const request = mapServiceHubFormToRequest(serviceUser, formData);
    return postItems<ServiceHubUserRequest, ServiceUserResponse>(ENDPOINTS.SERVICE_HUB.CREATE_USER, request).then(
      (response) => {
        return response.result;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const resetUserPassword =
  (serviceUser: ServiceUser): AppThunk =>
  async (dispatch) => {
    return postItems<ApiId, IApiResponse<boolean>>(
      ENDPOINTS.SERVICE_HUB.FORGOTTEN_USER_PASSWORD(serviceUser.serviceUserId),
      ''
    ).then(
      (response) => {
        return response.result;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const getAvailable2FaMediums =
  (id: ApiId): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, IApiResponse<string[]>>(ENDPOINTS.USERS.AVAILABLE_2FA_MEDIUMS(id), '').then(
      (response) => {
        dispatch(setAvailable2FaMediums(response.result));
        return response.result;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const enable2FA =
  (id: ApiId, medium: Preferred2FaEnum): AppThunk =>
  async (dispatch) => {
    return postItems<ApiId, IApiResponse<boolean>>(ENDPOINTS.USERS.ENABLE_2FA(id, medium), '').then(
      (response) => {
        console.log(response);
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const disable2FA =
  (id: ApiId): AppThunk =>
  async (dispatch) => {
    return postItems<ApiId, IApiResponse<boolean>>(ENDPOINTS.USERS.DISABLE_2FA(id), '').then(
      (response) => {
        console.log(response);
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const selectAvailable2FaMedia = (state: RootState) => state.serviceHub.available2FaMediums;

export default serviceHubSlice.reducer;
