import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ApiId, StyledButton, StyledDialog } from 'millbrook-core';
import ProductInstallationForm from './components/ProductInstallationForm/ProductInstallationForm';
import { formId } from './components/ProductInstallationForm/productInstallationForm.validation';

interface ProductInstallationDialogProps {
  productId: ApiId;
  isOpen: boolean | undefined;
  onClose: () => void;
  onSuccess: (notes: string, files: FileList) => void;
}

export const ProductInstallationDialog: React.FC<ProductInstallationDialogProps> = ({
  productId,
  isOpen,
  onClose,
  onSuccess
}) => {
  return (
    <StyledDialog
      headingTitle="Installation requirements"
      headingTitleIcon={<InfoOutlinedIcon />}
      open={!!isOpen}
      onClose={onClose}
      footerChild={
        <StyledButton form={formId} type="submit">
          Submit
        </StyledButton>
      }
    >
      <ProductInstallationForm productId={productId} onSuccess={onSuccess} />
    </StyledDialog>
  );
};
