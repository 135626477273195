import { Box, Link, makeStyles } from '@material-ui/core';
import { ROUTES } from 'constants/routes';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import {
  ContractProfessionalAssessmentOverview,
  ErrorBox,
  Page,
  PageIntro,
  ProductImageCard,
  StyledButton,
  StyledGrid
} from 'millbrook-core';
import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
// component types
interface ContractProfessionalAssessmentFormProps {
  contractProfessionalAssessmentOverviewList: ContractProfessionalAssessmentOverview[];
  onSuccess?: () => void;
}

const proAssessmentCardStyles = makeStyles((theme) => ({
  media: {
    height: 200,
    backgroundSize: 'contain',
    margin: theme.spacing(4, 2, 0)
  },
  content: {
    textAlign: 'left',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      border: '1px solid black',
      borderRadius: '5px'
    }
  },
  selectbutton: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  prod: {
    backgroundColor: 'transparent'
  }
}));

export const ProfessionalAssessmentAreasForm: React.FC<ContractProfessionalAssessmentFormProps> = ({
  contractProfessionalAssessmentOverviewList,
  onSuccess
}) => {
  const classes = proAssessmentCardStyles();

  /* hooks */
  const { handleSubmit, errors } = useForm();

  /* values */
  const CONTRACT_PROFESSIONAL_ASSESSMENT_FORM_ID = 'CONTRACT-PROFESSIONAL-ASSESSMENT-FORM';

  const handleClick = () => {
    onSuccess && onSuccess();
  };

  return (
    <form id={CONTRACT_PROFESSIONAL_ASSESSMENT_FORM_ID} onSubmit={handleSubmit(handleClick)} noValidate>
      <Page>
        <Box pt={1} pb={5}>
          <ErrorBox error={errors?.apiError} />
          <ErrorBox error={errors?.selectedReviewTypes} />
          <PageIntro title={'Assessment areas'} />
          <StyledGrid container>
            {contractProfessionalAssessmentOverviewList
              ?.filter((x) => x.contractProfessionalAssessmentId != null && x.inActive == false)
              .map((contractProfessionalAssessmentOverview: ContractProfessionalAssessmentOverview, index: number) => {
                return (
                  <Fragment key={contractProfessionalAssessmentOverview.professionalAssessmentId}>
                    <StyledGrid item xs={4} md={4} className={classes.content}>
                      <Box my={2}>
                        <StyledGrid item xs={12}>
                          <Link
                            component={RouterLink}
                            to={ROUTES.CONTRACT_PROFESSIONAL_ASSESSMENTS.assessment(
                              contractProfessionalAssessmentOverview.contractProfessionalAssessmentId
                            )}
                            title={'Start ' + contractProfessionalAssessmentOverview.title + ' assessment'}
                          >
                            <ProductImageCard
                              className={classes.prod}
                              image={contractProfessionalAssessmentOverview.image?.linkUrl}
                              title={'Start ' + contractProfessionalAssessmentOverview.title + ' assessment'}
                              ratio="widescreen"
                            />
                          </Link>
                        </StyledGrid>

                        <StyledButton
                          className={classes.selectbutton}
                          component={RouterLink}
                          to={ROUTES.CONTRACT_PROFESSIONAL_ASSESSMENTS.assessment(
                            contractProfessionalAssessmentOverview.contractProfessionalAssessmentId
                          )}
                          variant="link"
                          size="large"
                          title={'Start ' + contractProfessionalAssessmentOverview.title + ' assessment'}
                        >
                        {contractProfessionalAssessmentOverview.title} <ArrowRightAltIcon style={{marginLeft:15}} />
                        </StyledButton>
                      </Box>
                    </StyledGrid>
                  </Fragment>
                );
              })}
          </StyledGrid>
        </Box>
      </Page>
    </form>
  );
};
