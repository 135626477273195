import { yupResolver } from '@hookform/resolvers';
import { Box } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { selectHasProductsInBasket } from 'features/basket/basket.slice';
import { ServiceUsersSearchFormState } from 'features/search/search.slice';
import {
    DateField,
    ErrorBox,
    IconLabel,
    InputField,
    CheckboxField,
    serviceUserSchema,
    ServiceUserSchemaFormData,
    StyledDivider,
    StyledGrid,
    WarningLabel
} from 'millbrook-core';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { selectContractData } from '../../../contract/contract.slice';

const serviceUsersSearchFormValidation = yup.object().shape({
    idNumber: yup.string().transform((x) => (x || '').trim()),
    user: serviceUserSchema,
    userOrSocialNumber: yup.bool().when(['idNumber', 'user'], {
        is: (idNumber, user: ServiceUserSchemaFormData) => {
            return ((user.firstName || user.surname) && (user.dob || user.postcode)) || idNumber;
        },
        then: yup.bool(),
        otherwise: yup
            .bool()
            .required(
                'Enter Reference / NHS / Social ID number or provide first name and/or surname, and date of birth and/or postcode'
            )
    })
});

export type ServiceUsersSearchFormData = yup.InferType<typeof serviceUsersSearchFormValidation>;

export const SERVICE_USER_SEARCH_FORM_ID = 'service-user-search-form';

interface ServiceUsersSearchFormProps {
    onSubmit: (data: ServiceUsersSearchFormData) => void;
}

export const ServiceUsersSearchForm: React.FC<ServiceUsersSearchFormProps> = ({ onSubmit }) => {
    const hasBasketProducts = useSelector(selectHasProductsInBasket);
    const location = useLocation();
    const { soundexSearchServiceUserSurnameEnabled, soundexSearchServiceUserSurnameDefault } = useSelector(selectContractData);

    // This will pull the search data that was used in the SU results page, if we are on that page. Otherwise, it should just be blank
    const userSearchData = location.state as ServiceUsersSearchFormState | undefined;

    const { control, handleSubmit, errors } = useForm<ServiceUsersSearchFormData>({
        // TODO: hook into mergeFormData when other branches are synced
        defaultValues: {
            idNumber: userSearchData?.idNumber || '',
            user: {
                firstName: userSearchData?.user.firstName || '',
                surname: userSearchData?.user.surname || '',
                postcode: userSearchData?.user.postcode || '',
                dob: userSearchData?.user.dob || null,
                soundexSearch: soundexSearchServiceUserSurnameDefault
            }
        },
        resolver: yupResolver(serviceUsersSearchFormValidation)
    });

    return (
        <form id={SERVICE_USER_SEARCH_FORM_ID} onSubmit={handleSubmit(onSubmit)} noValidate>
            <ErrorBox error={errors?.userOrSocialNumber} />
            {hasBasketProducts && <WarningLabel message="Changing the service user will also clear your basket." />}
            <InputField
                name="idNumber"
                error={errors?.idNumber}
                control={control}
                label="Enter Service user Reference, NHS or Social ID number"
                placeholder="Please enter SU Ref / NHS / Social ID number"
            />
            <StyledDivider text="or" color="regalBlue" spacing={8} />
            <Box mb={3}>
                <IconLabel
                    icon={<InfoOutlinedIcon color="primary" />}
                    text="Please enter first name and/or surname, and date of birth and/or postcode"
                />
            </Box>
            <StyledGrid container>
                {soundexSearchServiceUserSurnameEnabled &&
                    <StyledGrid item xs={12} sm={12}>
                        <CheckboxField
                            name="user.soundexSearch"
                            error={errors?.user?.soundexSearch}
                            control={control}
                            label="Surname soundex search"
                            title="SOUNDEX is a tool that allows phonetic search, meaning that if you mispell the surname it will return results that are phonetically similar to the value you entered."
                        />
                    </StyledGrid>
                }
                <StyledGrid item xs={12} sm={6}>
                    <InputField
                        name="user.firstName"
                        error={errors?.user?.firstName}
                        control={control}
                        label="First name"
                        placeholder="Please enter first name"
                    />
                </StyledGrid>
                <StyledGrid item xs={12} sm={6}>
                    <InputField
                        name="user.surname"
                        error={errors?.user?.surname}
                        control={control}
                        label="Surname"
                        placeholder="Please enter surname"
                    />
                </StyledGrid>
                <StyledGrid item xs={12} sm={6}>
                    <DateField
                        name="user.dob"
                        error={errors?.user?.dob}
                        control={control}
                        label="Date of birth"
                        disableFuture
                        autoOk
                    />
                </StyledGrid>
                <StyledGrid item xs={12} sm={6}>
                    <InputField
                        name="user.postcode"
                        error={errors?.user?.postcode}
                        control={control}
                        label="Postcode"
                        placeholder="Please enter postcode"
                    />
                </StyledGrid>
            </StyledGrid>
        </form>
    );
};
