import { ENDPOINTS } from 'constants/api';
import { mapActivityProcurementNoteFormToRequest } from 'features/activities/mappers';
import {
  ActivityActionResponseModel,
  ActivityAlerts,
  ActivityDetailsModel,
  ActivityNote,
  ActivityProcurementNote,
  ActivityReasonCode,
  ActivitySearchResponseModel,
  ActivityStatusDisplayName,
  addActivityNote,
  addActivityProcurementNote,
  ApiId,
  IApiResponse,
  mapApiErrors,
  mapBasketItemsToActivityBasketItemModel,
  mapBasketItemsForSpecialReqActivity,
  setActivityAlerts,
  setActivityDetails,
  setActivityProcurementNotes,
  ServiceTypeCodeEnum
} from 'millbrook-core';
import { serialize } from 'object-to-formdata';
import { getItem, getItems, postItems } from 'services/api.service';
import { AppThunk } from 'store/store';
import { CreatePPMFormData } from '../../pages/service-users/ServiceUserDetails/components/ServiceUserEosCreatePPM/EosCreatePPM';
import {
  clearServiceUserDetails,
  ServiceUserResponse,
  setSelectedServiceUser
} from '../service-users/serviceUser.slice';
import { NewActivityNoteFormData } from './NewActivityNoteForm/NewActivityNoteForm';
import { NewOrderNoteFormData } from './NewOrderNoteForm/NewOrderNoteForm';

/* types */
export type ActivityResponse = IApiResponse<ActivityDetailsModel>;
export type ActivityProcurementNotesResponse = IApiResponse<ActivityProcurementNote[]>;
export type ActivitySummaryResponse = IApiResponse<ActivitySearchResponseModel>;

export type ActivityProcurementNoteRequest = Omit<
  ActivityProcurementNote,
  'id' | 'dateCreated' | 'dateUpdated' | 'user'
>;
export type ActivityProcurementNoteResponse = IApiResponse<ActivityProcurementNote>;
export type CreatePPMFormDataResponse = IApiResponse<CreatePPMFormData>;

export type ActivityNoteRequest = Omit<ActivityNote, 'id' | 'dateCreated' | 'dateUpdated' | 'createdBy'>;
export type ActivityNoteResponse = IApiResponse<ActivityNote>;

/* thunks */
export const fetchActivityDetails =
  (activityId: ApiId, includeProductStatus = false, keepIndividualItems = true): AppThunk =>
  async (dispatch) => {
    return getItems<ActivityResponse>(
      `${ENDPOINTS.ACTIVITIES.ACTIVITY}/${activityId}?includeProductStatus=${includeProductStatus}`
    ).then(
      (response) => {
        let activityDetails = response.result;

        if (activityDetails) {
          const { authorisationHistory: authHistory, contractServiceSpeed: speed, ...rest } = activityDetails;

          // map the auth history action to names for the table sorting
          const authorisationHistory = authHistory.map((a) => ({
            ...a,
            actionName: ActivityStatusDisplayName[a.action]
          }));

          activityDetails = { ...rest, authorisationHistory };

          if (activityDetails?.basket.basketItems) {
            if (speed?.serviceTypeCode === ServiceTypeCodeEnum.SpecialRequisition) {
              activityDetails.basket.basketItems = mapBasketItemsForSpecialReqActivity(activityDetails);
            } else if (!keepIndividualItems) {
              activityDetails.basket.basketItems = mapBasketItemsToActivityBasketItemModel(activityDetails);
            }
          }

          dispatch(setActivityDetails(activityDetails));
        }

        return activityDetails;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchActivityAlerts =
  (activityId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<IApiResponse<ActivityAlerts>>(ENDPOINTS.ACTIVITIES.ALERTS(activityId)).then(
      (response) => {
        dispatch(setActivityAlerts(response.result));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchAllActivityDetails =
  (activityId: ApiId, includeProductStatus = false): AppThunk =>
  async (dispatch, getState) => {
    return Promise.all([
      dispatch<any>(fetchActivityDetails(activityId, includeProductStatus)),
      dispatch<any>(fetchActivityAlerts(activityId))
    ]).catch((response) => {
      const error = mapApiErrors(response);
      throw new Error(error);
    });
  };

export const fetchActivitySummary =
  (activityId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<ActivitySummaryResponse>(ENDPOINTS.ACTIVITIES.ACTIVITY_SUMMARY(activityId)).then((response) => {
      return response.result;
    });
  };

export const fetchActivityUpdatedActions =
  (activityId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<IApiResponse<Record<ApiId, ActivityActionResponseModel[]>>>(
      ENDPOINTS.ACTIVITIES.ACTIVITY_LAST_FIVE_ACTIONS + `?activityIds=${activityId}`,
      // Short cache just for flicking between activities. Adding longer cache will mean you need to invalidate this cache for every activity update in the system.
      { cacheName: 'activity-updates', cacheExpires: 0.5, disableFullPageLoader: true }
    ).then(
      (response) => {
        return (response.result && response.result[activityId]) || [];
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchActivityProcurementNotes =
  (activityId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<ActivityProcurementNotesResponse>(
      `${ENDPOINTS.ACTIVITIES.ACTIVITY}/${activityId}/ProcurementNotes`,
      {
        disableFullPageLoader: true
      }
    ).then(
      (response) => {
        dispatch(setActivityProcurementNotes(response.result || []));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const createActivityProcurementNotes =
  (procurementNote: NewOrderNoteFormData, activityId: ApiId): AppThunk =>
  async (dispatch) => {
    return postItems<ActivityProcurementNoteRequest, ActivityProcurementNoteResponse>(
      `${ENDPOINTS.ACTIVITIES.ACTIVITY}/${activityId}/ProcurementNotes`,
      mapActivityProcurementNoteFormToRequest(procurementNote, activityId)
    )
      .then((response) => {
        if (response.result) {
          dispatch(addActivityProcurementNote(response.result));
        }
      })
      .catch((response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      });
  };

export const createEoSPPMActivity =
  (createPPMFormData: CreatePPMFormData): AppThunk =>
  async (dispatch) => {
    await dispatch<any>(clearServiceUserDetails());
    return postItems<CreatePPMFormData, ServiceUserResponse>(
      `${ENDPOINTS.ACTIVITIES.ACTIVITY}/CreateEoSPPMActivity`,
      createPPMFormData
    )
      .then((response) => {
        if (response.result) {
          dispatch(setSelectedServiceUser(response.result));
          return response.result;
        }
      })
      .catch((response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      });
  };

export const createActivityNotes =
  (activityNote: NewActivityNoteFormData): AppThunk =>
  async (dispatch) => {
    const { files, ...rest } = activityNote;

    const formData = serialize(rest, { indices: false, allowEmptyArrays: false });

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    return postItems<FormData, ActivityNoteResponse>(ENDPOINTS.ACTIVITIES.ACTIVITY_NOTES, formData)
      .then((response) => {
        if (response.result) {
          dispatch(addActivityNote(response.result));
        }
      })
      .catch((response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      });
        };

export const generatePPMLetter =
    (activityId: ApiId): AppThunk =>
        async (dispatch) => {
            return postItems<ApiId, ActivityNoteResponse>(ENDPOINTS.LETTERS.PPMLetter_Create(activityId), activityId)
                .then((response) => {
                    if (response.result) {
                        return response.result;
                    }
                })
                .catch((response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                });
        };

export const getLatestReasonCodeForActivity =
  (activityId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, IApiResponse<ActivityReasonCode>>(ENDPOINTS.ACTIVITIES.GET_LATEST_REASON_CODE(activityId))
      .then((response) => {
        return response.result;
      })
      .catch((response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      });
  };
