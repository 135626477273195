import {
  mapAdditionalContactFormDataToServiceUserAdditionalContactModel,
  mapServiceUserAddressFormDataToServiceUserAddressFormData
} from 'features/checkout/mappers';
import { differenceBy } from 'lodash';
import {
  ApiId,
  BasketItem,
  IContractClientAlert,
  IServiceUserClientAlert,
  mapYesNoToBooleanOrReturnValue,
  ProductSyncStatusEnum,
  ServiceUserAddressFormData,
  ServiceUserAddressModel,
  ServiceUserAddressUpdateModel
} from 'millbrook-core';
import { ActivityDeliveryDetailsFormData } from 'pages/Activities/EditActivityDeliveryDetailPage/components/ActivityDeliveryDetailsForm/ActivityDeliveryDetailsForm.validation';
import { AdditionalContactFormData } from 'pages/service-users/components/ContactForm/ContactForm';
import { ActivityNoteRequest, ActivityProcurementNoteRequest } from './activity.slice';
import { ActivityDeliveryDetailsUpdateModel } from './activity.types';
import { NewActivityNoteFormData } from './NewActivityNoteForm/NewActivityNoteForm';
import { NewOrderNoteFormData } from './NewOrderNoteForm/NewOrderNoteForm';

export const mapActivityProcurementNoteFormToRequest = (
  procurementNote: NewOrderNoteFormData,
  activityId: ApiId
): ActivityProcurementNoteRequest => {
  return {
    ...procurementNote,
    activityId,
    notifyProcurement: true
  };
};

export const mapActivityNoteFormToRequest = (
  activityNote: NewActivityNoteFormData,
  activityId: ApiId
): ActivityNoteRequest => {
  return {
    ...activityNote,
    activityId
  };
};

export const mapDeliveryDetailsUpdateFormToRequest = (
  activityDeliveryDetailsFormData: ActivityDeliveryDetailsFormData,
  activityId: ApiId,
  newAddress?: ServiceUserAddressFormData,
  selectedAddress?: ServiceUserAddressUpdateModel,
  newAlternativeContact?: AdditionalContactFormData,
  selectedAlternativeContact?: ApiId,
  modifiedClientAlerts: IContractClientAlert[] = [],
  serviceUserClientAlerts: IServiceUserClientAlert[] = []
): ActivityDeliveryDetailsUpdateModel => {
  const {
    arrangeBooking,
    accessIssues,
    dogOnPremise,
    keySafe,
    parkingAvailable,
    stepsLeadingUpToTheProperty,
    propertyTypeId,
    // these aren't needed in the post
    dob,
    primaryContactNumberCode,
    contractServiceUserGroup,
    ...formDataRest
  } = activityDeliveryDetailsFormData;

  if (selectedAddress) {
    // merge updates with server data
    selectedAddress = {
      ...selectedAddress,
      accessIssues,
      dogOnPremise: mapYesNoToBooleanOrReturnValue(dogOnPremise, undefined),
      keySafe: mapYesNoToBooleanOrReturnValue(keySafe, undefined),
      parkingAvailable,
      stepsLeadingUpToTheProperty,
      address: {
        ...selectedAddress.address,
        propertyTypeId: propertyTypeId || null
      }
    };
  }

  let addressToAdd: ServiceUserAddressModel | undefined = undefined;

  if (newAddress) {
    addressToAdd = mapServiceUserAddressFormDataToServiceUserAddressFormData(newAddress);

    if (addressToAdd) {
      addressToAdd = {
        ...addressToAdd,
        accessIssues,
        dogOnPremise: mapYesNoToBooleanOrReturnValue(dogOnPremise, undefined),
        keySafe: mapYesNoToBooleanOrReturnValue(keySafe, undefined),
        parkingAvailable,
        stepsLeadingUpToTheProperty,
        address: {
          ...addressToAdd.address,
          propertyTypeId: propertyTypeId || null
        }
      };
    }
  }

  // added alerts
  const selectedContractClientAlerts = differenceBy(
    modifiedClientAlerts,
    serviceUserClientAlerts.map((x) => x.contractServiceUserAlert),
    'id'
  ).map((x) => x.id);

  // removed alerts use the serviceUserClientAlertId
  const serviceUserClientAlertsToRemove = serviceUserClientAlerts
    .filter(
      (origAlert) => !modifiedClientAlerts.find((modAlert) => modAlert.id === origAlert.contractServiceUserAlert.id)
    )
    .map((x) => x.id);

  return {
    activityId,
    telephoneToServiceUserNeeded: mapYesNoToBooleanOrReturnValue(arrangeBooking, false),
    selectedServiceUserAddress: selectedAddress,
    newServiceUserAddress: addressToAdd,
    selectedAdditionalContact: selectedAlternativeContact,
    newServiceUserAdditionalContact: newAlternativeContact
      ? mapAdditionalContactFormDataToServiceUserAdditionalContactModel(newAlternativeContact)
      : undefined,
    selectedContractClientAlerts,
    serviceUserClientAlertsToRemove,
    ...formDataRest
  };
};

export const hasBasketItemsNotSynced = (basketItems: BasketItem[] = []) => {
  const hasUnsyncedItems = basketItems.some(
    // productSyncStatus of null means the product needs synced
    (item) =>
      item.productSyncStatus === null ||
      (item.productSyncStatus !== ProductSyncStatusEnum.Synced &&
        item.productSyncStatus !== ProductSyncStatusEnum.SyncNotNeeded)
  );

  return hasUnsyncedItems;
};
