import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActivitySearch, ActivityStatus, IsoDate } from 'millbrook-core';
import { RootState } from 'store/store';
import { defaultOrderSearchFormData } from './components/OrdersSearchForm/OrdersSearchForm.validation';
import { ProductSearchFormData } from './components/ProductSearchForm/ProductSearchForm';

const defaultStatuses = [
  ActivityStatus.PendingAuthorisation,
  ActivityStatus.Authorised,
  ActivityStatus.Declined,
  ActivityStatus.OnHold,
  ActivityStatus.AwaitingScheduling,
  ActivityStatus.Scheduled,
  ActivityStatus.Routed,
  ActivityStatus.Picked,
  ActivityStatus.Despatched,
  ActivityStatus.AwaitingSupplier,
  ActivityStatus.NoAuthorisationRequired,
  ActivityStatus.DeliverySpeedChanged,
  ActivityStatus.ProductSwapped,
  ActivityStatus.ProductCancelled,
  ActivityStatus.Received,
  ActivityStatus.AwaitingProcurement,
  ActivityStatus.AuthoriserRequestedInfo,
  ActivityStatus.PrescriberProvidedInfoToAuthoriser,
  ActivityStatus.Failed_CARESError,
  ActivityStatus.Failed_SnapError,
  ActivityStatus.Failed_StreamError
];

export interface ServiceUsersSearchFormState {
  idNumber: string;
  serviceUserReference: string;
  user: {
    firstName?: string;
    surname?: string;
    postcode?: string;
    dob?: IsoDate;
    soundexSearch?: boolean;
  };
}

export enum SearchTypes {
  Product = 0,
  Order = 1,
  User = 2
}

interface SearchState {
  // TODO: not storing product search in the store anymore, using querystrings, so this can probably be removed
  products?: ProductSearchFormData;
  orders: ActivitySearch;
  productCount?: number;
  ordersCount?: number;
  usersCount?: number;
  searchOpen: boolean;
  hideTabs: boolean;
  isSwap: boolean;
  tabIndex: SearchTypes;
}

const defaultOrderSearch = { ...defaultOrderSearchFormData, allActivities: true };

export const initialSearchState: SearchState = {
  orders: defaultOrderSearch,
  searchOpen: false,
  hideTabs: false,
  isSwap: false,
  tabIndex: SearchTypes.Product
};

const SearchsSlice = createSlice({
  name: 'search',
  initialState: initialSearchState,
  reducers: {
    setProductsForm(state, action: PayloadAction<ProductSearchFormData>) {
      state.products = action.payload;
    },
    setOrdersForm(state, action: PayloadAction<ActivitySearch>) {
      state.orders = { ...action.payload, allActivities: true };
    },
    setOrdersFormFilterByPin(state, action: PayloadAction<boolean>) {
      // this should reset the order search, not just set the filterByPin open. All usages of this dispatch are tied to a link to the activities page
      // also going to the add the "open" order statuses for the filters
      state.orders = {
        ...defaultOrderSearchFormData,
        filterByPin: action.payload,
        allActivities: true,
        statuses: defaultStatuses
      };
    },
    setProductsCount(state, action: PayloadAction<number>) {
      state.productCount = action.payload;
    },
    setOrdersCount(state, action: PayloadAction<number>) {
      state.ordersCount = action.payload;
    },
    setUsersCount(state, action: PayloadAction<number>) {
      state.usersCount = action.payload;
    },
    // generic search open
    setOpenSearchDialog(state) {
      state.tabIndex = SearchTypes.Product;
      state.searchOpen = true;
      state.hideTabs = false;
      // extrareducer in basket.slice.ts
    },
    // search dialog in user search tab
    // the action is used in the extraReducers in basket slice
    setOpenUserSearchDialog(state, action: PayloadAction<string | boolean | undefined>) {
      state.tabIndex = SearchTypes.User;
      state.searchOpen = true;
      state.hideTabs = true;
      // extrareducer in basket.slice.ts where the payloadaction is consumed
    },
    // search dialog in product search tab
    setOpenProductSearchDialog(state) {
      state.tabIndex = SearchTypes.Product;
      state.searchOpen = true;
      state.hideTabs = true;
    },
    // search dialog when swapping item
    setOpenSwapProductSearchDialog(state) {
      state.tabIndex = SearchTypes.Product;
      state.searchOpen = true;
      state.hideTabs = true;
      state.isSwap = true;
    },
    // search dialog in order search tab
    setOpenOrderSearchDialog(state) {
      state.tabIndex = SearchTypes.Order;
      state.searchOpen = true;
      state.hideTabs = true;
    },
    setCloseSearchDialog(state) {
      state.searchOpen = false;
      state.isSwap = false;
    },
    setDialogTabIndex(state, action: PayloadAction<SearchTypes>) {
      state.tabIndex = action.payload;
    }
  }
});

/* actions */

export const {
  setProductsForm,
  setOrdersForm,
  setOrdersFormFilterByPin,
  setOrdersCount,
  setProductsCount,
  setUsersCount,
  setCloseSearchDialog,
  setOpenOrderSearchDialog,
  setOpenProductSearchDialog,
  setOpenSwapProductSearchDialog,
  setOpenSearchDialog,
  setOpenUserSearchDialog,
  setDialogTabIndex
} = SearchsSlice.actions;

/* selectors */

export const selectDialogState = (state: RootState) => state.search.searchOpen;
export const selectDialogTabsHidden = (state: RootState) => state.search.hideTabs;
export const selectDialogTabIndex = (state: RootState) => state.search.tabIndex;
export const selectDialogIsSwap = (state: RootState) => state.search.isSwap;
export const selectOrderFormData = (state: RootState) => state.search.orders;
export const selectProductFormData = (state: RootState) => state.search.products;

export default SearchsSlice.reducer;
