import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, ISelectOption, mapApiErrors, PinGroup, PinGroupResponse } from 'millbrook-core';
import { getItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';

interface GroupsState {
  groupList: PinGroup[];
}

const initialState: GroupsState = { groupList: [] };

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setGroupList(state, action: PayloadAction<PinGroup[]>) {
      state.groupList = action.payload;
    }
  }
});

export const fetchGroups =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<PinGroupResponse>(ENDPOINTS.CONTRACT.BUDGET_PIN.GROUPS(contractId)).then(
      (response) => {
        dispatch(setGroupList(response.result || []));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/* actions */
export const { setGroupList } = groupsSlice.actions;

/* selectors */
export const selectGroupList = (state: RootState) => state.budgetPin.groups.groupList;

export const getGroupSelectOptions = createSelector([selectGroupList], (groupList): ISelectOption[] => {
  return groupList.map((item) => {
    return { label: item.name, value: item.id };
  });
});

/* reducers */
export default groupsSlice.reducer;
