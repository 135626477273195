import { Box } from '@material-ui/core';
import { deleteRecycledProductReservation } from 'features/products/products.slice';
import { BodyText, ConfirmDialog, IContractProductSummary, StyledButton, StyledDialog } from 'millbrook-core';
import { useDispatch } from 'react-redux';
import {
  ProductReservationForm,
  PRODUCT_RESERVATION_FORM
} from './components/ProductReservationForm/ProductReservationForm';

interface ProductReservationProps {
  product: IContractProductSummary;
  reserving?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: (isUnReserved?: boolean) => void;
}

export const ProductReservation: React.FC<ProductReservationProps> = ({
  product,
  reserving,
  isOpen,
  onClose,
  onSuccess
}) => {
  /* hooks */
  const dispatch = useDispatch();

  if (!product) {
    return null;
  }

  /* events */
  const handleUnreserve = () => {
    dispatch<any>(deleteRecycledProductReservation(product.contractProductId)).then(() => {
      onSuccess && onSuccess(true);
    });
  };

  return (
    <>
      <StyledDialog
        headingTitle="Reserve item"
        open={isOpen && !!reserving}
        onClose={onClose}
        footerChild={
          <StyledButton form={PRODUCT_RESERVATION_FORM} type="submit">
            Reserve
          </StyledButton>
        }
      >
        <Box mb={4}>
          <BodyText type="regular">Reserving {product.name}</BodyText>
        </Box>
        <ProductReservationForm productId={product.contractProductId} onSuccess={onSuccess} />
      </StyledDialog>
      <ConfirmDialog
        open={isOpen && !reserving}
        onClose={onClose}
        title="Unreserve item"
        message={`Are you sure you want to unreserve ${product.name}?`}
        onConfirm={handleUnreserve}
      />
    </>
  );
};
