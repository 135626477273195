import { makeStyles } from '@material-ui/core';
import { COLORS } from 'millbrook-core';

export const productCompatibleWithCardStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  productContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderTop: `1px solid ${COLORS.border}`
  },
  image: {
    width: 116,
    marginRight: theme.spacing(3)
  },
  description: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  link: { textDecoration: 'none' }
}));
