import { addMonths, format } from 'date-fns';
import { ActivitySearchDateTypeEnum, dateValidationSchema, SHORT_UTC_DATE_FORMAT } from 'millbrook-core';
import * as yup from 'yup';

export const orderSearchFormValidation = yup
  .object()
  .shape({
    filterByPin: yup.boolean().notRequired(),
    activityReference: yup.string().notRequired(),
    activityReferenceExact: yup.bool().notRequired(),
    serviceUserFirstName: yup.string().notRequired(),
    serviceUserLastName: yup.string().notRequired(),
    dateSearchType: yup.number().notRequired(),
    fromDate: dateValidationSchema().notRequired(),
    toDate: dateValidationSchema().notRequired()
  })
  .test(
    'atLeastOne',
    'You must provide at least one field',
    (value) =>
      !!(
        value.activityReference ||
        value.serviceUserFirstName ||
        value.serviceUserLastName ||
        value.fromDate ||
        value.toDate
      )
  );

export type OrderSearchFormData = yup.InferType<typeof orderSearchFormValidation>;

export const defaultOrderSearchFormData: OrderSearchFormData = {
  filterByPin: false,
  activityReference: '',
  activityReferenceExact: true,
  serviceUserFirstName: '',
  serviceUserLastName: '',
  dateSearchType: ActivitySearchDateTypeEnum.OrderDate,
  fromDate: format(addMonths(new Date(), -6), SHORT_UTC_DATE_FORMAT),
  toDate: format(new Date(), SHORT_UTC_DATE_FORMAT)
};
