import { yupResolver } from '@hookform/resolvers';
import { Box, Card } from '@material-ui/core';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import WavesIcon from '@material-ui/icons/Waves';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import { RecycledItemTag } from 'components/Cards/ProductCard';
import { BREADCRUMB_LABELS } from 'constants/menu';
import { ROUTES } from 'constants/routes';
import { selectCanAddToBasket } from 'features/auth-pin/auth-pin.slice';
import { selectBasketItems } from 'features/basket/basket.selector';
import {
    addToBasket,
    clearAddToBasketReturnProduct,
    selectHasSelectedServiceUser,
    selectServiceUserAddToBasketProduct
} from 'features/basket/basket.slice';
import { selectContractData, selectTelephoneNumber } from 'features/contract/contract.slice';
import { fetchProduct } from 'features/products/products.slice';
import { setProductsCataloguesCurrentCategory } from 'features/products/productsCategories.slice';
import { selectContractProfessionalAssessment } from 'features/professionalAssessments/contractProfessionalAssessments.slice';
import { useReserveProductDialog } from 'hooks/useReserveProductDialog';
import {
    ApiId,
    BodyText,
    ButtonsList,
    DataTable,
    DisplayAsMoney,
    GenericPageError,
    Heading,
    IconLabel,
    IContractProduct,
    IContractProductSummary,
    ImageCarousel,
    ImageCarouselSection,
    InputField,
    LabelName,
    Page,
    ProductDetailsAdditionalInfo,
    ShelfTypeEnum,
    SpacingSection,
    StyledBreadcrumb,
    StyledButton,
    StyledDialog,
    StyledDivider,
    StyledGrid,
    StyledLink,
    TelephoneLink,
    UploadFile360,
    WarningLabel
} from 'millbrook-core';
import Image360Viewer from 'millbrook-core/lib/components/elements/Image360Viewer/Image360Viewer';
import { QuantityFormData, quantitySchema } from 'pages/checkout/components/BasketSummaryProduct/BasketSummaryProduct';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LinkedProducts } from '../components/LinkedProducts/LinkedProducts';
import { ProductInstallationDialog } from '../components/ProductInstallationDialog/ProductInstallationDialog';
import { ProductReservation } from '../components/ProductReservation/ProductReservation';
import { productDetailsStyles } from './ProductDetailsPage.styles';

interface Params {
    productCategoryId: ApiId;
    productId: ApiId;
    contractProfessionalAssessmentId: ApiId;
}

const ProductDetailsPage: React.FC<any> = () => {
    /* hooks */
    let { productCategoryId, productId, contractProfessionalAssessmentId } = useParams<Params>();
    const dispatch = useDispatch();
    const classes = productDetailsStyles();
    const contractTelephone = useSelector(selectTelephoneNumber);
    const basketItems = useSelector(selectBasketItems);
    const hasSelectedServiceUser = useSelector(selectHasSelectedServiceUser);
    const canAddToBasket = useSelector(selectCanAddToBasket);
    const { displayStockLevels } = useSelector(selectContractData);

    // check to see if this product needs to be added automatically from a previous click on "add to basket" as was redirected because there was no service user.
    const serviceUserAddToBasketProduct = useSelector(selectServiceUserAddToBasketProduct);

    // This page can be accessed from the professional assessment page. Need to handle the breadcrumbs
    const contractProfessionalAssessment = useSelector(selectContractProfessionalAssessment);

    const { reserveDialogIsOpen, reserving, handleStartReserveItem, handleStartUnreserveItem, handleCloseReserveItem } =
        useReserveProductDialog();

    const { control, errors, handleSubmit, register, setValue } = useForm<QuantityFormData>({
        defaultValues: { quantity: 1 },
        resolver: yupResolver(quantitySchema)
    });

    /* state */
    const [product, setProduct] = useState<IContractProduct>({} as IContractProduct);
    const [relatedProductsList, setRelatedProductsList] = useState<IContractProductSummary[]>([]);
    const [compatibleProductsList, setCompatibleProductsList] = useState<IContractProductSummary[]>([]);
    const [error, setError] = useState(false);
    const [installationDialogIsOpen, setInstallationDialogIsOpen] = useState<boolean>();
    const [listOfFiles, setListOfFiles] = useState<UploadFile360[]>([]);
    const [viewPreview, setViewPreview] = useState<boolean>(false);

    /* effects */
    useEffect(() => {
        productCategoryId && dispatch(setProductsCataloguesCurrentCategory(productCategoryId));
    }, [dispatch, productCategoryId]);

    useEffect(() => {
        // remove related and compatible products that are RSPs and have been added to the basket
        const basketIds = basketItems.map((x) => x.contractProductId);
        const relatedProducts = (product?.relatedProductsList || []).filter(
            (p) => !(p.shelfType === ShelfTypeEnum.RecycledSpecial && basketIds.indexOf(p.contractProductId) !== -1)
        );

        const compatibleProducts = (product?.compatibleProductsList || []).filter(
            (p) => !(p.shelfType === ShelfTypeEnum.RecycledSpecial && basketIds.indexOf(p.contractProductId) !== -1)
        );

        setRelatedProductsList(relatedProducts);
        setCompatibleProductsList(compatibleProducts);
    }, [product, basketItems]);

    const setPage = useCallback((product: IContractProduct) => {
        setProduct(product);
    }, []);

    const breadcrumb = useMemo(() => {
        const productName = product.name ?? 'Product';

        if (contractProfessionalAssessmentId) {
            return {
                [ROUTES.CONTRACT_PROFESSIONAL_ASSESSMENTS.root]: 'Professional assessment areas',
                [ROUTES.CONTRACT_PROFESSIONAL_ASSESSMENTS.assessment(
                    contractProfessionalAssessmentId
                )]: `Professional assessment${contractProfessionalAssessment?.title ? ' - ' + contractProfessionalAssessment.title : ''
                    }`,
                last: productName
            };
        }

        return {
            [ROUTES.PRODUCT.catalogue]: BREADCRUMB_LABELS[ROUTES.PRODUCT.catalogue],
            final: productName
        };
    }, [product, contractProfessionalAssessmentId, contractProfessionalAssessment]);

    const handleFetchProduct = useCallback(
        (productId: ApiId) => {
            dispatch<any>(fetchProduct(productId))
                .then((response: IContractProduct) => {
                    setPage(response);
                })
                .catch(() => {
                    setError(true);
                });
        },
        [dispatch, setPage]
    );

    useEffect(() => {
        handleFetchProduct(productId);
    }, [dispatch, productId, handleFetchProduct]);

    /* events */
    const handleAddToBasket = useCallback(
        async (quantity: number, notes?: string, files?: FileList) => {
            dispatch<any>(addToBasket(product, quantity, notes, files)).then((success: boolean) => {
                handleFetchProduct(productId);
            });
        },
        [dispatch, product, productId, handleFetchProduct]
    );

    useEffect(() => {
        if (product && product.product360Images && product.product360Images.length > 0) {
            const newListOfFiles: UploadFile360[] = product.product360Images.map((image) => {
                // Create a new UploadFile360 object and map the values
                const newFile: UploadFile360 = {
                    id: image.name,
                    name: image.name,
                    thumbnail: image.linkUrl
                };
                return newFile;
            });

            // Add the new UploadFile360 objects to the list
            setListOfFiles(newListOfFiles);
        }
    }, [product]);

    // this function exists so we can call it directly without hitting the validation when we are returning from a previous "add to basket"
    // there seems to be a race condition between the useEffect and the react hook form, so trying to get around that here by splitting up the methods
    const handleAddAfterValidate = useCallback(
        (quantity: number) => {
            // If product requires installation, show installation dialog with additional questions
            // check there is a service user first
            if (hasSelectedServiceUser && product.requiresInstallationNotes) {
                setInstallationDialogIsOpen(true);
            } else {
                handleAddToBasket(quantity);
            }
        },
        [product, handleAddToBasket, hasSelectedServiceUser]
    );

    const handleAddClick = useCallback(() => {
        handleSubmit((data: QuantityFormData) => {
            handleAddAfterValidate(data.quantity);
        })();
    }, [handleAddAfterValidate, handleSubmit]);

    const handleCloseInstallationDialog = () => {
        setInstallationDialogIsOpen(false);
    };

    const handleReservationSuccess = (isUnReserved?: boolean) => {
        setProduct({ ...product, isReserved: !isUnReserved, isReservedForUser: !isUnReserved });

        handleCloseReserveItem();
    };

    // useEffect to auto add to basket when the user is returning from a service user lookup. The "continueWithOrder" property is set in the serviceuserrecordcard
    // and that should be the only entrance into here.
    useEffect(() => {
        if (
            serviceUserAddToBasketProduct &&
            serviceUserAddToBasketProduct.productId === product.contractProductId &&
            serviceUserAddToBasketProduct.continueWithOrder
        ) {
            // jump straight to the add to basket methods, don't need to resubmit the form.
            handleAddAfterValidate(serviceUserAddToBasketProduct.quantity);
            // need some jank to set the value, because otherwise it isn't updating (it's only for the appearance, we aren't using the form for the add-to-basket-try-again functionality)
            setTimeout(() => setValue('quantity', serviceUserAddToBasketProduct.quantity), 0);
            dispatch(clearAddToBasketReturnProduct());
        }
    }, [serviceUserAddToBasketProduct, product, dispatch, setValue, handleAddAfterValidate]);

    /* values */

    const {
        requiresInstallationNotes,
        shelfType,
        isReserved,
        isReservedForUser,
        isPooled,
        isAuthorisationRequired,
        quantityInCleaning,
        closeTechnicalEquivalents,
        anyCteConfigured,
        supplierLeadTime,
        stockOnOrder,
        isAvailableToOrder,
        /*isMasterAvailableToOrder,*/
        canRspBeAddedToBasket
    } = product;

    const quantity = Math.max(product.quantity, 0);

    const isRecycledSpecial = shelfType === ShelfTypeEnum.RecycledSpecial;
    const isReservable = isRecycledSpecial && !isReserved;
    const isProductReserved = isRecycledSpecial && isReserved;
    const isReservedByLoggedUser = isRecycledSpecial && isReservedForUser;
    const isReservedByOtherUser = isProductReserved && !isReservedByLoggedUser;
    const canOrder = isAvailableToOrder; //&& isMasterAvailableToOrder;

    const recycledSpecialInBasket = useMemo(() => {
        if (isRecycledSpecial) {
            return basketItems.some((b) => b.contractProductId === productId);
        }
    }, [basketItems, isRecycledSpecial, productId]);

    const isAddButtonDisabled = useMemo(() => {
        return (
            isReservedByOtherUser ||
            recycledSpecialInBasket ||
            !canAddToBasket ||
            (isRecycledSpecial && !canRspBeAddedToBasket)
        );
    }, [isReservedByOtherUser, recycledSpecialInBasket, canAddToBasket, isRecycledSpecial, canRspBeAddedToBasket]);

    const addButtonMessage = useMemo(() => {
        return isReservedByOtherUser
            ? 'Reserved'
            : recycledSpecialInBasket
                ? 'In basket'
                : isRecycledSpecial && !canRspBeAddedToBasket
                    ? 'Cannot order this RSP'
                    : canAddToBasket
                        ? 'Add to basket'
                        : `Can't create order`;
    }, [isReservedByOtherUser, recycledSpecialInBasket, isRecycledSpecial, canRspBeAddedToBasket, canAddToBasket]);

    const addButtonTooltip = useMemo(() => {
        return isRecycledSpecial && !canRspBeAddedToBasket
            ? 'This RSP is currently either in a basket, part of an activity, or not on shelf.'
            : !canAddToBasket
                ? "You don't have permissions to create a new basket."
                : '';
    }, [canAddToBasket, isRecycledSpecial, canRspBeAddedToBasket]);

    const imageCarouselSections: ImageCarouselSection[] = [];

    // little bit layout juggling with the CTE message. If there is room, show it inline, is there isn't, show it on the next line (as a subsection in the image)
    const showCteMessageAsSubsection = quantityInCleaning && (supplierLeadTime || stockOnOrder);
    const cteMessage = anyCteConfigured ? <StyledLink href="#ctes">CTEs Available</StyledLink> : 'No CTEs configured';
    const cteSubSection = showCteMessageAsSubsection ? cteMessage : null;

    if (displayStockLevels && !isRecycledSpecial) {
        imageCarouselSections.push({
            icon: <WidgetsOutlinedIcon />,
            text: (
                <Fragment>
                    <strong>{quantity}</strong> on shelf {!showCteMessageAsSubsection && <Fragment>({cteMessage})</Fragment>}
                </Fragment>
            )
        });

        if (quantityInCleaning) {
            imageCarouselSections.push({
                icon: <WavesIcon />,
                text: (
                    <Fragment>
                        <strong>{quantityInCleaning}</strong> in cleaning
                    </Fragment>
                )
            });
        }

        if (quantity <= 0 && supplierLeadTime && !stockOnOrder) {
            imageCarouselSections.push({
                text: (
                    <Fragment>
                        Lead time: <strong>{supplierLeadTime}</strong> days
                    </Fragment>
                )
            });
        }
    }

    if (stockOnOrder) {
        imageCarouselSections.push({
            text: <Fragment>Stock on order</Fragment>
        });
    }

    if (!product.contractId) {
        return null;
    }

    return (
        <Page>
            {breadcrumb && <StyledBreadcrumb breadcrumb={breadcrumb} />}
            <SpacingSection>
                {error && (
                    <GenericPageError
                        title="Product details error"
                        message="Sorry, there was an error getting the product request. Please try again or go back to the previous page."
                    />
                )}

                {product && (
                    <StyledGrid container justify="space-between">
                        {/* LHS IMAGES */}
                        <StyledGrid item xs={12} md={6}>
                            <ImageCarousel
                                images={product.productImages}
                                sections={imageCarouselSections}
                                subSection={cteSubSection}
                                additionalOverlay={isRecycledSpecial ? RecycledItemTag(isPooled) : null}
                            />
                            {product.product360Images && product.product360Images.length > 0 && (
                                <StyledButton
                                    component="label"
                                    variant="primary"
                                    onClick={() => setViewPreview(true)}
                                >
                                    View 360 image
                                </StyledButton>
                            )}
                        </StyledGrid>

                        {/* RHS Main PRODUCT INFO */}
                        <StyledGrid item xs={12} sm={9} md={5}>
                            <Box mb={2}>
                                <Heading className={classes.alignSection} component="h2" type="h4" color="regalBlue">
                                    <Box mr={2}>{product.name}</Box>
                                    <Box flexShrink={0}>
                                        <DisplayAsMoney amount={product.issueCost} />
                                    </Box>
                                </Heading>
                                <BodyText type="small">{product.sku}</BodyText>
                            </Box>
                            <Box mb={6}>
                                <BodyText type="regular" gutterBottom preserveLinebreaks>
                                    {product.description}
                                </BodyText>

                                {isAuthorisationRequired && (
                                    <IconLabel
                                        icon={<AnnouncementIcon color="primary" />}
                                        text="This product requires authorisation"
                                        textSize="small"
                                    />
                                )}

                                {requiresInstallationNotes && (
                                    <IconLabel
                                        icon={<AnnouncementIcon color="primary" />}
                                        text="This product requires installation"
                                        textSize="small"
                                    />
                                )}

                                {product.isTelecare && (
                                    <IconLabel
                                        icon={<AnnouncementIcon color="primary" />}
                                        text="This product requires a monitoring form"
                                        textSize="small"
                                    />
                                )}

                                {isRecycledSpecial && (
                                    <Card className={classes.recycledSpecialCard}>
                                        <Heading gutterBottom>{isPooled ? 'Pooled r' : 'R'}ecycled special details</Heading>
                                        <StyledGrid container>
                                            <StyledGrid item xs={6} sm={4} md={6}>
                                                <LabelName label="Contract owner" name={product.contractName} textType="regular" />
                                            </StyledGrid>
                                            {isPooled && (
                                                <StyledGrid item xs={6} sm={4} md={6}>
                                                    <LabelName
                                                        label="Cost of transfer"
                                                        name={<DisplayAsMoney amount={product.costOfTransfer || 0} allowZero />}
                                                        textType="regular"
                                                    />
                                                </StyledGrid>
                                            )}
                                        </StyledGrid>

                                        {product.refurbAfterShelf && <WarningLabel spacingTop={2} message="Refurbishment required" />}
                                        <Box mt={2}>
                                            <BodyText>
                                                To arrange a viewing of this product or find out more, call the Service Centre at{' '}
                                                <TelephoneLink phoneNumber={contractTelephone} />.
                                            </BodyText>
                                        </Box>
                                    </Card>
                                )}
                            </Box>

                            {canOrder ? (
                                <ButtonsList>
                                    {!isRecycledSpecial ? (
                                        <InputField
                                            label="Quantity"
                                            animatedLabel
                                            style={{ width: 120 }}
                                            control={control}
                                            error={errors?.quantity}
                                            name="quantity"
                                            value={quantity}
                                            type="number"
                                            inputProps={{ min: 1, max: 100 }}
                                            readOnly={requiresInstallationNotes}
                                        />
                                    ) : (
                                        // This is to fake the RSP input. Easier than playing around with validation or the handleSubmit
                                        <input type="hidden" ref={register} name="quantity" />
                                    )}

                                    <StyledButton onClick={handleAddClick} disabled={isAddButtonDisabled} tooltip={addButtonTooltip}>
                                        {addButtonMessage}
                                    </StyledButton>
                                    {isReservable && canAddToBasket && (
                                        <StyledButton variant="secondary" onClick={handleStartReserveItem}>
                                            Reserve item
                                        </StyledButton>
                                    )}
                                    {isReservedByLoggedUser && (
                                        <StyledButton variant="secondary" onClick={handleStartUnreserveItem}>
                                            Unreserve
                                        </StyledButton>
                                    )}
                                </ButtonsList>
                            ) : (
                                <WarningLabel message="Product not available to order." />
                            )}
                            <ProductDetailsAdditionalInfo product={product} spacingTop={2} />
                        </StyledGrid>

                        {/* LINKED PRODUCTS */}
                        {!!compatibleProductsList?.length && (
                            <StyledGrid item xs={12}>
                                <LinkedProducts
                                    heading="Compatible products"
                                    subHeading="* It is solely the prescriber's responsibility to ensure any products which are ordered are compatible"
                                    products={compatibleProductsList}
                                />
                            </StyledGrid>
                        )}

                        {!!relatedProductsList?.length && (
                            <StyledGrid item xs={12}>
                                <LinkedProducts heading="Related products" products={relatedProductsList} />
                            </StyledGrid>
                        )}

                        {!!closeTechnicalEquivalents?.length && (
                            <StyledGrid item xs={12} id="ctes">
                                <StyledDivider spacing={3} />
                                <Box mb={3}>
                                    <Heading type="h3" gutterBottom>
                                        Close technical equivalents
                                    </Heading>
                                </Box>
                                <DataTable
                                    rows={closeTechnicalEquivalents}
                                    pKey="contractProductId"
                                    columns={[
                                        { headerName: 'SKU', field: 'sku' },
                                        { headerName: 'Name', field: 'name' },
                                        { headerName: 'Description', field: 'description' }
                                    ]}
                                    paging
                                />
                            </StyledGrid>
                        )}
                    </StyledGrid>
                )}
            </SpacingSection>
            <StyledDialog
                headingTitle={'Product 360 image upload preview'}
                open={viewPreview}
                onClose={() => setViewPreview(false)}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Image360Viewer images={listOfFiles} width={500} height={500} />
            </StyledDialog>

            <ProductInstallationDialog
                productId={product.contractProductId}
                isOpen={installationDialogIsOpen}
                onClose={handleCloseInstallationDialog}
                onSuccess={(notes: string, files: FileList) => {
                    handleCloseInstallationDialog();
                    handleAddToBasket(1, notes, files);
                }}
            />

            <ProductReservation
                product={product as IContractProductSummary}
                reserving={reserving}
                isOpen={reserveDialogIsOpen}
                onClose={handleCloseReserveItem}
                onSuccess={handleReservationSuccess}
            />
        </Page>
    );
};
 
export default ProductDetailsPage;
