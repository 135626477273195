import { yupResolver } from '@hookform/resolvers';
import { fetchPortalRecycledSpecialHoldTimes, selectRecycledSpecialHoldTimes } from 'features/contract/contract.slice';
import { createRecycledProductReservation } from 'features/products/products.slice';
import { ApiId, ErrorBox, IFormDataWithApiError, mapIReferenceToSelectOptions, SelectField } from 'millbrook-core';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

// Constants
export const PRODUCT_RESERVATION_FORM = 'product-reservation-form';

// Schema / Data
export const productReservationFormSchema = yup.object().shape({
  holdTimeId: yup.string().required('Please select a reservation period')
});

export type ProductReservationFormData = yup.InferType<typeof productReservationFormSchema>;

export const defaultProductReservationFormData: ProductReservationFormData = {
  holdTimeId: ''
};

interface Props {
  productId: ApiId;
  onSuccess?: () => void;
}

export const ProductReservationForm: React.FC<Props> = ({ productId, onSuccess }) => {
  /* hooks */
  const dispatch = useDispatch();

  const holdTimes = useSelector(selectRecycledSpecialHoldTimes);

  const { control, handleSubmit, errors, setError } = useForm<ProductReservationFormData & IFormDataWithApiError>({
    defaultValues: defaultProductReservationFormData,
    resolver: yupResolver(productReservationFormSchema),
    reValidateMode: 'onBlur'
  });

  /* effects */
  useEffect(() => {
    dispatch<any>(fetchPortalRecycledSpecialHoldTimes()).catch((e: any) => {
      setError('apiError', { message: e.message });
    });
  }, [dispatch, setError]);

  /* events */
  const handleSaveProductReservationFormData = async (formData: ProductReservationFormData) => {
    try {
      await dispatch(createRecycledProductReservation(productId, formData.holdTimeId));
      onSuccess && onSuccess();
    } catch (e: any) {
      setError('apiError', { message: e.message });
    }
  };

  // borrowed from example in ContactForm.tsx
  const formSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      // sometimes not true, e.g. React Native
      if (typeof event.preventDefault === 'function') {
        event.preventDefault();
      }
      if (typeof event.stopPropagation === 'function') {
        // prevent any outer forms from receiving the event too
        event.stopPropagation();
      }
    }

    return handleSubmit(handleSaveProductReservationFormData)(event);
  };

  return (
    <form id={PRODUCT_RESERVATION_FORM} onSubmit={formSubmit} noValidate>
      <ErrorBox error={errors?.apiError} />

      <SelectField
        label="Hold time"
        control={control}
        error={errors?.holdTimeId}
        name="holdTimeId"
        pleaseSelect="Select a reservation period"
        options={mapIReferenceToSelectOptions(holdTimes)}
        defaultValue=""
      />
    </form>
  );
};
