import {
  AdditionalServiceUserContractField,
  ApiId,
  ContractServiceUserGroup,
  IAddressModel,
  IContractClientAlert,
  IFlagList,
  IGuidName,
  ISelectOption,
  IServerSelectOption,
  IsoDate
} from 'millbrook-core';

export interface ServiceUserFormData {
  propertyTypes: IServerSelectOption[];
  tenancies: IGuidName[];
  languages: IGuidName[];
  ethnicities: IGuidName[];
  contractServiceUserGroups: ContractServiceUserGroup[];
  contractClientAlerts: IContractClientAlert[];
  serviceUserFieldsValidation: IFlagList;
  contractFields: AdditionalServiceUserContractField[];
}

export interface AdditionalServiceUserFieldsRequestData {
  contractId: string;
  serviceUserFieldValues: AdditionalServiceUserContractField[];
}

export interface ServiceUserDuplicateCriteria {
  nhsNumber?: number | null;
  socialCareNumber?: string;
  firstName?: string;
  surname?: string;
  dob?: IsoDate;
  postCode?: string;
}

export interface EosProductExtendReviewRequest {
  reviewPeriodId: ApiId;
}

export interface ServiceUserCardData {
  serviceUserId: ApiId;
  serviceUser: string; // This is the service user name
  serviceUserAddress: IAddressModel;
  primaryContactNumber: string;
  recentOrderReference: ApiId;
  dateOfLastOrder: IsoDate;
  activityId: ApiId;
  logDate: IsoDate;
  contractId: string;
}

export enum Title {
  Mr = 'Mr',
  Mrs = 'Mrs',
  Miss = 'Miss',
  Mx = 'Mx',
  Ms = 'Ms',
  Doctor = 'Doctor',
  Barroness = 'Barroness',
  Canon = 'Canon',
  Captain = 'Captain',
  Colonel = 'Colonel',
  Dame = 'Dame',
  Father = 'Father',
  Lady = 'Lady',
  Lord = 'Lord',
  Major = 'Major',
  Rabbi = 'Rabbi',
  Reverend = 'Reverend',
  Sir = 'Sir',
  Sister = 'Sister',
  Professor = 'Professor',
  Master = 'Master',
  Unspecified = 'Unspecified'
}

export const TitleDisplayName = {
  [Title.Mr]: 'Mr',
  [Title.Mrs]: 'Mrs',
  [Title.Ms]: 'Ms',
  [Title.Miss]: 'Miss',
  [Title.Mx]: 'Mx',
  [Title.Master]: 'Master',
  [Title.Doctor]: 'Doctor',
  [Title.Barroness]: 'Barroness',
  [Title.Canon]: 'Canon',
  [Title.Captain]: 'Captain',
  [Title.Colonel]: 'Colonel',
  [Title.Dame]: 'Dame',
  [Title.Father]: 'Father',
  [Title.Lady]: 'Lady',
  [Title.Lord]: 'Lord',
  [Title.Major]: 'Major',
  [Title.Professor]: 'Professor',
  [Title.Rabbi]: 'Rabbi',
  [Title.Sir]: 'Sir',
  [Title.Sister]: 'Sister',
  [Title.Reverend]: 'Reverend',
  [Title.Unspecified]: 'Unspecified'
};

export const getTitleSelectOptions = (): ISelectOption[] => {
  return Object.values(Title).map((key) => ({
    value: key,
    label: TitleDisplayName[key]
  }));
};

export enum ContactPreference {
  SMS = 'SMS',
  Email = 'Email',
  Telephone = 'Telephone'
}

export const ContactPreferenceDisplayName = {
  [ContactPreference.SMS]: 'SMS',
  [ContactPreference.Email]: 'Email',
  [ContactPreference.Telephone]: 'Telephone'
};

export const getContactPreferenceOptions = (): ISelectOption[] => {
  return Object.values(ContactPreference).map((key) => ({
    value: key,
    label: ContactPreferenceDisplayName[key]
  }));
};

// merging

export enum ServiceUserMergeUrlSteps {
  SelectUsers = '',
  Details = 'details',
  Activities = 'activities',
  EquipmentOnSite = 'equipment-on-site'
}

export enum ServiceUserMergeSelectState {
  Active,
  Archive
}
