import { makeStyles } from '@material-ui/core';
import { ROUTES } from 'constants/routes';
import { selectContractId } from 'features/contract/contract.slice';
import {
  fetchContractProfessionalAssessmentsByContractId,
  selectEnabledAssessmentAreas
} from 'features/professionalAssessments/contractProfessionalAssessments.slice';
import {
  ProfessionalAssessmentTermsAgreedStore,
  selectUserSettings,
  setUserSettings
} from 'features/ui/userSettings.slice';
import {
  BodyText,
  confirmDialog,
  GenericPageError,
  Page,
  SpacingSection,
  StyledBreadcrumb,
  StyledGrid
} from 'millbrook-core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProfessionalAssessmentAreasForm } from './components/ProfessionalAssessmentAreasForm';

const ProfessionalAssessmentAreas: React.FC = () => {
  /* hooks */
  const dispatch = useDispatch();
  const contractId = useSelector(selectContractId);
  const contractProfessionalAssessmentsOverviewList = useSelector(selectEnabledAssessmentAreas);
  const { professionalAssessmentTermsAgreedStore } = useSelector(selectUserSettings);

  /* states */
  const [error, setError] = useState(false);

  const breadcrumb = {
    [ROUTES.CONTRACT_PROFESSIONAL_ASSESSMENTS.root]: 'Professional assessment areas'
  };

  const proAssessmentCardStyles = makeStyles((theme) => ({
    content: {
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        border: '1px solid black',
        borderRadius: '5px'
      }
    }
  }));

  const classes = proAssessmentCardStyles();

  /* events */
  useEffect(() => {
    var DNow = new Date(Date.now());

    if (
      professionalAssessmentTermsAgreedStore?.dateAgreed == null ||
      (professionalAssessmentTermsAgreedStore?.dateAgreed &&
        new Date(professionalAssessmentTermsAgreedStore?.dateAgreed).getDate() != DNow.getDate())
    ) {
      //Has yet to agree to the disclaimer terms and needs to see them.
      handleDisclaimerAgreement();
    } else {
      contractId &&
        dispatch<any>(fetchContractProfessionalAssessmentsByContractId(contractId)).catch(() => setError(true));
    }

    // eslint-disable-next-line
  }, [dispatch, contractId]);

  const handleDisclaimerAgreement = () => {
    confirmDialog({
      title: `Professional assessment disclaimer`,
      message: `This assessment tool is a guide only and it is the prescriber's responsibility to ensure the products ordered are suitable.`,
      confirmButtonLabel: 'Confirm',
      onlyOkay: true,
      enableCloseIcon: false
    }).then(() => {
      //Store agreed disclaimer terms for return.
      var settings: ProfessionalAssessmentTermsAgreedStore = {
        dateAgreed: Date()
      };

      dispatch(setUserSettings({ professionalAssessmentTermsAgreedStore: settings }));

      contractId &&
        dispatch<any>(fetchContractProfessionalAssessmentsByContractId(contractId)).catch(() => setError(true));
    });
  };

  if (error) {
    return <GenericPageError title="Professional assessment areas" />;
  }

  return (
    <Page>
      <StyledBreadcrumb breadcrumb={breadcrumb} />
      <SpacingSection>
        <StyledGrid container>
          <StyledGrid item xs={12}>
            {contractProfessionalAssessmentsOverviewList.length > 0 && (
              <ProfessionalAssessmentAreasForm
                contractProfessionalAssessmentOverviewList={contractProfessionalAssessmentsOverviewList}
              />
            )}
            {contractProfessionalAssessmentsOverviewList.length == 0 && (
              <StyledGrid item xs={12} className={classes.content}>
                <BodyText type="large">No assessments active at this time</BodyText>
              </StyledGrid>
            )}
          </StyledGrid>
        </StyledGrid>
      </SpacingSection>
    </Page>
  );
};

export default ProfessionalAssessmentAreas;
