import { ROUTES } from 'constants/routes';
import { Page, PageIntro, SpacingSection, StyledButton } from 'millbrook-core';
import { Link } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  return (
    <Page>
      <SpacingSection>
        <PageIntro
          title="Page not found"
          bodyText="Sorry, we couldn’t find the page you were looking for. If you had expected something to be here, please contact an administrator."
        />
        <StyledButton variant="secondary" component={Link} to={ROUTES.HOME}>
          Go to the dashboard
        </StyledButton>
      </SpacingSection>
    </Page>
  );
};

export default NotFoundPage;
