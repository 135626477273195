import { EquipmentReviewSearch } from 'features/equipmentReviews/equipmentReviews.slice';
import { ISearchPagination } from 'millbrook-core';

// PRODUCTS
export const NUMBER_OF_PRODUCTS_PER_PAGE = 12;
export const NUMBER_OF_PRODUCTS_PER_PAGE_TABLET = 8;

// ACTIVITIES
export const NUMBER_OF_ACTIVITIES_PER_PAGE = 50;
export const BASE_ACTIVITIES_SEARCH: ISearchPagination = {
  pageSize: NUMBER_OF_ACTIVITIES_PER_PAGE,
  pageIndex: 1,
  sortOrder: 'desc',
  sortColumn: 'dateCreated'
};

// FEEDBACK
export const NUMBER_OF_FEEDBACK_PER_PAGE = 50;
export const BASE_FEEDBACK_SEARCH: ISearchPagination = {
  pageSize: NUMBER_OF_FEEDBACK_PER_PAGE,
  pageIndex: 1,
  sortOrder: 'desc',
  sortColumn: 'lastUpdateDate'
};

// NEWS
export const NUMBER_OF_NEWS_ADMIN_PER_PAGE = 12;

//  EQUIPMENT REVIEWS
export const NUMBER_OF_EQUIPMENT_REVIEWS_PER_PAGE = 12;
export const NUMBER_OF_EQUIPMENT_REVIEWS_PER_PAGE_TABLET = 8;

export const BASE_EQUIPMENT_REVIEWS_SEARCH: EquipmentReviewSearch = {
  pageSize: NUMBER_OF_EQUIPMENT_REVIEWS_PER_PAGE,
  pageIndex: 1
};

// EQUIPMENT REVIEWS BY CONTRACT
export const NUMBER_OF_CONTRACT_EQUIPMENT_REVIEWS_PER_PAGE = 10;
