import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { differenceBy, uniqBy } from 'lodash';
import { ApiId, IApiResponse, IsoDate, ServiceTypeCodeEnum, ServiceUserAddressFormData } from 'millbrook-core';
import { AdditionalContactFormData } from 'pages/service-users/components/ContactForm/ContactForm';
import { CheckoutInitialState as initialState } from './sliceSections/CheckoutState';
import {
  ActivitiesAvailableEnum,
  ActivityAuthorisationCheckResponseModel,
  ActivityAuthorisationTypeEnum,
  ActivityCreateResponseModel,
  ActivityMediaModel,
  BaseContractServiceTimeSlotItemModel,
  InitialCheckoutFormData
} from './types/checkout.types';
import {
  CheckoutStepName,
  Step2FormDataState,
  Step3FormDataState,
  Step4FormDataState,
  Step5FormDataState,
  Step6FormDataState
} from './types/steps.types';

export type ActivityCreatedResponse = IApiResponse<ActivityCreateResponseModel>;

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    updateInitialFormData(state, action: PayloadAction<InitialCheckoutFormData>) {
      // When re-entering the checkout, we want to clear certain steps, because they are throwing errors when values exist when they shouldn't
      // e.g. added a special to the basket and completed steps 5 and 6, then removed the special.
      // Not going to clear the whole thing, because it's handy to have the first 2 steps there
      // If this causes issues, then clear the whole thing

      state.initialData = {
        ...state.initialData,
        ...action.payload
      };

      const checkoutFieldsAvailability = action.payload.checkoutFieldsAvailability;

      // step 1,2,3 should be mostly okay. Step 7 is informational
      // TODO: This seems excessive spreading, but immer was having issues with step 5 and then setting one of the values in the data.
      //       Don't understand enough about how immer works. Issue was "cannot set readonly..."
      state.step4 = { ...initialState.step4, data: { ...initialState.step4.data } }; // CTE, because the products could changed when edited
      state.step5 = { ...initialState.step5, data: { ...initialState.step5.data } }; // Delivery speeds, just because
      state.step6 = { ...initialState.step6, data: { ...initialState.step6.data } }; // issues types, authorisors, reviews are based on products in the basket

      // standardActivitySpeeds is returning null when there is no delivery. Easier if it's not null
      state.initialData.standardActivitySpeeds = state.initialData.standardActivitySpeeds || [];

      // get rid of the nulls
      state.initialData.nonWorkingDates = !!state.initialData.nonWorkingDates ? state.initialData.nonWorkingDates : [];
      state.initialData.daysWithTimeSlots = !!state.initialData.daysWithTimeSlots
        ? state.initialData.daysWithTimeSlots
        : [];

      // clear the added/removed client alerts because this causes issues when leaving the basket and changing alerts on the service user edit
      state.step2.data.addedClientAlerts = [];
      state.step2.data.removedClientAlerts = [];

      // set the selected alternate address
      state.step2.data.selectedAlternativeContact = state.initialData.serviceUserAdditionalContacts.find(
        (x) => x.isPrimaryAdditionalContact
      )?.id;

      // set the init client alerts
      state.modifiedClientAlerts = state.initialData.serviceUserClientAlerts.map((x) => x.contractServiceUserAlert);

      state.step4.notRequired = !checkoutFieldsAvailability.closeTechnicalEquivalentVisible;

      // TODO: does this need to be action.payload when the prop is hooked up?
      // TODO: This is using the availableActivities because the actual field is broken deliverStandardSpecialTogetherVisible
      // TODO: Also going to overwrite the value in the checkoutFieldsAvailability
      state.initialData.checkoutFieldsAvailability.deliverStandardSpecialTogetherVisible =
        state.initialData.activitiesAvailable === ActivitiesAvailableEnum.StandardAndSpecial;

      // set the delivery together option to '' if it's a dual activity. It will force the user to select an option
      // NOTE: JM 2021-09-10 temporarily remove this option because MPL order is being removed. Interim solution is to force all users to split the basket
      if (state.initialData.checkoutFieldsAvailability.deliverStandardSpecialTogetherVisible) {
        state.step5.data.deliverStandardAndSpecialTogether = '';
      }

      const { activitiesAvailable } = state.initialData;
      state.initialData.hasOnlySpecialActivity = activitiesAvailable === ActivitiesAvailableEnum.Special;
      state.initialData.hasSpecialActivity = !!(activitiesAvailable & ActivitiesAvailableEnum.Special);

      // Work out the "delivery" language. Theory is that you will have a delivery that cover products,
      // special, TA, EM and MA, and collection and repairs that cover the rest.
      // So take the first speed and check that. Need to check and account for standalone services that happen without a product.
      const activityService = state.initialData.standardActivitySpeeds[0];

      // pooled special is on it's own, but looks like a delivery
      state.initialData.standardOrderTypeName =
        !activityService || activityService?.serviceTypeCode === ServiceTypeCodeEnum.PooledSpecials
          ? 'Delivery'
          : activityService.contractServiceTypeName;
    },
    addActivityMedia(state, action: PayloadAction<ActivityMediaModel[]>) {
      state.initialData.activityMedias.push(...action.payload);
    },
    removeActivityMedia(state, action: PayloadAction<ApiId[]>) {
      state.initialData.activityMedias = state.initialData.activityMedias.filter(
        (x) => action.payload!.indexOf(x.id) === -1
      );
    },
    updateRequiresAuthorisation(state, action: PayloadAction<ActivityAuthorisationCheckResponseModel>) {
      state.orderRequiresAuthorisation = action.payload.type || ActivityAuthorisationTypeEnum.NotRequired;
      state.orderAuthorisationReasons = action.payload.reasons || [];
    },
    updateStep0(state) {
      state.currentStep = 'step1';
      state.step0.status = 'completed';
    },
    updateStep1(state, action: PayloadAction<boolean | undefined>) {
      state.currentStep = 'step2';
      state.step1.status = 'completed';
      state.step1.notRequired = !!action.payload;
    },
    updateStep2(state, action: PayloadAction<Step2FormDataState>) {
      state.currentStep = 'step3';
      state.step2.status = 'completed';
      state.step2.data = {
        ...state.step2.data,
        ...action.payload
      };
    },
    updateAlert(state, action: PayloadAction<{ id: ApiId; method: 'add' | 'remove' }>) {
      if (state.step2.data) {
        if (action.payload.method === 'add') {
          const newAlert = state.initialData.contractClientAlerts.find((x) => x.id === action.payload.id);
          newAlert && (state.modifiedClientAlerts = uniqBy([...state.modifiedClientAlerts, newAlert], 'id'));
        } else {
          state.modifiedClientAlerts = state.modifiedClientAlerts.filter((x) => x.id !== action.payload.id);
        }

        // TODO: why can't the below be calculated on submit?
        // added alerts use the contractServiceUserAlertId
        state.step2.data.addedClientAlerts = differenceBy(
          state.modifiedClientAlerts,
          state.initialData.serviceUserClientAlerts.map((x) => x.contractServiceUserAlert),
          'id'
        ).map((x) => x.id);

        // removed alerts use the serviceUserClientAlertId
        state.step2.data.removedClientAlerts = state.initialData.serviceUserClientAlerts
          .filter(
            (origAlert) =>
              !state.modifiedClientAlerts.find((modAlert) => modAlert.id === origAlert.contractServiceUserAlert.id)
          )
          .map((x) => x.id);
      }
    },
    selectAlternativeContact(state, action: PayloadAction<ApiId | null>) {
      state.step2.data.selectedAlternativeContact = action.payload;
    },
    addAlternativeContact(state, action: PayloadAction<AdditionalContactFormData | undefined>) {
      state.step2.data.newAlternativeContact = action.payload;
    },
    // step 3
    updateStep3(state, action: PayloadAction<Step3FormDataState>) {
      if (state.step4.notRequired) {
        state.currentStep = 'step5';
        state.step5.status = 'in-progress';
      } else {
        state.currentStep = 'step4';
        state.step4.status = 'in-progress';
      }

      state.step3 = {
        status: 'completed',
        data: {
          ...state.step3.data,
          ...action.payload
        }
      };
      },
      updateStep3ArrangeBookingFalse(state) {
          state.step3.data.arrangeBooking = 'no';
      },
    updateStep3Partial(state, action: PayloadAction<Partial<Step3FormDataState>>) {
      state.step3.data = { ...state.step3.data, ...action.payload };
    },
    updateStep4(state, action: PayloadAction<Step4FormDataState>) {
      state.currentStep = 'step5';
      state.step5.status = 'in-progress';

      state.step4 = {
        status: 'completed',
        data: {
          ...state.step4.data,
          ...action.payload
        }
      };
    },
    updateStep5(state, action: PayloadAction<Step5FormDataState>) {
      state.currentStep = 'step6';
      state.step6.status = 'in-progress';

      state.step5 = {
        data: {
          ...initialState.step5.data,
          // TODO: not sure why the below is needed because all of the data for the form should be coming from the submit. Remove when confident it's working
          //...state.step5.data,
          ...action.payload
        },
        status: 'completed'
      };
    },
    updateStep6(state, action: PayloadAction<Step6FormDataState | undefined>) {
      state.currentStep = 'step7';
      state.step7.status = 'in-progress';

      // If we have skipped this step from the step 5 check in the authorisation thunk then set it as not required.
      // This is so when we hit the back button it goes to step 5
      const stepSkipped = !action.payload ? true : undefined;

      state.step6 = {
        data: {
          ...initialState.step6.data,
          ...action.payload
        },
        status: 'completed',
        notRequired: stepSkipped
      };
    },
    updateStep6Partial(state, action: PayloadAction<Partial<Step6FormDataState>>) {
      state.step6.data = { ...state.step6.data, ...action.payload };
    },
    selectAddress(state, action: PayloadAction<ApiId | null>) {
      // Reset fields as address selection invalidates property props
      state.step3.data.accessIssues = undefined;
      state.step3.data.dogOnPremise = undefined;
      state.step3.data.keySafe = undefined;
      state.step3.data.parkingAvailable = undefined;
      state.step3.data.stepsLeadingUpToTheProperty = undefined;
      state.step3.data.propertyTypeId = undefined;
      state.step3.data.arrangeBooking = undefined;

      // select address
      state.step3.data.selectedAddress = action.payload;
    },
    addAddress(state, action: PayloadAction<ServiceUserAddressFormData | undefined>) {
      state.step3.data.newAddress = action.payload;
    },
    stepBack(state, action: PayloadAction<CheckoutStepName | null>) {
      if (action.payload) {
        state.currentStep = action.payload;
      }
    },
    clearCheckout() {
      return initialState;
    },
    setTimeSlots(state, action: PayloadAction<BaseContractServiceTimeSlotItemModel[]>) {
      state.timeSlots = action.payload;
    },
    addDateWithoutTimeSlots(state, action: PayloadAction<IsoDate>) {
      state.timeSlotDaysNotAvailable = [action.payload, ...state.timeSlotDaysNotAvailable];
    }
  }
});

/**
 * ACTIONS
 */

export const {
  updateStep0,
  updateStep1,
  updateStep2,
  updateStep3,
    updateStep3Partial,
  updateStep3ArrangeBookingFalse,
  updateStep4,
  updateStep5,
  updateStep6,
  updateAlert,
  selectAlternativeContact,
  addAlternativeContact,
  updateInitialFormData,
  selectAddress,
  addAddress,
  stepBack,
  clearCheckout,
  updateRequiresAuthorisation,
  setTimeSlots,
  addDateWithoutTimeSlots,
  addActivityMedia,
  removeActivityMedia
} = checkoutSlice.actions;

/**
 * SELECTORS
 */

/**
 *  initial selectors - in separate file in the selectors folder
 * /


/**
 * STEP 2 selectors - in separate file in the selectors folder
 */

/**
 * STEP 3 selectors - in separate file in the selectors folder
 */

/**
 * STEP 4 selectors - in separate file in the selectors folder
 */

/**
 * STEP 5 selectors - delivery details in separate file in the selectors folder
 */

/**
 * STEP 6 selectors - in separate file in the selectors folder
 */

/**
 * STEP 7 selectors - summary - in separate file in the selectors folder
 */

export default checkoutSlice.reducer;
