import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, mapApiErrors, PinTeam, PinTeamResponse } from 'millbrook-core';
import { getItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';

interface TeamsState {
  teamList: PinTeam[];
}

const initialState: TeamsState = { teamList: [] };

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setTeamList(state, action: PayloadAction<PinTeam[]>) {
      state.teamList = action.payload;
    }
  }
});

// thunks for teams

export const fetchTeams =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<PinTeamResponse>(ENDPOINTS.CONTRACT.BUDGET_PIN.TEAMS(contractId)).then(
      (response) => {
        dispatch(setTeamList(response.result || []));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/* actions */
export const { setTeamList } = teamsSlice.actions;

/* selectors */
export const selectTeamList = (state: RootState) => state.budgetPin.teams.teamList;

export const getTeamSelectOptions = createSelector([selectTeamList], (teamList) => {
  return teamList.map((team) => {
    return { label: team.name, value: team.id };
  });
});

/* reducers */
export default teamsSlice.reducer;
