import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { IApiResponse, Supplier } from 'millbrook-core';
import { getItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';

/* types */
export type SuppliersResponse = IApiResponse<Supplier[]>;

/* state */
interface SuppliersState {
  suppliers: Supplier[];
}

const initialState: SuppliersState = {
  suppliers: []
};

const suppliersSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    setSuppliers(state, action: PayloadAction<Supplier[]>) {
      state.suppliers = action.payload;
    }
  }
});

/* thunks */

export const fetchSuppliers = (): AppThunk => async (dispatch, getState) => {
  return getItems<SuppliersResponse>(ENDPOINTS.SUPPLIERS, {
    enableGlobalErrorDialog: true
  }).then(
    (response) => {
      if (response.result) {
        dispatch(setSuppliers(response.result || []));
      }
    },
    () => {
      // handled with global error handler
    }
  );
};

/* actions */
export const { setSuppliers } = suppliersSlice.actions;

/* selectors */
export const selectSuppliers = (state: RootState) => state.suppliers.suppliers;

export const getSupplierOptions = createSelector([selectSuppliers], (suppliers) => {
  return suppliers.map((supplier) => {
    return { label: supplier.name, value: supplier.id };
  });
});

export const getSupplier = (supplierId: string = '') => {
  return createSelector([selectSuppliers], (suppliers) => {
    const supplier: Supplier[] = suppliers.filter((supplier) => supplier.id === supplierId);
    return supplier[0] ? supplier[0] : null;
  });
};

/* reducers */
export default suppliersSlice.reducer;
