import {
  ApiId,
  CareOutcomeModel,
  ContractReviewTypeModel,
  ContractSpeed,
  Days,
  DeliveryTypeEnum,
  IContractClientAlert,
  IFlagList,
  IGuidName,
  IMediaModel,
  ISelectOption,
  IServerSelectOption,
  IServiceUserClientAlert,
  IsoDate,
  MasterReviewPeriod,
  MediaTypeEnum,
  PinUserSimpleModel,
  ServiceUserAdditionalContactModel,
  ServiceUserAddressModel,
  ServiceUserAddressUpdateModel,
  ServiceUserContactPreferenceModel
} from 'millbrook-core';
import { AdditionalContactFormData } from 'pages/service-users/components/ContactForm/ContactForm';

export interface PropertyTypeServerSelect extends IServerSelectOption {
  propertyTypeCode: number;
}

export interface PropertyTypeSelectOption extends ISelectOption {
  propertyTypeCode: number;
}

export interface NonWorkingDate {
  id: ApiId;
  contractId: ApiId;
  date: IsoDate;
  deleted: boolean;
}

export interface ActivityServiceUserDeliveryDetailsRequest {
  selectedContractClientAlerts?: ApiId[];
  serviceUserClientAlertsToRemove?: ApiId[];
  selectedAdditionalContact?: ApiId;
  alternativeContactNote?: string;
  mobile: string;
  landline: string;
  emailAddress: string;
  contactPreferences: string[];
  newServiceUserAddress?: ServiceUserAddressModel;
  generalNotes?: string;
  selectedServiceUserAddress?: ServiceUserAddressModel;
  newServiceUserAdditionalContact?: ServiceUserAdditionalContactModel;
  telephoneToServiceUserNeeded: boolean;
}

export interface CheckoutCreateOrderRequest extends ActivityServiceUserDeliveryDetailsRequest {
  clinicalNotes?: string;
  riskAssessmentNotes?: string;
  basketId: ApiId;
  serviceUserId: ApiId;

  acceptedProductCTEs: string[];

  deliverStandardAndSpecialTogether: boolean;
  safeGuardingAlertAvailable: boolean; // BE: refactor, can this be derived from the client alerts (at some point in the future, was a bit time consuming)
  standardJointVisitRequired: boolean;
  basketContainsSpecialProducts: boolean; // BE: refactor, can this be derived from the products?

  directDelivery: boolean; // BE: refactor, can this be derived from the deliverytype?

  /* standard activity */
  deliveryType?: DeliveryTypeEnum;
  contractServiceSpeedId: ApiId;
  careOutcomeId?: ApiId;
  careOutcomeAlternativeId?: ApiId;
  currentWeeklyCareCost?: number;
  newWeeklyCareCost?: number;
  authorisers: ApiId[];
  authorisationNotes?: string;
  deliveryDay?: IsoDate;
  contractServiceTimeSlotId?: ApiId;

  /* special activity */
  specialActivityDeliveryType?: DeliveryTypeEnum;
  specialActivityContractServiceSpeedId?: ApiId;
  specialActivityCareOutcomeId?: ApiId;
  specialActivityCareOutcomeAlternativeId?: ApiId;
  specialActivityCurrentWeeklyCareCost?: number;
  specialActivityNewWeeklyCareCost?: number;
  specialActivityAuthorisers?: ApiId[];
  specialActivityAuthorisationNotes?: string;
  specialActivityDeliveryDay?: IsoDate;

  // NOTE: files are handled in in the formData object
}

// TODO: move to activity.types.ts
export interface ActivityCreateResponseModel {
  activityDetails: SimpleActivityDetailsModel[];
  generalNotes: string;
  telephoneToServiceUserNeeded: boolean;
    deliverStandardAndSpecialTogether: boolean;
    selectedTimeSlot: string;
}

export interface SimpleActivityDetailsModel {
  id: ApiId;
  activityReference: string;
  isSpecial?: boolean;
  hospitalCostSaving?: number;
  careCostSaving?: number;
}

/**
 * Form setup
 */

export type AdditionalContacts = Array<AdditionalContactFormData & { id: ApiId }>;

export interface ActivityServiceUserInitialData {
  serviceUser: ServiceUserDetails;
  serviceUserClientAlerts: IServiceUserClientAlert[];
  contractClientAlerts: IContractClientAlert[];
  careOutcomes: CareOutcomeModel[];
  serviceUserFieldsValidation: IFlagList;
  serviceUserAdditionalContacts: AdditionalContacts;
  propertyTypes: PropertyTypeServerSelect[];
  tenancies: IGuidName[];
}

export interface InitialCheckoutFormData extends ActivityServiceUserInitialData {
  // Pin data
  pinUsers: PinUserSimpleModel[];

  // working days and timeslots
  nonWorkingDates: IsoDate[];
  daysWithTimeSlots: DayWithTimeSlots[];
  timeSlotCharge: number;

  // Reviews types
  contractReviewTypes: ContractReviewTypeModel[];
  reviewPeriods: MasterReviewPeriod[];

  // Speeds and delivery types
  standardActivityDeliveryTypes: DeliveryTypeEnum[];
  standardActivitySpeeds: ContractSpeed[];
  specialActivityDeliveryTypes: DeliveryTypeEnum[];
  specialActivitySpeed: ContractSpeed | null;

  activitiesAvailable: ActivitiesAvailableEnum;
  checkoutFieldsAvailability: CheckoutFieldsAvailabilityModel;
  twoPersonChargeAvailable: TwoPersonChargeAvailableEnum;

  isActivityOnBehalfOfExternalPinUser: boolean;

  // derived fields activitiesAvailable, not coming from the server
  hasSpecialActivity: boolean;
  hasOnlySpecialActivity: boolean;
  // this is used to define the language used throughout the checkout for "Delivery" vs "Collection" or "Repair". It is pulled from the first speed
  standardOrderTypeName: string;

  activityMedias: ActivityMediaModel[];
}

export interface ActivityMediaModel {
  mediaType: MediaTypeEnum;
  activityId: ApiId;
  id: ApiId;
  media: IMediaModel;
  isTempFile: boolean;
}

export interface DayWithTimeSlots {
  day: Days;
  hasTimeSlots: boolean;
}

// REFACTOR - this should be merged with ServiceUserProfile in service-user.types
export interface ServiceUserDetails {
  id: ApiId;
  title: string;
  firstName: string;
  middleName: string;
  surname: string;
  dob: IsoDate;
  contractServiceUserGroup: { id: ApiId; contractId: ApiId; serviceUserGroup: IServerSelectOption };
  mobile: string;
  landline: string;
  emailAddress: string;
  serviceUserContactPreferences: ServiceUserContactPreferenceModel[];
  serviceUserAddresses: Array<ServiceUserAddressUpdateModel>;
}

export interface CheckoutFieldsAvailabilityModel {
  closeTechnicalEquivalentVisible: boolean;
  deliverStandardSpecialTogetherVisible: boolean;
  deliveryTypeVisible: boolean;
  jointVisitVisible: boolean;
  twoPersonVisible: boolean;
  dateTimeSlotVisible: boolean;
  careOutcomeVisible: boolean;
  reviewTypePeriodVisible: boolean;
  clinicalAssessmentVisible: boolean;
  riskAssessmentVisible: boolean;
  authorisationVisible: boolean;
}

export interface ExistingDeliveryCheckRequestModel {
  activityId: ApiId;
  postCode: string;
  propertyTypeId: ApiId;
  contactPreferences: string[];
  targetDate: IsoDate;
  standardJointVisitRequired: boolean;
}

export interface TimeSlotAvailabilityModel {
  date: IsoDate;
  totalAvailableTimeSlots: number;
  availableTimeSlots: BaseContractServiceTimeSlotItemModel[];
}

export interface BaseContractServiceTimeSlotItemModel {
  id: ApiId;
  fromTime?: string; // not currently using, but it matches the BE model (apart from the optional '?')
  toTime?: string; // not currently using, but it matches the BE model (apart from the optional '?')
  slotName: string;
}

// used in the checkout requires auth response
export interface ActivityAuthorisationCheckResponseModel {
  type: ActivityAuthorisationTypeEnum;
  reasons: ActivityAuthorisationReasonEnum[];
}

/**
 * ENUMS
 */

// SERVER: Authorisation uses bitwise
export enum ActivityAuthorisationTypeEnum {
  // [Description("No authorisation required")]
  NotRequired = 1,

  // [Description("Standard authorisation required")]
  StandardAuthorisationRequired = 2,

  // [Description("Special authorisation required")]
  SpecialAuthorisationRequired = 4,

  //[Description("Rsp authorisation required")]
  // This would never be sent on its own.
  RspAuthorisationRequired = 8,

  //[Description("Standard and Special authorisation required")]
  StandardAndSpecialAuthorisationRequired = StandardAuthorisationRequired | SpecialAuthorisationRequired,

  //[Description("Standard Rsp authorisation required")]
  StandardRspAuthorisationRequired = StandardAuthorisationRequired | RspAuthorisationRequired,

  //[Description("Standard Rsp and Special authorisation required")]
  RspAndSpecialAuthorisationRequired = StandardRspAuthorisationRequired | SpecialAuthorisationRequired
}

// SERVER: authorisation reasons. Used in the checkout requires auth response
export enum ActivityAuthorisationReasonEnum {
  //#region Standard Order Authorisations Reasons

  // [Description("Maximum price exceeded for standard product item")]
  MaxItemPriceExceededStandardProduct = 1,
  //  [Description("Maximum order price exceeded")]
  MaxOrderPriceExceeded = 2,
  //  [Description("Pin user doesn't have contract service speed")]
  PinUserDoesntHaveContractServiceSpeed = 3,
  //  [Description("Pin group service type requires authorisation")]
  PinGroupServiceType = 4,
  //  [Description("Pin group contract product requires authorisation")]
  PinGroupContractProduct = 5,

  //  #region Special Order Authorisation Reasons

  //[Description("Maximum special order price exceeded")]
  MaxSpecialOrderPriceExceeded = 6,
  //  [Description("Pin user doesn't have contract special service speed")]
  PinUserDoesntHaveContractSpecialServiceSpeed = 7,
  //  [Description("Pin group special service type requires authorisation")]
  PinGroupSpecialServiceType = 8
}

export const ActivityAuthorisationReasonEnumDisplayNames = {
  // Standard
  [ActivityAuthorisationReasonEnum.MaxItemPriceExceededStandardProduct]:
    'Maximum price exceeded for standard product item',
  [ActivityAuthorisationReasonEnum.MaxOrderPriceExceeded]: 'Maximum order price exceeded',
  [ActivityAuthorisationReasonEnum.PinUserDoesntHaveContractServiceSpeed]:
    "Pin user doesn't have contract service speed",
  [ActivityAuthorisationReasonEnum.PinGroupServiceType]: 'Pin group service type requires authorisation',
  [ActivityAuthorisationReasonEnum.PinGroupContractProduct]: 'Pin group contract product requires authorisation',
  // Special
  [ActivityAuthorisationReasonEnum.MaxSpecialOrderPriceExceeded]: 'Maximum special order price exceeded',
  [ActivityAuthorisationReasonEnum.PinUserDoesntHaveContractSpecialServiceSpeed]:
    "Pin user doesn't have contract special service speed",
  [ActivityAuthorisationReasonEnum.PinGroupSpecialServiceType]: 'Pin group special service type requires authorisation'
};

// SERVER: Two Person Charge Enum
export enum TwoPersonChargeAvailableEnum {
  NotAvailable = 0,
  Product = 1,
  PropertyType = 2,
  ProductAndPropertyType = Product | PropertyType
}

export enum ActivitiesAvailableEnum {
  Standard = 1,
  Special = 2,
  StandardAndSpecial = Standard | Special
}

export const fileUploadsToMediaTypeMap = {
  generalNotesFiles: MediaTypeEnum.GeneralNoteFiles,
  authorisationNoteFiles: MediaTypeEnum.AuthorisationNoteFiles,
  clinicalNoteFiles: MediaTypeEnum.ClinicalNoteFiles,
  riskAssessmentNoteFiles: MediaTypeEnum.RiskAssessmentNoteFiles,
  specialActivityAuthorisationNoteFiles: MediaTypeEnum.SpecialAuthorisationNoteFiles
};

export const mediaTypeToFileUploads = Object.fromEntries(
  Object.entries(fileUploadsToMediaTypeMap).map((a) => a.reverse())
);

export type CheckoutFileUploadTypes = keyof typeof fileUploadsToMediaTypeMap;

//export const mediaTypeToFileUploads = new Map<MediaTypeEnum, string>();
//fileUploadsToMediaTypeMap.forEach((value, key) => mediaTypeToFileUploads.set(value, key));
// would prefer something like this, but typescript is giving me grief
// const inv = new Map(Array.from(origMap, a => a.reverse()));

//type keysaa = keyof typeof fileUploadsToMediaTypeMap;

//type KeyOfMap<M extends Map<unknown, unknown>> = M extends Map<infer K, unknown> ? K : never

//type t = KeyOfMap<fileUploadsToMediaTypeMap>;
