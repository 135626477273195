import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, IApiResponse } from 'millbrook-core';
import { getItem, postItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';

export interface TelecareFormModel {
  contractId: ApiId;
  title: string;
  questionnaireScript: string;
  attemptAutoPopulation: boolean;
  autoPopulationFromCares: boolean;
  telecareEnabled: boolean;
}

export interface TelecareSubmitModel {
  ServiceUserId: ApiId;
  PinUserId: ApiId;
  ActivityId: ApiId;
  OriginalQuestionnaireScript: string;
  CompletedResultsJson: string;
}

interface TelecareState {
  telecareFormData?: TelecareFormModel;
}

const initialState: TelecareState = {
  telecareFormData: undefined
};

const telecareSlice = createSlice({
  name: 'telecare',
  initialState,
  reducers: {
    setTelecareForm(state, action: PayloadAction<TelecareFormModel | undefined>) {
      state.telecareFormData = action.payload;
    }
  }
});

export const { setTelecareForm } = telecareSlice.actions;

export type TelecareFormModelResponse = IApiResponse<TelecareFormModel>;
export type TelecareSubmitModelResponse = IApiResponse<TelecareSubmitModel>;

// thunks
export const fetchTelecareForm =
  (contractId: string): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, TelecareFormModelResponse>(ENDPOINTS.TELECARE.telecareform(contractId)).then((response) => {
      dispatch(setTelecareForm(response.result));
      return response;
    });
  };

export const createTelecare =
  (telecareData: TelecareSubmitModel): AppThunk =>
  async (dispatch) => {
    return postItems<TelecareSubmitModel, TelecareSubmitModelResponse>(
      ENDPOINTS.TELECARE.addtelecare,
      telecareData
    ).then((response) => {
      return response;
    });
  };

export const selectTelecareForm = (state: RootState) => state.telecare && state.telecare.telecareFormData;

export default telecareSlice.reducer;
