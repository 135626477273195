import { Box, makeStyles, Tab, Tabs, Theme } from '@material-ui/core';
import { ROUTES } from 'constants/routes';
import { fetchActivityGuidByReference } from 'features/activities/activitiesData.slice';
import {
  SearchTypes,
  selectDialogIsSwap,
  selectDialogState,
  selectDialogTabIndex,
  selectDialogTabsHidden,
  setCloseSearchDialog,
  setDialogTabIndex,
  setOrdersForm,
  setProductsForm
} from 'features/search/search.slice';
import {
  ActivityReferenceLookupResponseModel,
  COLORS,
  confirmDialog,
  snackbarUtils,
  StyledButton,
  StyledDialog
} from 'millbrook-core';
import qs from 'query-string';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { OrdersSearchForm, ORDER_SEARCH_FORM_ID } from '../OrdersSearchForm/OrdersSearchForm';
import { OrderSearchFormData } from '../OrdersSearchForm/OrdersSearchForm.validation';
import {
  ProductSearchForm,
  ProductSearchFormData,
  PRODUCT_SEARCH_FORM_ID
} from '../ProductSearchForm/ProductSearchForm';
import {
  ServiceUsersSearchForm,
  ServiceUsersSearchFormData,
  SERVICE_USER_SEARCH_FORM_ID
} from '../ServiceUsersSearchForm/ServiceUsersSearchForm';

const useStyles = makeStyles((theme: Theme) => ({
  dialogHeight: {
    height: '80vh'
  },
  tabButton: {
    minHeight: 75
  }
}));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index: SearchTypes) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: SearchTypes;
  value: any;
}

interface SearchDialogProps {
  onClose?: () => any;
}

export const SearchDialog: React.FC<SearchDialogProps> = ({ onClose }) => {
  /* hooks */
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const open = useSelector(selectDialogState);
  const hideTab = useSelector(selectDialogTabsHidden);
  const tabValue = useSelector(selectDialogTabIndex);
  const isSwap = useSelector(selectDialogIsSwap);

  /* state */
  const [headingTitle, setHeadingTitle] = useState('Search for');
  const [currentFormId, setCurrentFormId] = useState(PRODUCT_SEARCH_FORM_ID);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    dispatch(setDialogTabIndex(newValue));
  };

  /* effects */
  useEffect(() => {
    let title: string = 'Search for';
    if (hideTab) {
      switch (tabValue) {
        case SearchTypes.Order:
          title += ' orders';
          break;
        case SearchTypes.User:
          title += ' service users';
          break;
        case SearchTypes.Product:
          title += ' products';
          break;
      }
      if (isSwap) {
        title += ' to swap';
      }
    }
    setHeadingTitle(title);
  }, [hideTab, tabValue, isSwap]);

  useEffect(() => {
    switch (tabValue) {
      case SearchTypes.Order:
        setCurrentFormId(ORDER_SEARCH_FORM_ID);
        break;
      case SearchTypes.User:
        setCurrentFormId(SERVICE_USER_SEARCH_FORM_ID);
        break;
      case SearchTypes.Product:
        setCurrentFormId(PRODUCT_SEARCH_FORM_ID);
        break;
    }
  }, [tabValue]);

  /* events */
  const handleClose = () => {
    onClose && onClose();
    dispatch(setCloseSearchDialog());
  };

  const handleSubmitProductSearch = async (data: ProductSearchFormData) => {
    handleClose();
    dispatch(setProductsForm(data));
    let params = qs.stringify(data, { skipEmptyString: true });
    if (isSwap) {
      history.push(ROUTES.ACTIVITIES.swapProduct + '?' + params);
    } else {
      history.push(ROUTES.PRODUCT.searchResults + '?' + params);
    }
  };

  const handleSubmitOrderSearch = (data: OrderSearchFormData) => {
    if (data.activityReference && data.activityReferenceExact) {
      dispatch<any>(fetchActivityGuidByReference(data.activityReference)).then(
        (activity: ActivityReferenceLookupResponseModel) => {
          let message = '';

          if (!activity.isSearchValid) {
            message = `Activity ${data.activityReference} was found, but is on a different contract.`;
          } else if (!activity.activityId) {
            message = `Activity ${data.activityReference} not found, please check your reference code.`;
          }

          if (message) {
            confirmDialog({
              title: 'Activity reference lookup',
              message,
              onlyOkay: true,
              confirmButtonLabel: 'Close'
            });
          } else {
            // activityId can't be null/undefined at this point, but the compiler can't derive that properly hence the usage of !
            handleClose();
            history.push(ROUTES.ACTIVITIES.activityDetails(activity.activityId!));
          }
        },
        (e: any) => {
          snackbarUtils.error(e.message);
        }
      );
    } else {
      handleClose();
      dispatch(setOrdersForm(data));
      history.push(ROUTES.ACTIVITIES.root);
    }
  };

  const handleSubmitServiceUSerSearch = (data: ServiceUsersSearchFormData) => {
    handleClose();

    // if page is the search results page, the replace rather than push.
    const isUserSearchResultsPage = !!matchPath(location.pathname, { path: ROUTES.SERVICE_USERS.searchResults });

    history[isUserSearchResultsPage ? 'replace' : 'push'](ROUTES.SERVICE_USERS.searchResults, {
      ...data,
      user: {
        ...data.user,
        dob: data.user.dob || ''
      }
    });
  };

  return (
    <StyledDialog
      heightClass={!hideTab ? classes.dialogHeight : undefined}
      open={open}
      onClose={handleClose}
      headingTitle={headingTitle}
      footerChild={
        <StyledButton type="submit" form={currentFormId}>
          Show results
        </StyledButton>
      }
      headerChild={
        !hideTab && (
          <Box bgcolor={COLORS.edward20}>
            <Tabs
              textColor="primary"
              indicatorColor="primary"
              variant="fullWidth"
              value={tabValue}
              onChange={handleChange}
            >
              <Tab className={classes.tabButton} label="Products" {...a11yProps(SearchTypes.Product)} />
              <Tab className={classes.tabButton} label="Orders" {...a11yProps(SearchTypes.Order)} />
              <Tab className={classes.tabButton} label="Service users" {...a11yProps(SearchTypes.User)} />
            </Tabs>
          </Box>
        )
      }
    >
      <TabPanel value={tabValue} index={SearchTypes.Product}>
        <ProductSearchForm onSubmit={handleSubmitProductSearch} />
      </TabPanel>
      <TabPanel value={tabValue} index={SearchTypes.Order}>
        <OrdersSearchForm onSubmit={handleSubmitOrderSearch} />
      </TabPanel>
      <TabPanel value={tabValue} index={SearchTypes.User}>
        <ServiceUsersSearchForm onSubmit={handleSubmitServiceUSerSearch} />
      </TabPanel>
    </StyledDialog>
  );
};
