import { combineReducers } from '@reduxjs/toolkit';
import serviceUser from 'features/service-users/serviceUser.slice';
import editing from 'features/service-users/editing.slice';
import merging from 'features/service-users/merging.slice';

/* reducers */
export default combineReducers({
  serviceUser,
  editing,
  merging
});
