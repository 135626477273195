import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { addDays, endOfDay, isBefore, isSameDay, startOfDay } from 'date-fns';
import { fetchServiceCentre } from 'features/service-centres/serviceCentres.slice';
import {
  ApiId,
  getStartOfTheWeek,
  IApiResponse,
  IServiceCentreSummary,
  IsoDate,
  mapApiErrors,
  ServiceTypeCodeEnum
} from 'millbrook-core';
import { getItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';

/* types */
export interface DailyTotals {
  activityCount: number;
  twoPersonActivityCount: number;
  jointVisitActivityCount: number;
  totalTime?: number;
}

export interface ServiceTypeDailyTotals extends DailyTotals {
  contractServiceTypeId: ApiId;
  serviceTypeId: ApiId;
  serviceTypeCode: ServiceTypeCodeEnum;
  serviceTypeName: string;
}

export interface CapacityDates {
  startDate: IsoDate;
  endDate: IsoDate;
}

export interface DailyActivityTotals {
  date: IsoDate;
  serviceTypesTotals?: ServiceTypeDailyTotals[];
  activityCount: number;
  twoPersonActivityCount: number;
  jointVisitActivityCount: number;
  twoPersonAsCategory: DailyTotals;
  postalAndCounterAsCategory: DailyTotals;
}

export type CapacityResponse = IApiResponse<DailyActivityTotals[]>;
export type CapacityServiceCentreResponse = IApiResponse<IServiceCentreSummary>;

/* state */
interface CapacityState {
  serviceCentreId?: ApiId;
  serviceCentre?: IServiceCentreSummary;
  capacity?: DailyActivityTotals[];
  startDate: IsoDate;
  endDate: IsoDate;
  selectedDate: IsoDate;
  viewCapacity: boolean;
  currentWeekCapacity?: DailyActivityTotals[];
}

const today = new Date();
const weekStart = getStartOfTheWeek();
const initialState: CapacityState = {
  startDate: weekStart.toISOString(),
  endDate: addDays(endOfDay(weekStart), 6).toISOString(),
  selectedDate: today.toISOString(),
  viewCapacity: false,
  capacity: []
};

/* slice */
const capacity = createSlice({
  name: 'capacity',
  initialState,
  reducers: {
    setCapacityServiceCentreId(state, action: PayloadAction<ApiId | undefined>) {
      state.serviceCentreId = action.payload;
    },
    setCapacityServiceCentre(state, action: PayloadAction<IServiceCentreSummary | undefined>) {
      state.serviceCentre = action.payload;
      state.serviceCentreId = action.payload?.id;
    },
    setCapacityDates(state, action: PayloadAction<IsoDate>) {
      if (action.payload) {
        const selectedDate = new Date(action.payload);
        const startOfWeek = getStartOfTheWeek(selectedDate);

        state.startDate = startOfWeek.toISOString();
        state.endDate = addDays(startOfWeek, 6).toISOString();
        state.selectedDate = action.payload;
      }
    },
    setCapacity(state, action: PayloadAction<DailyActivityTotals[]>) {
      state.capacity = action.payload;
    },
    setCurrentWeekCapacity(state, action: PayloadAction<DailyActivityTotals[]>) {
      state.currentWeekCapacity = action.payload;
    },
    setViewCapacity(state, action: PayloadAction<boolean>) {
      state.viewCapacity = action.payload;

      if (!action.payload) {
        state.startDate = initialState.startDate;
        state.endDate = initialState.endDate;
        state.selectedDate = initialState.selectedDate;
      }
    },
    setCapacitySelectedDate(state, action: PayloadAction<IsoDate>) {
      state.selectedDate = action.payload;
    },
    unsetCapacity(state) {
      state.startDate = initialState.startDate;
      state.endDate = initialState.endDate;
      state.selectedDate = initialState.selectedDate;
      state.serviceCentreId = initialState.serviceCentreId;
      state.serviceCentre = initialState.serviceCentre;
      state.capacity = initialState.capacity;
      state.viewCapacity = initialState.viewCapacity;
      state.currentWeekCapacity = initialState.currentWeekCapacity;
    }
  }
});

/* thunks */
export const fetchPeriodCapacity =
  (serviceCentre: ApiId, startDate: IsoDate, endDate: IsoDate): AppThunk =>
  async (dispatch, getState) => {
    if (!serviceCentre || !startDate || !endDate) {
      return;
    }

    const start = startOfDay(new Date(startDate));
    const end = endOfDay(new Date(endDate));

    return getItems<CapacityResponse>(
      `${
        ENDPOINTS.ACTIVITIES.SERVICE_CENTRE_CAPACITY
      }?serviceCentreId=${serviceCentre}&fromDate=${start.toISOString()}&toDate=${end.toISOString()}`
      // TODO - don’t know if caching should happen just now as we are scheduling things left right and centre and can’t see the scheduled numbers
      // on the capacity straight away with the cache in place. Need to think of an optimal time to cache it
      // {
      //   cacheName: CAPACITY_CACHE
      // }
    )
      .then((response) => {
        return response.result || [];
      })
      .catch((response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      });
  };

// TODO: this may not be needed. leaving for now
export const fetchThisWeeksCapacity =
  (serviceCentre: ApiId): AppThunk =>
  async (dispatch, getState) => {
    if (!serviceCentre) {
      return;
    }

    const start = today.toISOString();
    const end = endOfDay(addDays(today, 6)).toISOString();

    return dispatch<any>(fetchPeriodCapacity(serviceCentre, start, end))
      .then((response: DailyActivityTotals[]) => {
        dispatch(setCurrentWeekCapacity(response));
      })
      .catch((response: any) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      });
  };

export const fetchCapacity =
  (serviceCentre: ApiId): AppThunk =>
  async (dispatch, getState) => {
    const startDate = getState().activities.capacity.startDate;
    const endDate = getState().activities.capacity.endDate;

    if (!serviceCentre || !startDate || !endDate) {
      return Promise.reject('No service centre');
    }

    const start = startOfDay(new Date(startDate)).toISOString();
    const end = endOfDay(new Date(endDate)).toISOString();

    return dispatch<any>(fetchPeriodCapacity(serviceCentre, start, end))
      .then((response: DailyActivityTotals[]) => {
        dispatch(setCapacity(response));
      })
      .catch((response: any) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      });
  };

export const initServiceCentreCapacity = (): AppThunk => async (dispatch, getState) => {
  const serviceCentreId = getState().activities.capacity.serviceCentreId;

  if (!serviceCentreId) {
    throw new Error();
  }

  return Promise.all([
    dispatch<any>(fetchServiceCentre(serviceCentreId)),
    dispatch<any>(fetchCapacity(serviceCentreId))
  ])
    .then((responses) => {
      dispatch(setCapacityServiceCentre(responses[0]));
    })
    .catch((response) => {
      const error = mapApiErrors(response);
      return new Error(error);
    });
};

/* actions */
export const {
  setCapacityServiceCentreId,
  setCapacityServiceCentre,
  setCapacityDates,
  setCapacity,
  setCurrentWeekCapacity,
  setViewCapacity,
  setCapacitySelectedDate,
  unsetCapacity
} = capacity.actions;

/* selectors */
export const selectCapacityServiceCentreId = (state: RootState) => state.activities.capacity.serviceCentreId;
export const selectCapacityServiceCentre = (state: RootState) => state.activities.capacity.serviceCentre;
export const selectViewingCapacity = (state: RootState) => state.activities.capacity.viewCapacity;
export const selectCurrentWeekCapacity = (state: RootState) => state.activities.capacity.currentWeekCapacity;
export const selectCapacity = (state: RootState) => state.activities.capacity.capacity;
export const selectCapacityStartDate = (state: RootState) => state.activities.capacity.startDate;
export const selectCapacityEndDate = (state: RootState) => state.activities.capacity.endDate;
export const selectCapacityDates = createSelector(
  [selectCapacityStartDate, selectCapacityEndDate],
  (startDate, endDate) => {
    return {
      startDate,
      endDate
    };
  }
);
export const selectCapacityWeek = createSelector(
  [selectCapacity, selectCapacityStartDate, selectCapacityEndDate],
  (periodCapacity, startDate, endDate) => {
    let weekCapacity: DailyActivityTotals[] = [];

    if (startDate && endDate) {
      let day = startOfDay(new Date(startDate));
      let finalDay = startOfDay(addDays(new Date(endDate), 1));

      const addAnotherDay = () => {
        const dayCapacity = periodCapacity?.find((capacityDay: DailyActivityTotals) => {
          return capacityDay.date ? isSameDay(startOfDay(new Date(capacityDay.date)), day) : false;
        });

        weekCapacity.push(
          dayCapacity || {
            date: day.toISOString(),
            activityCount: 0,
            twoPersonActivityCount: 0,
            jointVisitActivityCount: 0,
            twoPersonAsCategory: {
              activityCount: 0,
              twoPersonActivityCount: 0,
              jointVisitActivityCount: 0
            },
            postalAndCounterAsCategory: {
              activityCount: 0,
              twoPersonActivityCount: 0,
              jointVisitActivityCount: 0
            },
            serviceTypesTotals: []
          }
        );

        day = addDays(day, 1);
      };

      while (isBefore(day, finalDay)) {
        addAnotherDay();
      }
    }
    return weekCapacity;
  }
);
export const selectCapacitySelectedDate = (state: RootState) => state.activities.capacity.selectedDate;
export const selectCapacitySelectedDateTotals = createSelector(
  [selectCapacity, selectCapacitySelectedDate],
  (periodCapacity, selectedDate) => {
    return (
      periodCapacity?.find((day: DailyActivityTotals) => {
        return selectedDate && day.date
          ? isSameDay(startOfDay(new Date(day.date)), startOfDay(new Date(selectedDate)))
          : false;
      }) || {
        date: selectedDate,
        activityCount: 0,
        twoPersonActivityCount: 0,
        jointVisitActivityCount: 0,
        twoPersonAsCategory: {
          activityCount: 0,
          twoPersonActivityCount: 0,
          jointVisitActivityCount: 0
        },
        postalAndCounterAsCategory: {
          activityCount: 0,
          twoPersonActivityCount: 0,
          jointVisitActivityCount: 0
        },
        serviceTypesTotals: []
      }
    );
  }
);

/* reducers */
export default capacity.reducer;
