import { Avatar, Box, Card, CardActionArea, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { ApiId, BodyText, COLORS, RequireAtLeastOne } from 'millbrook-core';

export const AVATAR_COLORS: any = {
  a: COLORS.scienceBlue,
  b: COLORS.edward140,
  c: COLORS.edward,
  d: COLORS.curiousBlue,
  e: COLORS.hibiscus,
  f: COLORS.jungleGreen,
  g: COLORS.regalBlue,
  h: COLORS.curiousBlue,
  i: COLORS.mySin,
  j: COLORS.error,
  k: COLORS.scienceBlue,
  l: COLORS.edward140,
  m: COLORS.edward,
  o: COLORS.curiousBlue,
  p: COLORS.hibiscus,
  q: COLORS.jungleGreen,
  r: COLORS.regalBlue,
  s: COLORS.curiousBlue,
  t: COLORS.mySin,
  u: COLORS.error,
  v: COLORS.scienceBlue,
  w: COLORS.edward140,
  x: COLORS.edward,
  y: COLORS.curiousBlue,
  z: COLORS.hibiscus,
  0: COLORS.scienceBlue,
  1: COLORS.edward140,
  '2': COLORS.edward,
  3: COLORS.curiousBlue,
  4: COLORS.hibiscus,
  5: COLORS.jungleGreen,
  6: COLORS.regalBlue,
  7: COLORS.curiousBlue,
  8: COLORS.mySin,
  9: COLORS.error
};

const getAvatarColor = (name?: string) => {
  if (!name) {
    return AVATAR_COLORS.a;
  }
  const color = AVATAR_COLORS[name[0].toLocaleLowerCase()];

  return color ? color : AVATAR_COLORS.a;
};

function avatarInitials(name = '') {
  // take the name and remove "and"

  name = name.replace(/ \band\b/g, '').toUpperCase();
  let firstLetters = name.match(/\b[0-9A-Z]/g) || [];
  return firstLetters.slice(0, 2).join('');
}

interface StyleProps {
  color: string;
  layout?: 'grid' | 'list';
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  card: {
    height: ({ layout }) => (layout === 'grid' ? '100%' : undefined),
    marginBottom: ({ layout }) => (layout === 'list' ? theme.spacing(2) : undefined)
  },
  cardGrid: {
    height: '100%'
  },
  cardList: {
    marginBottom: theme.spacing(2)
  },
  cardBlocked: {
    background: '#eeeeee',
    pointer: 'disabled'
  },
  cardInner: {
    padding: theme.spacing(2),
    display: 'flex',
    height: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  avatar: {
    height: '50px',
    width: '50px',
    background: ({ color }) => color
  },
  ctaLink: {
    alignSelf: 'center',
    flexShrink: 0,
    marginLeft: 'auto'
  }
}));

type PinCardContractData = RequireAtLeastOne<
  {
    contractName?: string;

    // Used for the pin user contract info
    id?: ApiId;
    pinTeamName?: string;
    pinNumber?: string;

    // Used for service user contact info
    serviceUserId?: ApiId;
  },
  'id' | 'serviceUserId'
>;

interface PinCardProps {
  pin: PinCardContractData;
  onClick?(id: ApiId): void;
  isActive?: boolean;
  layout?: 'grid' | 'list';
  disabled?: boolean;
  ctaLinkText?: string;
}

export const PinCard = ({ pin, onClick, isActive, layout, disabled, ctaLinkText }: PinCardProps) => {
  const classes = useStyles({ color: getAvatarColor(pin.contractName), layout: layout });

  const id = pin.id || pin.serviceUserId;

  return (
    <Card
      elevation={isActive && !disabled ? 3 : 0}
      variant={isActive && !disabled ? 'elevation' : 'outlined'}
      className={clsx(classes.card, layout === 'list' && classes.cardList, disabled && classes.cardBlocked)}
    >
      <CardActionArea
        disabled={!onClick || isActive || disabled}
        className={classes.cardInner}
        onClick={onClick && id ? () => onClick(id) : undefined}
      >
        <Avatar variant="rounded" className={classes.avatar}>
          {avatarInitials(pin.contractName)}
        </Avatar>
        <Box ml={2}>
          <BodyText component="h2" type="large" color="regalBlue">
            {pin.contractName}
          </BodyText>
          {pin.pinTeamName && <BodyText type="regular">{pin.pinTeamName}</BodyText>}
          {pin.pinNumber && <BodyText type="small">{pin.pinNumber}</BodyText>}
          {disabled && (
            <BodyText type="small" color="error">
              PIN blocked, contact administrator
            </BodyText>
          )}
        </Box>
        {ctaLinkText && (
          <BodyText type="small" color="scienceBlue" className={classes.ctaLink}>
            View record
          </BodyText>
        )}
      </CardActionArea>
    </Card>
  );
};
