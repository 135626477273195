import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import {
    ApiId,
    ContractHoldTimes,
    ContractHoldTimesResponse,
    HoldTime,
    IApiResponse,
    IMediaModel,
    mapApiErrors
} from 'millbrook-core';
import { getItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';

/* types */
export interface ContractInfoModel {
    contractId: ApiId;
    openingTime?: string;
    closingTime?: string;
    telephoneNumber: string;
    isOutOfHours: boolean;
    prescriberLogos: IMediaModel[];
    hireTrialEnabled: boolean;
    hireLength?: number;
    trialLength?: number;
    displayStockLevels: boolean;
    displayCheaperAlternatives: boolean;
    soundexSearchServiceUserSurnameEnabled: boolean;
    soundexSearchServiceUserSurnameDefault: boolean;
}

/* state */
interface State extends ContractInfoModel {
    showOutOfHoursDialog: boolean;
    recycledSpecialHoldTimes?: HoldTime[];
    hasPeripheralStores: boolean;
    hasSpecialRequisitions: boolean;
    hasOrderableServices: boolean;
}

const initialState: State = {
    contractId: '',
    telephoneNumber: '',
    isOutOfHours: false,
    prescriberLogos: [],
    hireTrialEnabled: false,
    showOutOfHoursDialog: false,
    hasPeripheralStores: false,
    hasSpecialRequisitions: false,
    hasOrderableServices: false,
    displayStockLevels: false,
    displayCheaperAlternatives: false,
    soundexSearchServiceUserSurnameEnabled: false,
    soundexSearchServiceUserSurnameDefault: false
};

/* slice */
const slice = createSlice({
    name: 'contract',
    initialState,
    reducers: {
        setContractData(state, action: PayloadAction<ContractInfoModel>) {
            return { ...state, ...action.payload };
        },
        showOutOfHoursDialog(state) {
            state.showOutOfHoursDialog = true;
        },
        hideOutOfHoursDialog(state) {
            state.showOutOfHoursDialog = false;
        },
        setRecycledSpecialHoldTimes(state, action: PayloadAction<ContractHoldTimes | undefined>) {
            state.recycledSpecialHoldTimes = action.payload?.holdTimes;
        }
    }
});

/* thunks */
export const fetchContractData = (): AppThunk => async (dispatch) => {
    return getItems<IApiResponse<ContractInfoModel>>(ENDPOINTS.SETTINGS.CONTRACT_INFO, {
        enableGlobalErrorDialog: true
    }).then(
        (response) => {
            dispatch(setContractData(response.result || ({} as ContractInfoModel)));
            return response.result;
        },
        () => {
            // handled with global error handler
        }
    );
};

export const fetchContractRecycledSpecialHoldTimes = (): AppThunk => async (dispatch, getState) => {
    const contractId = getState().contract.contractId;

    if (contractId) {
        return getItems<ContractHoldTimesResponse>(ENDPOINTS.CONTRACT_RECYCLED_SPECIAL_HOLD_TIMES(contractId)).then(
            (response) => {
                dispatch(setRecycledSpecialHoldTimes(response.result));
            },
            (response) => {
                const error = mapApiErrors(response);
                throw new Error(error);
            }
        );
    }
};

export const fetchPortalRecycledSpecialHoldTimes = (): AppThunk => async (dispatch, getState) => {
    const contractId = getState().contract.contractId;

    if (contractId) {
        return getItems<ContractHoldTimesResponse>(ENDPOINTS.PRODUCTS.PORTAL_RECYCLED_SPECIAL_HOLD_TIMES(contractId), {
            cacheName: 'holdtimes',
            cacheExpires: 10
        }).then(
            (response) => {
                dispatch(setRecycledSpecialHoldTimes(response.result));
            },
            (response) => {
                const error = mapApiErrors(response);
                throw new Error(error);
            }
        );
    }
};

/* actions */
export const { setContractData, showOutOfHoursDialog, hideOutOfHoursDialog, setRecycledSpecialHoldTimes } =
    slice.actions;

/* selectors */
export const selectContractData = (state: RootState) => state.contract;
export const selectTelephoneNumber = (state: RootState) => state.contract.telephoneNumber;
export const selectShowOutOfHoursDialog = (state: RootState) => state.contract.showOutOfHoursDialog;
export const selectContractId = (state: RootState) => state.contract.contractId;
export const selectRecycledSpecialHoldTimes = (state: RootState) => state.contract.recycledSpecialHoldTimes || [];
export const selectPrescriberLogo = (state: RootState) => state.contract.prescriberLogos;
export const selectHasPeripheralStores = (state: RootState) => state.contract.hasPeripheralStores;
export const selectHasSpecialRequisitions = (state: RootState) => state.contract.hasSpecialRequisitions;

export default slice.reducer;
