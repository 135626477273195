import { Box } from '@material-ui/core';
import { ProductCard } from 'components/Cards/ProductCard';
import { ApiId, BodyText, IContractProductSummary, StyledGrid } from 'millbrook-core';
import { Fragment } from 'react';

interface ProductListProps {
  products: IContractProductSummary[];
  productDetailsRoute?: (productId: ApiId) => string;
}

export const ProductList: React.FC<ProductListProps> = ({ products, productDetailsRoute }) => {
  /* state */

  /* selectors */
  const productCount = products.length;

  /* paging */
  const hasProducts = !!products?.length;

  const productCountText = () => {
    if (productCount) {
      return `${productCount} product${productCount === 1 ? '' : 's'} found`;
    } else {
      return "Sorry, we couldn't find any products";
    }
  };

  return (
    <Fragment>
      <Box mb={4}>
        <BodyText>{productCountText()}</BodyText>
      </Box>
      <StyledGrid container>
        {hasProducts &&
          products.map((product) => (
            <StyledGrid key={product.contractProductId} item xs={12} sm={6} md={4}>
              <ProductCard product={product} productDetailsRoute={productDetailsRoute} />
            </StyledGrid>
          ))}
      </StyledGrid>
    </Fragment>
  );
};
