import {
  ActivityFiltersEnum,
  ActivityFiltersResponseModel,
  ICheckboxOption,
  mapFilterItemModelToCheckbox
} from 'millbrook-core';

// export enum UnscheduledActivityFilterFieldsEnum {
//   CareOutcomes,
//   Postcodes,
//   PpmTypes,
//   SlaDates,
//   UpdatedDates,
//   OrderDates,
//   ActivitySpeeds,
//   ReasonCodes
// }

const { Statuses, ...UnscheduledActivityFilterFieldsEnum } = ActivityFiltersEnum;

// next line disabled because the UnscheduledActivityFilterFieldsEnum is a both a type and an enum. This is where I got it from https://devimalplanet.com/typescript-how-to-extend-one-enum-from-another
// eslint-disable-next-line
type UnscheduledActivityFilterFieldsEnum = Exclude<ActivityFiltersEnum, typeof ActivityFiltersEnum.Statuses>;

export { UnscheduledActivityFilterFieldsEnum };

export type UnscheduledActivityFilterFields = { [key in UnscheduledActivityFilterFieldsEnum]: ICheckboxOption[] };

export const parseUnscheduledActivityFilters = (
  activityFilters: ActivityFiltersResponseModel
): UnscheduledActivityFilterFields => {
  return {
    [UnscheduledActivityFilterFieldsEnum.CareOutcomes]: mapFilterItemModelToCheckbox(activityFilters.careOutcomes),
    [UnscheduledActivityFilterFieldsEnum.PostcodeAreas]: mapFilterItemModelToCheckbox(activityFilters.postcodeAreas),
    [UnscheduledActivityFilterFieldsEnum.PpmTypes]: mapFilterItemModelToCheckbox(activityFilters.ppmTypes),
    [UnscheduledActivityFilterFieldsEnum.SlaDates]: mapFilterItemModelToCheckbox(activityFilters.slaDates),
    [UnscheduledActivityFilterFieldsEnum.UpdateDates]: mapFilterItemModelToCheckbox(activityFilters.updateDates),
    [UnscheduledActivityFilterFieldsEnum.OrderDates]: mapFilterItemModelToCheckbox(activityFilters.orderDates),
    [UnscheduledActivityFilterFieldsEnum.ActivitySpeeds]: mapFilterItemModelToCheckbox(activityFilters.activitySpeeds),
    [UnscheduledActivityFilterFieldsEnum.ReasonCodes]: mapFilterItemModelToCheckbox(activityFilters.reasonCodes),
    [UnscheduledActivityFilterFieldsEnum.EventTypes]: mapFilterItemModelToCheckbox(activityFilters.eventTypes)
  };
};
