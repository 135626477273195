import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { eosDuplicateAddToBasketDialog } from 'components/EosDuplicateAddToBasketDialog/eosDuplicateAddToBasketDialog';
import { push } from 'connected-react-router';
import { ENDPOINTS } from 'constants/api';
import { ROUTES } from 'constants/routes';
import { ActivitContractServiceType } from 'features/activities/activity.types';
import { selectCurrentStep } from 'features/checkout/selectors/initial.selectors';
import { productdetailsCacheName } from 'features/products/products.slice';
import { setOpenSearchDialog, setOpenUserSearchDialog } from 'features/search/search.slice';
import {
  ApiId,
  BasketItem,
  BasketModel,
  confirmDialog,
  ExistingActivityDetailsModel,
  IApiResponse,
  IContractProductSummary,
  mapApiErrors,
  ProductAction,
  ServiceTypeCodeEnum,
  showGenericErrorDialog,
  snackbarUtils
} from 'millbrook-core';
import { deleteItem, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { BaseServiceUser } from 'types';
import { selectBasketItems } from './basket.selector';
import {
  BasketPutRequest,
  BasketPutReviewRequest,
  BasketResponse,
  BasketUpdateData,
  ProductReview
} from './basket.types';

/* types */

// BE - simplify this:
export interface BasketServiceModel extends Omit<ActivitContractServiceType, 'serviceTypeId'> {
  contractServiceTypeId: ApiId;
  contractServiceType: ActivitContractServiceType;
}

/* state */
interface BasketState {
  basket?: BasketModel;
  deliveryCost?: number;
  lastAddedProduct?: IContractProductSummary;
  selectedServiceUser?: BaseServiceUser;
  serviceUserReturnUrl?: string;
  // This is to store when the user clicks "add to basket", when they return to the product listing it should complete the action they were intending
  // there is a check in place so this can only be triggered when the user select "continue with order" from the client card.
  serviceUserAddToBasketProduct?: { productId: ApiId; quantity: number; continueWithOrder?: boolean };
}

const initialState: BasketState = {};

/* slice */
const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    addProduct: (state, action: PayloadAction<IContractProductSummary>) => {
      state.lastAddedProduct = action.payload;
    },
    clearLastAddedProduct: (state) => {
      state.lastAddedProduct = undefined;
    },
    clearBasket: (state, action?: PayloadAction<boolean | undefined>) => {
      // default to keeping the service user attached to the basket. So send true to the dispatch to clear the service user.
      const selectedServiceUser = action?.payload ? undefined : state.selectedServiceUser;
      return { ...initialState, selectedServiceUser };
    },
    setServiceUser: (state, action: PayloadAction<BaseServiceUser>) => {
      state.selectedServiceUser = action.payload;
      state.serviceUserReturnUrl = undefined;
    },
    updateBasket: (state, action: PayloadAction<BasketModel | undefined>) => {
      state.basket = action.payload;
    },
    refreshBasket: (state) => {
      // This is a method to rerender the basket items when nothing has actually changed.
      // This is used when there is an error in the basket item update
      if (state.basket) {
        state.basket.basketItems = state.basket.basketItems.map((x) => ({ ...x }));
      }
    },
    setAddToBasketReturnProduct: (state, action: PayloadAction<{ productId: ApiId; quantity: number }>) => {
      state.serviceUserAddToBasketProduct = action.payload;
    },
    confirmAddToBasketReturnProduct: (state) => {
      state.serviceUserAddToBasketProduct && (state.serviceUserAddToBasketProduct.continueWithOrder = true);
    },
    clearAddToBasketReturnProduct: (state) => {
      state.serviceUserAddToBasketProduct = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setOpenUserSearchDialog, (state, action) => {
        // --action payload cases--
        // undefined or true = use current url
        // string = set to value
        // false = set to undefined

        let url: string | undefined = undefined;
        // if there is a location sent in the action, set the return url
        if (typeof action.payload === 'string') {
          url = action.payload;
        } else if (action.payload === true || action.payload === undefined) {
          // if there is no return url set or it is not defined, then use the current location
          const location = window.location;
          url = location.pathname + location.search;
        }

        state.serviceUserReturnUrl = url;
      })
      .addCase(setOpenSearchDialog, (state) => {
        // clear the service user return url
        state.serviceUserReturnUrl = undefined;
      });
  }
});

/**
 * THUNKS
 */

export const fetchBasket = (): AppThunk => async (dispatch, getState) => {
  return getItems<IApiResponse<BasketModel>>(ENDPOINTS.BASKET.Basket).then(
    (response) => {
      dispatch(updateBasket(response.result));
    },
    (response) => {
      // error handled globally
    }
  );
};

export const addToBasket =
  (
    product: IContractProductSummary,
    quantity: number = 1,
    notes?: string,
    files?: FileList,
    skipCheckExistingEos?: boolean
  ): AppThunk =>
  async (dispatch, getState) => {
    const servicesUser = getState().basket.selectedServiceUser;
    if (!servicesUser) {
      // return url handling in this slice "basket.slice.ts" as an extra reducer
      // also go straight to the product details page when adding the product
      dispatch(setOpenUserSearchDialog(ROUTES.PRODUCT.productDetails(product.contractProductId)));
      dispatch(setAddToBasketReturnProduct({ productId: product.contractProductId, quantity }));
      return Promise.resolve();
    }

    const formData = new FormData();
    formData.append('serviceUserId', servicesUser.id);
    formData.append('productId', product.contractProductId);
    formData.append('quantity', quantity.toString());
    formData.append('checkExistingEos', (!skipCheckExistingEos).toString());

    if (notes) {
      formData.append('notes', notes);
    }

    if (files) {
      for (let i = 0; i < files.length; i += 1) {
        formData.append('media', files[i]);
      }
    }

    // Errors are captured and custom dialogs are presented.
    // This will only resolve with a "success" boolean where the basket item was added or not.
    return new Promise<boolean>(async (resolve) => {
      try {
        const response = await postItems<FormData, BasketResponse>(ENDPOINTS.BASKET.AddProductToBasket, formData, {
          // clear the cache for the product details
          cacheName: productdetailsCacheName
        });

        const relatedItems = response.result?.basketItems.filter(
          (item) => item.contractProductId === product.contractProductId
        )[0]?.relatedProducts;

        dispatch(updateBasket(response.result));
        // NOTE: this is not the "relatedProducts" found on the contract product. That is the ContractProductLinkModel
        dispatch(addProduct({ ...product, relatedProductsList: relatedItems || [] }));
        resolve(true);
      } catch (response: any) {
        // Errors are a mix of
        // - real api errors
        // - Basket warnings where you can't add to the basket
        // - Basket warning where the product is already an EOS

        const error = mapApiErrors(response);
        const status = response?.response?.status;

        // EOS is 409 and others are 400
        if (status === 409) {
          eosDuplicateAddToBasketDialog(error, servicesUser.id)
            .then(async () => {
              // Try again with the add to basket. Hopefully won't create any loops
              // This should resolve down the chain to the original resolution
              const response = await dispatch<any>(addToBasket(product, quantity, notes, files, true));
              resolve(response);
            })
            .catch(() => {
              resolve(false);
            });
        } else {
          confirmDialog({
            title: "Can't add to basket",
            message: error,
            onlyOkay: true,
            maxWidth: 'sm'
          }).then(() => {
            resolve(false);
          });
        }
      }
    });
  };

export const updateBasketItem =
  (basketItemId: ApiId, data: BasketUpdateData): AppThunk =>
  async (dispatch, getState) => {
    const servicesUser = getState().basket.selectedServiceUser;
    const basketId = getState().basket.basket?.id;

    if (!servicesUser || !basketId) {
      dispatch(setOpenUserSearchDialog());

      return Promise.resolve();
    }

    return putItem<BasketPutRequest, BasketResponse>(
      ENDPOINTS.BASKET.ChangeQuantity,
      { serviceUserId: servicesUser.id, ...data },
      basketItemId,
      {
        // clear the product details cache if there is no quantity (so the reserve item thing isn't stored)
        cacheName: data.quantity === 0 ? productdetailsCacheName : undefined
      }
    ).then(
      (response) => {
        dispatch(updateBasket(response.result));

        snackbarUtils.close();
        snackbarUtils.success(data.quantity === 0 ? 'Basket item deleted' : 'Basket item updated');
      },
      (response) => {
        // error handled globally
        const error = mapApiErrors(response);
        snackbarUtils.error(error);

        // reload the product if there is an error
        dispatch(refreshBasket());
      }
    );
  };

export const putReviewRequest =
  (productReviews: ProductReview[]): AppThunk =>
  async (dispatch, getState) => {
    const servicesUserId = getState().basket.selectedServiceUser;
    const basketId = getState().basket.basket?.id;

    if (!servicesUserId || !basketId) {
      dispatch(setOpenUserSearchDialog());

      return Promise.resolve();
    }

    if (!productReviews || productReviews.length === 0) {
      return Promise.resolve();
    }

    const data = {
      basketId,
      productReviews
    };

    return putItem<BasketPutReviewRequest, BasketResponse>(ENDPOINTS.BASKET.PutProductReviews, data, '').then(
      (response) => {
        dispatch(updateBasket(response.result));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const deleteBasket =
  (keepServiceUser?: boolean): AppThunk =>
  async (dispatch) => {
    return deleteItem<undefined, any>(ENDPOINTS.BASKET.Basket, undefined, {
      enableGlobalErrorDialog: true
    }).then(
      () => {
        dispatch(clearBasket(keepServiceUser));
      },
      () => {
        // handled with global error handler
      }
    );
  };

export const setBasketServiceUser =
  (user: BaseServiceUser): AppThunk =>
  async (dispatch, getState) => {
    const app = getState();
    const selectedServiceUser = selectSelectedServiceUser(app);
    const hasBasket = selectHasProductsInBasket(app);
    const currentUserId = selectedServiceUser?.id;
    const isDifferentUser = currentUserId && currentUserId !== user.id;
    const triggerDeleteBasket = hasBasket && isDifferentUser;

    // clear out the "add to basket" return info if we are switching users and one has already been selected
    // this is just a catch for when you:-
    // 1 - didn't have a service user selected
    // 2 - chose to add to basket then had to find a service
    // 3 - DIDN'T choose to "continue with order"
    // 4 - search for and chose a different service user.
    isDifferentUser && dispatch(clearAddToBasketReturnProduct());

    triggerDeleteBasket && (await dispatch<any>(deleteBasket()));
    dispatch(setServiceUser(user));
  };

export const addRepairOrCollectionToBasket =
  (
    itemIds: ApiId | ApiId[],
    serviceUserId: ApiId,
    action: ProductAction,
    isUnknown: boolean = false // for PCIs
  ): AppThunk =>
  async (dispatch, getState) => {
    const formData = new FormData();
    let totalProducts = 0;

    if (Array.isArray(itemIds)) {
      totalProducts = itemIds.length;

      itemIds.forEach(async (itemId: ApiId, index: number) => {
        // Collection endpoint expects an array of models
        const itemPrefix = action === 'collection' ? `models[${index}].` : '';
        formData.append(`${itemPrefix}serviceUserId`, serviceUserId);
        !isUnknown && formData.append(`${itemPrefix}stockId`, itemId);
        !!isUnknown && formData.append(`${itemPrefix}unknownProductId`, itemId);
      });
    } else {
      const itemPrefix = action === 'collection' ? `models[0].` : '';
      formData.append(`${itemPrefix}serviceUserId`, serviceUserId);
      !isUnknown && formData.append(`${itemPrefix}stockId`, itemIds);
      !!isUnknown && formData.append(`${itemPrefix}unknownProductId`, itemIds);
    }

    const endpoint = action === 'repair' ? ENDPOINTS.BASKET.AddRepairToBasket : ENDPOINTS.BASKET.AddCollectionToBasket;

    return postItems<FormData, IApiResponse<null | string[]>>(endpoint, formData, {
      enableGlobalErrorDialog: true
    })
      .then((response) => {
        // if anything is returned then there has been an error with one or more of the items
        // otherwise continue on to the basket summary page
        if (Array.isArray(response.result) && response.result.length) {
          dispatch(showGenericErrorDialog(response.result.join('||')));
          // if the result list doesn't match the input list then something worked, so update the basket
          if (response.result.length !== totalProducts) {
            dispatch<any>(fetchBasket());
          }
        } else {
          dispatch(push(ROUTES.CHECKOUT.step0));
        }
      })
      .catch(() => {
        // handled with global handler
      });
  };

export const addRepairToBasket =
  (
    itemId: ApiId,
    serviceUserId: ApiId,
    isUnknown: boolean = false // for PCIs
  ): AppThunk =>
  async (dispatch, getState) => {
    const formData = new FormData();

    formData.append(`serviceUserId`, serviceUserId);
    !isUnknown && formData.append(`stockId`, itemId);
    !!isUnknown && formData.append(`unknownProductId`, itemId);

    const endpoint = ENDPOINTS.BASKET.AddRepairToBasket;

    return postItems<FormData, BasketResponse>(endpoint, formData, {
      enableGlobalErrorDialog: true
    })
      .then((response) => {
        dispatch(updateBasket(response.result));
      })
      .catch(() => {
        // error handled with global
      });
  };

export const getOpenOrderDetails = (): AppThunk => async (dispatch, getState) => {
  try {
    const response = await getItems<IApiResponse<ExistingActivityDetailsModel[]>>(
      ENDPOINTS.ACTIVITIES.OPEN_ORDER_DETAILS
    );

    return response.result;
  } catch (response) {
    const error = mapApiErrors(response);
    throw new Error(error);
  }
};

export const swapCteBasketItem =
  (basketItemId: ApiId, contractProductId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return putItem<any, BasketResponse>(
      ENDPOINTS.BASKET.SwapCteBasketItem(basketItemId, contractProductId),
      undefined,
      undefined,
      { enableGlobalErrorDialog: true }
    ).then(
      (response) => {
        dispatch(updateBasket(response.result));
      },
      (error) => {
        // error handled globally
      }
    );
  };

/* actions */
export const {
  addProduct,
  clearBasket,
  clearLastAddedProduct,
  setServiceUser,
  updateBasket,
  refreshBasket,
  setAddToBasketReturnProduct,
  confirmAddToBasketReturnProduct,
  clearAddToBasketReturnProduct
} = basketSlice.actions;

/* selectors */
export const selectBasketState = (state: RootState) => state.basket;
export const selectBasket = (state: RootState) => state.basket.basket;

export const selectGroupedBasketItems = createSelector([selectBasketItems], (basketItems) => {
  const groups: { specialBasketItems: BasketItem[]; standardBasketItems: BasketItem[] } = {
    specialBasketItems: [],
    standardBasketItems: []
  };

  basketItems.forEach((product) =>
    groups[
      product.serviceTypeCode === ServiceTypeCodeEnum.SpecialRequisition ? 'specialBasketItems' : 'standardBasketItems'
    ].push(product)
  );

  return groups;
});

export const selectSpecialBasketItems = createSelector([selectBasketItems], (basketItems) => {
  return basketItems.filter((x) => x.serviceTypeCode === ServiceTypeCodeEnum.SpecialRequisition);
});

export const selectBasketProductsCount = createSelector([selectBasketItems], (products) =>
  products.reduce((prev, current) => prev + current.quantity, 0)
);

export const selectBasketServiceCharges = (state: RootState) => state.basket.basket?.basketServiceCharges || [];

export const selectBasketItemTotal = createSelector([selectBasketItems], (products) =>
  // price should be coming as part of the quote from the service charge. Make sure it does
  products.reduce((prev, current) => prev + (current.price || 0) * current.quantity, 0)
);

export const selectBasketServiceChargesTotal = createSelector([selectBasketServiceCharges], (charges) =>
  charges.reduce((prev, current) => prev + current.price, 0)
);

export const selectBasketTotal = createSelector(
  [selectBasketServiceChargesTotal, selectBasketItemTotal, selectCurrentStep],

  (charges, productTotal, currentStep) => {
    // don't show charges until after step 5
    const showCharges = currentStep.name.match(/6|7/);
    return productTotal + (showCharges ? charges : 0);
  }
);

export const selectHasProductsInBasket = createSelector([selectBasketItems], (products) => products.length > 0);
export const selectLastAddedProduct = (state: RootState) => state.basket.lastAddedProduct;
export const selectSelectedServiceUser = (state: RootState) => {
  if (!state.basket.selectedServiceUser) {
    return null;
  }

  return state.basket.selectedServiceUser;
};

export const selectHasSelectedServiceUser = createSelector([selectSelectedServiceUser], (serviceUser) => {
  return !!serviceUser;
});

export const selectServiceUserReturnUrl = (state: RootState) => state.basket.serviceUserReturnUrl;

export const selectBasketStockWarning = (state: RootState) => state.basket.basket?.basketStockWarning;

export const selectBasketItemsWithCTEs = createSelector([selectBasketItems], (basketItems) =>
  basketItems.filter((basketItem) => basketItem.contractProductId && basketItem.hasCloseTechnicalEquivalents)
);

export const selectServiceUserAddToBasketProduct = (state: RootState) => state.basket.serviceUserAddToBasketProduct;

/* reducer */
export default basketSlice.reducer;
