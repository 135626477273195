// order of imports matters
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// HACK IE11 button form submit outside of form workaround
// TODO: put into core, otherwise don't forget to update the other apps!!!
if (!(window as any).ActiveXObject && 'ActiveXObject' in window) {
  // capture click bubbles
  document.addEventListener('click', (e: any) => {
    const targ = e.target;
    // get the relevant button - note it might be a nested span or something so needs to get the closest button
    if (targ.matches('[form],[form] *')) {
      // get the button
      const button = targ.closest('[form]');
      // get the formId
      const formId = button.getAttribute('form');
      // get the form
      const form = document.getElementById(formId) as HTMLFormElement;
      // make sure that the button isn't inside the form otherwise there will be a double submit
      if (form && !form.querySelector(`[form='${form.id}']`)) {
        // create a new submit event. Note: "new Event" doesn't work
        const evt = document.createEvent('Event');
        evt.initEvent('submit', true, true);
        // submit the event
        form.dispatchEvent(evt);
      }
    }
  });
}
