import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { selectSelectedPinUser } from 'features/auth-pin/auth-pin.slice';
import {
  ApiId,
  BaseSearchPaging,
  IApiResponse,
  IContractProduct,
  IContractProductSummary,
  IEmptyResponse,
  IServerSelectOption,
  ISortOrder,
  mapApiErrors,
  mapIServerTreeViewOptionToTreeViewOptions,
  objectPropExists,
  PagedContractProduct,
  PagedRspProducts
} from 'millbrook-core';
import { ProductFilterSortColumn } from 'pages/Products/components/ProductResultsList/ProductFilter.validation';
import qs from 'query-string';
import { getItem, getItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { ProductCatalogueCategoriesResponse } from './productsCategories.slice';

/* types and interfaces */
export class CatalogueProductFilters extends BaseSearchPaging {
  categoryId?: ApiId = '';
  // TODO: what are category and subcategory regarding filters?
  //  category: string = ''
  //  subCategory: string = '';
  searchTerm?: string = '';
  manufacturer?: string = '';

  public constructor(init?: Partial<CatalogueProductFilters>) {
    super(init);
    // sanitize the object from the querystring
    const test = objectPropExists(this, init);
    Object.assign(this, test);
  }
}

export class RspFilters extends BaseSearchPaging {
  searchText?: string;
  isPooled?: boolean;
  categoryId?: ApiId;

  public constructor(init?: Partial<RspFilters>) {
    super(init);
    // sanitize the object from the querystring
    const test = objectPropExists(this, init);
    Object.assign(this, test);
  }
}

export type ContractProductsResponse = IApiResponse<PagedContractProduct>;
export type MostOrderedResponse = IApiResponse<IContractProductSummary[]>;
export type ContractProductResponse = IApiResponse<IContractProduct>;
export type RecycledSpecialReservationResponse = IApiResponse<IEmptyResponse>;
export type RecycledSpecialReservationRequest = IEmptyResponse;
export type PciTypesResponse = IApiResponse<IServerSelectOption[]>;

/* state */
interface ProductState {
  productList: IContractProductSummary[];
  filter: ProductFilterState;
  sort: ProductSortingState;
}

interface ProductFilterState {
  minimumPrice?: number | null;
  maximumPrice?: number | null;
}

interface ProductSortingState {
  sortColumn: ProductFilterSortColumn;
  sortOrder: ISortOrder;
}

const initialState: ProductState = {
  productList: [],
  sort: {
    sortColumn: 'issueCost',
    sortOrder: 'asc'
  },
  filter: {
    minimumPrice: null,
    maximumPrice: null
  }
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProductList(state, action: PayloadAction<IContractProductSummary[]>) {
      state.productList = action.payload;
    },
    clearProductList(state) {
      state.productList = [];
    },
    setProductReservedToggle(state, action: PayloadAction<ApiId>) {
      const rsp = state.productList.find((x) => x.contractProductId === action.payload);

      if (rsp) {
        rsp.isReserved = !rsp.isReserved;
        rsp.isReservedForUser = !rsp.isReservedForUser;
      }
    },
    setProductSort(state, action: PayloadAction<ProductSortingState>) {
      state.sort = action.payload;
    },
    setProductFilter(state, action: PayloadAction<ProductFilterState>) {
      state.filter = action.payload;
    },
    clearProductSort(state) {
      state.sort = { ...initialState.sort };
    },
    clearProductFilter(state) {
      state.filter = { ...initialState.filter };
    },
    clearProductSortAndFilter(state) {
      state.sort = { ...initialState.sort };
      state.filter = { ...initialState.filter };
    }
  }
});
/* thunks */
export const productdetailsCacheName = 'product-details';

export const fetchContractProducts =
  (filters: CatalogueProductFilters): AppThunk =>
  async (dispatch, getState) => {
    const pinUser = selectSelectedPinUser(getState());
    const sortFilter = selectProductSortAndFilter(getState());

    // clear the store first
    dispatch(clearProductList());

    const filterParams = {
      ...filters,
      ...sortFilter,
      fetchAll: true
    };

    if (pinUser) {
      const URL = `${ENDPOINTS.PRODUCTS.PRODUCT_SEARCH}?${qs.stringify(filterParams, {
        skipEmptyString: true
      })}`;

      return getItems<ContractProductsResponse>(URL, { enableGlobalErrorDialog: true }).then((response) => {
        dispatch(setProductList(response.result?.data || []));
      });
    }
  };

export const fetchProduct =
  (id: ApiId): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, ContractProductResponse>(ENDPOINTS.PRODUCTS.PRODUCT, id, {
      enableGlobalErrorDialog: true,
      cacheName: productdetailsCacheName,
      cacheExpires: 1
    }).then((response) => {
      return response.result || {};
    });
  };

export const createRecycledProductReservation =
  (productId: ApiId, holdTimeId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    if (productId && holdTimeId) {
      return putItem<RecycledSpecialReservationRequest, RecycledSpecialReservationResponse>(
        `${ENDPOINTS.PRODUCTS.RESERVE(productId, holdTimeId)}`,
        '',
        undefined,
        { cacheName: productdetailsCacheName }
      ).then(
        (response) => {},
        (response) => {
          const error = mapApiErrors(response);
          throw new Error(error);
        }
      );
    }
  };

export const deleteRecycledProductReservation =
  (productId: ApiId): AppThunk =>
  async (dispatch) => {
    if (productId) {
      return putItem<RecycledSpecialReservationRequest, RecycledSpecialReservationResponse>(
        `${ENDPOINTS.PRODUCTS.UNRESERVE(productId)}`,
        '',
        undefined,
        { cacheName: productdetailsCacheName, enableGlobalErrorDialog: true }
      ).then(() => {
        // handled with global error handler
      });
    }
  };

export const fetchMostOrderedProducts = (): AppThunk => async () => {
  return getItems<MostOrderedResponse>(ENDPOINTS.ACTIVITIES.MOST_ORDERED_PRODUCTS, {
    enableGlobalErrorDialog: true
  }).then(
    (response) => {
      // Not dealing with product results in the store
      return response.result || [];
    },
    () => {
      // handled with global error handler
    }
  );
};

export const fetchPciTypes = (): AppThunk => async (dispatch) => {
  return getItems<PciTypesResponse>(ENDPOINTS.PRODUCTS.PCI_TYPES).then(
    (response) => {
      return response.result;
    },
    () => {
      // handled with global error handler
    }
  );
};

/* rsp thunks */

export const fetchContractRSPs =
  (filters?: CatalogueProductFilters): AppThunk =>
  async (dispatch, getState) => {
    const URL = `${ENDPOINTS.PRODUCTS.RSP_PRODUCTS}?${qs.stringify(
      { ...filters },
      {
        skipEmptyString: true
      }
    )}`;

    return getItems<IApiResponse<PagedRspProducts>>(URL, { enableGlobalErrorDialog: true }).then((response) => {
      return response.result;
    });
  };

export const fetchRspCategories = (): AppThunk => async (dispatch, getState) => {
  return getItems<ProductCatalogueCategoriesResponse>(ENDPOINTS.PRODUCTS.RSP_CATEGORIES, {
    cacheName: 'rspcategories'
  }).then((response) => {
    return mapIServerTreeViewOptionToTreeViewOptions(response.result);
  });
};

export const updatePooledSpecial =
  (contractProductId: ApiId, issueCost: number, isPooled: boolean = true): AppThunk =>
  async (dispatch) => {
    return putItem<any, any>(
      ENDPOINTS.PRODUCTS.RSP_PRODUCT_UPDATE_POOLED_SPECIAL(contractProductId),
      { isAvailable: isPooled, price: issueCost },
      ''
    ).then(
      () => {},
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const removePooledSpecial =
  (contractProductId: ApiId): AppThunk =>
  async (dispatch) => {
    return putItem<any, any>(
      ENDPOINTS.PRODUCTS.RSP_PRODUCT_UPDATE_POOLED_SPECIAL(contractProductId),
      { isAvailable: false },
      '',
      { enableGlobalErrorDialog: true }
    ).then(
      () => {},
      (response) => {
        // handled in global dialog
      }
    );
  };

/* actions */
export const {
  setProductList,
  clearProductList,
  setProductReservedToggle,
  setProductFilter,
  setProductSort,
  clearProductSortAndFilter
} = productsSlice.actions;

/* selectors */
const productList = (state: RootState) => state.productsCatalogue.products.productList;

export const selectProductList = createSelector([productList], (products) => {
  return products;
});

export const selectProductSort = (state: RootState) => state.productsCatalogue.products.sort;

export const selectProductFilter = (state: RootState) => state.productsCatalogue.products.filter;

export const selectProductSortAndFilter = createSelector([selectProductSort, selectProductFilter], (sort, filter) => {
  return { ...sort, ...filter };
});

export const selectProductById = (contractProductId: string) => (state: RootState) =>
  state.productsCatalogue.products.productList.find((product) => product.contractProductId === contractProductId);

/* reducers */
export default productsSlice.reducer;
