import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { BREADCRUMB_LABELS } from 'constants/menu';
import { ROUTES } from 'constants/routes';
import { setProductsCatalogueMenu } from 'features/ui/menu.slice';
import { ApiId, createHashFromArray, IApiResponse, ISelectOption, MenuBreadcrumb } from 'millbrook-core';
import { getItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';

/* types */
export interface ProductCategory {
  id: ApiId;
  parentId: ApiId;
  name: string;
  imageUrl: string;
  children: ProductCategory[];
  productCount: number;

  // derived in the slice when the categories are requested from the server
  href: string;
}

export type ProductCatalogueCategoryRequest = ProductCategory;
export type ProductCatalogueCategoriesResponse = IApiResponse<ProductCategory[]>;

/* state */
interface ProductState {
  categories?: ProductCategory[];
  currentCategoryId?: ApiId;
}

const initialState: ProductState = {};

const productsCategoriesSlice = createSlice({
  name: 'productsCategories',
  initialState,
  reducers: {
    setProductsCataloguesCategories(state, action: PayloadAction<ProductCategory[]>) {
      state.categories = action.payload;
    },
    setProductsCataloguesCurrentCategory(state, action: PayloadAction<ApiId | undefined>) {
      state.currentCategoryId = action.payload;
    }
  }
});

/* thunks */
function setCatHref(categories: ProductCategory[]) {
  return categories.map((category) => {
    category.children = setCatHref(category.children);
    category.href = ROUTES.PRODUCT.category(category.id);
    return category;
  });
}

export const fetchProductsCatalogue = (): AppThunk => async (dispatch, getState) => {
  return getItems<ProductCatalogueCategoriesResponse>(ENDPOINTS.PRODUCTS.CATEGORIES, {
    cacheName: 'prod_catalogue'
  }).then((response) => {
    // set the href for each of the categories
    const categories = setCatHref(response.result || []);
    dispatch(setProductsCataloguesCategories(categories));
    dispatch(setProductsCatalogueMenu(categories));
  });
};

/* actions */
export const { setProductsCataloguesCategories, setProductsCataloguesCurrentCategory } =
  productsCategoriesSlice.actions;

/* selectors */
export const selectProductsCatalogueCategories = (state: RootState) => state.productsCatalogue.categories.categories;

export const getHashedProductsCatalogueCategories = createSelector([selectProductsCatalogueCategories], (categories) =>
  createHashFromArray<ProductCategory>(categories || [])
);

export const selectCurrentProductCategoryId = (state: RootState) =>
  state.productsCatalogue.categories.currentCategoryId;

export const selectProductsCatalogueCurrentCategory = createSelector(
  [selectCurrentProductCategoryId, getHashedProductsCatalogueCategories],
  (id, hash) => {
    if (!id) {
      return null;
    }

    return hash[id];
  }
);

export const selectProductsCatalogueCurrentCategoryBreadcrumb = createSelector(
  [selectCurrentProductCategoryId, getHashedProductsCatalogueCategories],
  (id, hash) => {
    const breadcrumb: MenuBreadcrumb = {
      [ROUTES.PRODUCT.catalogue]: BREADCRUMB_LABELS[ROUTES.PRODUCT.catalogue]
    };

    if (id) {
      let category = hash[id];
      const categoryTree = [id];

      while (category && category.parentId) {
        categoryTree.splice(0, 0, category.parentId);
        category = hash[category.parentId];
      }

      categoryTree.forEach((category) => {
        const productCategory = hash[category];
        if (productCategory) {
          const categoryLink = productCategory.href;

          breadcrumb[categoryLink] = productCategory.name;
        }
      });
    }

    return breadcrumb;
  }
);

export const getProductCategoryOptions = createSelector(
  [getHashedProductsCatalogueCategories],
  (categories): ISelectOption[] => {
    // turn the hashed entries into an array
    return (
      Object.entries(categories)
        .reduce((output: ISelectOption[], entry) => {
          const id = entry[0];
          const category = entry[1];
          // only get categories that have no children i.e. the search doesn't allow for parent categories. Should it?
          if (!category.children.length) {
            output.push({ label: `${category.name} (${category.productCount})`, value: id });
          }
          return output;
        }, [])
        // sort the categories alphabetically
        .sort((a, b) => (a.label > b.label ? 1 : -1))
    );
  }
);

/* reducers */
export default productsCategoriesSlice.reducer;
