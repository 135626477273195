import { createSelector } from '@reduxjs/toolkit';
import { ROUTES } from 'constants/routes';
import { UploadFile } from 'millbrook-core';
import { RootState } from 'store/store';
import { CheckoutFileUploadTypes, InitialCheckoutFormData, mediaTypeToFileUploads } from '../types/checkout.types';
import {
  CheckoutStep,
  CheckoutStepName,
  CheckoutStepState,
  Step1FormDataState,
  Step2FormDataState,
  Step3FormDataState,
  Step4FormDataState,
  Step5FormDataState,
  Step6FormDataState
} from '../types/steps.types';

export const selectCheckoutState = (state: RootState) => state.checkout;

export const selectInitialData = (state: RootState): InitialCheckoutFormData => state.checkout.initialData;

export const selectStepByName = (stepName: CheckoutStepName) => (state: RootState) => {
  let url = (ROUTES.CHECKOUT as any)[stepName as any];
  let status = (state.checkout as any)?.[stepName]?.status;

  return { name: stepName, status: status, url: url };
};

export const selectCurrentStep = (state: RootState) => {
  const stepName = state.checkout.currentStep;
  let url = (ROUTES.CHECKOUT as any)[stepName as any];
  let status = (state.checkout as any)?.[stepName]?.status;

  return { name: stepName, status: status, url: url };
};

export const selectIsCteStepRequired = (state: RootState) => !state.checkout.step4.notRequired;

export const selectNextStep = createSelector(
  [selectCheckoutState, selectIsCteStepRequired],
  (checkout, cteRequired) => {
    let stepNumber = parseInt(checkout.currentStep.slice(-1));

    if (stepNumber === CheckoutStep.Delivery) {
      stepNumber = cteRequired ? CheckoutStep.Cte : CheckoutStep.DeliverySpeed;
    } else {
      stepNumber = stepNumber + 1;
    }

    const stepName = `step${stepNumber}` as any;
    let url = (ROUTES.CHECKOUT as any)[stepName as any];
    let status = (checkout as any)?.[stepName]?.status;

    return { name: stepName, status: status, url: url };
  }
);

export const selectPreviousStep = createSelector([selectCheckoutState], (checkout) => {
  let stepNumber = parseInt(checkout.currentStep.slice(-1));

  let prevStep: CheckoutStepState<any>;

  do {
    prevStep = checkout['step' + --stepNumber] as CheckoutStepState<any>;
  } while (stepNumber >= 0 && prevStep?.notRequired);

  const stepName = `step${stepNumber}` as any;
  let url = (ROUTES.CHECKOUT as any)[stepName as any];
  let status = (checkout as any)?.[stepName]?.status;

  return { name: stepName, status: status, url: url };
});

// at the moment, pooled RSPs can't be in the basket with anything else
export const selectCheckoutHasPooledSpecial = (state: RootState) =>
  state.basket.basket?.basketItems.some((x) => x.isPooled) || false;

export const selectActivityMedias = (state: RootState) => state.checkout.initialData.activityMedias;

export const selectCheckoutFiles = createSelector([selectActivityMedias], (activityMedias) => {
  return activityMedias.reduce<{ [key in CheckoutFileUploadTypes]: UploadFile[] }>(
    (fileOutput, media) => {
      const fileNameField = mediaTypeToFileUploads[media.mediaType] as CheckoutFileUploadTypes;

      // declare and assign the media to the correct file naming used in the solution based on the file names used for the file uploads
      (fileOutput[fileNameField] || (fileOutput[fileNameField] = [])).push({ name: media.media.name, id: media.id });

      return fileOutput;
    },
    {
      generalNotesFiles: [],
      authorisationNoteFiles: [],
      clinicalNoteFiles: [],
      riskAssessmentNoteFiles: [],
      specialActivityAuthorisationNoteFiles: []
    }
  );
});

export const selectStep1Data = (state: RootState): Step1FormDataState => state.checkout.step1?.data;
export const selectStep2Data = (state: RootState): Step2FormDataState => state.checkout.step2?.data;
export const selectStep3Data = (state: RootState): Step3FormDataState => state.checkout.step3?.data;
export const selectStep4Data = (state: RootState): Step4FormDataState => state.checkout.step4?.data;
export const selectStep5Data = (state: RootState): Step5FormDataState => state.checkout.step5?.data;
export const selectStep6Data = (state: RootState): Step6FormDataState => state.checkout.step6?.data;
