import { IContractClientAlert, IsoDate } from 'millbrook-core';
import {
  ActivitiesAvailableEnum,
  ActivityAuthorisationReasonEnum,
  ActivityAuthorisationTypeEnum,
  BaseContractServiceTimeSlotItemModel,
  InitialCheckoutFormData,
  ServiceUserDetails,
  TwoPersonChargeAvailableEnum
} from '../types/checkout.types';
import {
  CheckoutStepName,
  CheckoutStepState,
  Step0FormDataState,
  Step1FormDataState,
  Step2FormDataState,
  Step3FormDataState,
  Step4FormDataState,
  Step5FormDataState,
  Step6FormDataState,
  Step7FormDataState
} from '../types/steps.types';

export interface CheckoutState {
  currentStep: CheckoutStepName;
  initialData: InitialCheckoutFormData;
  step0: CheckoutStepState<Step0FormDataState>;
  step1: CheckoutStepState<Step1FormDataState>;
  step2: CheckoutStepState<Step2FormDataState>;
  step3: CheckoutStepState<Step3FormDataState>;
  step4: CheckoutStepState<Step4FormDataState>;
  step5: CheckoutStepState<Step5FormDataState>;
  step6: CheckoutStepState<Step6FormDataState>;
  step7: CheckoutStepState<Step7FormDataState>;

  /* additional data - derived or set with apis during the checkout i.e. not initial data */
  orderRequiresAuthorisation: ActivityAuthorisationTypeEnum;
  orderAuthorisationReasons: ActivityAuthorisationReasonEnum[];
  modifiedClientAlerts: IContractClientAlert[];
  timeSlots: BaseContractServiceTimeSlotItemModel[];
  timeSlotDaysNotAvailable: IsoDate[];
  [key: string]: any;
}

export const ActivityServiceUserInitialState = {
  propertyTypes: [],
  tenancies: [],
  contractClientAlerts: [],
  careOutcomes: [],
  serviceUser: {} as ServiceUserDetails,
  serviceUserFieldsValidation: {},
  serviceUserAdditionalContacts: [],
  serviceUserClientAlerts: []
};

export const CheckoutInitialState: CheckoutState = {
  currentStep: 'step0',
  initialData: {
    ...ActivityServiceUserInitialState,
    standardActivityDeliveryTypes: [],
    standardActivitySpeeds: [],
    specialActivityDeliveryTypes: [],
    specialActivitySpeed: null,
    careOutcomes: [],
    reviewPeriods: [],
    contractReviewTypes: [],
    pinUsers: [],
    nonWorkingDates: [],
    daysWithTimeSlots: [],
    timeSlotCharge: 0,
    activitiesAvailable: ActivitiesAvailableEnum.Standard,
    checkoutFieldsAvailability: {
      closeTechnicalEquivalentVisible: false,
      deliverStandardSpecialTogetherVisible: false,
      deliveryTypeVisible: false,
      jointVisitVisible: false,
      twoPersonVisible: false,
      dateTimeSlotVisible: false,
      careOutcomeVisible: false,
      reviewTypePeriodVisible: false,
      clinicalAssessmentVisible: false,
      riskAssessmentVisible: false,
      authorisationVisible: false
    },
    twoPersonChargeAvailable: TwoPersonChargeAvailableEnum.NotAvailable,

    hasSpecialActivity: false,
    hasOnlySpecialActivity: false,
    standardOrderTypeName: '',
    isActivityOnBehalfOfExternalPinUser: false,

    activityMedias: []
  },
  step0: {
    status: 'in-progress',
    data: {}
  },
  step1: {
    status: 'not-started',
    data: {}
  },
  step2: {
    status: 'not-started',
    data: {
      removedClientAlerts: [],
      addedClientAlerts: [],
      alternativeContactNote: ''
    }
  },
  step3: {
    status: 'not-started',
    data: {
      generalNotes: '',
      arrangeBooking: ''
    }
  },
  step4: {
    status: 'not-started',
    data: {
      acceptedProductCTEs: []
    }
  },
  step5: {
    status: 'not-started',
    data: {
      // user must select an option for deliverStandardAndSpecialTogether to continue, or have it set for them in the rules
      // this will be set to '' in the initialise action if it is a dual activity
      deliverStandardAndSpecialTogether: 'no',
      isJointVisitRequired: 'no',
      isTwoPersonDelivery: 'no',
      deliverySpeed: '',
      contractServiceTimeSlotId: '',
      deliveryDate: '',
      specialActivityDeliveryType: 0
    }
  },
  step6: {
    status: 'not-started',
    data: {
      careOutcomeId: '',
      careOutcomeAlternativeId: '',
      specialActivityCareOutcomeId: '',
      specialActivityCareOutcomeAlternativeId: '',
      authorisers: [],
      standardProductReviews: [],
      specialProductReviews: []
    }
  },
  step7: {
    status: 'not-started',
    data: {}
  },
  // additional fields
  orderRequiresAuthorisation: ActivityAuthorisationTypeEnum.NotRequired,
  orderAuthorisationReasons: [],
  modifiedClientAlerts: [],
  timeSlots: [],
  timeSlotDaysNotAvailable: []
};
