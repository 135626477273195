import { Box } from '@material-ui/core';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import { productCompatibleWithCardStyles } from 'components/Cards/ProductCompatibleWithCard/ProductCompatibleWithCard.styles';
import { ROUTES } from 'constants/routes';
import { addToBasket } from 'features/basket/basket.slice';
import {
  BodyText,
  CircleIconButton,
  DisplayAsMoney,
  IconLabel,
  IContractProductSummary,
  ProductImageCard
} from 'millbrook-core';
import { ProductInstallationDialog } from 'pages/Products/components/ProductInstallationDialog/ProductInstallationDialog';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

interface ProductsummaryItemProps {
  product: IContractProductSummary;
  onLinkClick?: () => void;
}

export const ProductSummaryItem: React.FC<ProductsummaryItemProps> = ({ product, onLinkClick }) => {
  const classes = productCompatibleWithCardStyles();
  const dispatch = useDispatch();

  const [installationDialogIsOpen, setInstallationDialogIsOpen] = useState<boolean>();

  const handleAddClick = () => {
    // If product requires installation, show installation dialog with additional questions
    if (requiresInstallationNotes) {
      setInstallationDialogIsOpen(true);
    } else {
      handleAddToBasket();
    }
  };

  const handleAddToBasket = (notes?: string, files?: FileList) => {
    product && dispatch<any>(addToBasket(product, 1, notes, files));
  };

  const handleCloseInstallationDialog = () => {
    setInstallationDialogIsOpen(false);
  };

  const handleLinkClick = () => {
    onLinkClick && onLinkClick();
  };

  /* values. Just pulling the props out */
  const { mainImage, contractProductId, issueCost, description, requiresInstallationNotes } = product;

  return (
    <Box className={classes.productContainer}>
      <Link to={ROUTES.PRODUCT.productDetails(contractProductId)} className={classes.image}>
        <ProductImageCard image={mainImage?.linkUrl} ratio="square" />
      </Link>
      <Box maxWidth={210}>
        <Link to={ROUTES.PRODUCT.productDetails(contractProductId)} className={classes.link} onClick={handleLinkClick}>
          <BodyText bold color="regalBlue" gutterBottom>
            {product.name}
          </BodyText>
        </Link>
        <Box mb={2}>
          <BodyText type="small" bold>
            <DisplayAsMoney amount={issueCost} />
          </BodyText>
        </Box>
        <BodyText type="small" className={classes.description} gutterBottom>
          {description}
        </BodyText>
        {requiresInstallationNotes && (
          <IconLabel
            icon={<AnnouncementIcon color="primary" />}
            text="This product requires installation"
            textSize="small"
          />
        )}
      </Box>
      <Box ml="auto">
        <CircleIconButton
          aria-label="add to basket"
          variant="filled"
          iconColor="white"
          bgColor="scienceBlue"
          onClick={handleAddClick}
        >
          <AddShoppingCartIcon />
        </CircleIconButton>
      </Box>
      {requiresInstallationNotes && (
        <ProductInstallationDialog
          productId={contractProductId}
          isOpen={installationDialogIsOpen}
          onClose={handleCloseInstallationDialog}
          onSuccess={(notes: string, files: FileList) => {
            handleCloseInstallationDialog();
            handleAddToBasket(notes, files);
          }}
        />
      )}
    </Box>
  );
};
