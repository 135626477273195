import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { CancelOrderItemFormData } from 'features/activities/components/CancelOrderForms/CancelOrderItemsForm';
import { ActivityAuthorisationSummaryResponse } from 'features/authorisations/authorisations.types';
import {
  ActivityAlerts,
  ActivityBasketItemCancelModel,
  ActivityDetailsModel,
  ActivityProcurementNote,
  ApiId,
  getActivityMasterServiceTypeIds,
  IApiResponse,
  mapApiErrors,
  setActivityDetails
} from 'millbrook-core';
import { deleteItem, deleteItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { fetchAllActivityDetails } from './activity.slice';

/* types */
export type ActivityResponse = IApiResponse<ActivityDetailsModel>;
export type ActivityAlertsResponse = IApiResponse<ActivityAlerts>;
export type ActivityProcurementNotesResponse = IApiResponse<ActivityProcurementNote[]>;

export interface ActivityDeleteRequest {}

/* state */
interface CancellingState {
  activityId?: ApiId;
  activity?: ActivityDetailsModel;
  cancellingActivity: boolean;
  cancellingItems: boolean;
  cancelItems?: ApiId[];
  cancelled: boolean;
}

const initialState: CancellingState = {
  cancellingActivity: false,
  cancellingItems: false,
  cancelled: false
};

/* slice */
const cancellingSlice = createSlice({
  name: 'cancelling',
  initialState,
  reducers: {
    setCancellingActivityDetails(state, action: PayloadAction<ActivityDetailsModel>) {
      state.activity = action.payload;
      state.activityId = action.payload.id;
      state.cancelled = false;
    },
    setCancellingActivity(state, action: PayloadAction<undefined>) {
      state.cancellingActivity = true;
      state.cancellingItems = false;
    },
    setCancellingActivityItems(state, action: PayloadAction<ApiId[] | undefined>) {
      state.cancellingItems = action.payload ? true : false;
      state.cancelItems = action.payload || undefined;
      state.cancellingActivity = false;
    },
    setActivityCancelled(state, action: PayloadAction<boolean>) {
      state.cancelled = action.payload;
    },
    unsetCancellingActivityDetails(state) {
      state.activity = initialState.activity;
      state.activityId = initialState.activityId;
      state.cancellingActivity = initialState.cancellingActivity;
      state.cancellingItems = initialState.cancellingItems;
      state.cancelItems = initialState.cancelItems;
      state.cancelled = initialState.cancelled;
    }
  },
  extraReducers: (builder) => {
    // when a new activity is set, reset all cancelling activity info
    builder.addCase(setActivityDetails, (state, action) => {
      state = { ...initialState };
    });
  }
});

/* thunks */
export const deleteOrder =
  (reasonCodeId: ApiId, activityId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return deleteItem<ActivityDeleteRequest, IApiResponse<{}>>(
      ENDPOINTS.ACTIVITIES.DELETE_ORDER(activityId, reasonCodeId)
    ).then(
      (response) => {
        dispatch(setActivityCancelled(true));
        dispatch(fetchAllActivityDetails(activityId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const reasonCodeActivity =
  (reasonCodeId: ApiId, activityId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return putItem<ActivityDeleteRequest, IApiResponse<{}>>(
      ENDPOINTS.ACTIVITIES.REASON_CODE(activityId, reasonCodeId)
    ).then(
      (response) => {
        dispatch(fetchAllActivityDetails(activityId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const deleteOrderItems =
  (activityId: ApiId, items: CancelOrderItemFormData[]): AppThunk =>
  async (dispatch, getState) => {
    const serviceUserId = getState().activities.cancelling.activity?.serviceUserId;

    if (!serviceUserId) {
      return false;
    }

    // get all of the item ids that are being sent from the form. Either "cancel" will be selected (where there is a quantity of one) or a quantity is selected
    const basketItems = items.reduce<ActivityBasketItemCancelModel[]>((ids, item) => {
      const quantityToDelete = Number(item.quantity) || Number(!!item.cancel);

      if (quantityToDelete > 0) {
        ids.push({
          basketItemId: item.id,
          quantity: quantityToDelete
        });
      }

      // NOTE: notSplit and basketItemIds are only available when fetchActivityDetails is called with keepIndividualItems = false

      // if (item.notSplit) {
      //   ids.push({
      //     basketItemId: item.basketItemIds[0],
      //     quantity: quantityToDelete
      //   });
      // } else {
      //   ids = ids.concat(item.basketItemIds.slice(0, quantityToDelete).map((x) => ({ basketItemId: x, quantity: 1 })));
      // }

      return ids;
    }, []);

    return deleteItems<ActivityBasketItemCancelModel[], ActivityAuthorisationSummaryResponse>(
      ENDPOINTS.ACTIVITIES.CANCEL_PRODUCT(activityId),
      basketItems
    )
      .then((responses) => {
        dispatch(unsetCancellingActivityDetails());
        dispatch(fetchAllActivityDetails(activityId));
      })
      .catch((response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      });
  };

/* actions */
export const {
  setCancellingActivityDetails,
  setCancellingActivity,
  setCancellingActivityItems,
  unsetCancellingActivityDetails,
  setActivityCancelled
} = cancellingSlice.actions;

/* selectors */
export const selectCancellingActivity = (state: RootState) => state.activities.cancelling.activity;
export const selectCancellingActivityId = (state: RootState) => state.activities.cancelling.activityId;
export const selectCancellingActivityBasket = (state: RootState) => state.activities.cancelling.activity?.basket;
export const selectCancellingActivityBasketItems = createSelector(
  [selectCancellingActivityBasket],
  (basket) => basket?.basketItems || []
);

export const selectIsCancellingOrder = (state: RootState) => state.activities.cancelling.cancellingActivity;
export const selectIsCancellingActivityItems = (state: RootState) => state.activities.cancelling.cancellingItems;

export const selectCancellingActivityCancelItems = (state: RootState) => state.activities.cancelling.cancelItems;

export const setIsCancellingActivityCancelled = (state: RootState) => state.activities.cancelling.cancelled;

export const selectCancellingActivityMasterServiceTypes = createSelector(
  [selectCancellingActivityBasketItems],
  (basket) => {
    return getActivityMasterServiceTypeIds(basket);
  }
);

/* reducers */
export default cancellingSlice.reducer;
