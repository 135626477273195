import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  hideOutOfHoursDialog,
  selectShowOutOfHoursDialog,
  selectTelephoneNumber
} from 'features/contract/contract.slice';
import { BodyText, StyledButton, StyledDialog, TelephoneLink } from 'millbrook-core';
import { useDispatch, useSelector } from 'react-redux';

export const OutOfHoursDialog: React.FC = () => {
  const dispatch = useDispatch();
  const showOutOfHoursDialog = useSelector(selectShowOutOfHoursDialog);
  const telephoneNumber = useSelector(selectTelephoneNumber);

  const handleClose = () => {
    dispatch(hideOutOfHoursDialog());
  };

  return (
    <StyledDialog
      headingTitle="Out of Hours"
      disableEscapeKeyDown
      disableCloseIcon
      headingTitleIcon={<InfoOutlinedIcon />}
      open={showOutOfHoursDialog}
      onClose={handleClose}
      footerChild={
        <StyledButton onClick={handleClose} type="button">
          Continue
        </StyledButton>
      }
    >
      <BodyText>
        Please telephone the service centre on <TelephoneLink phoneNumber={telephoneNumber} variant="body2" /> in order
        to place an emergency order or continue to place a non-emergency order.
      </BodyText>
    </StyledDialog>
  );
};
