import { Box, Hidden, Link } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import SearchIcon from '@material-ui/icons/Search';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import millbrookLogo from 'assets/svg/millbrook-logo.svg';
import clsx from 'clsx';
import { QuickActionsDrawer } from 'components/QuickActionsDrawer/QuickActionsDrawer';
import { selectAuthorisationCount } from 'components/QuickActionsDrawer/quickActionsDrawer.slice';
import { ROUTES } from 'constants/routes';
import { selectPinUsersCount, selectSelectedPinUser } from 'features/auth-pin/auth-pin.slice';
import { selectBasketProductsCount, selectSelectedServiceUser } from 'features/basket/basket.slice';
import BasketActionDrawer from 'features/basket/BasketActionDrawer/BasketActionDrawer';
import { selectPrescriberLogo } from 'features/contract/contract.slice';
import { SearchDialog } from 'features/search/components/SearchDialog/SearchDialog';
import { setOpenSearchDialog, setOpenUserSearchDialog } from 'features/search/search.slice';
import {
  AUTH_ROUTES,
  BodyText,
  CircleIconButton,
  COLORS,
  selectPortalUserIsLoggedIn,
  selectUserIsLoggedIn,
  SpacingContainer,
  StyledButton,
  StyledDockedDrawerAnchor
} from 'millbrook-core';
import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import MainMenuDrawer from '../MainMenuDrawer/MainMenuDrawer';

const useStyles = makeStyles((theme) => ({
  logoWrapper: {
    flexGrow: 1,
    padding: theme.spacing(2, 2, 2, '4%'),
    '& a': {
      display: 'inline-block'
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, '1%')
    }
  },
  logoInnerWrapper: {
    display: 'flex'
  },
  logo: {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      height: '30px'
    }
  },
  prescriberLogo: {
    maxWidth: '175px',
    maxHeight: '55px',
    marginLeft: theme.spacing(4),
    display: 'none', // hide on mbile, not enough space in header

    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  logoNotLoggedIn: {
    paddingLeft: 0
  },
  badgeButton: {
    margin: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      margin: '1.4%',
      padding: theme.spacing(0.6),
      '& svg': {
        fontSize: '20px' // small?
      }
    }
  },
  toolbar: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '100px'
    }
  },
  mobileNavLink: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '11px',
    color: COLORS.white,
    maxWidth: '90%'
  },
  secondaryNav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
    minHeight: theme.spacing(5),
    flexWrap: 'wrap'
  },
  secondaryNavItem: {
    maxWidth: '48%',
    display: 'flex',
    alignItems: 'center'
  },
  secondaryNavPin: {
    minWidth: 0,
    display: 'flex',
    flexWrap: 'wrap'
  },
  secondaryNavPinPart: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '&:first-child': {
      marginRight: theme.spacing(1)
    }
  }
}));

const Header = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mainOpen, setMainOpen] = useState(false);
  const [quickActionOpen, setQuickActionsOpen] = useState(false);
  const userIsLoggedIn = useSelector(selectUserIsLoggedIn);
  const pinUserIsLoggedInAndSelected = useSelector(selectPortalUserIsLoggedIn);
  const selectedPinUser = useSelector(selectSelectedPinUser);
  const basketProductCount = useSelector(selectBasketProductsCount);
  const pinUsersCount = useSelector(selectPinUsersCount);
  const selectedClient = useSelector(selectSelectedServiceUser);
  const authorisationCount = useSelector(selectAuthorisationCount);
  const prescriberPortalLogo = useSelector(selectPrescriberLogo);

  const handleMainDrawerOpen = () => {
    setMainOpen(true);
  };

  const handleMainDrawerClose = () => {
    setMainOpen(false);
  };

  const handleQuickDrawerOpen = () => {
    setQuickActionsOpen(true);
  };

  const handleQuickDrawerClose = () => {
    setQuickActionsOpen(false);
  };

  const handleSearchOpen = () => {
    dispatch(setOpenSearchDialog());
  };

  const handleClientSelect = () => dispatch(setOpenUserSearchDialog(false));

  return (
    <Fragment>
      <AppBar position="fixed">
        <SpacingContainer>
          <Toolbar className={classes.toolbar} disableGutters>
            {pinUserIsLoggedInAndSelected && (
              <IconButton edge="start" aria-label="open drawer" onClick={handleMainDrawerOpen}>
                <MenuIcon />
              </IconButton>
            )}
            <Box className={clsx(classes.logoWrapper, !pinUserIsLoggedInAndSelected && classes.logoNotLoggedIn)}>
              <Link component={RouterLink} to={ROUTES.HOME} title="Millbrook home">
                <Box className={classes.logoInnerWrapper}>
                  <img className={classes.logo} src={millbrookLogo} alt="Millbrook home" />
                  {prescriberPortalLogo && prescriberPortalLogo.length > 0 && (
                    <img
                      className={classes.prescriberLogo}
                      src={`${prescriberPortalLogo[0].linkUrl}?width=175&height=55`}
                      alt={prescriberPortalLogo[0].name}
                    />
                  )}
                </Box>
              </Link>
            </Box>
            {pinUserIsLoggedInAndSelected && (
              <Fragment>
                <CircleIconButton className={classes.badgeButton} onClick={handleSearchOpen} title="Search">
                  <SearchIcon />
                </CircleIconButton>
                <CircleIconButton
                  className={classes.badgeButton}
                  onClick={handleQuickDrawerOpen}
                  title="Quick navigation"
                >
                  <Badge badgeContent={authorisationCount} color="primary">
                    <PersonOutlineOutlinedIcon />
                  </Badge>
                </CircleIconButton>
                <CircleIconButton
                  className={classes.badgeButton}
                  onClick={() => history.push(ROUTES.CHECKOUT.step0)}
                  title="Basket summary"
                >
                  <Badge badgeContent={basketProductCount} color="primary">
                    <ShoppingCartOutlinedIcon />
                  </Badge>
                </CircleIconButton>
              </Fragment>
            )}
            {userIsLoggedIn && !selectedPinUser && (
              <StyledButton size="small" variant="secondary" component={RouterLink} to={AUTH_ROUTES.LogOut}>
                Logout
              </StyledButton>
            )}
          </Toolbar>
        </SpacingContainer>
        {pinUserIsLoggedInAndSelected && (
          <Box bgcolor="secondary.main">
            <SpacingContainer>
              <Box className={classes.secondaryNav}>
                {selectedPinUser && (
                  <Fragment>
                    <Hidden smDown>
                      <Box className={classes.secondaryNavItem}>
                        <BodyText className={classes.secondaryNavPin} component="span" type="small" color="white">
                          <span className={classes.secondaryNavPinPart}>{selectedPinUser.contractName}</span>
                          <span className={classes.secondaryNavPinPart}>{selectedPinUser.pinTeamName}</span>
                        </BodyText>

                        {pinUsersCount > 1 && (
                          <Box display="inline-flex" ml={2} flexShrink={0}>
                            <StyledButton
                              size="small"
                              variant="tertiary"
                              component={RouterLink}
                              to={ROUTES.PIN_SELECTION}
                            >
                              Change pin
                            </StyledButton>
                          </Box>
                        )}
                      </Box>
                    </Hidden>
                    <Hidden mdUp>
                      {pinUsersCount <= 1 && (
                        <BodyText className={classes.secondaryNavPin} component="span" type="small" color="white">
                          <span className={classes.secondaryNavPinPart}>{selectedPinUser.contractName}</span>
                          <span className={classes.secondaryNavPinPart}>{selectedPinUser.pinTeamName}</span>
                        </BodyText>
                      )}
                      {pinUsersCount > 1 && (
                        <Link className={classes.mobileNavLink} component={RouterLink} to={ROUTES.PIN_SELECTION}>
                          <BodyText className={classes.secondaryNavPin} component="span" type="small" color="white">
                            <span className={classes.secondaryNavPinPart}>{selectedPinUser.contractName}</span>
                            <span className={classes.secondaryNavPinPart}>{selectedPinUser.pinTeamName}</span>
                          </BodyText>                          <ChevronRightIcon />
                        </Link>
                      )}
                    </Hidden>{' '}
                  </Fragment>
                )}
                <Fragment>
                  <Hidden smDown>
                    <Box className={classes.secondaryNavItem}>
                      <BodyText component="span" type="small" color="white">
                        {selectedClient ? (
                          <Fragment>
                            Ordering for :{' '}
                            <Link
                              component={RouterLink}
                              to={ROUTES.SERVICE_USERS.details(selectedClient.id)}
                              color="inherit"
                              underline="always"
                            >
                              {selectedClient.name}
                            </Link>
                          </Fragment>
                        ) : (
                          'No client selected'
                        )}
                      </BodyText>

                      <Box display="inline-flex" ml={2} flexShrink={0}>
                        <StyledButton size="small" variant="tertiary" onClick={handleClientSelect}>
                          {selectedClient ? 'Change' : 'Choose'} service user
                        </StyledButton>
                      </Box>
                    </Box>
                  </Hidden>
                  <Hidden mdUp>
                    <Link className={classes.mobileNavLink} onClick={handleClientSelect}>
                      {selectedClient ? `Ordering for : ${selectedClient.name}` : 'No client selected'}
                      <ChevronRightIcon />
                    </Link>
                  </Hidden>
                </Fragment>
              </Box>
            </SpacingContainer>
          </Box>
        )}
        {/* This is a placeholder to measure against for the header height and the positioning that can be used for the right hand sticky nav */}
        <StyledDockedDrawerAnchor />
      </AppBar>
      {pinUserIsLoggedInAndSelected && (
        <Fragment>
          <MainMenuDrawer open={mainOpen} onClose={handleMainDrawerClose} />
          <QuickActionsDrawer open={quickActionOpen} onClose={handleQuickDrawerClose} />
          <BasketActionDrawer />
          <SearchDialog />
        </Fragment>
      )}
    </Fragment>
  );
};

export default Header;
