import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, IApiResponse } from 'millbrook-core';
import { getItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';

/* types */
export type AuthorisationCount = {
  pendingAuthorisations: number;
};

export type AuthorisationCountRequest = {
  id: ApiId;
};

export type AuthorisationCountResponse = IApiResponse<AuthorisationCount>;

/* state */
interface State {
  authorisationCount: number | undefined;
}

const initialState: State = {
  authorisationCount: undefined
};

/* slice */
const slice = createSlice({
  name: 'quickActionDrawer',
  initialState,
  reducers: {
    setAuthorisationCount(state, action: PayloadAction<number | undefined>) {
      state.authorisationCount = action.payload;
    }
  }
});

/* selectors */
export const selectAuthorisationCount = (state: RootState) => state.quickActionDrawer.authorisationCount;

/* actions */
export const { setAuthorisationCount } = slice.actions;

/* thunks */
export const fetchAuthorisationCount = (): AppThunk => async (dispatch) => {
  return getItems<AuthorisationCountResponse>(ENDPOINTS.ACTIVITIES.AUTHORISATIONS_PENDING_COUNT, {
    enableGlobalErrorDialog: true,
    disableFullPageLoader: true
  }).then(
    (response) => {
      dispatch(setAuthorisationCount(response.result?.pendingAuthorisations));
    },
    () => {
      // handled with global error handler
    }
  );
};

export default slice.reducer;
