import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, IApiResponse, IContractProductSummary } from 'millbrook-core';
import { serialize } from 'object-to-formdata';
import { SpecialOrderFormData } from 'pages/service-users/components/SpecialOrderForm/SpecialOrderForm.validation';
import { getItems, postItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import qs from 'query-string';

// interface SpecialOrderProductType {
//   id: ApiId;
//   name: string;
// }

//interface SpecialOrderEquipmentType {
//  id: ApiId;
//  name: string;
//  children: [];
//  productTypes: SpecialOrderProductType[];
//}

export interface ProductCategory {
  id: ApiId;
  parentId: string;
  name: string;
  imageUrl: string;
  children: ProductCategory[];
  productCount: number;
}

export interface ProductTypes {
  id: ApiId;
  name: string;
}

export interface SpecialOrderProductCategory {
  categories: ProductCategory[];
  productTypes: ProductTypes[];
}

/* types */
export type SpecialOrderProductCategoryDataResponse = IApiResponse<SpecialOrderProductCategory>;
export type RecycledSpecialProductsResponse = IApiResponse<IContractProductSummary[]>;
export type CreateSpecialOrderResponse = FormData;

/* state */
interface SpecialOrderState {
  productCategoryData?: SpecialOrderProductCategory;
}

const initialState: SpecialOrderState = {};

const specialOrderSlice = createSlice({
  name: 'specialOrder',
  initialState,
  reducers: {
    setProductCategoryData(state, action: PayloadAction<SpecialOrderProductCategory | undefined>) {
      state.productCategoryData = action.payload;
    }
  }
});

/* thunks */
export const fetchProductCategoryData = (): AppThunk => async (dispatch, getState) => {
  return getItems<SpecialOrderProductCategoryDataResponse>(ENDPOINTS.PRODUCTS.GetSpecialOrderFormData, {
    enableGlobalErrorDialog: true
  }).then(
    (response) => {
      dispatch(setProductCategoryData(response.result || undefined));
    },
    () => {
      // handled with global error handler
    }
  );
};

export const createSpecialOrder =
  (data: SpecialOrderFormData): AppThunk =>
  async (dispatch) => {
    const { productCategoryId, productSubCategoryId, media, servicePackRequired, supplierId, ...restOfData } = data;

    const transformedData = {
      productCategoryId: productSubCategoryId || productCategoryId,
      supplierId: supplierId === 'other' ? null : supplierId,
      ...restOfData
    };

    const formData = serialize(transformedData);

    Array.from(media || []).forEach((file) => formData.append('media', file as File));

    return postItems<CreateSpecialOrderResponse, object>(ENDPOINTS.BASKET.AddSpecialToBasket, formData, {
      enableGlobalErrorDialog: true
    }).then(
      (response) => {
        return response;
      },
      () => {
        // handled with global error handler
      }
    );
  };

export const fetchRecycledSpecialProducts =
  (productTypeId: ApiId, productCategoryId: ApiId, productSubCategoryId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    // MIL-2042 - limited to 5 recycled products through API

    const URL = `${ENDPOINTS.PRODUCTS.RECYCLED_SPECIAL_PRODUCT_SEARCH}?${qs.stringify(
      {
        productTypeId: productTypeId,
        productCategoryId: productCategoryId,
        productSubCategoryId: productSubCategoryId
      },
      {
        skipEmptyString: true
      }
    )}`;

    return getItems<RecycledSpecialProductsResponse>(URL, {
      enableGlobalErrorDialog: true
    }).then(
      (response) => {
        return response.result || [];
      },
      () => {
        // handled with global error handler
      }
    );
  };

/* actions */
export const { setProductCategoryData } = specialOrderSlice.actions;

/* selectors */
export const selectProductCategoryData = (state: RootState) => state.specialOrder.productCategoryData;

export const getProductCategoryOptions = createSelector([selectProductCategoryData], (categoryData) => {
  if (categoryData) {
    return categoryData.categories.map((categoryData) => {
      return { label: categoryData.name, value: categoryData.id };
    });
  }
});

export const getProductCategory = (categoryId: string) => {
  return createSelector([selectProductCategoryData], (categoryData) => {
    if (categoryData) {
      return categoryData.categories.filter((categoryData) => {
        return categoryData.id === categoryId;
      });
    }
  });
};

export const getSubCategoryOptions = (categoryId: string) => {
  return createSelector([getProductCategory(categoryId)], (categoryData) => {
    if (categoryData && categoryData.length > 0) {
      return categoryData[0].children.map((categoryData) => {
        return { label: categoryData.name, value: categoryData.id };
      });
    }
  });
};

export const getProductSubCategory = (categoryId: string, subCategoryId: string) => {
  return createSelector([getProductCategory(categoryId)], (subCategoryData) => {
    if (subCategoryData) {
      if (subCategoryData.length > 0) {
        return subCategoryData[0].children.filter((subCategoryData) => {
          return subCategoryData.id === subCategoryId;
        });
      }
    }
  });
};

export const getProductTypeOptions = createSelector([selectProductCategoryData], (categoryData) => {
  if (categoryData) {
    return categoryData.productTypes.map((productTypeData) => {
      return { label: productTypeData.name, value: productTypeData.id };
    });
  }
});

/* reducers */
export default specialOrderSlice.reducer;
