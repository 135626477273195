import { Box, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { ProductSummaryItem } from 'components/Cards/ProductCompatibleWithCard/ProductSummaryItem';
import { ROUTES } from 'constants/routes';
import { clearLastAddedProduct, selectLastAddedProduct } from 'features/basket/basket.slice';
import {
  BodyText,
  COLORS,
  Heading,
  StyledButton,
  StyledDrawer,
  StyledDrawerContent,
  StyledDrawerHeader
} from 'millbrook-core';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

interface BasketActionDrawerProps {}

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    '& .MuiDrawer-paper': {
      background: COLORS.regalBlue
    }
  },
  drawerContent: {
    backgroundColor: 'white',
    padding: theme.spacing(4),
    flex: '1'
  },
  drawerContentTitle: {
    marginBottom: theme.spacing(4)
  }
}));

const BasketActionDrawer: React.FC<BasketActionDrawerProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const lastProduct = useSelector(selectLastAddedProduct);

  const handleClose = () => {
    dispatch(clearLastAddedProduct());
  };

  return (
    <StyledDrawer open={!!lastProduct} onClose={handleClose} anchor="right" className={classes.drawerHeader}>
      <StyledDrawerHeader>
        <Box mb={6} mt={2}>
          <Heading type="h4" color="white" gutterBottom>
            {lastProduct?.name}
          </Heading>
          <Box display="inline-flex" alignItems="center">
            <Box component="span" mr={1}>
              <BodyText color="white">has been added to your basket</BodyText>
            </Box>
            <CheckIcon htmlColor="white" />
          </Box>
        </Box>
        <StyledButton component={RouterLink} to={ROUTES.CHECKOUT.step0} onClick={handleClose} variant="tertiary">
          View basket
        </StyledButton>
      </StyledDrawerHeader>
      <StyledDrawerContent className={classes.drawerContent}>
        {!!lastProduct?.relatedProductsList?.length && (
          <Fragment>
            <BodyText type="large" component="h4" bold className={classes.drawerContentTitle}>
              Related products
            </BodyText>
            {(lastProduct?.relatedProductsList).map((relatedProduct) => (
              <ProductSummaryItem
                product={relatedProduct}
                key={'related-product-item-' + relatedProduct.contractProductId}
                onLinkClick={handleClose}
              />
            ))}
          </Fragment>
        )}
      </StyledDrawerContent>
    </StyledDrawer>
  );
};

export default BasketActionDrawer;
