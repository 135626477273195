import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, ErrorResponse, IApiResponse, mapApiErrors, MasterEventType } from 'millbrook-core';
import { getItem, getItems, postItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { ActivityEventResponseModel } from './event.types';
import { NewActivityEventFormData } from './NewActivityEventForm/NewActivityEventForm';

/* types */
export type ActivityEventListResponse = IApiResponse<ActivityEventResponseModel[]>;
export type ActivityEventResponse = IApiResponse<ActivityEventResponseModel>;
export type EventTypesListResponse = IApiResponse<MasterEventType[]>;

/* state */
interface EventState {
  events: ActivityEventResponseModel[];
  event?: ActivityEventResponseModel;
  eventTypes?: MasterEventType[];
}

const initialState: EventState = {
  events: [],
  eventTypes: []
};

/* slice */
const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEvents(state, action: PayloadAction<ActivityEventResponseModel[]>) {
      state.events = action.payload;
    },
    setEvent(state, action: PayloadAction<ActivityEventResponseModel | undefined>) {
      state.event = action.payload;
    },
    setEventTypes(state, action: PayloadAction<MasterEventType[]>) {
      state.eventTypes = action.payload;
    }
  }
});

export const fetchActivityEvents =
  (activityId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<ActivityEventListResponse>(ENDPOINTS.EVENTS.GetEventByActivityId(activityId), {
      enableGlobalErrorDialog: true
    }).then(
      (response) => {
        dispatch(setEvents(response.result || []));
      },
      (response) => {
        // handled in global error
        //        const error = mapApiErrors(response);
        //        throw new Error(error);
      }
    );
  };

export const fetchEventByServiceUserId =
  (serviceUserId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<ActivityEventListResponse>(ENDPOINTS.EVENTS.GetEventByServiceUserId(serviceUserId)).then(
      (response) => {
        dispatch(setEvents(response.result || []));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const createEvent =
  (newEvent: NewActivityEventFormData): AppThunk =>
  async (dispatch) => {
    return postItems<NewActivityEventFormData, ActivityEventResponse>(ENDPOINTS.EVENTS.EVENT, newEvent).then(
      (response) => {
        //dispatch<any>(fetchEvent(response.result.));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchEventTypes = (): AppThunk => async (dispatch) => {
  return getItem<ApiId, EventTypesListResponse>(ENDPOINTS.EVENT_TYPES.EVENT_TYPE).then((response) => {
    dispatch(setEventTypes(response.result || []));
    return response;
  });
};

export const { setEvents, setEvent, setEventTypes } = eventSlice.actions;

export const selectEventTypes = (state: RootState) => state.event.eventTypes;
export const selectEvents = (state: RootState) => state.event.events;

export default eventSlice.reducer;
