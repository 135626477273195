import { combineReducers } from '@reduxjs/toolkit';
import scheduling from 'features/activities/scheduling.slice';
import cancelling from 'features/activities/cancelling.slice';
import capacity from 'features/activities/capacity.slice';
import closing from 'features/activities/closing.slice';
import authorising from 'features/activities/authorising.slice';
import editing from 'features/activities/editing.slice';

/* reducers */
export default combineReducers({
  authorising,
  editing,
  scheduling,
  capacity,
  closing,
  cancelling
});
