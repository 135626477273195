import {
  ContractSpeed,
  DeliveryOptionsDisplayNames,
  DeliveryTypeEnum,
  ISelectOption,
  mapYesNoToBoolean,
  mapYesNoToBooleanOrReturnValue,
  MediaTypeEnum,
  ServiceSpeedTypeCodeEnum,
  ServiceUserAdditionalContactModel,
  ServiceUserAddressFormData,
  ServiceUserAddressModel,
  UploadFile
} from 'millbrook-core';
import { AdditionalContactFormData } from 'pages/service-users/components/ContactForm/ContactForm';
import { ActivityMediaModel } from './types/checkout.types';

export const mapServiceUserAddressFormDataToServiceUserAddressFormData = (
  newAddress: ServiceUserAddressFormData | undefined
): ServiceUserAddressModel | undefined => {
  if (!newAddress) {
    return undefined;
  }

  const {
    isPrimary,
    dogOnPremise,
    keySafe,
    accessIssues,
    parkingAvailable,
    stepsLeadingUpToTheProperty,
    tenancyId,
    ...address
  } = newAddress;

  return {
    isPrimary,
    address,
    dogOnPremise: mapYesNoToBooleanOrReturnValue(dogOnPremise, undefined),
    keySafe: mapYesNoToBooleanOrReturnValue(keySafe, undefined),
    accessIssues,
    parkingAvailable,
    stepsLeadingUpToTheProperty,
    tenancyId
  };
};

export const mapAdditionalContactFormDataToServiceUserAdditionalContactModel = (
  newAddress: AdditionalContactFormData | undefined
): ServiceUserAdditionalContactModel | undefined => {
  if (!newAddress) {
    return undefined;
  }

  const { useServiceUserAddress, ...address } = newAddress;

  return {
    ...address,
    useServiceUserAddress: mapYesNoToBoolean(useServiceUserAddress)
  };
};

export const mapDeliveryTypesToOptions = (deliveryTypes: DeliveryTypeEnum[]): ISelectOption[] => {
  return deliveryTypes.map((deliveryType) => ({
    label: DeliveryOptionsDisplayNames[deliveryType],
    value: deliveryType
  }));
};

export const mapDeliveryTypeToSpeeds = (
  deliveryType: DeliveryTypeEnum,
  speeds: ContractSpeed[],
  isJointVisit: boolean
): ContractSpeed[] => {
  // All specials delivery types will be the special speed. Just need to worry about the standard activity
  let filteredSpeeds: ContractSpeed[];

  const millbrookDeliversExcludeSpeeds = [
    ServiceSpeedTypeCodeEnum.Postal,
    ServiceSpeedTypeCodeEnum.Prescription,
    ServiceSpeedTypeCodeEnum.Counter
  ];

  if (isJointVisit) {
    millbrookDeliversExcludeSpeeds.push(ServiceSpeedTypeCodeEnum.DatedDelivery);
  }

  switch (deliveryType) {
    case DeliveryTypeEnum.ByPost:
      // by post => postal speed
      filteredSpeeds = speeds.filter((x) => x.serviceSpeedTypeCode === ServiceSpeedTypeCodeEnum.Postal);
      break;
    case DeliveryTypeEnum.CollectFromRetailer:
      // pick from retailer => prescription speed
      filteredSpeeds = speeds.filter((x) => x.serviceSpeedTypeCode === ServiceSpeedTypeCodeEnum.Prescription);
      break;
    case DeliveryTypeEnum.CollectFromMillbrookServiceCenter:
      // collect from millbrook => counter speed
      filteredSpeeds = speeds.filter((x) => x.serviceSpeedTypeCode === ServiceSpeedTypeCodeEnum.Counter);
      break;
    default:
      // millbrook delivers => delivery speeds (everything not postal, retailer, collect)
      // if joint visit, can't have the Dated delivery speed
      filteredSpeeds = speeds.filter((x) => millbrookDeliversExcludeSpeeds.indexOf(x.serviceSpeedTypeCode) === -1);
  }

  return filteredSpeeds;
};

export const mapActivityFilesToUploadFile = (
  activityMedias: ActivityMediaModel[] = [],
  mediaType: MediaTypeEnum
): UploadFile[] => {
  return activityMedias.filter((x) => x.mediaType === mediaType).map((x) => ({ name: x.media.name, id: x.id }));
};
