import { Box } from '@material-ui/core';
import { ROUTES } from 'constants/routes';
import {
  fetchContractProfessionalAssessment,
  postContractProfessionalAssessmentResults,
  selectContractProfessionalAssessment
} from 'features/professionalAssessments/contractProfessionalAssessments.slice';
import {
  ApiId,
  BodyText,
  ContractProfessionalAssessmentQuestionOverview,
  IContractProductSummary,
  Page,
  PageIntro,
  ProductAnswer,
  RadioField,
  SpacingSection,
  StyledBreadcrumb,
  StyledButton,
  StyledDivider,
  StyledGrid
} from 'millbrook-core';
import { ProductList } from 'pages/Account/MostOrdered/components/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  clearContractProfessionalAssessmentAnswers,
  selectContractProfessionalAssessmentAnswers,
  setContractProfessionalAssessmentAnswers
} from '../../features/professionalAssessments/contractProfessionalAssessments.slice';

interface Params {
  contractProfessionalAssessmentId: ApiId;
}

export interface ProfessionalAssessmentResults {
  contractProfessionalAssessmentId: ApiId;
  answers: Answer[];
}

export interface Answer {
  contractProfessionalAssessmentQuestionId: ApiId;
  answer?: boolean;
}

const ProfessionalAssessment: React.FC = () => {
  const dispatch = useDispatch();
  const { contractProfessionalAssessmentId } = useParams<Params>();

  /* hooks */
  const { control, errors, handleSubmit, watch } = useForm({
    reValidateMode: 'onBlur'
  });

  const answers = watch();

  const hasAllAnswers = useMemo(() => {
    return !Object.values(answers).some((x) => x === '');
  }, [answers]);

  const contractProfessionalAssessment = useSelector(selectContractProfessionalAssessment);
  const contractProfessionalAssessmentAnswers = useSelector(
    selectContractProfessionalAssessmentAnswers(contractProfessionalAssessmentId)
  );

  const [products, setProducts] = useState<IContractProductSummary[]>();

  const assessmentTitle = contractProfessionalAssessment?.title ?? 'Unknown assessment title';

  /* events */
  useEffect(() => {
    dispatch<any>(fetchContractProfessionalAssessment(contractProfessionalAssessmentId));
  }, [dispatch, contractProfessionalAssessmentId]);

  useEffect(() => {
    if (contractProfessionalAssessmentAnswers) {
      dispatch<any>(postContractProfessionalAssessmentResults(contractProfessionalAssessmentAnswers)).then(
        (response: IContractProductSummary[]) => setProducts(response)
      );
    }
  }, [dispatch, contractProfessionalAssessmentAnswers]);

  const breadcrumb = {
    [ROUTES.CONTRACT_PROFESSIONAL_ASSESSMENTS.root]: 'Professional assessment areas',
    [ROUTES.CONTRACT_PROFESSIONAL_ASSESSMENTS.assessment(
      contractProfessionalAssessmentId
    )]: `Professional assessment - ${assessmentTitle}`
  };

  const handleClickFinishAssessment = (data: { [key: string]: ProductAnswer }) => {
    const answers = Object.entries(data).map(([contractProfessionalAssessmentQuestionId, value]) => ({
      contractProfessionalAssessmentQuestionId,
      answer: getBooleanAnswer(value)
    }));

    dispatch(
      setContractProfessionalAssessmentAnswers({
        contractProfessionalAssessmentId: contractProfessionalAssessmentId,
        answers: answers
      })
    );
  };

  const handleViewResultsSwitch = () => {
    dispatch(clearContractProfessionalAssessmentAnswers(contractProfessionalAssessmentId));
    setProducts(undefined);
  };

  return (
    <Page>
      <StyledBreadcrumb breadcrumb={breadcrumb} />
      <SpacingSection pageHasBreadcrumbs>
        {!contractProfessionalAssessmentAnswers && (
          <Fragment>
            <PageIntro title={assessmentTitle} />

            <Box mb={4}>
              <BodyText>Answer the questions below to complete assessment</BodyText>
            </Box>

            <StyledDivider color="edward20" />
            {contractProfessionalAssessment &&
              contractProfessionalAssessment.questions
                ?.filter((x) => x.id != null)
                .map(
                  (
                    contractProfessionalAssessmentQuestionOverview: ContractProfessionalAssessmentQuestionOverview,
                    index: number
                  ) => {
                    return (
                      <Fragment key={contractProfessionalAssessmentQuestionOverview.professionalAssessmentQuestionId}>
                        <StyledGrid container>
                          <StyledGrid item xs={9}>
                            <Box>{contractProfessionalAssessmentQuestionOverview.question}</Box>
                            <Box>
                              <RadioField
                                key={index}
                                control={control}
                                row
                                id={contractProfessionalAssessmentQuestionOverview.id}
                                label={'hidden'}
                                name={contractProfessionalAssessmentQuestionOverview.id}
                                error={errors?.answer}
                                hideLegend
                                defaultValue={''}
                                options={
                                  contractProfessionalAssessmentQuestionOverview.sometimesOption
                                    ? [
                                        { label: 'Yes', value: ProductAnswer.Yes },
                                        { label: 'No', value: ProductAnswer.No },
                                        { label: 'Sometimes', value: ProductAnswer.Sometimes }
                                      ]
                                    : [
                                        { label: 'Yes', value: ProductAnswer.Yes },
                                        { label: 'No', value: ProductAnswer.No }
                                      ]
                                }
                              />
                            </Box>
                          </StyledGrid>
                        </StyledGrid>
                      </Fragment>
                    );
                  }
                )}
            <StyledGrid item xs={12}>
              <Box mt={2} mb={2}>
                <StyledButton
                  variant="secondary"
                  disabled={!hasAllAnswers}
                  onClick={() => handleSubmit(handleClickFinishAssessment)()}
                >
                  Finish assessment
                </StyledButton>
              </Box>
            </StyledGrid>
          </Fragment>
        )}

        {contractProfessionalAssessmentAnswers && (
          <Box mb={4}>
            <PageIntro
              title={
                contractProfessionalAssessment && contractProfessionalAssessment.title != undefined
                  ? 'Assessment results for : ' + contractProfessionalAssessment.title
                  : 'Unknown assessment title'
              }
            />
            <Box mb={4}>
              <BodyText>
                {products?.length
                  ? `${products.length} product/s recommended based on the assessment answers given.`
                  : 'No products match your answers provided, please restart this assessment or start new assessment'}
              </BodyText>
            </Box>
            <Box mb={4}>
              <StyledGrid container>
                <StyledGrid item xs={3}>
                  <StyledButton variant="secondary" onClick={() => handleViewResultsSwitch()}>
                    Restart assessment
                  </StyledButton>
                </StyledGrid>
                <StyledGrid item xs={3}>
                  <StyledButton
                    variant="primary"
                    component={RouterLink}
                    to={ROUTES.CONTRACT_PROFESSIONAL_ASSESSMENTS.root}
                  >
                    Start new assessment
                  </StyledButton>
                </StyledGrid>
              </StyledGrid>
            </Box>
            <StyledDivider color="edward20" />
            {!!products?.length && (
              <ProductList
                products={products}
                productDetailsRoute={(productId) =>
                  ROUTES.CONTRACT_PROFESSIONAL_ASSESSMENTS.productDetails(contractProfessionalAssessmentId, productId)
                }
              />
            )}
          </Box>
        )}
      </SpacingSection>
    </Page>
  );
};

const getBooleanAnswer = (answerNumber: number) => {
  if (answerNumber == ProductAnswer.Yes) {
    return true;
  } else if (answerNumber == ProductAnswer.No) {
    return false;
  } else if (answerNumber == ProductAnswer.Sometimes) {
    return undefined;
  }
};

export default ProfessionalAssessment;
