import add from 'date-fns/add';
import {
    AdditionalServiceUserContractField,
    ApiId,
    mapYesNoToBoolean,
    mapYesNoToBooleanOrReturnValue,
    MasterReviewPeriod,
    TimeFrameDisplayName
} from 'millbrook-core';
import { CreateUserFormData } from 'pages/service-users/components/create-user/CreateUserForm.validation';
import { CreateServiceUserRequest } from './editing.slice';

export const mapFormDataToResponse = (data: CreateUserFormData): CreateServiceUserRequest => {
    const {
        additionalContacts,
        primaryContactNumberCode,
        addresses,
        clientAlerts,
        additionalContactsValidation,
        addressesValidation,
        serviceUserGroup,
        ethnicity,
        contractFields,
        ...rest
    } = data;
    const mappedAddreses =
        addresses &&
        addresses.map(
            ({
                isPrimary,
                dogOnPremise,
                keySafe,
                accessIssues,
                parkingAvailable,
                stepsLeadingUpToTheProperty,
                tenancyId,
                ...address
            }) => ({
                isPrimary,
                address: { ...address, id: address.id || undefined },
                dogOnPremise: mapYesNoToBooleanOrReturnValue(dogOnPremise, undefined),
                keySafe: mapYesNoToBooleanOrReturnValue(keySafe, undefined),
                accessIssues,
                parkingAvailable,
                tenancyId,
                stepsLeadingUpToTheProperty
            })
        );
    const mappedContacts =
        additionalContacts &&
        additionalContacts.map(({ useServiceUserAddress, ...restOfContact }) => ({
            ...restOfContact,
            useServiceUserAddress: mapYesNoToBoolean(useServiceUserAddress)
        }));

    // TODO: Why are there all these any's in here?
    return {
        addresses: (mappedAddreses as any) || [],
        additionalContacts: (mappedContacts as any) || [],
        contractServiceUserGroupId: serviceUserGroup,
        ethnicityId: ethnicity,
        clientAlerts,
        contractFields: (contractFields as AdditionalServiceUserContractField[]) || [],
        primaryContactNumberCode:
            data.landline && data.mobile && primaryContactNumberCode !== undefined ? primaryContactNumberCode : undefined,
        ...rest,
    };
};

export const getExtendedReviewPeriodDate = (selectedReviewPeriod: MasterReviewPeriod): Date | undefined => {
    let newDate: Date | undefined = undefined;

    if (selectedReviewPeriod) {
        const periodTypeToAdd = TimeFrameDisplayName[selectedReviewPeriod.reviewPeriodType].toLowerCase();
        newDate = add(new Date(), { [periodTypeToAdd]: selectedReviewPeriod.units });
    }

    return newDate;
};

export const getExtendedReviewPeriodDate1 = (
    reviewPeriodId: ApiId,
    reviewPeriods: MasterReviewPeriod[]
): Date | undefined => {
    const selectedReviewPeriod = reviewPeriods.find((rp) => rp.id === reviewPeriodId);

    let newDate: Date | undefined = undefined;

    if (selectedReviewPeriod) {
        const periodTypeToAdd = TimeFrameDisplayName[selectedReviewPeriod.reviewPeriodType].toLowerCase();
        newDate = add(new Date(), { [periodTypeToAdd]: selectedReviewPeriod.units });
    }

    return newDate;
};
