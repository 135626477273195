import { fileValidationMaxFiveDocuments } from 'millbrook-core';
import * as yup from 'yup';

// Constants
export const formId = 'productInstallationForm';

// Schema / Data
export const productInstallationFormSchema = yup
  .object()
  .shape({
    installationRequirements: yup.string(),
    files: fileValidationMaxFiveDocuments
  })
  .test(
    'atLeastOne',
    'Please provide supporting notes or files',
    (value) => !!(value.installationRequirements || value.files?.length)
  );

export type ProductInstallationFormData = yup.InferType<typeof productInstallationFormSchema>;

export const defaultProductInstallationFormData: ProductInstallationFormData = {
  installationRequirements: ''
};
