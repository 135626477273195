import { format, parseISO } from 'date-fns';
import {
  ContractServiceUserGroup,
  IContractClientAlert,
  IServerSelectOption,
  mapBooleanToYesNo,
  ServiceUser,
  SHORT_UTC_DATE_FORMAT
} from 'millbrook-core';
import { CreateUserFormData } from 'pages/service-users/components/create-user/CreateUserForm.validation';

export const mapServerDataToFormData = (data?: ServiceUser): Partial<CreateUserFormData> => {
  if (!data) {
    return {} as Partial<CreateUserFormData>;
  }

  const {
    additionalInformation: { additionalDetails, additionalContacts, contactDetails, addresses },
    serviceUserClientAlerts,
    details
  } = data;

  return {
    ...additionalDetails,
    additionalContacts: additionalContacts.map((contact) => ({
      ...contact,
      useServiceUserAddress: mapBooleanToYesNo(contact.useServiceUserAddress)
    })),

    ...contactDetails,
    serviceUserGroup: additionalDetails.contractServiceUserGroup?.id,
    clientAlerts: serviceUserClientAlerts.map((alert) => alert.contractServiceUserAlert.id),
    addresses: addresses.map((mapedAddress) => {
      const { address, dogOnPremise, keySafe, ...rest } = mapedAddress;
      return {
        ...rest,
        ...address,
        dogOnPremise: mapBooleanToYesNo(dogOnPremise),
        keySafe: mapBooleanToYesNo(keySafe)
      };
    }),
    dob: details.dob ? format(parseISO(details.dob), SHORT_UTC_DATE_FORMAT) : null,
    title: details.title,
    firstName: details.firstName,
    middleName: details.middleName || '',
    surname: details.surname,
    contractFields: details.contractFields || [],
    primaryContactNumberCode: details.primaryContactNumberCode,
    ethnicity: details.ethnicity?.id || ''
  } as any;
};

export const mapServiceUserGroupOptionsFromServer = (list?: ContractServiceUserGroup[]): IServerSelectOption[] => {
  return (list || []).map((group) => ({ id: group.id, name: group.serviceUserGroup.name }));
};

export const mapServiceUserClientAlertsOptionsFromServer = (list?: IContractClientAlert[]): IServerSelectOption[] => {
  return (list || []).map((alert) => ({ id: alert.id, name: alert.name }));
};
