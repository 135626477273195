import { yupResolver } from '@hookform/resolvers';
import { Box, Card, CardActionArea, CardContent, Divider, fade, makeStyles } from '@material-ui/core';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import CachedIcon from '@material-ui/icons/Cached';
import ReservedIcon from '@material-ui/icons/LocalOffer';
import { ROUTES } from 'constants/routes';
import { selectCanAddToBasket } from 'features/auth-pin/auth-pin.slice';
import { addToBasket, selectHasSelectedServiceUser } from 'features/basket/basket.slice';
import { selectContractData } from 'features/contract/contract.slice';
import { useReserveProductDialog } from 'hooks/useReserveProductDialog';
import {
    ApiId,
    BodyText,
    ButtonsList,
    COLORS,
    DisplayAsMoney,
    IconLabel,
    IContractProductSummary,
    InputField,
    mapServiceToImage,
    ProductImageCard,
    ServiceTypeCodeEnum,
    ShelfTypeEnum,
    StyledButton,
    StyledGrid
} from 'millbrook-core';
import { QuantityFormData, quantitySchema } from 'pages/checkout/components/BasketSummaryProduct/BasketSummaryProduct';
import { ProductInstallationDialog } from 'pages/Products/components/ProductInstallationDialog/ProductInstallationDialog';
import { ProductReservation } from 'pages/Products/components/ProductReservation/ProductReservation';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const productCardStyles = makeStyles((theme) => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    nameLink: {
        cursor: 'pointer'
    },
    content: {
        height: '100%'
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3)
    }
}));

interface ProductCardProps {
    product: IContractProductSummary;
    isSwap?: boolean;
    handleSwapItem?: (item: IContractProductSummary) => void;
    onReserveToggle?: (contractProductId: ApiId) => void;
    onAddToBasket?: () => void;
    productDetailsRoute?: (productId: ApiId) => string;
}

export const ProductCard: React.FC<ProductCardProps> = ({
    product,
    isSwap,
    handleSwapItem,
    onReserveToggle,
    onAddToBasket,
    productDetailsRoute
}) => {
    /* hooks */
    const history = useHistory();
    const classes = productCardStyles();
    const dispatch = useDispatch();
    const hasSelectedServiceUser = useSelector(selectHasSelectedServiceUser);
    const canAddToBasket = useSelector(selectCanAddToBasket);
    const { displayStockLevels } = useSelector(selectContractData);

    const { reserveDialogIsOpen, reserving, handleStartReserveItem, handleStartUnreserveItem, handleCloseReserveItem } =
        useReserveProductDialog();

    const { control, errors, handleSubmit, register } = useForm<QuantityFormData>({
        defaultValues: { quantity: 1 },
        resolver: yupResolver(quantitySchema)
    });

    /* state */
    const [installationDialogIsOpen, setInstallationDialogIsOpen] = useState<boolean>();

    const productDetailsUrl = productDetailsRoute
        ? productDetailsRoute(product.contractProductId)
        : ROUTES.PRODUCT.productDetails(product.contractProductId);

    /* events */
    const handleDetailsClick = (e: React.MouseEvent<any, MouseEvent>) => {
        e?.preventDefault();

        history.push(productDetailsUrl);
    };

    const handleSwapClick = (product: IContractProductSummary) => (e: React.MouseEvent<any, MouseEvent>) => {
        handleSwapItem && handleSwapItem(product);
    };

    const handleAddToBasket = useCallback(
        (product: IContractProductSummary, notes?: string, files?: FileList) => {
            handleSubmit((data: QuantityFormData) => {
                const quantity = data.quantity;
                product &&
                    dispatch<any>(addToBasket(product, quantity, notes, files)).then((success: boolean) => {
                        success && onAddToBasket && onAddToBasket();
                    });
            })();
        },
        [dispatch, handleSubmit, onAddToBasket]
    );

    const handleAddClick = useCallback(
        (product: IContractProductSummary) => () => {
            // If product requires installation, show installation dialog with additional questions
            // check there is a service user first
            if (hasSelectedServiceUser && product.requiresInstallationNotes) {
                setInstallationDialogIsOpen(true);
            } else {
                handleAddToBasket(product);
            }
        },
        [handleAddToBasket, hasSelectedServiceUser]
    );

    const handleCloseInstallationDialog = () => {
        setInstallationDialogIsOpen(false);
    };

    const handleReservationSuccess = () => {
        handleCloseReserveItem();
        onReserveToggle && onReserveToggle(product.contractProductId);
    };

    if (!product) {
        return null;
    }

    /* values. Just pulling the props out */
    const {
        quantityInCleaning,
        mainImage,
        shelfType,
        isReserved,
        isReservedForUser,
        contractProductId,
        issueCost,
        name,
        description,
        isAuthorisationRequired,
        requiresInstallationNotes,
        sku,
        isTelecare,
        anyCteConfigured,
        supplierLeadTime,
        stockOnOrder
    } = product;

    const quantity = Math.max(product.quantity, 0);

    //TODO: The contract product model is being used in other places and data is coming back as a string from api instead of mediamodel we expect.
    //          JM: This is happening with the related products api, hopefully updated soon
    const image = typeof mainImage === 'string' ? (mainImage as unknown as string) : mainImage?.linkUrl;

    // this *should* work, I think this card is mostly used for delivery type scenarios. Either way the ContractProductSummary doesn't have the service on it.
    const productImage = image ?? mapServiceToImage(ServiceTypeCodeEnum.Delivery, shelfType);

    const isRecycledSpecial = shelfType === ShelfTypeEnum.RecycledSpecial;
    // TODO: Not sure how out of stock works with recycled specials? Are they unique. Think that if they have no stock, they should not be in the result set.
    const isReservable = isRecycledSpecial && !isReserved;
    const isProductReserved = isRecycledSpecial && isReserved;
    const isReservedByLoggedUser = isRecycledSpecial && isReservedForUser;

    return (
        <Card variant="outlined" className={classes.card}>
            <CardActionArea component="div" onClick={handleDetailsClick}>
                <ProductImageCard image={productImage} title={name} ratio="productcard" disableBottomRadius has360Images={product.product360Images && product.product360Images.length > 0}>
                    {isRecycledSpecial && RecycledItemTag(product.isPooled)}
                    {isProductReserved
                        && product.reservedDate != undefined && product.reservedEndDate != undefined && product.reservedProductHoldTime != undefined
                        && ReservedItemTag(isReservedByLoggedUser, product.reservedByName, product.reservedProductHoldTime, product.reservedDate as any, product.reservedEndDate as any)}
                </ProductImageCard>
            </CardActionArea>
            <CardContent className={classes.content}>
                <Box display="flex" flexDirection="column" height="100%">
                    <Box>
                        {/* Main details */}
                        <Box display="flex" justifyContent="space-between">
                            <div onClick={handleDetailsClick} className={classes.nameLink}>
                                <BodyText type="small" color="regalBlue" bold gutterBottom>
                                    {sku}
                                </BodyText>
                                <BodyText component="h2" type="regular" color="regalBlue" bold gutterBottom>
                                    {name}
                                </BodyText>
                            </div>
                            <BodyText overflowWrap="normal" type="regular" bold>
                                <DisplayAsMoney amount={issueCost} />
                            </BodyText>
                        </Box>
                        <BodyText type="small" gutterBottom>
                            {description}
                        </BodyText>

                        {isProductReserved && (
                            <IconLabel icon={<AnnouncementIcon color="primary" />} text="This product is reserved" textSize="small" />
                        )}

                        {!!isAuthorisationRequired && (
                            <IconLabel
                                icon={<AnnouncementIcon color="primary" />}
                                text="This product requires authorisation"
                                textSize="small"
                            />
                        )}

                        {isTelecare && (
                            <IconLabel
                                icon={<AnnouncementIcon color="primary" />}
                                text="This product requires a monitoring form"
                                textSize="small"
                            />
                        )}

                        {requiresInstallationNotes && (
                            <IconLabel
                                icon={<AnnouncementIcon color="primary" />}
                                text="This product requires installation"
                                textSize="small"
                            />
                        )}

                        {product.product360Images && product.product360Images.length > 0 && (
                            <IconLabel
                                icon={<AnnouncementIcon color="primary" />}
                                text="360 degree view avaliable"
                                textSize="small"
                            />
                        )}
                        <Divider className={classes.divider} />
                    </Box>

                    <Box mt="auto">
                        {displayStockLevels && !isRecycledSpecial && (
                            <>
                                <BodyText component="div" type="small">
                                    <StyledGrid container spacing={2} justify="space-between">
                                        <StyledGrid item>
                                            {/* Quantity will always show, and have no bearing on if a product is orderable or not */}
                                            <strong>{quantity}</strong> on shelf
                                        </StyledGrid>
                                        {!!quantityInCleaning && (
                                            <StyledGrid item>
                                                <strong>{quantityInCleaning}</strong> in cleaning
                                            </StyledGrid>
                                        )}
                                        {quantity <= 0 && supplierLeadTime > 0 && !stockOnOrder && (
                                            <StyledGrid item>
                                                Lead time: <strong>{supplierLeadTime}</strong> days
                                            </StyledGrid>
                                        )}
                                        {stockOnOrder && <StyledGrid item>Stock on order</StyledGrid>}
                                    </StyledGrid>
                                    <span style={{ color: COLORS.scienceBlue }}>
                                        {anyCteConfigured ? 'CTEs Available' : 'No CTEs configured'}
                                    </span>
                                </BodyText>
                                <Divider className={classes.divider} />
                            </>
                        )}

                        {/* Quantity */}
                        <ButtonsList>
                            {isSwap || isRecycledSpecial ? (
                                // This is to fake the RSP input. Easier than playing around with validation or the handleSubmit
                                <input type="hidden" ref={register} name="quantity" />
                            ) : (
                                <InputField
                                    label="Quantity"
                                    animatedLabel
                                    style={{ width: 120 }}
                                    control={control}
                                    error={errors?.quantity}
                                    name="quantity"
                                    value={quantity}
                                    type="number"
                                    inputProps={{ min: 1, max: 100 }}
                                    readOnly={requiresInstallationNotes}
                                />
                            )}

                            {/* Recycled */}
                            {isReservable && canAddToBasket && (
                                <StyledButton fullWidth variant="secondary" onClick={handleStartReserveItem}>
                                    Reserve item
                                </StyledButton>
                            )}

                            {isReservedByLoggedUser && (
                                <StyledButton fullWidth variant="secondary" onClick={handleStartUnreserveItem}>
                                    Unreserve { }
                                </StyledButton>
                            )}

                            {!isSwap && (
                                <StyledButton
                                    fullWidth
                                    onClick={handleAddClick(product)}
                                    disabled={isProductReserved || !canAddToBasket}
                                    tooltip={canAddToBasket ? '' : "You don't have permissions to create a new basket."}
                                >
                                    {isProductReserved ? 'Reserved' : canAddToBasket ? 'Add to basket' : `Can't create order`}
                                </StyledButton>
                            )}

                            {isSwap && (
                                <StyledButton fullWidth onClick={handleSwapClick(product)} disabled={isProductReserved}>
                                    {isProductReserved ? 'Reserved' : 'Swap'}
                                </StyledButton>
                            )}
                        </ButtonsList>
                    </Box>
                </Box>
            </CardContent>
            {requiresInstallationNotes && (
                <ProductInstallationDialog
                    productId={contractProductId}
                    isOpen={installationDialogIsOpen}
                    onClose={handleCloseInstallationDialog}
                    onSuccess={(notes: string, files: FileList) => {
                        handleCloseInstallationDialog();
                        handleAddToBasket(product, notes, files);
                    }}
                />
            )}
            {isRecycledSpecial && (
                <ProductReservation
                    product={product}
                    reserving={reserving}
                    isOpen={reserveDialogIsOpen}
                    onClose={handleCloseReserveItem}
                    onSuccess={handleReservationSuccess}
                />
            )}
        </Card>
    );
};

export const RecycledItemTag = (isPooled?: boolean) => (
    <Box
        bgcolor={fade(COLORS.white, 0.8)}
        m={1}
        px={2}
        py={0.5}
        alignItems="center"
        display="flex"
        position="absolute"
        left={0}
        bottom={0}
        borderRadius={8}
        border={`1px solid ${COLORS.jungleGreen}`}
    >
        <CachedIcon htmlColor={COLORS.jungleGreen} />
        <Box display="inline" ml={1}>
            {isPooled ? 'Pooled r' : 'R'}ecycled item
        </Box>
    </Box>
);

export const ReservedItemTag = (isReservedByLoggedUser: boolean, reservedName: string, reservedHoldTime: number, resDate: Date, resEndDate: Date) => (
    <Box
        bgcolor={fade(COLORS.white, 0.8)}
        m={1}
        px={2}
        py={0.5}
        alignItems="center"
        display="flex"
        position="absolute"
        left={0}
        marginTop={1}
        borderRadius={8}
        border={`1px solid ${COLORS.jungleGreen}`}
        zIndex={100}
        title={!isReservedByLoggedUser ? `Reserved by: ${reservedName}\nReserved for ${reservedHoldTime} days\nReservation Started: ${new Date(resDate).toLocaleDateString('en-GB')} ${new Date(resDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}\nReservation Ending: ${new Date(resEndDate).toLocaleDateString('en-GB')} ${new Date(resEndDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}` : `Reserved for ${reservedHoldTime} days\nReservation Started: ${new Date(resDate).toLocaleDateString('en-GB')} ${new Date(resDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}\nReservation Ending: ${new Date(resEndDate).toLocaleDateString('en-GB')} ${new Date(resEndDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}
    >

        <ReservedIcon htmlColor={COLORS.jungleGreen} />
        <Box display="inline" ml={1}>
            {isReservedByLoggedUser ? 'Reserved by you' : 'Reserved'}
        </Box>

    </Box>
);
