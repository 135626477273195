import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { replace } from 'connected-react-router';
import { ENDPOINTS } from 'constants/api';
import { ROUTES } from 'constants/routes';
import { selectSelectedServiceUser } from 'features/basket/basket.slice';
import { selectContractId } from 'features/contract/contract.slice';
import {
  ActivityFromPeripheralStoreModel,
  ActivityFromPeripheralStoresSearchRequestModel,
  ApiId,
  ContractReviewTypeModel,
  IApiPagination,
  IApiResponse,
  ISearchPagination,
  mapApiErrors,
  MasterReviewPeriod,
  PeripheralStoreBaskets,
  PeripheralStoreDeliveryRequestModel,
  PeripheralStoreDeliveryResponseModel,
  PeripheralStoreDeliverySpeedModel,
  PeripheralStorePortal,
  PeripheralStorePukSummary
} from 'millbrook-core';
import {
  PeripheralStoreCheckoutFormData,
  PeripheralStoreCreateActivityRequest
} from 'pages/PeripheralStores/PeripheralStoreCheckoutPage/components/PeripheralStoreCheckout.validation';
import qs from 'query-string';
import { getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';

type PukBasketMethod = 'add' | 'remove';

export const defaultPstoreActivityPaging: ISearchPagination = {
  pageSize: 5,
  pageIndex: 1,
  sortColumn: 'targetDate',
  sortOrder: 'desc'
};

export interface PeripheralStorePukManage {
  key: string;
  product: PeripheralStorePukSummary;
  method: PukBasketMethod;
}

export interface PstoreActivityFormModel {
  contractReviewTypes: ContractReviewTypeModel[];
  reviewPeriods: MasterReviewPeriod[];
}

export interface PeripheralStoreCheckoutInit {
  serviceUserId: ApiId;
  stockIds: ApiId[];
}

export interface PStoreOrderItemModel {
  stockId: ApiId;
  contractReviewTypeId?: ApiId;
  reviewPeriodId?: ApiId;
}

/* state */
interface State {
  peripheralStoreList: PeripheralStorePortal[];
  selectedPeripheralStore: PeripheralStorePortal | undefined;
  peripheralStoreBaskets: PeripheralStoreBaskets;
  peripheralStoreDeliveryResponse: PeripheralStoreDeliveryResponseModel | undefined;
}

const initialState: State = {
  peripheralStoreList: [],
  selectedPeripheralStore: undefined,
  peripheralStoreBaskets: {} as PeripheralStoreBaskets,
  peripheralStoreDeliveryResponse: undefined
};

/* slice */
const peripheralStoresSlice = createSlice({
  name: 'peripheralStores',
  initialState,
  reducers: {
    setPeripheralStoreList(state, action: PayloadAction<PeripheralStorePortal[]>) {
      state.peripheralStoreList = action.payload;
    },
    setSelectedPeripheralStore(state, action: PayloadAction<PeripheralStorePortal | undefined>) {
      state.selectedPeripheralStore = action.payload;
    },
    updatePStoreBasket(state, action: PayloadAction<PeripheralStorePukManage>) {
      const { key, product, method } = action.payload;

      let basket = state.peripheralStoreBaskets[key] || [];

      if (method === 'add') {
        const isDuplicate = basket.some((p) => p.stockId === product.stockId);
        !isDuplicate && (basket = [product, ...basket]);
      } else if (method === 'remove') {
        basket = basket.filter((p) => p.stockId !== product.stockId);
      }

      state.peripheralStoreBaskets[key] = basket;
    },
    pStoreBasketOrdered(state, action: PayloadAction<{ key: string; activityReference: string }>) {
      const { key, activityReference } = action.payload;
      // switch the basket from the contractId serviceUserId combo to the activityReference
      // get the current basket. Clone it just in case. Don't want a reference
      const basket = state.peripheralStoreBaskets[key];
      if (basket) {
        state.peripheralStoreBaskets[activityReference] = [...basket];
        delete state.peripheralStoreBaskets[key];
      }
    },
    deletePStoreBasket(state, action: PayloadAction<string>) {
      // this can be the contractId + serviceUserId, or the activityReference. Don't know if this will be used
      delete state.peripheralStoreBaskets[action.payload];
    },
    setPeripheralStoreDeliveryResponse(state, action: PayloadAction<PeripheralStoreDeliveryResponseModel>) {
      state.peripheralStoreDeliveryResponse = action.payload;
    }
  }
});

/**
 * Selectors
 */
export const getPeripheralStoreList = (state: RootState) => state.peripheralStores.peripheralStoreList;
export const getSelectedPeripheralStore = (state: RootState) => state.peripheralStores.selectedPeripheralStore;
export const getPeripheralStoreDeliveryResponse = (state: RootState) =>
  state.peripheralStores.peripheralStoreDeliveryResponse;
const getPeripheralStoreBaskets = (state: RootState) => state.peripheralStores.peripheralStoreBaskets;

// Gets the basket default to the contract and service user id
export const selectPeripheralStoreBasketItems = createSelector(
  [getPeripheralStoreBaskets, selectContractId, selectSelectedServiceUser],
  (baskets, contractId, serviceUser): PeripheralStorePukSummary[] => {
    return (baskets && contractId && !!serviceUser && baskets[contractId + serviceUser.id]) || [];
  }
);

// Gets the basket for the order reference (if it exists)
export const getOrderPeripheralStoreBasket = (activityReference?: string) =>
  createSelector([getPeripheralStoreBaskets], (baskets): PeripheralStorePukSummary[] | undefined => {
    return activityReference ? baskets[activityReference] : undefined;
  });

/**
 * Actions
 */
export const { setPeripheralStoreList, setSelectedPeripheralStore, setPeripheralStoreDeliveryResponse } =
  peripheralStoresSlice.actions;

/**
 * Thunks
 */
export const fetchPeripheralStoreList = (): AppThunk => async (dispatch) => {
  return getItems<IApiResponse<PeripheralStorePortal[]>>(ENDPOINTS.PERIPHERAL_STORES.FetchList, {
    enableGlobalErrorDialog: true
  }).then(
    (response) => {
      dispatch(setPeripheralStoreList(response.result || initialState.peripheralStoreList));
    },
    () => {
      // handled with global error handler
    }
  );
};

export const fetchPeripheralStoreOptions = (): AppThunk => async () => {
  return getItems<IApiResponse<PeripheralStorePortal[]>>(ENDPOINTS.PERIPHERAL_STORES.FetchOptions, {
    enableGlobalErrorDialog: true
  }).then(
    (response) => {
      return response.result?.map((item) => ({ label: item.name, value: item.id })) || [];
    },
    () => {
      // handled with global error handler
    }
  );
};

export const fetchPeripheralStore =
  (peripheralStoreId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<IApiResponse<any>>(ENDPOINTS.PERIPHERAL_STORES.FetchDetails(peripheralStoreId), {
      enableGlobalErrorDialog: true
    }).then(
      (response) => {
        dispatch(setSelectedPeripheralStore(response.result || initialState.selectedPeripheralStore));
      },
      () => {
        // handled with global error handler
      }
    );
  };

export const fetchPStoreActivities =
  (searchFilters: ActivityFromPeripheralStoresSearchRequestModel = {}): AppThunk =>
  async (dispatch, getState) => {
    const URL = qs.stringifyUrl(
      {
        url: ENDPOINTS.PERIPHERAL_STORES.Activities,
        query: {
          ...defaultPstoreActivityPaging,
          ...searchFilters
        }
      },
      { skipEmptyString: true }
    );

    return getItems<IApiResponse<IApiPagination<ActivityFromPeripheralStoreModel[]>>>(URL, {
      disableFullPageLoader: true
    }).then((response) => {
      return response.result;
    });
  };

export const updatePeripheralStoreOrderQuantity =
  (peripheralStoreId: ApiId, productId: ApiId, nextDeliveryQuantity: number): AppThunk =>
  async (dispatch) => {
    interface RequestData {
      quantity: number;
    }

    const requestData: RequestData = {
      quantity: nextDeliveryQuantity
    };

    return putItem<RequestData, IApiResponse<PeripheralStorePortal>>(
      ENDPOINTS.PERIPHERAL_STORES.UpdateOrderQuantity(peripheralStoreId),
      requestData,
      productId,
      {
        enableGlobalErrorDialog: true
      }
    ).then(
      () => {
        dispatch(fetchPeripheralStore(peripheralStoreId));
      },
      () => {
        // handled with global error handler
      }
    );
  };

export const searchPeripheralStores =
  (puk: string): AppThunk =>
  async () => {
    return getItems<IApiResponse<PeripheralStorePukSummary>>(ENDPOINTS.PERIPHERAL_STORES.Search(puk), {
      enableGlobalErrorDialog: true
    })
      .then((response) => {
        return response.result || null;
      })
      .catch(() => {
        // handled with global handler
      });
  };

export const initialisePeripheralStoreOrder = (): AppThunk => async (dispatch, getState) => {
  // get the data from the basket
  const app = getState();
  const products = selectPeripheralStoreBasketItems(app);
  const serviceUser = selectSelectedServiceUser(app);

  // You should have a service user at this point, this is to placate typescript
  if (serviceUser) {
    return postItems<PeripheralStoreCheckoutInit, any>(
      ENDPOINTS.PERIPHERAL_STORES.OrderFormData,
      {
        serviceUserId: serviceUser.id,
        stockIds: products.map((p) => p.stockId)
      },
      { enableGlobalErrorDialog: true }
    )
      .then((response) => {
        return response.result || null;
      })
      .catch(() => {
        // handled with global handler
      });
  }
};

export const createPeripheralStoreOrder =
  (data: PeripheralStoreCheckoutFormData): AppThunk =>
  async (dispatch, getState) => {
    const app = getState();
    const selectedServiceUser = selectSelectedServiceUser(app);

    if (!selectedServiceUser) {
      throw Error('No selected service user');
    }

    let request = { ...data, serviceUserId: selectedServiceUser.id };

    return postItems<PeripheralStoreCreateActivityRequest, any>(ENDPOINTS.PERIPHERAL_STORES.CreateOrder, request, {
      enableGlobalErrorDialog: true
    })
      .then((response) => {
        // switch the basket over. Should allow us to temporaryily view the basket if we need to on the order page - at least until the session ends
        dispatch(pStoreBasketOrdered(response.result));

        dispatch(replace(ROUTES.PERIPHERAL_STORES.confirmation(response.result || '')));
      })
      .catch(() => {
        // handled with global error handler
      });
  };

export const createPeripheralStoreDelivery =
  (pstoreActivity: PeripheralStoreDeliveryRequestModel): AppThunk =>
  async (dispatch, getState) => {
    return postItems<PeripheralStoreDeliveryRequestModel, IApiResponse<PeripheralStoreDeliveryResponseModel>>(
      ENDPOINTS.PERIPHERAL_STORES.CreateDelivery,
      pstoreActivity,
      { enableGlobalErrorDialog: true }
    )
      .then((response) => {
        if (response.result) {
          dispatch(setPeripheralStoreDeliveryResponse(response.result));
          return response.result;
        }
      })
      .catch(() => {
        console.error('createPeripheralStoreDelivery error');
      });
  };

export const peripheralStoreDeliverySpeeds = (): AppThunk => async (dispatch, getState) => {
  const app = getState();
  const contractId = selectContractId(app);

  if (contractId) {
    return getItems<IApiResponse<PeripheralStoreDeliverySpeedModel[]>>(
      ENDPOINTS.PERIPHERAL_STORES.DeliverySpeeds(contractId),
      {
        enableGlobalErrorDialog: true
      }
    )
      .then(
        (response) => {
          if (response.result) {
            return response.result;
          }
        },
        (response) => {
          const error = mapApiErrors(response);
          throw new Error(error);
        }
      )
      .catch((e) => {
        throw new Error(e);
      });
  }
};

// shortcuts to the action so you don't need to keep getting the stuff from the state. This is probably not great, but I didn't want to pass service user ids and contract ids around on the pages
export const updatePStoreBasket =
  (product: PeripheralStorePukSummary, method: PukBasketMethod): AppThunk =>
  async (dispatch, getState) => {
    const app = getState();
    const contractId = selectContractId(app);
    const selectedServiceUser = selectSelectedServiceUser(app);

    if (contractId && selectedServiceUser) {
      dispatch(
        peripheralStoresSlice.actions.updatePStoreBasket({
          key: contractId + selectedServiceUser.id,
          product,
          method
        })
      );
    }
  };

export const addPukToPStoreBasket =
  (product: PeripheralStorePukSummary): AppThunk =>
  async (dispatch, getState) => {
    dispatch(updatePStoreBasket(product, 'add'));
  };

export const removePukFromPStoreBasket =
  (product: PeripheralStorePukSummary): AppThunk =>
  async (dispatch, getState) => {
    dispatch(updatePStoreBasket(product, 'remove'));
  };

// you can only delete the basket you are currently working with
export const pStoreBasketOrdered =
  (activityReference: ApiId): AppThunk =>
  async (dispatch, getState) => {
    const app = getState();
    const contractId = selectContractId(app);
    const selectedServiceUser = selectSelectedServiceUser(app);

    if (contractId && selectedServiceUser) {
      dispatch(
        peripheralStoresSlice.actions.pStoreBasketOrdered({
          key: contractId + selectedServiceUser.id,
          activityReference
        })
      );
    }
  };

export const pStoreDeleteOrder = (): AppThunk => async (dispatch, getState) => {
  const app = getState();
  const contractId = selectContractId(app);
  const selectedServiceUser = selectSelectedServiceUser(app);

  if (contractId && selectedServiceUser) {
    dispatch(peripheralStoresSlice.actions.deletePStoreBasket(contractId + selectedServiceUser.id));
    dispatch(replace(ROUTES.SERVICE_USERS.details(selectedServiceUser.id)));
  }
};

export default peripheralStoresSlice.reducer;
