import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { BASE_FEEDBACK_SEARCH } from 'constants/pagination';
import {
  ApiId,
  FeedbackCategoryEnum,
  FeedbackCategoryText,
  FeedbackStatusEnum,
  IApiPagination,
  IApiResponse,
  ISelectOption,
  mapApiErrors
} from 'millbrook-core';
import { RespondFeedbackFormData } from 'pages/Feedback/components/forms/RespondFeedbackForm';
import qs from 'query-string';
import { getItem, getItems, postItems, putItem } from 'services/api.service';
import store, { AppThunk, RootState } from 'store/store';
import {
  FeedbackAssignee,
  FeedbackCreatePostModel,
  FeedbackDetailResponse,
  FeedbackResponsePutRequest,
  FeedbackSearch,
  FeedbackStatusPutRequest,
  FeedbackStatusPutResponse,
  FeedbackTypeResponse
} from './feedback.types';

/* types */
export type PagedFeedback = IApiPagination<FeedbackDetailResponse[]>;
export type FeedbackTypeListResponse = IApiResponse<FeedbackTypeResponse[]>;

/* state */
interface ViewFeedbackState {
  feedbackTypes: FeedbackTypeResponse[];
  feedbackSearchData: FeedbackSearch;
  selectedFeedbackDetail: FeedbackDetailResponse | undefined;
}

const initialState: ViewFeedbackState = {
  feedbackTypes: [],
  feedbackSearchData: { statuses: [FeedbackStatusEnum.Open] },
  selectedFeedbackDetail: undefined
};

/* slice */
const feedbackSlice = createSlice({
  name: 'feedbackSlice',
  initialState,
  reducers: {
    setFeedbackDetail(state, action: PayloadAction<FeedbackDetailResponse>) {
      state.selectedFeedbackDetail = action.payload;
    },
    setFeedbackSearchData(state, action: PayloadAction<FeedbackSearch>) {
      state.feedbackSearchData = action.payload;
    },
    updateFeedbackSearchData(state, action: PayloadAction<FeedbackSearch>) {
      state.feedbackSearchData = { ...state.feedbackSearchData, ...action.payload };
    },
    resetFeedbackSearchData(state) {
      state.feedbackSearchData = initialState.feedbackSearchData;
    },
    setFeedbackTypes(state, action: PayloadAction<FeedbackTypeResponse[]>) {
      state.feedbackTypes = action.payload;
    }
  }
});

/* helpers */

export const resetFeedbackSearchParams = () => {
  store.dispatch(feedbackSlice.actions.resetFeedbackSearchData());
};

/* thunks */
const generateFeedbackParamsUrl = (searchFilters: FeedbackSearch, baseUrl: string) => {
  return qs.stringifyUrl(
    {
      url: baseUrl,
      query: {
        ...BASE_FEEDBACK_SEARCH,
        ...searchFilters
      }
    },
    { skipEmptyString: true }
  );
};

export const fetchFeedbackList =
  (searchFilters: FeedbackSearch = {}): AppThunk =>
  async (dispatch) => {
    const URL = generateFeedbackParamsUrl(searchFilters, ENDPOINTS.FEEDBACK.Get);

    return getItems<IApiResponse<PagedFeedback>>(URL).then(
      (response) => {
        return response.result;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchFeedbackFilters =
  (searchFilters: FeedbackSearch = {}): AppThunk =>
  async (dispatch, getState) => {
    const URL = generateFeedbackParamsUrl(searchFilters, ENDPOINTS.FEEDBACK.GetFilters);

    return getItems<any>(URL, { cacheName: 'feedback-filters', disableFullPageLoader: true }).then((response) => {
      return response.result;
    });
  };

export const fetchFeedbackDetail =
  (feedbackId: string): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, IApiResponse<FeedbackDetailResponse>>(ENDPOINTS.FEEDBACK.Get, feedbackId, {
      enableGlobalErrorDialog: true
    }).then((response) => {
      if (response.result) {
        dispatch(setFeedbackDetail(response.result));
      }
    });
  };

export const addFeedbackResponse =
  (data: RespondFeedbackFormData, feedbackId: ApiId, authUserId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return postItems<FeedbackResponsePutRequest, IApiResponse<FeedbackDetailResponse>>(
      ENDPOINTS.FEEDBACK.RespondFeedback,
      {
        feedbackId: feedbackId,
        feedbackText: data.feedbackText,
        userId: authUserId
      },
      {
        enableGlobalErrorDialog: true
      }
    ).then(
      (response) => {
        dispatch(fetchFeedbackDetail(feedbackId));
      },
      (response) => {
        // error handled globally
      }
    );
  };

export const setFeedbackStatus =
  (feedbackId: ApiId, data: FeedbackStatusPutRequest): AppThunk =>
  async (dispatch, getState) => {
    return putItem<FeedbackStatusPutRequest, IApiResponse<FeedbackStatusPutResponse>>(
      ENDPOINTS.FEEDBACK.UpdateStatus,
      data,
      feedbackId,
      {
        enableGlobalErrorDialog: true
      }
    ).then(
      (response) => {
        dispatch(fetchFeedbackDetail(feedbackId));
      },
      (response) => {
        // error handled globally
      }
    );
  };

export const updateFeedbackStatus =
  (feedbackId: ApiId, status: FeedbackStatusEnum, outcome: string): AppThunk =>
  async (dispatch) => {
    const data: FeedbackStatusPutRequest = {
      status,
      outcome
    };

    return putItem<FeedbackStatusPutRequest, IApiResponse<FeedbackStatusPutResponse>>(
      ENDPOINTS.FEEDBACK.UpdateStatus,
      data,
      feedbackId,
      {
        enableGlobalErrorDialog: true
      }
    )
      .then(() => {
        dispatch(fetchFeedbackDetail(feedbackId));
      })
      .then(() => {
        // Handled by global error dialog
      });
  };

export const createFeedback =
  (postModel: FeedbackCreatePostModel): AppThunk =>
  async (dispatch) => {
    return postItems<FeedbackCreatePostModel, FeedbackDetailResponse>(ENDPOINTS.FEEDBACK.Get, postModel);
  };

export const fetchFeedbackTypes = (): AppThunk => async (dispatch) => {
  return getItems<FeedbackTypeListResponse>(ENDPOINTS.FEEDBACK.FeedbackTypes).then(
    (response) => {
      dispatch(setFeedbackTypes(response.result || []));
    },
    (response) => {
      const error = mapApiErrors(response);
      throw new Error(error);
    }
  );
};

export const fetchFeedbackAssignees = (): AppThunk => async () => {
  return getItems<IApiResponse<FeedbackAssignee[]>>(ENDPOINTS.FEEDBACK.FetchAssignees, {
    enableGlobalErrorDialog: true
  }).then((response) => {
    return response.result || [];
  });
};

export const updateFeedbackAssignee =
  (feedbackId: ApiId, assigneeId: ApiId): AppThunk =>
  async (dispatch) => {
    return putItem<ApiId, IApiResponse<{}>>(
      ENDPOINTS.FEEDBACK.UpdateAssignee(feedbackId, assigneeId),
      undefined,
      undefined,
      {
        enableGlobalErrorDialog: true
      }
    ).then(() => {
      dispatch(fetchFeedbackDetail(feedbackId));
    });
  };

export const getFeedbackCategories = () => {
  let categories: ISelectOption[] = [];
  for (let item in FeedbackCategoryEnum) {
    if (!isNaN(Number(item))) {
      categories.push({ label: FeedbackCategoryText[item], value: item });
    }
  }
  return categories;
};

/* actions */
export const { setFeedbackTypes, setFeedbackDetail, setFeedbackSearchData, updateFeedbackSearchData } =
  feedbackSlice.actions;

/* selectors */
export const selectSelectedFeedbackDetail = (state: RootState) => state.feedback.selectedFeedbackDetail;
export const selectFeedbackTypes = (state: RootState) => state.feedback.feedbackTypes || [];
export const selectFeedbackSearchData = (state: RootState) => state.feedback.feedbackSearchData;

/* reducer */
export default feedbackSlice.reducer;
