// NOTE: This isn't really a component in the strictest sense of the word, but needs a tsx file for the react component in the message
// ALSO NOTE: There was/is an issue where the typecaster is getting confused when building the project when the message in the confirmDialog (just here, not in components)
//            is a react element. Gives the message "Cannot read property 'start' of undefined" when the varible message is put directly into the props.
//            This component was rewritten to remove looping of the eos dialog and dupe dialag, so the eos list shows in the same dialog.

import { ApiId, BodyText, confirmDialog } from 'millbrook-core';
import { Fragment } from 'react';
import { AddToBasketEosList } from './AddToBasketEosList';

export const eosDuplicateAddToBasketDialog = async (warningMessage: string, serviceUserId: ApiId) => {
  const message = (
    <Fragment>
      <BodyText>{warningMessage}</BodyText>
      <AddToBasketEosList serviceUserId={serviceUserId} />
    </Fragment>
  );

  return confirmDialog({
    title: 'EOS duplicate found',
    message: message,
    maxWidth: 'md',
    enableCloseIcon: true,
    confirmButtonLabel: 'Yes, add to basket',
    cancelButtonLabel: 'No, remove item',
    catchOnCancel: true
  });
};
