import { yupResolver } from '@hookform/resolvers';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  Radio,
  RadioGroup,
  Checkbox
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ROUTES } from 'constants/routes';
import { BasketUpdateData } from 'features/basket/basket.types';
import { ContractInfoModel } from 'features/contract/contract.slice';
import {
  ApiId,
  BasketItem,
  BasketItemHireTrialOptionsEnum,
  BodyText,
  DisplayAsMoney,
  IFormDataWithApiError,
  InputField,
  mapServiceToImage,
  ProductImageCard,
  quantityValidation,
  ServiceTypeCodeEnum,
  ShelfTypeEnum,
  StyledButton
} from 'millbrook-core';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { basketSummaryListStyles } from '../BasketSummaryList/BasketSummaryList.styles';

interface BasketSummaryProductProps {
  product: BasketItem;
  quantity: number;
  contractData: ContractInfoModel;
  onUpdate?: (id: ApiId, data: BasketUpdateData) => void;
}

export const quantitySchema = yup.object().shape({ quantity: quantityValidation });
export type QuantityFormData = yup.InferType<typeof quantitySchema>;

const BasketSummaryProduct: React.FC<BasketSummaryProductProps> = ({ product, quantity, contractData, onUpdate }) => {
  const classes = basketSummaryListStyles();

  const { control, errors, handleSubmit, watch, setValue } = useForm<QuantityFormData & IFormDataWithApiError>({
    defaultValues: { quantity },
    resolver: yupResolver(quantitySchema)
  });

  const [hireTrialType, setHireTrialType] = useState<BasketItemHireTrialOptionsEnum>(
    product.hireTrialType & product.hireTrialOptions
  );

  const [doNotUse, setDoNotUse] = useState<boolean>(product.doNotUse);

  /* handlers */
  const handleQuantityChange = (data: QuantityFormData) => {
    onUpdate && onUpdate(product.id, { quantity: data.quantity || 0 });
  };

  const handleRemoveItem = () => {
    onUpdate && onUpdate(product.id, { quantity: 0 });
  };

  const handleHireTrialChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const hireTrialType = Number((event.target as HTMLInputElement).value) as BasketItemHireTrialOptionsEnum;

    onUpdate && onUpdate(product.id, { hireTrialType });

    setHireTrialType(hireTrialType);
  };

  const handleDoNotUse = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isDoNotUse = event.target.checked;

    onUpdate && onUpdate(product.id, { doNotUse: isDoNotUse });

    setDoNotUse(isDoNotUse);
  };

  useEffect(() => {
    if (product) {
      // This is mostly here to reset after an error. The defaults are set on load as well.
      setValue('quantity', product.quantity);
      setHireTrialType(product.hireTrialType & product.hireTrialOptions);
    }
  }, [setValue, product]);

  /* derived and watch */
  const formQuantity = watch('quantity');

  const quantityChanged = Number(formQuantity) !== quantity;

  const productIsCollectionOrRepair =
    product.serviceTypeCode === ServiceTypeCodeEnum.Collection ||
    product.serviceTypeCode === ServiceTypeCodeEnum.Repair;

  const basketImage = (
    <ProductImageCard
      image={product.imageUrl ?? mapServiceToImage(product.serviceTypeCode, product.shelfType)}
      title={product.name}
      ratio="square"
    />
  );

  /* renders */

  const productName = (
    <Fragment>
      {productIsCollectionOrRepair && (
        <BodyText type="small" color="edward170" bold>
          {product.masterServiceName}
        </BodyText>
      )}
      <BodyText type="large" color="regalBlue">
        {product.name}
      </BodyText>
      <BodyText type="large" gutterBottom color="regalBlue">
        {product.sku ?? product.partNumber}
      </BodyText>
    </Fragment>
  );

  const baseComponent = (
    <Grid container alignItems="center" wrap="nowrap">
      {product.contractProductId ? (
        <Link to={ROUTES.PRODUCT.productDetails(product.contractProductId)} className={classes.image}>
          {basketImage}
        </Link>
      ) : (
        <Box className={classes.image}>{basketImage}</Box>
      )}
      <form onSubmit={handleSubmit(handleQuantityChange)} className={classes.detailsWrapper} noValidate>
        <div className={classes.detailsColumn}>
          <div className={classes.contentColumn}>
            {product.isRsp && <BodyText type="small">{product.isPooled ? 'Pooled r' : 'R'}ecycled special</BodyText>}
            {product.contractProductId ? (
              <Link to={ROUTES.PRODUCT.productDetails(product.contractProductId)} className={classes.headerLink}>
                {productName}
              </Link>
            ) : (
              <div>{productName}</div>
            )}
            <Hidden smUp>
              <BodyText type="large" className={classes.mobilePrice}>
                <DisplayAsMoney amount={product.price || 0} />
              </BodyText>
            </Hidden>
            <BodyText gutterBottom className={classes.description}>
              {product.description}
            </BodyText>
          </div>
          <div className={classes.quantityColumn}>
            <InputField
              className={classes.quantityField}
              control={control}
              error={errors?.quantity}
              name="quantity"
              value={quantity}
              type={product.fixedQuantity ? 'text' : 'number'}
              inputProps={{ min: 0, max: 100 }}
              readOnly={product.fixedQuantity}
              InputProps={{
                endAdornment: null // To prevent the default read-only lock icon from blocking the text
              }}
            />
            {/* watch value is returning a string not a number */}
            {quantityChanged && !isNaN(parseInt(formQuantity.toString())) && (
              <IconButton aria-label="update" type="submit">
                <RefreshIcon color="primary" />
              </IconButton>
            )}
          </div>
          <BodyText className={classes.priceColumn}>
            <DisplayAsMoney amount={product.price || 0} />
          </BodyText>
        </div>
        {product &&
          contractData.hireTrialEnabled &&
          product.shelfType !== ShelfTypeEnum.RecycledSpecial &&
          product.hireTrialOptions !== BasketItemHireTrialOptionsEnum.None && (
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="authoriserType"
                name="authoriserType"
                value={hireTrialType}
                onChange={handleHireTrialChange}
                row
              >
                <FormControlLabel
                  value={BasketItemHireTrialOptionsEnum.None}
                  control={<Radio color="primary" />}
                  label="Standard DEL"
                />
                {!!(product.hireTrialOptions & BasketItemHireTrialOptionsEnum.Trial) && (
                  <FormControlLabel
                    value={BasketItemHireTrialOptionsEnum.Trial}
                    control={<Radio color="primary" />}
                    label="Trial"
                  />
                )}
                {!!(product.hireTrialOptions & BasketItemHireTrialOptionsEnum.Hire) && (
                  <FormControlLabel
                    value={BasketItemHireTrialOptionsEnum.Hire}
                    control={<Radio color="primary" />}
                    label="Hire"
                  />
                )}
              </RadioGroup>
              <BodyText>{hireTrialText(hireTrialType, contractData)}</BodyText>
            </FormControl>
          )}
        <FormControlLabel control={<Checkbox checked={doNotUse} onChange={handleDoNotUse} />} label="Do not use" />
        <StyledButton
          className={classes.remove}
          variant="link"
          endIcon={<ClearIcon color="primary" />}
          onClick={handleRemoveItem}
        >
          Remove from basket
        </StyledButton>
      </form>
    </Grid>
  );

  return <div className={classes.itemContainer}>{baseComponent}</div>;
};

const hireTrialText = (
  hireTrialType: BasketItemHireTrialOptionsEnum,
  contractData: ContractInfoModel
): React.ReactNode => {
  if (hireTrialType === BasketItemHireTrialOptionsEnum.None) return <>&nbsp;</>;

  const isTrial = !!(hireTrialType & BasketItemHireTrialOptionsEnum.Trial);
  const type = isTrial ? 'Trial' : 'Hire';

  const days = contractData[isTrial ? 'trialLength' : 'hireLength'] || 0;

  return `Note: ${type} period ${days > 0 ? `is set at ${days} days` : 'is open-ended'}.`;
};

export default BasketSummaryProduct;
