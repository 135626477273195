import { Box } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { ProductCard } from 'components/Cards/ProductCard';
import { BodyText, Heading, IContractProductSummary, StyledDivider, StyledGrid } from 'millbrook-core';
import { useState } from 'react';

const ITEMS_PER_PAGE = 3;

interface LinkedProductsProps {
  products: IContractProductSummary[];
  heading: string;
  subHeading?: string;
}

export const LinkedProducts: React.FC<LinkedProductsProps> = ({ products, heading, subHeading }) => {
  const [page, setPage] = useState<number>(1);

  const hasProducts = !!products?.length;
  const pages = Math.ceil(products.length / (ITEMS_PER_PAGE || 1));
  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const pagedProducts = products?.slice(startIndex, endIndex);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  if (!hasProducts) {
    return null;
  }

  return (
    <Box>
      <StyledDivider spacing={3} />
      <Box mb={3}>
        <Heading type="h3" gutterBottom>
          {heading}
        </Heading>
        {subHeading && <BodyText>{subHeading}</BodyText>}
      </Box>
      <StyledGrid container>
        {pagedProducts.map((product, index) => (
          <StyledGrid key={product.contractProductId} item xs={12} sm={6} md={4}>
            <ProductCard key={`product-card-${product.contractProductId}-${index}`} product={product} />
          </StyledGrid>
        ))}
      </StyledGrid>
      {pages > 1 && (
        <Box mt={6} justifyContent="center" display="flex">
          <Pagination count={pages} page={page} onChange={handleChange} />
        </Box>
      )}
    </Box>
  );
};
