import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { ENDPOINTS } from 'constants/api';
import { ROUTES } from 'constants/routes';
import { fetchBasket, selectBasket, setBasketServiceUser } from 'features/basket/basket.slice';
import {
  ContractInfoModel,
  fetchContractData,
  selectContractId,
  showOutOfHoursDialog
} from 'features/contract/contract.slice';
import { fetchProductsCatalogue } from 'features/products/productsCategories.slice';
import { sortBy } from 'lodash';
import {
  AggregatedPermissions,
  ApiId,
  IApiResponse,
  mapApiErrors,
  PinUser,
  selectUserPermissions,
  userHasPermissionToAccess,
  snackbarUtils,
  setUserTokenPermissions
} from 'millbrook-core';
import { getItems } from 'services/api.service';
import { resetAppAction } from 'store/rootReducer';
import { AppThunk, RootState } from 'store/store';

export interface Budget {
  id: string;
  name: string;
  type: string;
}

export interface PinSelection {
  selectedPinUser: ApiId;
  pinToken?: string;
}

interface AuthState {
  pinUsers: PinUser[];
  selectedPinUser?: ApiId;
  pinToken?: string;
}

const initialState: AuthState = {
  pinUsers: []
};

const authPinSlice = createSlice({
  name: 'authPin',
  initialState,
  reducers: {
    storePinUsers(state, action: PayloadAction<PinUser[]>) {
      state.pinUsers = sortBy(action.payload || [], ['contractName', 'pinTeamName']);
    },
    setPinUser(state, action: PayloadAction<PinSelection>) {
      Object.assign(state, action.payload);
    }
  }
});

export const { storePinUsers, setPinUser } = authPinSlice.actions;

// thunks
type PinUsersResponse = IApiResponse<PinUser[]>;
type PinSelectResponse = IApiResponse<{ token: string; permissions: string[] }>;

export const fetchPinUsers = (): AppThunk => async (dispatch, getState) => {
  return getItems<PinUsersResponse>(ENDPOINTS.PIN.GetAll).then((response) => {
    dispatch(storePinUsers(response.result || []));
  });
};

export const getPinUsers = (): AppThunk => async (dispatch, getState) => {
  // get the pin users and the basket
  // basket is attached to the identity user, so should be available after login
  // only request the basket if there is no selected pinuser

  const requestBasket = !getState().authPin.selectedPinUser;

  return Promise.all([
    dispatch<any>(fetchPinUsers()), // [0]
    requestBasket && dispatch<any>(fetchBasket()) // [1]
  ]).then(() => {
    // Basket check
    // if has basket, stop and check with dialog (this happen in the control and reads from the basket slice)
    //   if no then clear basket and carry on with the pin selection
    //   if yes then auto select the pinuser and service user
    // autoselection for single pin accounts
    // all of the above happens on the choose pin page

    const appState = getState();
    const hasBasket = !!appState.basket.basket;
    // NOTE: this is being used here to pass though because using the selector in the useEffect dependency causes a feedback loop
    const pinUsers = appState.authPin.pinUsers || [];

    return { pinUsers, hasBasket };
  });
};

export const getPinUserToken =
  (pinUserId: string, redirectRoute: string | boolean = true): AppThunk =>
  async (dispatch, getState) => {
    const appState = getState();

    return getItems<PinSelectResponse>(ENDPOINTS.PIN.Select(pinUserId)).then((response) => {
      // (MIL-1108) - changing pin user

      const selectedPinUser = selectSelectedPinUser(appState);
      const selectedPinUserIsOnTheSameContract = selectPinUserIsOnTheSameContract(pinUserId)(appState);

      // this will only return true if there is a selectable pin user and the user is not on the same contract
      const shouldReset = !!selectedPinUser && !selectedPinUserIsOnTheSameContract;

      // only fetch the contract data if the contract has changed or a new pin user
      !selectedPinUserIsOnTheSameContract &&
        dispatch<any>(fetchContractData()).then((response: ContractInfoModel) => {
          response && response.isOutOfHours && dispatch(showOutOfHoursDialog());
        });

      if (shouldReset) {
        // This action will reset most things, but keep a few others
        dispatch(resetAppAction());
      }

      dispatch(setUserTokenPermissions(response.result?.permissions || []));
      dispatch(
        setPinUser({
          selectedPinUser: pinUserId,
          pinToken: response.result?.token
        })
      );

      // always fetch catalogue based on pin user
      dispatch(fetchProductsCatalogue());

      // redirectRoute params will trump the returnUrl in the querystring, but returnUrl will trump home
      const urlCheck = window.location.search.match(/returnUrl=([^&]+)/);
      const returnUrl = urlCheck && decodeURIComponent(urlCheck[1]);

      // redirect if we need to
      if (redirectRoute) {
        dispatch(push(redirectRoute === true ? returnUrl ?? ROUTES.HOME : redirectRoute));
      }
    });
  };

export const switchContract =
  (data: { contractId: ApiId; serviceUser?: { id: ApiId; name: string } }): AppThunk =>
  async (dispatch, getState) => {
    const app = getState();

    const loggedInContractId = selectContractId(app);

    const { contractId, serviceUser } = data;

    if (contractId !== loggedInContractId) {
      // get the pin user by the contract on the service user selected
      const pinUsers = selectPinUsers(app);
      const pin = pinUsers?.find((x) => x.contractId === contractId);

      if (pin) {
        await dispatch<any>(getPinUserToken(pin.id, false)).catch((e: any) => {
          const error = mapApiErrors(e);
          throw Error(error);
        });

        snackbarUtils.success(`Switched to contract ${pin.contractName}`);
      } else {
        throw Error("Couldn't find the internal PIN user for the chosen contract");
      }
    }

    serviceUser && (await dispatch<any>(setBasketServiceUser(serviceUser)));
  };

// selectors
export const selectPinUsers = (state: RootState) => state.authPin && state.authPin.pinUsers;

export const selectSelectedPinUserId = (state: RootState) => state.authPin && state.authPin.selectedPinUser;

export const selectSelectedPinUser = createSelector([selectPinUsers, selectSelectedPinUserId], (pinUsers, selected) => {
  return pinUsers ? pinUsers.find((pin) => pin.id === selected) : null;
});

export const selectIsInternalPin = createSelector([selectSelectedPinUser], (pinUser) => {
  // this logic might change, but let's keep it all in one place
  return !pinUser?.loginId;
});

export const selectPinUsersCount = createSelector([selectPinUsers], (pinUsers) => {
  return pinUsers?.length || 0;
});

export const selectNotActivePinUsers = createSelector(
  [selectPinUsers, selectSelectedPinUserId, selectBasket],
  (pinUsers, selectedPinUserId, basket) =>
    pinUsers?.filter((p) => p.id !== selectedPinUserId && !p.blockUser && p.contractId === basket?.contractId) || []
);

export const selectPinUserIsOnTheSameContract = (newPinUserId: string) =>
  createSelector([selectSelectedPinUser, selectPinUsers], (currentPinUser, allPinUsers) => {
    const contractId = currentPinUser?.contractId;
    const newUserContractId = allPinUsers?.find((u) => u.id === newPinUserId)?.contractId;

    return contractId === newUserContractId;
  });

export const selectShowLoginBasket = createSelector(
  [selectSelectedPinUserId, selectBasket],
  (selectedPinUser, basket) => {
    return !selectedPinUser && !!basket;
  }
);

export const selectAvailableContractOptions = createSelector([selectPinUsers], (pinUsers) => {
  // Filter out duplicate contracts and map to ISelectOption
  return pinUsers
    .filter((x, index) => pinUsers.findIndex((y) => y.contractId === x.contractId) === index)
    .map((z) => ({
      label: z.contractName || '',
      value: z.contractId
    }));
});

export const selectUserHasCsaPermissions = createSelector([selectUserPermissions], (userPermissions) => {
  return userHasPermissionToAccess(userPermissions, AggregatedPermissions.CSA);
});

export const selectCanAddToBasket = createSelector([selectUserPermissions], (permissions) => {
  return userHasPermissionToAccess(permissions, AggregatedPermissions.CreateOrder);
});

export default authPinSlice.reducer;
