import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { format, parseISO } from 'date-fns';
import { ActivityServiceUserInitialState } from 'features/checkout/sliceSections/CheckoutState';
import { PropertyTypeSelectOption } from 'features/checkout/types/checkout.types';
import { Step3FormDataState } from 'features/checkout/types/steps.types';
import { uniqBy } from 'lodash';
import {
  ApiId,
  IApiResponse,
  IContractClientAlert,
  IServerSelectOption,
  mapApiErrors,
  mapBooleanToYesNo,
  mapIServerSelectOptionToSelectOptions,
  ServiceUserAddressFormData,
  ServiceUserAddressUpdateModel,
  ServiceUserAlertCodeEnum,
  setActivityDetails,
  SHORT_DATE_FORMAT
} from 'millbrook-core';
import { serialize } from 'object-to-formdata';
import { ActivityDeliveryDetailsFormData } from 'pages/Activities/EditActivityDeliveryDetailPage/components/ActivityDeliveryDetailsForm/ActivityDeliveryDetailsForm.validation';
import { AdditionalContactFormData } from 'pages/service-users/components/ContactForm/ContactForm';
import { getItems, postItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { ActivityDeliveryDetailsInitialData } from './activity.types';
import { mapDeliveryDetailsUpdateFormToRequest } from './mappers';

/* types */
export type ActivityDeliveryDetailsFormDataResponse = IApiResponse<ActivityDeliveryDetailsInitialData>;

/* state */
interface EditingState {
  formData: ActivityDeliveryDetailsInitialData;
  selectedAddressId?: ApiId;
  modifiedClientAlerts: IContractClientAlert[];
}

const initialState: EditingState = {
  formData: {
    ...ActivityServiceUserInitialState,
    telephoneToServiceUserNeeded: false,
    activityContactPreferences: []
  },
  modifiedClientAlerts: []
};

/* slice */
const editingSlice = createSlice({
  name: 'editing',
  initialState,
  reducers: {
    setDeliveryDetailsFormDataOptions(state, action: PayloadAction<ActivityDeliveryDetailsInitialData>) {
      state.formData = action.payload;
      state.selectedAddressId = action.payload.serviceUserAddressId;
      state.modifiedClientAlerts = action.payload.serviceUserClientAlerts.map((x) => x.contractServiceUserAlert);
    },
    setSelectedAddressId(state, action: PayloadAction<ApiId | undefined>) {
      state.selectedAddressId = action.payload;
    },
    updateAlert(state, action: PayloadAction<{ id: ApiId; method: 'add' | 'remove' }>) {
      if (action.payload.method === 'add') {
        const newAlert = state.formData.contractClientAlerts.find((x) => x.id === action.payload.id);
        newAlert && (state.modifiedClientAlerts = uniqBy([...state.modifiedClientAlerts, newAlert], 'id'));
      } else {
        state.modifiedClientAlerts = state.modifiedClientAlerts.filter((x) => x.id !== action.payload.id);
      }
    }
  },
  extraReducers: (builder) => {
    // when a new activity is set, reset all editing activity delivery details info
    builder.addCase(setActivityDetails, (state, action) => {
      state = { ...initialState };
    });
  }
});

/* thunks */
export const fetchActivityDeliveryDetailsFormData =
  (activityId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<ActivityDeliveryDetailsFormDataResponse>(
      ENDPOINTS.ACTIVITIES.DELIVERY_DETAILS_DATA(activityId)
    ).then(
      (response) => {
        response.result && dispatch(setDeliveryDetailsFormDataOptions(response.result));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateDeliveryDetails =
  (
    activityDeliveryDetailsFormData: ActivityDeliveryDetailsFormData,
    activityId: ApiId,
    newAddress?: ServiceUserAddressFormData,
    selectedAddress?: ServiceUserAddressUpdateModel,
    newAlternativeContact?: AdditionalContactFormData,
    selectedAlternativeContact?: ApiId
  ): AppThunk =>
  async (dispatch, getState) => {
    const { modifiedClientAlerts, formData } = getState().activities.editing;

    // map all the things
    const postRequest = mapDeliveryDetailsUpdateFormToRequest(
      activityDeliveryDetailsFormData,
      activityId,
      newAddress,
      selectedAddress,
      newAlternativeContact,
      selectedAlternativeContact,
      modifiedClientAlerts,
      formData.serviceUserClientAlerts
    );

    // take out the files
    const { generalNotesFiles, ...postData } = postRequest;

    // turn into form data
    let data = serialize(postData);

    if (generalNotesFiles) {
      for (let i = 0; i < generalNotesFiles.length; i++) {
        data.append('generalNoteFiles', generalNotesFiles[i]);
      }
    }

    return postItems<FormData, any>(ENDPOINTS.ACTIVITIES.UPDATE_DELIVERY_DETAILS, data).catch((response: any) => {
      const error = mapApiErrors(response);
      throw new Error(error);
    });
  };

/* actions */
export const { setDeliveryDetailsFormDataOptions, setSelectedAddressId, updateAlert } = editingSlice.actions;

/* selectors */
export const selectActivityDeliveryDetailsServiceUserPropertyTypes = (state: RootState) =>
  state.serviceUsers.editing.propertyTypeOptions || [];
export const selectActivityDeliveryDetailsServiceUserMandatoryFields = (state: RootState) =>
  state.serviceUsers.editing.serviceUserFieldsValidation;

export const selectFormData = (state: RootState) => state.activities.editing.formData;

export const selectSelectedAddressId = (state: RootState) => state.activities.editing.selectedAddressId;

export const selectSelectedAddress = createSelector(
  [selectFormData, selectSelectedAddressId],
  (initialData, selectedAddressId): Step3FormDataState | null => {
    const address = initialData?.serviceUser?.serviceUserAddresses.find((item) => item.id === selectedAddressId);

    if (address) {
      const {
        dogOnPremise,
        keySafe,
        accessIssues,
        parkingAvailable,
        stepsLeadingUpToTheProperty,
        address: { propertyTypeId }
      } = address;

      return {
        accessIssues,
        dogOnPremise: mapBooleanToYesNo(dogOnPremise),
        keySafe: mapBooleanToYesNo(keySafe),
        parkingAvailable: parkingAvailable,
        stepsLeadingUpToTheProperty: stepsLeadingUpToTheProperty || '',
        propertyTypeId: propertyTypeId || ''
      };
    }

    return null;
  }
);

export const selectActivityDeliveryData = createSelector(
  [selectFormData],
  (initialData): ActivityDeliveryDetailsFormData => {
    return {
      ...initialData?.serviceUser,

      // TODO: this isn't used on the form, but is part of the validation.
      primaryContactNumberCode: 1,

      // NOTE: this is NOT the service user contact preference, but the ones saved against the activity
      contactPreferences: initialData.activityContactPreferences,

      alternativeContactNote: initialData.alternativeContactNote ?? '',
      dob: initialData.serviceUser?.dob ? format(parseISO(initialData.serviceUser.dob), SHORT_DATE_FORMAT) : '',
      contractServiceUserGroup: initialData.serviceUser?.contractServiceUserGroup?.serviceUserGroup?.name ?? '',
      arrangeBooking: initialData.telephoneToServiceUserNeeded === true ? 'yes' : 'no',
      generalNotes: initialData.generalNotes ?? '',

      // these fields are set in the useEffect in the form
      stepsLeadingUpToTheProperty: '',
      propertyTypeId: '',
      parkingAvailable: '',
      accessIssues: '',
      keySafe: '',
      dogOnPremise: ''
    };
  }
);

export const selectPropertyTypeOptions = createSelector(
  [selectFormData],
  ({ propertyTypes }): PropertyTypeSelectOption[] => {
    return (propertyTypes || []).map((item) => ({
      value: item.id,
      label: item.name,
      propertyTypeCode: item.propertyTypeCode
    }));
  }
);

export const selectServiceUserPrimaryAddress = createSelector([selectFormData], (initialData) =>
  initialData?.serviceUser?.serviceUserAddresses.find((address) => address.isPrimary === true)
);

export const selectServiceUserAlternativeAddresses = createSelector(
  [selectFormData],
  (initialData) => initialData?.serviceUser?.serviceUserAddresses.filter((address) => address.isPrimary === false) || []
);

export const selectClientAlerts = (state: RootState) => state.activities.editing.formData.contractClientAlerts;

export const selectCurrentAlerts = (state: RootState) => state.activities.editing.modifiedClientAlerts;

export const selectCurrentAlertsOptions = createSelector(
  [selectCurrentAlerts],
  (currentAlerts = []): IServerSelectOption[] => {
    return (
      currentAlerts.map((alert) => ({
        id: alert.id,
        name: alert.name
      })) || []
    );
  }
);

export const selectClientAlertsOptions = createSelector(
  [selectClientAlerts, selectCurrentAlerts],
  (alerts = [], clientAlerts = []) => {
    return mapIServerSelectOptionToSelectOptions(alerts.filter((a) => !clientAlerts.find((ca) => a.id === ca.id)));
  }
);

export const selectSafeGuardingAlert = createSelector(
  [selectClientAlerts, selectCurrentAlerts],
  (alerts, currentAlerts): IContractClientAlert | null | undefined => {
    //check to see if the safeGuardingAlert is on the alerts. If it's not, then we can't do anything because it hasn't been added to the contract
    const safeGuardingAlert = alerts.find(
      (x) => x.serviceUserAlertCode === ServiceUserAlertCodeEnum.SafeguardingConcern
    );
    const SuSafeGuardingAlert = currentAlerts.find(
      (x) => x.serviceUserAlertCode === ServiceUserAlertCodeEnum.SafeguardingConcern
    );

    // this should return
    // - undefined if there is no SGA on the contract
    // - null is the SGA has been added to the service user
    // - the SGA object
    return SuSafeGuardingAlert ? null : safeGuardingAlert;
  }
);

/* reducers */
export default editingSlice.reducer;
