import { ServiceUserMergeUrlSteps } from 'features/service-users/service-users.types';

const mergeSteps = Object.values(ServiceUserMergeUrlSteps);

const regexpGuid = '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';

export const ROUTEPARAMS = {
  PRODUCT_CATEGORY_ID: ':productCategoryId?',
  SERVICE_USER_ID: `:serviceUserId(${regexpGuid})`,
  PRODUCT_ID_OPT: ':productId?',
  PRODUCT_ID: ':productId',
  SERVICE_USER_TELEPHONE: ':telephone?',
  ACTIVITY_REFERENCE: ':activityReference?',
  ACTIVITY_ID: ':activityId?',
  EOS_ID: `:eosid(${regexpGuid})`,
  NEWS_ID: ':newsId',
  HELP_PAGE: ':helpPageId',
  FEEDBACK_ID: ':feedbackId?',
  STOCK_ID: ':stockId',
  BASKET_PRODUCT_ID: ':basketProductId?',
  SWAP_ACTIVITY_ID: ':swapActivityId?',
  SWAP_BASKET_PRODUCT_ID: ':swapBasketProductId?',
  QUOTE_ID: ':quoteId',
  PERIPHERAL_STORE_ID: ':peripheralStoreId',
  CONTRACT_PROFESSIONAL_ASSESSMENT_ID: ':contractProfessionalAssessmentId',
  USER_ID: ':userId',
  SERVICEUSER_MERGE_STEP: ':step(' + mergeSteps.join('|') + ')?'
};
