import { ApiId, GLOBAL_ROUTES } from 'millbrook-core';
import { ROUTEPARAMS } from './routeParameters';

const ROUTES = {
  ...GLOBAL_ROUTES,
  PIN_SELECTION: '/pin-select',
  EQUIPMENTREVIEWS: { root: '/equipment-reviews' },
  FEEDBACK: {
    root: '/feedback',
    list: '/feedback/list',
    details: (id: ApiId = ROUTEPARAMS.FEEDBACK_ID) => `/feedback/details/${id}`,
    create: '/feedback/create',
    createExistingOrder: (activityId: ApiId = ROUTEPARAMS.ACTIVITY_ID) => `/feedback/createExistingOrder/${activityId}`,
    createEOS: (stockId: ApiId = ROUTEPARAMS.STOCK_ID, serviceUserId: ApiId = ROUTEPARAMS.SERVICE_USER_ID) =>
      `/feedback/createEOS/${stockId}/${serviceUserId}`
  },
  SERVICES: {
    root: '/products/services'
  },
  PRODUCT: {
    root: '/products',
    catalogue: '/products/catalogue',
    category: (productCategoryId: ApiId = ROUTEPARAMS.PRODUCT_CATEGORY_ID) =>
      `/products/catalogue/${productCategoryId}`,
    productDetails: (productId: ApiId = ROUTEPARAMS.PRODUCT_ID_OPT) => `/products/details/${productId}`,
    searchResults: '/products/results'
  },

  NEWS_ADMIN: {
    root: `/news-admin`,
    newsAdd: `/news-admin/add`,
    newsEdit: (newsId: ApiId = ROUTEPARAMS.NEWS_ID) => `/news-admin/edit/${newsId}`
  },

  NEWS_VIEWING: {
    root: `/news`,
    newsItem: (newsId: ApiId = ROUTEPARAMS.NEWS_ID) => `/news/${newsId}`
  },

  ACTIVITIES: {
    root: '/activities',
    unscheduledActivities: '/activities/unscheduled',
    authorisations: '/activities/authorisations',
    activityDetails: (activityId: ApiId = ROUTEPARAMS.ACTIVITY_ID) => `/activities/${activityId}`,
    activityDeliveryDetails: (activityId: ApiId = ROUTEPARAMS.ACTIVITY_ID) =>
      `/activities/${activityId}/delivery-details`,
    scheduledActivity: (activityId: ApiId = ROUTEPARAMS.ACTIVITY_ID) => `/activities/${activityId}/scheduled`,
    cancelledActivity: (activityId: ApiId = ROUTEPARAMS.ACTIVITY_ID) => `/activities/${activityId}/cancelled`,
    authorisedActivity: (activityId: ApiId = ROUTEPARAMS.ACTIVITY_ID) => `/activities/${activityId}/authorised`,
    swapProduct: '/activities/swap-product/',
    closedActivity: (activityId: ApiId = ROUTEPARAMS.ACTIVITY_ID) => `/activities/${activityId}/closed`
  },

  NOTIFICATIONS: { root: '/notifications' },

  FAVOURITES: { root: '/favourites' },

  MANAGE_PINS: {
    root: '/manage-pins',
    organisations: `/manage-pins/organisations`,
    areas: `/manage-pins/areas`,
    teams: `/manage-pins/teams`,
    groups: `/manage-pins/groups`,
    users: `/manage-pins/users`,
    userAdd: `/manage-pins/users/add`,
    userEdit: (userId: ApiId = ROUTEPARAMS.USER_ID) => `/manage-pins/users/${userId}`
  },

  HELP: {
    root: '/help-and-support',
    details: (helpPageId: string = ROUTEPARAMS.HELP_PAGE) => `/help-and-support/${helpPageId}`,
    documents: '/help-and-support/trust-documents'
  },

  ACCOUNT: {
    root: '/my-account',
    edit: '/my-account/edit',
    chooseBudget: '/my-account/choose-budget',
    mostOrdered: '/my-account/most-ordered'
  },

  SERVICE_USERS: {
    root: '/service-users',
    details: (id: ApiId = ROUTEPARAMS.SERVICE_USER_ID) => `/service-users/details/${id}`,
    additionalDetails: (id: ApiId = ROUTEPARAMS.SERVICE_USER_ID) => `/service-users/full-details/${id}`,
    edit: (id: ApiId = ROUTEPARAMS.SERVICE_USER_ID) => `/service-users/edit-details/${id}`,
    search: '/service-users/search',
    searchResults: '/service-users/results',
    telephoneSearch: (telephone: ApiId = ROUTEPARAMS.SERVICE_USER_TELEPHONE) =>
      `/service-users/search/tel/${telephone}`,
    create: '/service-users/create-user',
    orderSpecialProduct: '/service-users/order-special-product',
    eosDetails: (serviceUserId: ApiId = ROUTEPARAMS.SERVICE_USER_ID, productId: ApiId = ROUTEPARAMS.EOS_ID) =>
      `/service-users/${serviceUserId}/equipment-on-site/${productId}`,
    merge: (step: string = ROUTEPARAMS.SERVICEUSER_MERGE_STEP) => `/service-users/merge/${step}`
  },

  CHECKOUT: {
    root: '/checkout',
    step0: '/checkout/summary',
    step1: '/checkout/budget',
    step2: '/checkout/contact',
    step3: '/checkout/delivery',
    step4: '/checkout/cte',
    step5: '/checkout/delivery-speed',
    step6: '/checkout/additional-details',
    step7: '/checkout/review',
    confirmation: `/checkout/confirmation`,
    step: (step: number) => (step === 0 ? '/checkout/summary' : `/checkout/step${step}`)
  },

  QUOTES: {
    root: '/quotes',
    add: {
      minorAdaptation: '/quotes/add-minor-adaptation'
    },
    details: (quoteId: ApiId = ROUTEPARAMS.QUOTE_ID) => `/quotes/details/${quoteId}`,
    rejected: (activityReference: string = ROUTEPARAMS.ACTIVITY_REFERENCE) => `/quotes/rejected/${activityReference}`
  },

  POOLED_SPECIALS_ADMIN: {
    root: '/pooled-rsp-admin'
  },

  PERIPHERAL_STORES: {
    root: '/peripheral-stores',
    details: (peripheralStoreId: ApiId = ROUTEPARAMS.PERIPHERAL_STORE_ID) => `/peripheral-stores/${peripheralStoreId}`,
    order: '/peripheral-stores/order',
    checkout: '/peripheral-stores/checkout',
    confirmation: (activityReference: string = ROUTEPARAMS.ACTIVITY_REFERENCE) =>
      `/peripheral-stores/confirmation/${activityReference}`,
    delivery: (peripheralStoreId: ApiId = ROUTEPARAMS.PERIPHERAL_STORE_ID) =>
      `/peripheral-stores/${peripheralStoreId}/delivery`,
    deliveryConfirmation: (
      peripheralStoreId: ApiId = ROUTEPARAMS.PERIPHERAL_STORE_ID,
      activityReference: string = ROUTEPARAMS.ACTIVITY_REFERENCE
    ) => `/peripheral-stores/${peripheralStoreId}/delivery-confirmation/${activityReference}`
  },

  CONTRACT_PROFESSIONAL_ASSESSMENTS: {
    root: '/professional-assessment',
    assessment: (contractProfessionalAssessmentId: ApiId = ROUTEPARAMS.CONTRACT_PROFESSIONAL_ASSESSMENT_ID) =>
      `/professional-assessment/${contractProfessionalAssessmentId}`,
    productDetails: (
      contractProfessionalAssessmentId: ApiId = ROUTEPARAMS.CONTRACT_PROFESSIONAL_ASSESSMENT_ID,
      productId: ApiId = ROUTEPARAMS.PRODUCT_ID
    ) => `/professional-assessment/${contractProfessionalAssessmentId}/${productId}`,
    assessmentResults: '/professional-assessment-results'
  }
};

export { ROUTES };
