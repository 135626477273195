import Box from '@material-ui/core/Box';
import { PinCard } from 'components/Cards/PinCard/PinCard';
import { ROUTES } from 'constants/routes';
import {
  getPinUsers,
  getPinUserToken,
  selectPinUsers,
  selectSelectedPinUser,
  selectSelectedPinUserId,
  selectShowLoginBasket
} from 'features/auth-pin/auth-pin.slice';
import { deleteBasket, selectBasket, setBasketServiceUser } from 'features/basket/basket.slice';
import {
  ApiId,
  AUTH_ROUTES,
  BodyText,
  ConfirmDialog,
  GenericPageError,
  Page,
  PageIntro,
  PinUser,
  SpacingSection,
  StyledButton,
  StyledGrid,
  WarningLabel
} from 'millbrook-core';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ChoosePinPage: React.FC = () => {
  /* hooks */
  const dispatch = useDispatch();
  const pinUsers = useSelector(selectPinUsers);
  const selectedPinUserId = useSelector(selectSelectedPinUserId);
  const selectedPinUser = useSelector(selectSelectedPinUser);

  // This is to show the basket dialog if there is no selected user and there is a draft basket
  const showLoginBasket = useSelector(selectShowLoginBasket);

  // This is to pull data from the basket to show in the dialog. Currently only pulling pin number and service user name
  const basket = useSelector(selectBasket);

  /* state */
  const [apiError, setApiError] = useState(false);
  const [selectedPin, setSelectedPin] = useState<PinUser | undefined>();

  /* derived */
  const allPinsBlocked = useMemo(() => {
    // finding unblocked users is not the same as all blocked users. Empty set would result in the same
    return pinUsers.filter((x) => x.blockUser).length === pinUsers.length;
  }, [pinUsers]);

  /* events */
  const onBudgetSelect = (pinId: string) => {
    if (!selectedPinUser) {
      //setSelectedPin(pinId);
      onSwitchPinConfirm(pinId);
    } else {
      // show the warning about the pin user switching, but change the warning based on the contractId
      const pin = pinUsers?.find((x) => x.id === pinId);

      setSelectedPin(pin);
    }
  };

  const onCancelSwitchPinConfirm = () => {
    setSelectedPin(undefined);
  };

  const onSwitchPinConfirm = useCallback(
    (pinId: ApiId) => {
      dispatch<any>(getPinUserToken(pinId)).catch(() => {
        setApiError(true);
      });
    },
    [dispatch]
  );

  const checkSinglePin = useCallback(
    (pinUsers: PinUser[]) => {
      pinUsers.length === 1 && !allPinsBlocked && onSwitchPinConfirm(pinUsers[0].id);
    },
    [onSwitchPinConfirm, allPinsBlocked]
  );

  const handleRejectBasket = async () => {
    try {
      await dispatch<any>(deleteBasket());
      checkSinglePin(pinUsers);
    } catch (e : any) {
      setApiError(true);
    }
  };

  const handleAcceptBasket = async () => {
    try {
      if (basket) {
        // Check to see if the internal pin user is set on the basket
        let basketPinUserId = basket.internalPinUserId ?? basket.pinUserId;

        await dispatch<any>(getPinUserToken(basketPinUserId, ROUTES.CHECKOUT.step0));
        await dispatch<any>(setBasketServiceUser({ id: basket.serviceUserId, name: basket.serviceUserName || '' }));
      }
    } catch (e : any) {
      setApiError(true);
    }
  };

  /* effects */
  useEffect(() => {
    dispatch<any>(getPinUsers())
      .then(({ pinUsers, hasBasket }: { pinUsers: PinUser[]; hasBasket: boolean }) => {
        // if we aren't showing the basket, check to see if there is only one pin and carry on and use it
        // NOTE: not using the pinUsers from the selector because it makes the code loop
        // REFACTOR: is the pinuser selector needed?
        if (!hasBasket) {
          checkSinglePin(pinUsers);
        }
      })
      .catch(() => {
        setApiError(true);
      });
  }, [dispatch, checkSinglePin]);

  if (apiError) {
    return (
      <Page>
        <SpacingSection>
          <GenericPageError title="Select account" />
        </SpacingSection>
      </Page>
    );
  }

  return (
    <Page>
      <SpacingSection>
        {!pinUsers.length && (
          <Fragment>
            <PageIntro
              title="Select account"
              bodyText="There are no PINs linked to this login. Please contact an administrator."
            />
            <StyledButton component={Link} to={AUTH_ROUTES.LogOut} variant="secondary">
              Logout
            </StyledButton>
          </Fragment>
        )}

        {pinUsers.length > 0 && (
          <Fragment>
            <PageIntro
              title="Select account"
              bodyText="Please select a budget and role from the choices below. Catalogue and client selection will be tailored
          to your choice, but you can change your opinion any time."
              hasMaxWidth
            />

            <Box mb={2}>
              {allPinsBlocked && (
                <Fragment>
                  <BodyText color="error" gutterBottom>
                    All of the PINs associated with this login have been blocked, please contact an administrator.
                  </BodyText>
                  <StyledButton component={Link} to={AUTH_ROUTES.LogOut} variant="secondary">
                    Logout
                  </StyledButton>
                </Fragment>
              )}
              {!allPinsBlocked && <BodyText>Select an account type</BodyText>}
            </Box>

            <StyledGrid container>
              {pinUsers.map((pin) => (
                <StyledGrid key={pin.id} item md={4} sm={6} xs={12}>
                  <PinCard
                    layout="grid"
                    pin={pin}
                    onClick={onBudgetSelect}
                    isActive={pin.id === selectedPinUserId}
                    disabled={pin.blockUser}
                  />
                </StyledGrid>
              ))}
            </StyledGrid>
          </Fragment>
        )}

        {selectedPin && (
          <ConfirmDialog
            title="Are you sure you want to switch PIN group?"
            message={
              selectedPin.contractId !== selectedPinUser?.contractId
                ? `You are switching to a PIN on a different contract. Your basket and selected service user will be reset.`
                : `You are switching to a different PIN group, authorisations on your basket products, limits and speeds may change.`
            }
            open={!!selectedPin}
            onConfirm={() => onSwitchPinConfirm(selectedPin.id)}
            onClose={onCancelSwitchPinConfirm}
          />
        )}

        {/* DON'T CHANGE THIS TO THE USECONFIRMATION HOOK. IT MIGHT HAVE UNFORESEEN SIDE-EFFECTS */}
        <ConfirmDialog
          title="Continue with draft basket"
          message={
            <Fragment>
              <BodyText gutterBottom>
                You have a draft basket for PIN user <strong>{basket?.pinNumber}</strong> and service user{' '}
                <strong>{basket?.serviceUserName}</strong>.
              </BodyText>

              {basket?.basketStockWarning && (
                <WarningLabel message="Your basket has changed since your last login, some of the products have low stock levels." />
              )}

              <BodyText>Do you wish to continue with this basket?</BodyText>
            </Fragment>
          }
          open={showLoginBasket}
          confirmButtonLabel="Use basket"
          onConfirm={handleAcceptBasket}
          cancelButtonLabel="Clear basket"
          onCancel={handleRejectBasket}
        />
      </SpacingSection>
    </Page>
  );
};

export default ChoosePinPage;
