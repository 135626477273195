import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAuthorisationCount } from 'components/QuickActionsDrawer/quickActionsDrawer.slice';
import { ENDPOINTS } from 'constants/api';
import { format } from 'date-fns';
import {
  ActivityBasketItemModel,
  ActivityStatus,
  ApiId,
  ISelectOption,
  IsoDate,
  mapApiErrors,
  SHORT_DATE_FORMAT
} from 'millbrook-core';
import { getItem, getItems, postItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import {
  ActivityAuthorisationDetails,
  ActivityAuthorisationDetailsResponse,
  ActivityAuthorisationSummary,
  ActivityAuthorisationSummaryListResponse,
  ActivityAuthorisationSummaryResponse,
  ActivityAuthorisationSwapItemResponse,
  ActivityAuthorisationUpdateModel,
  SwapProductModel
} from '../authorisations/authorisations.types';
import { fetchActivityDetails, fetchAllActivityDetails } from './activity.slice';

/* state */
interface ViewAuthorisationsState {
  authorisationDetail?: ActivityAuthorisationDetails;
  authorisationConfirm?: ActivityAuthorisationSummary;
  swapProduct?: {
    basketItemToSwap: ActivityBasketItemModel;
    activityToSwapId: ApiId;
  };
}

const initialState: ViewAuthorisationsState = {};

const authorisingSlice = createSlice({
  name: 'authorisingSlice',
  initialState,
  reducers: {
    setAuthorisationDetails(state, action: PayloadAction<ActivityAuthorisationDetails | undefined>) {
      state.authorisationDetail = action.payload;
    },
    updateConfirmedAuthorisation(state, action: PayloadAction<ActivityAuthorisationSummary>) {
      state.authorisationConfirm = action.payload;
    },
    setSwapProduct(
      state,
      action: PayloadAction<{ basketItemToSwap: ActivityBasketItemModel; activityToSwapId: ApiId }>
    ) {
      state.swapProduct = action.payload;
    },
    clearSwap(state) {
      state.swapProduct = initialState.swapProduct;
    }
  }
});

/* thunks */
export const fetchAuthorisations = (): AppThunk => async (dispatch) => {
  return getItems<ActivityAuthorisationSummaryListResponse>(ENDPOINTS.ACTIVITIES.AUTHORISATIONS).then(
    (response) => {
      return response.result || [];
    },
    (response) => {
      const error = mapApiErrors(response);
      throw new Error(error);
    }
  );
};

export const fetchActivityAuthorisationDetail =
  (activityId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, ActivityAuthorisationDetailsResponse>(
      ENDPOINTS.ACTIVITIES.AUTHORISATION_DETAILS(activityId)
    ).then(
      (response) => {
        dispatch(setAuthorisationDetails(response.result));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchAuthorisationSummary =
  (activityId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, ActivityAuthorisationSummaryResponse>(
      ENDPOINTS.ACTIVITIES.AUTHORISATION_SUMMARY(activityId)
    ).then(
      (response) => {
        response.result && dispatch(updateConfirmedAuthorisation(response.result));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateAuthorisation =
  (activityId: ApiId, activity: ActivityAuthorisationUpdateModel): AppThunk =>
  async (dispatch) => {
    return postItems<ActivityAuthorisationUpdateModel, ActivityAuthorisationSummaryResponse>(
      ENDPOINTS.ACTIVITIES.AUTHORISATION_DETAILS(activityId),
      activity
    ).then(
      (response) => {
        if (response.result) {
          if (activity.status === ActivityStatus.Authorised) {
            dispatch(updateConfirmedAuthorisation(response.result));
            dispatch(fetchAuthorisationCount());
          } else if (activity.status === ActivityStatus.DeliverySpeedChanged) {
            dispatch(fetchAllActivityDetails(activityId));
          }
        }
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const postComment =
  (activityId: ApiId, activity: ActivityAuthorisationUpdateModel): AppThunk =>
  async (dispatch) => {
    return postItems<ActivityAuthorisationUpdateModel, ActivityAuthorisationSummary>(
      ENDPOINTS.ACTIVITIES.ADD_COMMENT(activityId),
      activity
    ).then(
      () => {
        dispatch(fetchActivityDetails(activityId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchPendingAuthorisations = (): AppThunk => async (dispatch) => {
  return getItems<ActivityAuthorisationSummaryListResponse>(ENDPOINTS.ACTIVITIES.AUTHORISATIONS_PENDING).then(
    (response) => {
      return response.result || [];
    }
  );
};

export const swapItem =
  (productId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    const swapProduct = getState().activities.authorising.swapProduct;

    if (!swapProduct) {
      return Promise.reject('Swap product not configured');
    }

    const postModel: SwapProductModel = {
      activityId: swapProduct.activityToSwapId,
      basketProductId: swapProduct.basketItemToSwap.id,
      productId
    };

    return postItems<SwapProductModel, ActivityAuthorisationSwapItemResponse>(
      ENDPOINTS.ACTIVITIES.SWAP_PRODUCT(postModel.activityId),
      postModel
    ).then(
      (response) => {
        return swapProduct.activityToSwapId;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/* actions */
export const { updateConfirmedAuthorisation, setSwapProduct, clearSwap, setAuthorisationDetails } =
  authorisingSlice.actions;

// Selectors
export const selectAuthorisationReasonCodes = (state: RootState) =>
  state.activities.authorising.authorisationDetail?.reasons;

export const selectAuthorisationContractSpeeds = (state: RootState) =>
  state.activities.authorising.authorisationDetail?.availableContractServiceSpeeds || [];

export const selectAutorisationContractSpeedsOptions = createSelector(
  [selectAuthorisationContractSpeeds],
  (speeds = []): (ISelectOption & { expectedDeliveryDate?: IsoDate })[] => {
    return speeds?.map((speed) => ({
      label: `${speed.description} - ${
        speed.expectedDeliveryDate && format(new Date(speed.expectedDeliveryDate), SHORT_DATE_FORMAT)
      }`,
      value: speed.id,
      expectedDeliveryDate: speed.expectedDeliveryDate
    }));
  }
);

export const selectAuthorisationConfirmed = (state: RootState) => state.activities.authorising.authorisationConfirm;

/* reducer */
export default authorisingSlice.reducer;
