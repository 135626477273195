import { ExistingDeliveryCheckRequestModel } from 'features/checkout/types/checkout.types';
import { ApiId, GLOBAL_ENDPOINTS, IsoDate, Preferred2FaEnum } from 'millbrook-core';
import qs from 'query-string';

export const ENDPOINTS = {
    ...GLOBAL_ENDPOINTS,
    PIN: {
        GetAll: 'PinUser/PinUsers',
        Select: (pinUserId: ApiId) => `PinUser/PinSelect?pinUserId=${pinUserId}`
    },
    HELP: {
        SECTIONS: 'HelpSections',
        DOCUMENTS: 'HelpDocuments/prescriber'
    },
    SERVICE_USERS: {
        SERVICE_USER_DETAILS: 'ServiceUserRecord',
        SERVICE_USER_MARK_AS_DECEASED: (serviceUserId: ApiId) => `ServiceUser/${serviceUserId}/markasdeceased`,
        SERVICE_USER_MARK_AS_INACTIVE: (serviceUserId: ApiId) => `ServiceUser/${serviceUserId}/markasinactive`,
        SERVICE_USERS: 'ServiceUser',
        SERVICE_USERS_DUPLICATE: 'ServiceUser/DuplicateUsers',
        SERVICE_USER_FORM_DATA: (serviceUserId: ApiId = '') => `ServiceUser/ServiceUserFormData/${serviceUserId}`,
        SERVICE_USERS_CLIENT_ALERTS: 'ServiceUserClientAlerts',
        SERVICE_USERS_CLIENT_ALERT_NOTE: 'ServiceUserClientAlerts/alertNote',
        SERVICE_USERS_EOS_PRODUCT: (serviceUserId: ApiId) => `ServiceUserRecord/${serviceUserId}/eosdetail`,
        SERVICE_USERS_EOS_REVIEW_PERIOD: (serviceUserId: ApiId, stockId: ApiId) =>
            `ServiceUserRecord/${serviceUserId}/eosdetail/${stockId}/extendreview`,
        SERVICE_USERS_COLLECTED_EQUIPMENT: (serviceUserId: ApiId) => `ServiceUserRecord/${serviceUserId}/collected`,
        RECENT_SERVICE_USERS: 'ServiceUser/LastAccessed',
        REVIEW_PERIODS: 'ReviewPeriods', // used for EOS review periods
        TELEPHONE_SERVICE_USERS: 'ServiceUser/search/telephone',
        SERVICE_USERS_MERGE_SEARCH: 'ServiceUser/merge',
        SERVICE_USERS_MERGE_DETAILS: (serviceUserId: ApiId, contractId: ApiId) =>
            `ServiceUserRecord/MergeGet/${serviceUserId}/${contractId}`,
        SERVICE_USERS_MERGE_COMPLETE: 'ServiceUser/merge',
        CONTRACT_CCGS: `ContractCCG`,
        CONTRACT_CCGS_GP_SEARCH: `ContractCCG/SearchForGP`,
        CONTRACT_CCGS_GP_BY_ID: `ContractCCG/GetNHSOrganisationById`,
        CONTRACT_SERVICE_USER_INACTIVE_REASONS: `ContractServiceUserInactiveReasons`,
        REMOVE_EOS: (serviceUserId: ApiId, eosId: ApiId) => `ServiceUserRecord/${serviceUserId}/RemoveEosPortal/${eosId}`
    },
    CHECKOUT: {
        FORM_DATA: 'Activity/ActivityCreationFormData',
        AUTHORISATION: (data: {
            activityId?: ApiId;
            contractServiceSpeedId?: ApiId;
            contractSpecialServiceSpeedId?: ApiId;
        }) => `Activity/RequiresAuthorisation?${qs.stringify(data, { skipEmptyString: true })}`,
        CHECK_EXISTING_DELIVERY_TARGET_DATE_IN_AREA: (data: ExistingDeliveryCheckRequestModel) =>
            `Activity/CheckExistingDeliveryTargetDateInArea?${qs.stringify(data, { skipEmptyString: true })}`,
        GET_AVAILABLE_TIMESLOTS: (fromDate: IsoDate, toDate: IsoDate) =>
            `Activity/AvailableTimeSlots?fromDate=${fromDate}&toDate=${toDate}`,
        SET_ACTIVITY_PIN: (activityId: ApiId, pin: string) => `Activity/${activityId}/SetOnBehalfOfPin/${pin}`,
        UPLOAD_ACTIVITY_FILES: (activityId: ApiId) => `Activity/${activityId}/UploadActivityFiles`,
        DELETE_ACTIVITY_FILES: (activityId: ApiId) => `Activity/${activityId}/DeleteActivityFiles`
    },
    PRODUCTS: {
        CATEGORIES: 'ContractProductCategories',
        PRODUCT_SEARCH: 'PortalProduct/search',
        PRODUCT: 'PortalProduct',
        RECYCLED_SPECIAL_PRODUCT_SEARCH: 'PortalProduct/rsp/search',
        RELATED_PRODUCTS: (productId: ApiId) => `RelatedContractProduct?productId=${productId}`,
        AddProductInstallationData: 'Adaptation',
        RESERVE: (contractProductId: ApiId, holdTimeId: ApiId) =>
            `PortalProduct/reserve/${contractProductId}/${holdTimeId}`,
        UNRESERVE: (contractProductId: ApiId) => `PortalProduct/unreserve/${contractProductId}`,
        PCI_TYPES: 'PortalProduct/pcitypes',
        ADD_PCI_ITEM: 'PortalProduct/addNewPCIItem',
        PORTAL_RECYCLED_SPECIAL_HOLD_TIMES: (contractId: ApiId = '') => `PortalRecycledSpecialHoldTimes/${contractId}`,
        RSP_CATEGORIES: 'ProductCategories/rspCategories',
        RSP_PRODUCTS: 'PortalProduct/rspProducts',
        RSP_PRODUCT_UPDATE_POOLED_SPECIAL: (contractProductId: ApiId) =>
            `PortalProduct/rspProduct/${contractProductId}/setRspAvailability`,
        GetSpecialOrderFormData: 'PortalProduct/specialReqFormInit'
    },
    BASKET: {
        Basket: `Basket`,
        AddProductToBasket: 'Basket/Products',
        AddQuoteToBasket: 'Basket/Quotes',
        AddQuoteRequestToBasket: 'Basket/QuoteRequests',
        ChangeQuantity: 'Basket/BasketItems',
        PutProductReviews: 'Basket/Reviews',
        AddSpecialToBasket: 'Basket/SpecialRequisition',
        AddRepairToBasket: 'Basket/Repair',
        AddCollectionToBasket: 'Basket/Collection',
        SwapCteBasketItem: (basketItemId: ApiId, contractProductId: ApiId) =>
            `Basket/SwapCteBasketItems/${basketItemId}/${contractProductId}`
    },
    TELECARE: {
        telecareform: (contractId: ApiId = '') => `Telecare/GetTelecareForm/${contractId}`,
        addtelecare: 'Telecare/SubmitTelecareData'
    },
    SUPPLIERS: 'suppliers/GetSuppliersForPortal',
    ACTIVITIES: {
        ACTIVITY: 'Activity',
        ACTIVITY_SUMMARY: (activityId: ApiId) => `Activity/${activityId}/summary`,
        ACTIVITY_NOTES: 'Activity/ActivityNotes',
        ALERTS: (activityId: ApiId) => `Activity/${activityId}/Alerts`,
        SCHEDULE: 'Activity/Schedule',
        ACTIVITY_SEARCH: 'Activity/Search',
        ACTIVITY_SEARCH_FILTERS: 'Activity/SearchFilters',
        SERVICE_CENTRE_CAPACITY: 'Activity/ServiceCentreTotals',
        MOST_ORDERED_PRODUCTS: 'Activity/MostOrderedProducts',
        MOST_RECENT_ORDERS: 'Activity/MostRecentOrders',
        AUTHORISATIONS: 'ActivityAuthorisation',
        AUTHORISATIONS_PENDING: 'ActivityAuthorisation/Authoriser/PendingAuthorisations',
        AUTHORISATIONS_PENDING_COUNT: 'ActivityAuthorisation/Authoriser/PendingAuthorisationsCount',
        AUTHORISATION_SUMMARY: (activityId: ApiId) => `ActivityAuthorisation/${activityId}/summary`,
        AUTHORISATION_DETAILS: (activityId: ApiId) => `ActivityAuthorisation/${activityId}`,
        DELETE_ORDER: (activityId: ApiId, reasonCodeId: ApiId) =>
            `Activity/${activityId}/Cancel?reasonCodeId=${reasonCodeId}`,
        REASON_CODE: (activityId: ApiId, reasonCodeId: ApiId) =>
            `Activity/${activityId}/ActivityReason?reasonCodeId=${reasonCodeId}`,
        REASON_CODE_ITEMS: (activityId: ApiId, reasonCodeId: ApiId) =>
            `Activity/ActivityReasonCodeItem/${activityId}/${reasonCodeId}`,
        CANCEL_PRODUCT: (activityId: ApiId) => `ActivityAuthorisation/${activityId}/activityproducts`,
        SWAP_PRODUCT: (activityId: ApiId) => `ActivityAuthorisation/${activityId}/activityproducts/swap`,
        CLOSE_DIRECT_DELIVERY: (activityId: ApiId) => `Activity/${activityId}/CompleteSpecialActivity`,
        DELIVERY_DETAILS_DATA: (activityId: ApiId) => `Activity/ActivityDeliveryDetailsFormData/${activityId}`,
        UPDATE_DELIVERY_DETAILS: 'Activity/UpdateDeliveryDetails',
        ADD_COMMENT: (activityId: ApiId) => `ActivityAuthorisation/${activityId}/AddAuthorisationResponse`,
        OPEN_ORDER_DETAILS: 'Activity/recentOpenOrderDetailsForServiceUser',
        ACTIVITY_LAST_FIVE_ACTIONS: `Activity/LastFiveActions`,
        LOOKUP_BY_REFERENCE: `Activity/LookupByReference`,
        GET_LATEST_REASON_CODE: (activityId: ApiId) => `Activity/GetLatestReasonCodeForActivity/${activityId}`,
        OPEN_ACTIVITIES: 'Activity/GetOpenActivities'
    },
    LETTERS: {
        PPMLetter_Create: (activityId: ApiId) => `PPMLetter/${activityId}`
    },
    EVENTS: {
        EVENT: 'ActivityEvent',
        GetEventByActivityId: (activityId: ApiId) => `ActivityEvent/${activityId}`,
        GetEventByServiceUserId: (serviceUserId: ApiId) => `ActivityEvent/GetEventsByServiceUserId/${serviceUserId}`
    },
    EVENT_TYPES: {
        EVENT_TYPE: 'EventType'
    },
    QUOTES: {
        FetchQuoteList: 'Quote',
        FetchQuoteDetails: (quoteId: ApiId) => `Quote/${quoteId}`,
        UpdateQuoteStatus: 'Quote'
    },
    NEWS: {
        News: 'News',
        NewsAdmin: 'News/NewsAdmin',
        Urgent: 'News/urgent',
        UrgentNewsRead: 'News/read'
    },
    EQUIPMENT_REVIEWS: {
        FetchByUser: 'EquipmentReview',
        FetchByContract: 'EquipmentReview/GetAllByContract'
    },
    ACCOUNT: {
        MyAccount: 'MyAccount'
    },
    FEEDBACK: {
        Get: 'Feedbacks',
        GetFilters: 'Feedbacks/SearchFilters',
        UpdateStatus: `Feedbacks/SetStatus`,
        RespondFeedback: `Feedbacks/Respond`,
        FeedbackTypes: 'Feedbacks/FeedbackTypes',
        FetchAssignees: 'Users/CsaUsers',
        UpdateAssignee: (feedbackId: ApiId, assigneeId: ApiId) =>
            `Feedbacks/ChangeAssignee/${feedbackId}?assigneeId=${assigneeId}`
    },
    SERVICES: {
        FetchAll: (contractId: ApiId) => `OrderableServices?contractId=${contractId}`,
        MinorAdaptation: {
            Create: 'Basket/QuoteRequest'
        },
        TrustedAssessment: {
            FetchTypes: 'TrustedAssessor',
            Create: 'Basket/TrustedAssessments'
        },
        EquipmentMove: {
            Create: 'Basket/EquipmentMove'
        }
    },
    PERIPHERAL_STORES: {
        FetchList: 'PortalPeripheralStores/GetForUser',
        FetchDetails: (peripheralStoreId: ApiId) => `PortalPeripheralStores/${peripheralStoreId}/delivery`,
        UpdateOrderQuantity: (peripheralStoreId: ApiId) => `PortalPeripheralStores/${peripheralStoreId}/delivery/product`,
        FetchOptions: 'PortalPeripheralStores',
        Search: (puk: string) => `PortalPeripheralStores/Search?puk=${encodeURIComponent(puk)}`,
        // This stuff is in the activity controller, but might make more sense if people are looking it to be here?
        OrderFormData: 'activity/PstoreActivityCreationFormData',
        CreateOrder: 'activity/CreatePstoreActivity',
        CreateDelivery: 'activity/CreatePstoreUrgentDelivery',
        DeliverySpeeds: (contractId: ApiId) => `PortalPeripheralStores/DeliverySpeeds/${contractId}`,
        Activities: 'Activity/SearchActivitiesFromPStore'
    },
    SERVICE_HUB: {
        CREATE_USER: 'ServiceHub/Create',
        FORGOTTEN_USER_PASSWORD: (userId: ApiId) => `ServiceHub/${userId}/ForgotPassword`
    },
    USERS: {
        AVAILABLE_2FA_MEDIUMS: (userId: ApiId) => `Users/${userId}/Available2FaMediums`,
        ENABLE_2FA: (userId: ApiId, medium: Preferred2FaEnum) => `Users/${userId}/Enable2Fa/${medium}`,
        DISABLE_2FA: (userId: ApiId) => `Users/${userId}/Disable2Fa`
    },
    CONTRACT_PROFESSIONAL_ASSESSMENTS: {
        CONTRACT_PROFESSIONAL_ASSESSMENT: `ContractProfessionalAssessment`,
        CONTRACT_PROFESSIONAL_ASSESSMENTS_BY_CONTRACT_ID: (contractId: ApiId) =>
            `ContractProfessionalAssessment/GetByContractId/${contractId}`,
        CONTRACT_PROFESSIONAL_ASSESSMENT_QUESTION: `ContractProfessionalAssessmentQuestion`,
        CONTRACT_PROFESSIONAL_ASSESSMENT_QUESTION_PRODUCT: `ContractProfessionalAssessmentQuestionProduct`,
        CONTRACT_PROFESSIONAL_ASSESSMENT_QUESTION_PRODUCT_RESULTS: `ContractProfessionalAssessmentQuestionProduct/results`,
        CONTRACT_PROFESSIONAL_ASSESSMENT_QUESTIONS_OVERVIEW: (contractId: ApiId, professionalAssessmentId: ApiId) =>
            `ContractProfessionalAssessment/GetContractProfessionalAssessmentsByContractAndProfessionalAssessmentId/${contractId}/${professionalAssessmentId}`
    },
    CONTRACT: {
        BUDGET_PIN: {
            ORGANISATIONS: (contractId: ApiId = '') => `Organisations${contractId && '?contractId=' + contractId}`,

            AREAS: (contractId: ApiId = '') => `PinArea${contractId && '?contractId=' + contractId}`,

            TEAMS: (contractId: ApiId = '') => `PinTeam/GetPinTeamsPinManager${contractId && '?contractId=' + contractId}`,

            GROUPS: (contractId: ApiId = '') =>
                `PinGroups/GetByContractIdPinManager${contractId && '?contractId=' + contractId}`,

            GROUP_STOCK: (pinGroupId: ApiId = '') => `PinGroupsContractProducts${pinGroupId && `?pinGroupId=${pinGroupId}`}`,

            ///// USER APIS ///////
            CONTRACT_PIN_USER_AUDIT: (PinUserId: ApiId) => `PinUser/GetPinUserAudit?PinUserId=${PinUserId}`,
            // This one gets all PIN users on a contract. This mixes the identity user with the PIN details
            CONTRACT_PIN_USER: (contractId: ApiId = '') => `PinUser${contractId && '?contractId=' + contractId}`,
            // This one gets all of the PINs that a pin user currently has
            USER_PIN_USERS: `PinUser/PinUserSearch`,
            // Search identity users
            SEARCH_USERS: 'Users/UserSearch',
            // Import user CSV
            CONTRACT_PIN_USER_IMPORT: `PinUser/import`,
            // This one gets all of the PIN users who are authorisers. This model returns a subset of the PIN user information
            AUTHORISERS: (contractId: ApiId = '') => `PinUser/authorisers?contractId=${contractId}`

            /////////////
        }
    },
    STOCK: {
        GET_COMPATIBLE_PRODUCTS: (stockId: ApiId) => `Stock/${stockId}/compatibleProducts`
    }
};
