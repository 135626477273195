// all-polyfills need to be imported first in order to work in IE11
import 'all-polyfills';
import { ConnectedRouter } from 'connected-react-router';
import { historyHelper as history, saveState, ScrollToTop } from 'millbrook-core';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'store/store';

store.subscribe(() => {
  // Remove items you don't want stored for things like page refreshes
  const { ui, search, userSettings, ...rest } = store.getState();

  saveState({
    ...rest,
    // search needs to be modified rather than removed
    search: { ...search, searchOpen: false, hideTabs: false }
  });

  // example putting something into persistent localstorage
  // it can be copied from the data going into session storage, or removed and only stored in localstorage
  // it is picked up and merged in store.ts
  saveState({ userSettings }, 'local');
});

function render() {
  const App = require('./app/App').default;
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ScrollToTop />
        <App />
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  );
}

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./app/App', render);
}
