import { makeStyles } from '@material-ui/core';

export const productDetailsStyles = makeStyles((theme) => ({
  alignSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },

  recycledSpecialCard: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3)
  },

  imageSectionDetailDelivery: {
    flexBasis: '40%'
  },
  imageSection: {
    width: '100%',
    height: 'auto'
  },
  actionsAdd: {
    flexBasis: '80%',
    '& button': {
      marginLeft: '10px'
    }
  }
}));
