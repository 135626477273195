import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { IApiResponse, mapApiErrors, setActivityDetails } from 'millbrook-core';
import { putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { ActivityId } from './activity.types';

/* types */
export type CloseActivityRequest = {};
export type CloseActivityResponse = IApiResponse<boolean>;

/* state */
interface ClosingState {
  activity?: ActivityId;
  closed: boolean;
}

const initialState: ClosingState = {
  closed: false
};

/* slice */
const closingSlice = createSlice({
  name: 'closing',
  initialState,
  reducers: {
    setClosedActivity(state, action: PayloadAction<ActivityId | undefined>) {
      state.closed = action.payload ? true : false;
      state.activity = action.payload;
    },
    unsetClosingActivity(state) {
      state.activity = undefined;
      state.closed = false;
    }
  },
  extraReducers: (builder) => {
    // when a new activity is set, reset all closing activity info
    builder.addCase(setActivityDetails, (state, action) => {
      state = { ...initialState };
    });
  }
});

/* thunks */
export const closeActivity =
  (activity: ActivityId): AppThunk =>
  async (dispatch) => {
    return putItem<CloseActivityRequest, CloseActivityResponse>(ENDPOINTS.ACTIVITIES.CLOSE_DIRECT_DELIVERY(activity.id))
      .then((response) => {
        dispatch(setClosedActivity(activity));

        return response.result || false;
      })
      .catch((response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      });
  };

/* actions */
export const { setClosedActivity, unsetClosingActivity } = closingSlice.actions;

/* selectors */
export const selectClosingActivity = (state: RootState) => state.activities.closing.activity;
export const selectClosingStatus = (state: RootState) => state.activities.closing.closed;

/* reducers */
export default closingSlice.reducer;
