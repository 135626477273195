import { yupResolver } from '@hookform/resolvers';
import { selectSelectedPinUser, selectUserHasCsaPermissions } from 'features/auth-pin/auth-pin.slice';
import { fetchProductsCatalogue, getProductCategoryOptions } from 'features/products/productsCategories.slice';
import { ErrorBox, InputField, SelectField, WarningLabel } from 'millbrook-core';
import { Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

const productSearchFormValidation = yup
  .object()
  .shape({
    categoryId: yup.string(),
    searchTerm: yup.string(),
    manufacturer: yup.string()
  })
  .test(
    'atLeastOne',
    'Please provide at least one field',
    // TODO: update when manufacturer is inserted
    // (value) => !!(value.productName || value.productCategory || value.productId || value.productManufacturer)
    (value) => !!(value.searchTerm || value.categoryId)
  );

export type ProductSearchFormData = yup.InferType<typeof productSearchFormValidation>;

export const PRODUCT_SEARCH_FORM_ID = 'product-search-form';

interface ProductSearchFormProps {
  onSubmit: (data: ProductSearchFormData) => void;
  onChange?: (data: ProductSearchFormData) => void;
}

export const ProductSearchForm: React.FC<ProductSearchFormProps> = ({ onSubmit, onChange }) => {
  const dispatch = useDispatch();
  const productCategoryOptions = useSelector(getProductCategoryOptions);
  const isCSA = useSelector(selectUserHasCsaPermissions);
  const selectedPinUser = useSelector(selectSelectedPinUser);
  const { control, handleSubmit, errors, formState, watch } = useForm<ProductSearchFormData>({
    defaultValues: {
      searchTerm: '',
      categoryId: '',
      manufacturer: ''
    },
    mode: 'onBlur',
    resolver: yupResolver(productSearchFormValidation)
  });

  const formValues = watch();
  const { isValid } = formState;

  useEffect(() => {
    if (isValid) {
      onChange && onChange(formValues);
    }
  }, [formValues, isValid, onChange]);

  useEffect(() => {
    dispatch<any>(fetchProductsCatalogue());
  }, [dispatch]);

  return (
    <form id={PRODUCT_SEARCH_FORM_ID} onSubmit={handleSubmit(onSubmit)} noValidate>
      <ErrorBox error={(errors as any)?.atLeastOne} />
      {isCSA && (
        <WarningLabel
          message={
            <Fragment>
              This search will only include products on the currently selected contract{' '}
              <strong>{selectedPinUser?.contractName}</strong>.
            </Fragment>
          }
        />
      )}
      <InputField
        name="searchTerm"
        error={errors?.searchTerm}
        control={control}
        label="Product name or SKU"
        placeholder="Please enter product name or SKU"
        inputProps={{ maxLength: 50 }}
      />

      <SelectField
        control={control}
        label="Product category"
        name="categoryId"
        error={errors?.categoryId}
        pleaseSelect
        options={productCategoryOptions}
      />
    </form>
  );
};
