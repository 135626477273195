import { yupResolver } from '@hookform/resolvers';
import { Box, makeStyles } from '@material-ui/core';
import { ROUTES } from 'constants/routes';
import { ApiId, BodyText, COLORS, ErrorBox, FileUploadField, IFormDataWithApiError, InputField } from 'millbrook-core';
import { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  defaultProductInstallationFormData,
  formId,
  ProductInstallationFormData,
  productInstallationFormSchema
} from './productInstallationForm.validation';

interface Props {
  productId: ApiId;
  onSuccess: (notes: string, files: FileList) => void;
}

const ProductInstallationForm: React.FC<Props> = ({ productId, onSuccess }) => {
  const classes = useStyles();

  const { control, handleSubmit, errors, setError } = useForm<ProductInstallationFormData & IFormDataWithApiError>({
    defaultValues: defaultProductInstallationFormData,
    resolver: yupResolver(productInstallationFormSchema),
    reValidateMode: 'onBlur'
  });

  const handleSaveProductInstallationFormData = async (data: ProductInstallationFormData) => {
    try {
      onSuccess && onSuccess(data.installationRequirements, data.files);
    } catch (e : any) {
      setError('apiError', { message: e.message });
    }
  };

  return (
    <Fragment>
      <Box mb={4}>
        <BodyText type="regular">Please enter your installation requirements below.</BodyText>
      </Box>
      <form id={formId} onSubmit={handleSubmit(handleSaveProductInstallationFormData)} noValidate>
        <ErrorBox error={errors?.apiError} />
        <ErrorBox error={(errors as any)?.atLeastOne} />

        <InputField
          multiline
          rows="6"
          label="Installation requirements"
          placeholder="Enter installation requirements"
          control={control}
          error={errors?.installationRequirements}
          name="installationRequirements"
          inputProps={{ maxLength: 500 }}
        />

        <Box mt={2}>
          <BodyText type="regular">
            In order to help us install your product,{' '}
            <Link to={ROUTES.HELP.root} className={classes.link}>
              please download the appropriate installation diagram document
            </Link>{' '}
            and complete. Once filled in, please upload below.
          </BodyText>
        </Box>

        <Box mt={2}>
          <FileUploadField
            label="Upload files"
            name="files"
            accept="document"
            control={control}
            error={errors?.files}
            multiple
            fileList
          />
        </Box>
      </form>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  link: {
    color: COLORS.regalBlue,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

export default ProductInstallationForm;
