import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { MostOrderedResponse } from 'features/products/products.slice';
import {
  ApiId,
  ContractProfessionalAssessment,
  ContractProfessionalAssessmentOverview,
  ErrorResponse,
  IApiResponse,
  mapApiErrors
} from 'millbrook-core';
import { ProfessionalAssessmentResults } from 'pages/ProfessionalAssessments/ProfessionalAssessment';
import { getItem, getItems, postItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';

/* types */
export type ContractProfessionalAssessmentOverviewListResponse = IApiResponse<ContractProfessionalAssessmentOverview[]>;
export type ContractProfessionalAssessmentResponse = IApiResponse<ContractProfessionalAssessment>;

/* thunks */
export const fetchContractProfessionalAssessment =
  (contractProfessionalAssessmentId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, ContractProfessionalAssessmentResponse>(
      ENDPOINTS.CONTRACT_PROFESSIONAL_ASSESSMENTS.CONTRACT_PROFESSIONAL_ASSESSMENT,
      contractProfessionalAssessmentId
    ).then(
      (response) => {
        dispatch(setContractProfessionalAssessment(response.result || undefined));
        return response;
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchContractProfessionalAssessmentsByContractId =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<ContractProfessionalAssessmentOverviewListResponse>(
      ENDPOINTS.CONTRACT_PROFESSIONAL_ASSESSMENTS.CONTRACT_PROFESSIONAL_ASSESSMENTS_BY_CONTRACT_ID(contractId)
    ).then((response) => {
      dispatch(setContractProfessionalAssessmentsOverview(response.result || []));
    });
  };

export const postContractProfessionalAssessmentResults =
  (answers: ProfessionalAssessmentResults): AppThunk =>
  async (dispatch) => {
    return postItems<ProfessionalAssessmentResults, MostOrderedResponse>(
      ENDPOINTS.CONTRACT_PROFESSIONAL_ASSESSMENTS.CONTRACT_PROFESSIONAL_ASSESSMENT_QUESTION_PRODUCT_RESULTS,
      answers
    )
      .then((response) => {
        return response.result || [];
      })
      .catch((response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      });
  };

interface ContractProfessionalAssessmentsState {
  contractProfessionalAssessment?: ContractProfessionalAssessment;
  contractProfessionalAssessmentsOverview: ContractProfessionalAssessmentOverview[];
  contractProfessionalAssessmentAnswers: ProfessionalAssessmentResults[];
}

const initialState: ContractProfessionalAssessmentsState = {
  contractProfessionalAssessmentsOverview: [],
  contractProfessionalAssessmentAnswers: []
};

const contractProfessionalAssessmentsSlice = createSlice({
  name: 'contractProfessionalAssessments',
  initialState,
  reducers: {
    setContractProfessionalAssessment(state, action: PayloadAction<ContractProfessionalAssessment | undefined>) {
      state.contractProfessionalAssessment = action.payload;
    },
    setContractProfessionalAssessmentsOverview(state, action: PayloadAction<ContractProfessionalAssessmentOverview[]>) {
      state.contractProfessionalAssessmentsOverview = action.payload;
    },
    setContractProfessionalAssessmentAnswers(state, action: PayloadAction<ProfessionalAssessmentResults>) {
      const index = state.contractProfessionalAssessmentAnswers.findIndex(
        (x) => x.contractProfessionalAssessmentId === action.payload.contractProfessionalAssessmentId
      );
      if (index >= 0) {
        state.contractProfessionalAssessmentAnswers[index] = action.payload;
      } else {
        state.contractProfessionalAssessmentAnswers.push(action.payload);
      }
    },
    clearContractProfessionalAssessmentAnswers(state, action: PayloadAction<ApiId>) {
      state.contractProfessionalAssessmentAnswers = state.contractProfessionalAssessmentAnswers.filter(
        (x) => x.contractProfessionalAssessmentId !== action.payload
      );
    }
  }
});

export const {
  setContractProfessionalAssessment,
  setContractProfessionalAssessmentsOverview,
  setContractProfessionalAssessmentAnswers,
  clearContractProfessionalAssessmentAnswers
} = contractProfessionalAssessmentsSlice.actions;

export const selectContractProfessionalAssessment = (state: RootState) =>
  state.contractProfessionalAssessments.contractProfessionalAssessment;
export const selectContractProfessionalAssessmentsOverview = (state: RootState) =>
  state.contractProfessionalAssessments.contractProfessionalAssessmentsOverview;

export const selectContractProfessionalAssessmentAnswers =
  (contractProfessionalAssessmentId: ApiId) => (state: RootState) =>
    state.contractProfessionalAssessments.contractProfessionalAssessmentAnswers.find(
      (x) => x.contractProfessionalAssessmentId === contractProfessionalAssessmentId
    );

export const selectEnabledAssessmentAreas = createSelector(
  [selectContractProfessionalAssessmentsOverview],
  (assessmentAreas) => {
    //We don't want to show any inactive or questionless results to the prescriber.
    return assessmentAreas.filter((p) => p.inActive !== true && p.hasQuestions === true);
  }
);

export default contractProfessionalAssessmentsSlice.reducer;
