import HowToRegIcon from '@material-ui//icons/HowToReg';
import { Box, makeStyles } from '@material-ui/core';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import CheckIcon from '@material-ui/icons/Check';
import EventIcon from '@material-ui/icons/Event';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ShareIcon from '@material-ui/icons/Share';
import StarIcon from '@material-ui/icons/Star';
import TocIcon from '@material-ui/icons/Toc';
import { PinCard } from 'components/Cards/PinCard/PinCard';
import { ROUTES } from 'constants/routes';
import {
    selectIsInternalPin,
    selectSelectedPinUser,
    selectUserHasCsaPermissions
} from 'features/auth-pin/auth-pin.slice';
import { resetFeedbackSearchParams } from 'features/feedback/feedback.slice';
import { setOrdersFormFilterByPin } from 'features/search/search.slice';
import {
    BodyText,
    DrawerActionList,
    GrantAccess,
    GrantFeatureAccess,
    HasAccess, IDrawerActionLinkList,
    StyledButton,
    StyledDrawer,
    StyledDrawerContent,
    StyledDrawerHeader,
    usePermissions
} from 'millbrook-core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { fetchAuthorisationCount, selectAuthorisationCount } from './quickActionsDrawer.slice';

interface QuickActionsDrawerProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  accountLinkContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4)
  },
  accountLink: {
    textTransform: 'none'
  }
}));

let pendingCountTimer = 0;

export const QuickActionsDrawer = ({ open, onClose }: QuickActionsDrawerProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const pinUser = useSelector(selectSelectedPinUser);
  const isPrescriberAuthoriser = usePermissions(GrantFeatureAccess('Authorisations'));
  const hasNotifications = usePermissions(GrantFeatureAccess('Notifications'));
  const isCSA = useSelector(selectUserHasCsaPermissions);
  const isInternalPin = useSelector(selectIsInternalPin);
  const isNewsPermission = usePermissions(GrantAccess(['News', 'Edit']));
  const isPooledRspAdminPermission = usePermissions(GrantAccess(['PooledRspAdmin', 'View']));
  const authorisationCount = useSelector(selectAuthorisationCount);

  const quickActions: IDrawerActionLinkList = [
    isPrescriberAuthoriser &&
      !isInternalPin && {
        icon: CheckIcon,
        label: 'Authorisations',
        href: ROUTES.ACTIVITIES.authorisations,
        color: 'scienceBlue',
        badgeContent: authorisationCount
      },
    isCSA && {
      icon: EventIcon,
      label: 'Unscheduled orders',
      href: ROUTES.ACTIVITIES.unscheduledActivities,
      color: 'scienceBlue'
    },
    hasNotifications && {
      icon: NotificationImportantIcon,
      label: 'Newsflash',
      href: ROUTES.NOTIFICATIONS.root,
      color: 'mySin'
    },
    isNewsPermission && {
      icon: AnnouncementIcon,
      label: 'News admin',
      href: ROUTES.NEWS_ADMIN.root,
      color: 'black'
    },
    {
      icon: TocIcon,
      label: isInternalPin ? 'Orders' : 'My orders',
      href: ROUTES.ACTIVITIES.root,
      color: 'jungleGreen',
      onClick: () => {
        dispatch(setOrdersFormFilterByPin(!isInternalPin));
      },
      allowHrefAndClick: true
    },
    {
      icon: PeopleAltIcon,
      label: 'Service users',
      href: ROUTES.SERVICE_USERS.searchResults,
      color: 'hibiscus'
    },
    !isInternalPin && {
      icon: StarIcon,
      label: 'Equipment reviews',
      href: ROUTES.EQUIPMENTREVIEWS.root,
      color: 'regalBlue'
    },
    {
      icon: ChatBubbleIcon,
      label: 'Feedback',
      href: ROUTES.FEEDBACK.list,
      color: 'curiousBlue',
      onClick: resetFeedbackSearchParams,
      allowHrefAndClick: true
    },
    {
      icon: AssignmentOutlinedIcon,
      label: 'My quotes',
      href: ROUTES.QUOTES.root,
      color: 'edward140'
    },
    isPooledRspAdminPermission && {
      icon: ShareIcon,
      label: 'Pooled RSP admin',
      href: ROUTES.POOLED_SPECIALS_ADMIN.root,
      color: 'poppingBlue'
    },
    {
      icon: HowToRegIcon,
      label: 'Start professional assessment',
      color: 'edward140',
      href: ROUTES.CONTRACT_PROFESSIONAL_ASSESSMENTS.root
    }
  ];

  useEffect(() => {
    // add a wee bit of jank, because this is getting hit at the wrong places
    clearTimeout(pendingCountTimer);

    if (isPrescriberAuthoriser) {
      pendingCountTimer = window.setTimeout(() => dispatch<any>(fetchAuthorisationCount()), 500);
    }

    return () => {
      clearTimeout(pendingCountTimer);
    };
  }, [dispatch, isPrescriberAuthoriser]);

  return (
    <StyledDrawer open={open} onClose={onClose} anchor="right">
      <StyledDrawerHeader>
        {pinUser && <PinCard pin={pinUser} isActive />}
        <Box className={classes.accountLinkContainer}>
          <BodyText bold={true}>Your account quick links</BodyText>
          <HasAccess
            yes={
              <StyledButton
                component={RouterLink}
                to={ROUTES.ACCOUNT.root}
                onClick={onClose}
                className={classes.accountLink}
                variant="link"
                size="small"
                endIcon={<ArrowRightAltIcon />}
              >
                Go to my account
              </StyledButton>
            }
            permission={GrantFeatureAccess('Account')}
          />
        </Box>
      </StyledDrawerHeader>
      <StyledDrawerContent>
        <DrawerActionList actions={quickActions} onListItemClick={onClose} />
      </StyledDrawerContent>
    </StyledDrawer>
  );
};
