import { ROUTES } from 'constants/routes';
import { selectHasPeripheralStores } from 'features/contract/contract.slice';
import {
  ApiAuthorizationRoutes,
  AUTH_ROUTES,
  GrantAccess,
  GrantFeatureAccess,
  LoadingSpinner,
  NotAuthorisedPage,
  ProtectedRoute,
  selectPortalUserIsLoggedIn,
  selectUserIsLoggedIn
} from 'millbrook-core';
import ChoosePinPage from 'pages/ChoosePinPage/ChoosePinPage';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import ProductDetailsPage from 'pages/Products/ProductDetailsPage/ProductDetailsPage';
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Redirect, Route, Switch } from 'react-router-dom';
import ProfessionalAssessment from '../pages/ProfessionalAssessments/ProfessionalAssessment';
import ProfessionalAssessmentAreas from '../pages/ProfessionalAssessments/ProfessionalAssessmentAreas';

const HomePage = React.lazy(() => import('pages/HomePage/HomePage'));
const DashboardPage = React.lazy(() => import('pages/DashboardPage/DashboardPage'));
const NotificationsPage = React.lazy(() => import('pages/Notifications/NotificationsPage'));
const EquipmentReviewsPage = React.lazy(() => import('pages/EquipmentReviews/EquipmentReviewsPage'));
const PooledSpecialsAdminPage = React.lazy(() => import('pages/PooledSpecialsAdmin/PooledSpecialsAdminPage'));
const ServiceUserRoot = React.lazy(() => import('pages/service-users'));
const NewsAdminRoot = React.lazy(() => import('pages/News/NewsAdmin'));
const NewsViewingRoot = React.lazy(() => import('pages/News/NewsViewing'));
const FeedbackRoute = React.lazy(() => import('pages/Feedback'));

const Products = React.lazy(() => import('pages/Products/'));
const CheckoutRoot = React.lazy(() => import('pages/checkout/'));

const HelpAndSupport = React.lazy(() => import('pages/HelpAndSupport/HelpAndSupportListing'));
const HelpAndSupportDetails = React.lazy(() => import('pages/HelpAndSupport/HelpAndSupportDetails'));
const HelpAndSupportDocuments = React.lazy(() => import('pages/HelpAndSupport/HelpAndSupportDocuments'));
const ActivitiesRoot = React.lazy(() => import('pages/Activities'));

const BudgetPinRoot = React.lazy(() => import('pages/ManagePINs/'));

const Quotes = React.lazy(() => import('pages/Quotes/'));
const Account = React.lazy(() => import('pages/Account/'));
const Services = React.lazy(() => import('pages/Services/'));
const PeripheralStores = React.lazy(() => import('pages/PeripheralStores/'));

const AppRouter = () => {
  const isPinUserLoggedIn = useSelector(selectPortalUserIsLoggedIn);
  const isUserLoggedIn = useSelector(selectUserIsLoggedIn);

  const hasPeripheralStores = useSelector(selectHasPeripheralStores);

  return (
    <Suspense fallback={<LoadingSpinner isLoading />}>
      <Switch>
        <Route path={ROUTES.HOME} exact>
          {isPinUserLoggedIn ? (
            <DashboardPage />
          ) : isUserLoggedIn ? (
            <Redirect to={ROUTES.PIN_SELECTION} />
          ) : (
            <HomePage />
          )}
        </Route>
        <Route path={AUTH_ROUTES.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />

        <ProtectedRoute path={ROUTES.PIN_SELECTION} component={ChoosePinPage} pageTitle="Choose PIN" />

        {!isPinUserLoggedIn && (
          <Route path="*">
            <Redirect
              to={`${ROUTES.PIN_SELECTION}?returnUrl=${encodeURIComponent(
                window.location.pathname + window.location.search
              )}`}
            />
          </Route>
        )}

        {/* Equipment Reviews */}
        <ProtectedRoute
          permission={GrantAccess(['Order', 'View'])}
          path={ROUTES.EQUIPMENTREVIEWS.root}
          exact
          component={EquipmentReviewsPage}
          pageTitle="Equipment reviews"
        />
        {/* Pooled RSP Admin */}
        <ProtectedRoute
          permission={GrantAccess(['PooledRspAdmin', 'View'])}
          path={ROUTES.POOLED_SPECIALS_ADMIN.root}
          exact
          component={PooledSpecialsAdminPage}
          pageTitle="Pooled RSP admin"
        />
        {/* Feedback */}
        <ProtectedRoute
          permission={GrantAccess(['Feedback', 'View'], ['CSAFeedback'])}
          path={ROUTES.FEEDBACK.root}
          component={FeedbackRoute}
          pageTitle="Feedback"
        />
        {/* Help and Support */}
        <ProtectedRoute
          permission={GrantAccess(['Help', 'View'])}
          path={ROUTES.HELP.root}
          exact
          component={HelpAndSupport}
          pageTitle="Help and support"
        />
        <ProtectedRoute
          permission={GrantAccess(['Help', 'View'])}
          path={ROUTES.HELP.documents}
          exact
          component={HelpAndSupportDocuments}
          pageTitle="Help and support - your trust documents"
        />
        <ProtectedRoute
          permission={GrantAccess(['Help', 'View'])}
          path={ROUTES.HELP.details()}
          component={HelpAndSupportDetails}
          pageTitle="Help and support"
        />
        {/* Manage PINs */}
        <ProtectedRoute
          permission={GrantAccess(['PINManager', 'View'])}
          path={ROUTES.MANAGE_PINS.root}
          component={BudgetPinRoot}
          pageTitle="Manage PINs"
        />
        {/* Notifications */}
        <ProtectedRoute
          permission={GrantAccess(['Notifications', 'View'])}
          path={ROUTES.NOTIFICATIONS.root}
          exact
          component={NotificationsPage}
          pageTitle="Newsflash"
        />
        <ProtectedRoute
          permission={GrantAccess(['News', 'Add', 'Edit'])}
          path={ROUTES.NEWS_ADMIN.root}
          component={NewsAdminRoot}
        />
        <ProtectedRoute
          permission={GrantAccess(['News', 'View'])}
          path={ROUTES.NEWS_VIEWING.root}
          component={NewsViewingRoot}
          pageTitle="News articles"
        />
        {/* Activities */}
        <ProtectedRoute path={ROUTES.ACTIVITIES.root} component={ActivitiesRoot} pageTitle="Activities" />
        {/* Services */}
        <ProtectedRoute path={ROUTES.SERVICES.root} component={Services} pageTitle="Services" />
        {/* Products */}
        <ProtectedRoute path={ROUTES.PRODUCT.root} component={Products} pageTitle="Products" />
        {/* Service Users */}
        {/* TODO: This is a temp fix because we gave over the wrong link to mitel. Generally you wouldn't do redirects on the front-end, it's bad practice */}
        <Route
          path="/service-user/search/tel/:tel"
          render={(props) => (
            <Redirect to={generatePath('/service-users/search/tel/:tel', { tel: props.match.params.tel })} />
          )}
        />
        <ProtectedRoute
          path={ROUTES.SERVICE_USERS.root}
          component={ServiceUserRoot}
          pageTitle="Service users"
          permission={GrantFeatureAccess('ServiceUser')}
        />
        {/* Checkout */}
        <ProtectedRoute path={ROUTES.CHECKOUT.root} component={CheckoutRoot} pageTitle="Checkout" />
        {/* Quotes */}
        <ProtectedRoute path={ROUTES.QUOTES.root} component={Quotes} />
        {/* Account */}
        <ProtectedRoute path={ROUTES.ACCOUNT.root} component={Account} permission={GrantFeatureAccess('Account')} />
        {/* Peripheral stores. No access if there are no pstores */}
        {hasPeripheralStores && <ProtectedRoute path={ROUTES.PERIPHERAL_STORES.root} component={PeripheralStores} />}
        {/* Professional Assessments */}
        <ProtectedRoute
          path={ROUTES.CONTRACT_PROFESSIONAL_ASSESSMENTS.productDetails()}
          component={ProductDetailsPage}
          permission={GrantFeatureAccess('ServiceUser')}
        />
        <ProtectedRoute
          path={ROUTES.CONTRACT_PROFESSIONAL_ASSESSMENTS.assessment()}
          component={ProfessionalAssessment}
          permission={GrantFeatureAccess('ServiceUser')}
        />
        <ProtectedRoute
          path={ROUTES.CONTRACT_PROFESSIONAL_ASSESSMENTS.root}
          component={ProfessionalAssessmentAreas}
          permission={GrantFeatureAccess('ServiceUser')}
        />
        {/* ERRORS */}
        <Route path={ROUTES.NOT_AUTHORISED} render={() => <NotAuthorisedPage url={ROUTES.HOME} />} />
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
};

export { AppRouter };
