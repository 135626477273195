import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ROUTES } from 'constants/routes';
import { selectContractData } from 'features/contract/contract.slice';
import { ProductCategory } from 'features/products/productsCategories.slice';
import { BaseMenuItem } from 'millbrook-core';
import { RootState } from 'store/store';

/* state */
interface MenuState {
  productCatalogueSubMenu?: BaseMenuItem[];
}

const initialState: MenuState = {};

/* slice */
const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setProductsCatalogueMenu(state, action: PayloadAction<ProductCategory[]>) {
      if (action.payload) {
        const productCatalogueSubMenu =
          action.payload.map((catalogueCategory) => {
            return {
              label: catalogueCategory.name,
              href: catalogueCategory.href
            };
          }) || [];

        state.productCatalogueSubMenu = productCatalogueSubMenu;
      }
    }
  }
});

/* thunks */

/* actions */
export const { setProductsCatalogueMenu } = menuSlice.actions;

/* selectors */
const productCatalogueSubMenu = (state: RootState) => state.menu.productCatalogueSubMenu || [];

export const selectProductsCatalogueSubMenu = createSelector(
  [productCatalogueSubMenu, selectContractData],
  (subMenu, { hasOrderableServices }) => {
    const additional: BaseMenuItem[] = [];

    if (hasOrderableServices) {
      additional.push({
        label: 'Services',
        href: ROUTES.SERVICES.root
      });
    }

    return [...additional, ...subMenu];
  }
);

/* reducers */
export default menuSlice.reducer;
