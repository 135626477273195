import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { SERVICE_USERS_CACHE } from 'constants/cache';
import { mapFormDataToResponse } from 'features/service-users/mappers';
import {
  AdditionalServiceUserFieldsRequestData,
  ServiceUserDuplicateCriteria,
  ServiceUserFormData
} from 'features/service-users/service-users.types';
import {
  ActivityStatus,
  AdditionalServiceUserContractField,
  ApiId,
  IApiResponse,
  IFlagList,
  IGuidName,
  IServerSelectOption,
  mapApiErrors,
  mapIServerSelectOptionToSelectOptions,
  ServiceTypeCodeEnum,
  ServiceUserOrderHistoryRequiresUpdateModel,
  ServiceUsersData,
  ServiceUserSummaryAlternative
} from 'millbrook-core';
import { CreateUserFormData } from 'pages/service-users/components/create-user/CreateUserForm.validation';
import qs from 'query-string';
import { getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { mapServiceUserClientAlertsOptionsFromServer, mapServiceUserGroupOptionsFromServer } from './fetch-mappers';
import { selectServiceUser, selectServiceUserHistory } from './serviceUser.slice';

/* types */
export enum ServiceUserEditSteps {
  Details = 0,
  AdditionalInformation = 1,
  Contacts = 2
}

export type FormDataResponse = IApiResponse<ServiceUserFormData>;
export type CreateServiceUserRequest = Omit<ServiceUsersData, 'id' | 'serviceUserReference'>;
export type UpdateServiceUserRequest = Omit<ServiceUsersData, 'id' | 'clientAlerts' | 'serviceUserReference'> & {
  activities?: ApiId[];
};
export type CreateServiceUserResponse = IApiResponse<ServiceUserSummaryAlternative>;
export type DuplicateServiceUsersResponse = IApiResponse<ServiceUserSummaryAlternative[]>;
export type CreateServiceUserAdditionalFieldsRequest = AdditionalServiceUserFieldsRequestData;
export type CreateServiceUserAdditionalFieldsResponse = IApiResponse<AdditionalServiceUserContractField[]>;

/* state */
interface EditingState {
  serviceUserGroups?: IServerSelectOption[];
  propertyTypeOptions?: IServerSelectOption[];
  tenancies?: IGuidName[];
  languages?: IGuidName[];
  ethnicities?: IServerSelectOption[];
  clientAlerts?: IServerSelectOption[];
  serviceUserFieldsValidation?: IFlagList;
  formState: Partial<CreateUserFormData>;
  duplicateRecords?: ServiceUserSummaryAlternative[];
  contractFields?: AdditionalServiceUserContractField[];
}

const initialState: EditingState = {
  formState: {}
};

/* slice */
const serviceUsersEditingSlice = createSlice({
  name: 'editing',
  initialState,
  reducers: {
    setFormDataOptions(state, action: PayloadAction<ServiceUserFormData | undefined>) {
      state.serviceUserGroups = mapServiceUserGroupOptionsFromServer(action.payload?.contractServiceUserGroups);
      state.clientAlerts = mapServiceUserClientAlertsOptionsFromServer(action.payload?.contractClientAlerts);
      state.propertyTypeOptions = action.payload?.propertyTypes;
      state.tenancies = action.payload?.tenancies;
      state.ethnicities = action.payload?.ethnicities;
      state.languages = action.payload?.languages;
      state.serviceUserFieldsValidation = action.payload?.serviceUserFieldsValidation;
      state.contractFields = action.payload?.contractFields;
    },
    updateServiceUserFormState(state, action: PayloadAction<Partial<CreateUserFormData>>) {
      state.formState = { ...state.formState, ...action.payload };
    },
    setServiceUserFormState(state, action: PayloadAction<Partial<CreateUserFormData>>) {
      state.formState = action.payload;
    },
    updateDuplicateRecords(state, action: PayloadAction<ServiceUserSummaryAlternative[]>) {
      state.duplicateRecords = action.payload;
    },
    setContractFields(state, action: PayloadAction<any[]>) {
      state.contractFields = action.payload;
    },
    clearCreateRecord(state) {
      return initialState;
    }
  }
});

/* thunks */
export const fetchDuplicateUsers =
  (filter?: ServiceUserDuplicateCriteria): AppThunk =>
  async (dispatch) => {
    const URL = filter
      ? `${ENDPOINTS.SERVICE_USERS.SERVICE_USERS_DUPLICATE}?${qs.stringify(filter as any, { skipEmptyString: true })}`
      : ENDPOINTS.SERVICE_USERS.SERVICE_USERS_DUPLICATE;

    return getItems<DuplicateServiceUsersResponse>(URL).then(
      (response) => {
        dispatch(updateDuplicateRecords(response.result || []));
        return response.result || [];
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchServiceUserFormData =
  (serviceUserId: ApiId = ''): AppThunk =>
  async (dispatch) => {
    return getItems<FormDataResponse>(ENDPOINTS.SERVICE_USERS.SERVICE_USER_FORM_DATA(serviceUserId)).then(
      (response) => {
        // initialise the form data
        dispatch(setFormDataOptions(response.result));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const getOpenActivitiesRequiringUpdate =
  (serviceUserId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    const activities = selectActivitiesForUpdate(getState());
    return Promise.resolve(activities);
  };

export const createServiceUser =
  (data: CreateUserFormData): AppThunk =>
  async (dispatch) => {
    const userResponse = mapFormDataToResponse(data);

    // Set client alerts to empty array, but ONLY for create, not for update
    if (!userResponse.clientAlerts) {
      userResponse.clientAlerts = [];
    }

    return postItems<CreateServiceUserRequest, CreateServiceUserResponse>(
      ENDPOINTS.SERVICE_USERS.SERVICE_USERS,
      userResponse,
      { cacheName: SERVICE_USERS_CACHE }
    ).then(
      (response) => {
        return response.result;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateServiceUser =
  (id: ApiId, activities: ApiId[]): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
      const formData = selectFormState(state);
    const mappedData = mapFormDataToResponse(formData as CreateUserFormData);

    // peel off the client alerts, we aren't sending them for the edit. This is handled in the service details page to remove additional complexity here
    const { clientAlerts, ...userResponse } = mappedData;

    return putItem<UpdateServiceUserRequest, any>(
      ENDPOINTS.SERVICE_USERS.SERVICE_USERS,
      { ...userResponse, activities },
      id
    ).then(
      (response) => {
        // TODO: what should happen here?
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/* actions */
export const {
  setFormDataOptions,
  updateServiceUserFormState,
  setServiceUserFormState,
  updateDuplicateRecords,
  clearCreateRecord,
  setContractFields
} = serviceUsersEditingSlice.actions;

/* selectors */
export const selectServiceUserGroupsOptions = (state: RootState) => state.serviceUsers.editing.serviceUserGroups || [];
export const selectServiceUserPropertyTypesOptions = (state: RootState) =>
  state.serviceUsers.editing.propertyTypeOptions || [];
export const selectServiceUserTenancyOptions = (state: RootState) => state.serviceUsers.editing.tenancies || [];
export const selectServiceUserLanguageOptions = (state: RootState) => state.serviceUsers.editing.languages || [];
export const selectServiceUserEthnicityOptions = (state: RootState) => state.serviceUsers.editing.ethnicities || [];
export const selectServiceUserOtherLanguageId = createSelector([selectServiceUserLanguageOptions], (languages) => {
  return languages.find((x) => x.name === 'Other')?.id;
});

export const selectServiceUserClientAlertsOptions = (state: RootState) =>
  mapIServerSelectOptionToSelectOptions(state.serviceUsers.editing.clientAlerts || []);
export const selectServiceUserMandatoryFields = (state: RootState) =>
  state.serviceUsers.editing.serviceUserFieldsValidation;

export const selectFormState = (state: RootState) => state.serviceUsers.editing.formState;

export const selectDuplicateRecords = (state: RootState) => state.serviceUsers.editing.duplicateRecords || [];
const serviceUserContractFields = (state: RootState) => state.serviceUsers.editing.contractFields || [];

export const selectActivitiesForUpdate = createSelector(
  [selectServiceUserHistory, selectFormState, selectServiceUser],
  (serviceUserHistory, formData, serviceUser) => {
    // only status of awaiting scheduling or scheduled or pending auth or on hold
    const statusesToCheck: ActivityStatus[] = [
      ActivityStatus.AwaitingScheduling,
      ActivityStatus.Scheduled,
      ActivityStatus.PendingAuthorisation,
      ActivityStatus.OnHold
    ];

    // CSA can change special req that are direct to customer, but prescriber can't

    // get a list of activities that match the above requirements
    const activities = serviceUserHistory?.orderHistory || [];

    // map the data so it's closer to the serviceUserData for comparing
    const mappedFormData = mapFormDataToResponse(formData as CreateUserFormData);

    // only return activities that match addresses that have been changed

    // - primary address change only (isPrimary is different)
    // get the current primary address and match against the formData primary address
    const primaryAddressId = serviceUser?.details.primaryAddress?.address.id;
    const formPrimaryAddressId = mappedFormData.addresses.find((x) => x.isPrimary)?.address.id;
    // if the primary address id is the same, then we can ignore activities that use this id
    const differentPrimaryAddress = primaryAddressId !== formPrimaryAddressId;

    // - if the address is changed then the address will be removed and added again creating a new Id (activityId addressId isn't in the formData address list)
    // only return activities that match alternative contacts that have changed primary
    // get all form address ids
    const formAddressIds = mappedFormData.addresses.map((x) => x.address.id);

    // figure out the primary contact and the form primary contact
    const primaryAdditionalContactId = serviceUser?.additionalInformation.additionalContacts.find(
      (x) => x.isPrimaryAdditionalContact
    )?.id;
    const formPrimaryAdditionalContactId = mappedFormData.additionalContacts.find(
      (x) => x.isPrimaryAdditionalContact
    )?.id;

    const differentPrimaryContact = primaryAdditionalContactId !== formPrimaryAdditionalContactId;
    // get all of the contact ids
    const formContactIds = mappedFormData.additionalContacts.map((x) => x.id);

    // reduce and filter the activities with reasons
    return activities.reduce<ServiceUserOrderHistoryRequiresUpdateModel[]>((filteredActivities, activity) => {
      // check the activity matches the base criteria. Anything that has a reason after than point will be added to the filteredActivities
      if (
        !statusesToCheck.some((status) => status === activity.status) ||
        (activity.activityTypes.some((service) => service === ServiceTypeCodeEnum.SpecialRequisition) &&
          activity.directDelivery)
      ) {
        return filteredActivities;
      }

      const reasonsForUpdate: string[] = [];

      // check for primary address change
      if (differentPrimaryAddress && activity.addressId === primaryAddressId) {
        reasonsForUpdate.push('Primary address changed');
      }

      // Check to see if the address was also changed. This could fire as well as the primary address change, whether the the primary address has been updated,
      // or there is a new address that can been marked as primary
      if (formAddressIds.indexOf(activity.addressId) < 0) {
        reasonsForUpdate.push('Delivery address updated');
      }

      // check the service user additional contact if it exists on the activity
      if (activity.serviceUserAdditionalContactId) {
        // Check to see if the primary additional contact has changed
        if (differentPrimaryContact && activity.serviceUserAdditionalContactId === primaryAdditionalContactId) {
          reasonsForUpdate.push('Primary additional contact changed');
        }

        // check to see if the activity contact ids exist after the update
        if (formContactIds.indexOf(activity.serviceUserAdditionalContactId) < 0) {
          reasonsForUpdate.push('Additional contact details changed');
        }
      }

      // if there are any reasons for changing then add the activity with the reasons to the filtered array
      if (reasonsForUpdate.length) {
        filteredActivities.push({ ...activity, reasonsForUpdate });
      }

      return filteredActivities;
    }, []);
  }
);

export const selectServiceUserContractFields = createSelector(
  [serviceUserContractFields, selectServiceUser],
  (contractFields, serviceUser) => {
    return !!serviceUser?.contractFields?.length ? serviceUser.contractFields : contractFields;
  }
);

/* reducer */
export default serviceUsersEditingSlice.reducer;
