import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, IApiResponse, IServiceCentre, IServiceCentreSummary } from 'millbrook-core';
import { getItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';

/* types */
export type ServiceCentresResponse = IApiResponse<IServiceCentre[]>;
export type ServiceCentreResponse = IApiResponse<IServiceCentreSummary>;

const initialState: ServiceCentreState = {
  serviceCentresList: []
};

const SERVICE_CENTRES_CACHE = 'serviceCentres';

/* state */
interface ServiceCentreState {
  serviceCentresList: IServiceCentre[];
}

const serviceCentresSlice = createSlice({
  name: 'serviceCentres',
  initialState,
  reducers: {
    setServiceCentresList(state, action: PayloadAction<IServiceCentre[]>) {
      state.serviceCentresList = action.payload;
    }
  }
});

// thunks
export const fetchServiceCentres = (): AppThunk => async (dispatch) => {
  return getItems<ServiceCentresResponse>(ENDPOINTS.SERVICE_CENTRES_LIST, {
    disableFullPageLoader: true,
    cacheName: 'service-centres'
  }).then((response) => {
    dispatch(setServiceCentresList(response.result || []));
  });
};

export const fetchServiceCentre =
  (serviceCentreId: ApiId): AppThunk =>
  async () => {
    if (!serviceCentreId) {
      return;
    }

    return getItems<ServiceCentreResponse>(`${ENDPOINTS.SERVICE_CENTRES_LIST}/${serviceCentreId}`, {
      cacheName: SERVICE_CENTRES_CACHE
    }).then((response) => {
      return response.result;
    });
  };

export const { setServiceCentresList } = serviceCentresSlice.actions;

export const selectServiceCentresList = (state: RootState) => state.serviceCentres.serviceCentresList;

export default serviceCentresSlice.reducer;
