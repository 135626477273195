import { resetFeedbackSearchParams } from 'features/feedback/feedback.slice';
import { setOrdersFormFilterByPin } from 'features/search/search.slice';
import { ServiceUserMergeUrlSteps } from 'features/service-users/service-users.types';
import { AUTH_ROUTES, BaseMenuItem, GrantAccess, GrantFeatureAccess, MainMenuItem } from 'millbrook-core';
import store from 'store/store';
import { ROUTES } from './routes';

export const MAIN_MENU = (isInternalPin: boolean, productsCatalogueSubmenu: BaseMenuItem[]): MainMenuItem[] => [
  {
    id: 'dashboard-menu-item',
    label: 'Dashboard',
    href: ROUTES.HOME
  },
  {
    id: 'product-catalogue-menu-item',
    label: 'Product catalogue',
    href: ROUTES.PRODUCT.catalogue,
    subMenu: productsCatalogueSubmenu
  },
  {
    id: 'orders-menu-item',
    label: isInternalPin ? 'Orders' : 'My orders',
    href: ROUTES.ACTIVITIES.root,
    onClick: () => {
      store.dispatch(setOrdersFormFilterByPin(!isInternalPin));
    }
  },
  {
    id: 'feedback-menu-item',
    label: 'Feedback',
    href: ROUTES.FEEDBACK.list,
    permissions: GrantAccess(['Feedback', 'View'], ['CSAFeedback', 'View']),
    onClick: resetFeedbackSearchParams
  },
  {
    id: 'help-support-menu-item',
    label: 'Help & Support',
    href: ROUTES.HELP.root
  },
  {
    id: 'your-trust-documents-item',
    label: 'Trust documents',
    href: ROUTES.HELP.documents
  },
  {
    id: 'manage-pins',
    label: 'Manage PINs',
    href: ROUTES.MANAGE_PINS.root,
    permissions: GrantFeatureAccess('PINManager')
  },
  {
    id: 'service-user-merge',
    label: 'Merge service users',
    href: ROUTES.SERVICE_USERS.merge(ServiceUserMergeUrlSteps.SelectUsers),
    permissions: GrantAccess(['CSAServiceUserMerge', 'Action'])
  },
  {
    id: 'logout-menu-item',
    label: 'Logout',
    href: AUTH_ROUTES.LogOut
  }
];

export const BREADCRUMB_LABELS = {
  [ROUTES.PRODUCT.catalogue]: 'Product catalogue',
  [ROUTES.QUOTES.root]: 'Quotes',
  [ROUTES.QUOTES.details()]: 'Quote details',
  [ROUTES.ACCOUNT.root]: 'My account',
  [ROUTES.ACCOUNT.edit]: 'Edit account details',
  [ROUTES.ACCOUNT.mostOrdered]: 'Most ordered',
  [ROUTES.SERVICES.root]: 'Services',
  [ROUTES.PERIPHERAL_STORES.root]: 'Peripheral stores',
  [ROUTES.PERIPHERAL_STORES.order]: 'Order',
  [ROUTES.PERIPHERAL_STORES.checkout]: 'Checkout',
  [ROUTES.PERIPHERAL_STORES.details()]: 'Peripheral store details',
  [ROUTES.PERIPHERAL_STORES.delivery()]: 'Additional delivery',
  [ROUTES.PERIPHERAL_STORES.deliveryConfirmation()]: 'Delivery confirmation'
};
