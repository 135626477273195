import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, IApiResponse } from 'millbrook-core';
import { getItem, getItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import qs from 'query-string';

/* types */
export interface ContractCCG {
    id: ApiId; // this is the master id
    name: string;
    extension: string;
    addrLn1: string;
    addrLn2: string;
    addrLn3: string;
    addrLn4: string;
    town: string;
    county: string;
    postCode: string;
    country: string;
    contacts: ContractCCGContact[];
}

export interface ContractCCGContact {
    id: ApiId;
    nhsOrganisationId: ApiId;
    type: string;
    value: string;
}

export interface ContractCCGMap {
    contractId: ApiId;
    nhsOrganisationId: ApiId;
}

export interface ContractGPSearch {
    contractId: ApiId;
    outOfArea: boolean;
    name: string;
    extension: string;
    address: string;
    postCode: string;
}


export type ContractCCGResponse = IApiResponse<ContractCCG[]>;
export type ContractGPResponse = IApiResponse<ContractCCG>;

/* state */
export interface ContractCCGsState {
    ccgs: ContractCCG[];
    gpSearchResults: ContractCCG[];
    gp?: ContractCCG;
}

const initialState: ContractCCGsState = { ccgs: [], gpSearchResults: [] };

/* slice */
const contractContractCCGsSlice = createSlice({
    name: 'contractContractCCGs',
    initialState,
    reducers: {
        setContractCCGs(state, action: PayloadAction<ContractCCG[]>) {
            state.ccgs = action.payload;
        },
        setGPSearchResults(state, action: PayloadAction<ContractCCG[]>) {
            state.gpSearchResults = action.payload;
        },
        setGP(state, action: PayloadAction<ContractCCG | undefined>) {
            state.gp = action.payload;
        },
    },
});

/* thunks */

export const fetchContractCCGs =
    (contractId: ApiId): AppThunk =>
        async (dispatch, getState) => {
            return getItems<ContractCCGResponse>(
                `${ENDPOINTS.SERVICE_USERS.CONTRACT_CCGS}?contractId=${contractId}`
            ).then((response) => {
                dispatch(setContractCCGs(response.result || []));
            });
        };

export const fetchGPById =
    (nhsOrganisationId: ApiId): AppThunk =>
        async (dispatch, getState) => {
            if (nhsOrganisationId) {
                return getItem<ApiId, ContractGPResponse>(
                    `${ENDPOINTS.SERVICE_USERS.CONTRACT_CCGS_GP_BY_ID}?nhsOrganisationId=${nhsOrganisationId}`
                ).then((response) => {
                    dispatch(setGP(response.result));
                });
            }
            else {
                dispatch(setGP(undefined));
            }
        };

export const fetchGPSearch =
    (
        contractId: ApiId,
        outOfArea: boolean,
        name?: string,
        extension?: string,
        address?: string,
        postCode?: string
    ): AppThunk =>
        async (dispatch, getState) => {
            var filters = qs.stringify(
                {
                    contractId,
                    outOfArea,
                    name,
                    extension,
                    address,
                    postCode
                },
                { skipEmptyString: true }
            );

            const url = `${ENDPOINTS.SERVICE_USERS.CONTRACT_CCGS_GP_SEARCH}?` + filters;

            return getItems<ContractCCGResponse>(url).then((response) => {
                dispatch(setGPSearchResults(response.result || []));
            });
        };


/* actions */
export const { setContractCCGs, setGPSearchResults, setGP } = contractContractCCGsSlice.actions;

/* selectors */
export const selectContractCCGsList = (state: RootState) => state.defineGPsByCCG.ccgs;

export const selectGpSearchResultsList = (state: RootState) => state.defineGPsByCCG.gpSearchResults;

export const selectGp = (state: RootState) => state.defineGPsByCCG.gp;

/* reducers */
export default contractContractCCGsSlice.reducer;
