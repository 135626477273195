import { makeStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from 'millbrook-core';

export const mainMenuDrawerStyles = makeStyles((theme: Theme) => ({
  slidingMenu: {
    height: '100%',
    width: '100%',
    whiteSpace: 'nowrap',
    transition: 'all 0.5s',
    '& > *': {
      whiteSpace: 'normal'
    }
  },
  slidingMenuSub: {
    transform: 'translateX(-100%)'
  },
  drawerContentSlide: {
    height: '100%',
    overflow: 'auto',
    width: '100%',
    display: 'inline-block',
    verticalAlign: 'top'
  },
  drawerContentSlideMain: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 8, 2, 10)
    }
  },
  drawerContentSlideSub: {
    position: 'relative',
    padding: theme.spacing(2, 5, 2, 8),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 10)
    }
  },
  subMenuItem: {
    padding: theme.spacing(3, 2)
  },
  subMenuItemParent: {
    borderBottom: `1px solid ${COLORS.edward30}`,
    marginBottom: theme.spacing(2)
  },
  subMenuItemChild: {},
  subMenuClose: {
    position: 'absolute',
    transform: 'translateX(-100%) rotate(180deg)',
    left: 4,
    color: COLORS.black,
    [theme.breakpoints.up('sm')]: {
      left: -4
    }
  },
  mainMenuListItem: {
    marginBottom: theme.spacing(3),
    color: COLORS.regalBlue
  },
  menuItem: {
    '&:hover': {
      color: COLORS.scienceBlue,
      '& .MuiSvgIcon-root': { transform: `translateX(${theme.spacing(1)}px)`, opacity: 1 }
    },
    '& .MuiSvgIcon-root': {
      opacity: 0,
      transition: 'all 0.5s'
    }
  }
}));
