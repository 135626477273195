import { makeStyles } from '@material-ui/core';
import { COLORS } from 'millbrook-core';

export const basketSummaryListStyles = makeStyles((theme) => ({
  header: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  itemContainer: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(4),
    borderTop: `1px solid ${COLORS.border}`
  },
  detailsWrapper: {
    flexGrow: 1
  },
  detailsColumn: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1
    }
  },
  contentColumn: {
    [theme.breakpoints.up('sm')]: {
      flexGrow: 1,
      paddingRight: theme.spacing(3)
    }
  },
  quantityColumn: {
    [theme.breakpoints.up('sm')]: {
      width: 130,
      flexShrink: 0
    }
  },
  priceColumn: {
    width: 110,
    flexShrink: 0,
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  mobilePrice: {
    margin: theme.spacing(2, 0)
  },
  headerText: {
    color: COLORS.regalBlue
  },
  headerLink: {
    color: COLORS.regalBlue,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  description: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical'
  },
  image: {
    width: 116,
    flexShrink: 0,
    marginRight: theme.spacing(1),
    alignSelf: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(4)
    }
  },
  remove: {
    paddingLeft: 0
  },
  quantityField: {
    minWidth: 0,
    width: 70,
    verticalAlign: 'middle',
    margin: theme.spacing(2, 0)
  },
  cheaperCard: {
    margin: theme.spacing(3, 0)
  },
  costSaving: {
    padding: theme.spacing(0, 1),
    fontSize: theme.typography.pxToRem(24)
  }
}));
