import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { NUMBER_OF_ACTIVITIES_PER_PAGE } from 'constants/pagination';
import { addDays, endOfDay, format } from 'date-fns';
import {
  ActivitySearch,
  ActivitySearchDateTypeEnum,
  ActivitySearchResponseModel,
  ActivityTabEnum,
  ApiId,
  IApiResponse,
  ISearchPagination,
  IServiceCentreSummary,
  IsoDate,
  mapApiErrors,
  mapArrayFlagsToEnum,
  mapReasonCodes,
  ReasonCodeLevelEnum,
  ReasonCodeUserTypeEnum,
  selectActivityMasterServiceTypes,
  selectReasonCodes,
  selectUserServiceCentres,
  ServiceActionEnum,
  ServiceTypeCodeEnum,
  setActivityDetails,
  SHORT_UTC_DATE_FORMAT
} from 'millbrook-core';
import { UnscheduledActivityFilterFieldsEnum } from 'pages/Activities/UnscheduledActivitiesPage/components/unscheduledActivityFilterHelpers';
import { postItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { fetchActivitySummary, fetchAllActivityDetails } from './activity.slice';
import { ScheduleDateFormData } from './ScheduleActivityDrawer/components/ScheduleActivityForm/ScheduleActivityForm';

export interface IActivityTab {
  displayName: string;
  services?: ServiceTypeCodeEnum[];
  filters?: UnscheduledActivityFilterFieldsEnum[];
}

export const ActivityTabs: { [key in ActivityTabEnum]: IActivityTab } = {
  [ActivityTabEnum.Delivery]: {
    displayName: 'Deliveries',
    services: [
      ServiceTypeCodeEnum.Delivery,
      ServiceTypeCodeEnum.EquipmentMove,
      ServiceTypeCodeEnum.SpecialRequisition,
      ServiceTypeCodeEnum.TrustedAssessment
    ],
    filters: [
      UnscheduledActivityFilterFieldsEnum.CareOutcomes,
      UnscheduledActivityFilterFieldsEnum.PostcodeAreas,
      UnscheduledActivityFilterFieldsEnum.UpdateDates,
      UnscheduledActivityFilterFieldsEnum.SlaDates,
      UnscheduledActivityFilterFieldsEnum.ActivitySpeeds,
      UnscheduledActivityFilterFieldsEnum.ReasonCodes,
      UnscheduledActivityFilterFieldsEnum.EventTypes
    ]
  },
  [ActivityTabEnum.Collection]: {
    displayName: 'Collections',
    services: [ServiceTypeCodeEnum.Collection],
    filters: [
      UnscheduledActivityFilterFieldsEnum.PostcodeAreas,
      UnscheduledActivityFilterFieldsEnum.UpdateDates,
      UnscheduledActivityFilterFieldsEnum.SlaDates,
      UnscheduledActivityFilterFieldsEnum.ActivitySpeeds,
        UnscheduledActivityFilterFieldsEnum.ReasonCodes,
        UnscheduledActivityFilterFieldsEnum.EventTypes
    ]
  },
  [ActivityTabEnum.Repair]: {
    displayName: 'Repairs',
    services: [ServiceTypeCodeEnum.Repair],
    filters: [
      UnscheduledActivityFilterFieldsEnum.PostcodeAreas,
      UnscheduledActivityFilterFieldsEnum.UpdateDates,
      UnscheduledActivityFilterFieldsEnum.SlaDates,
      UnscheduledActivityFilterFieldsEnum.ActivitySpeeds,
        UnscheduledActivityFilterFieldsEnum.ReasonCodes,
        UnscheduledActivityFilterFieldsEnum.EventTypes
    ]
  },
  [ActivityTabEnum.MinorAdaptations]: {
    displayName: 'Minor adaptations',
    services: [ServiceTypeCodeEnum.MinorAdaptationWork, ServiceTypeCodeEnum.MinorAdaptationQuote],
    filters: [
      UnscheduledActivityFilterFieldsEnum.PostcodeAreas,
      UnscheduledActivityFilterFieldsEnum.UpdateDates,
      UnscheduledActivityFilterFieldsEnum.OrderDates,
      UnscheduledActivityFilterFieldsEnum.ActivitySpeeds,
        UnscheduledActivityFilterFieldsEnum.ReasonCodes,
        UnscheduledActivityFilterFieldsEnum.EventTypes
    ]
  },
  [ActivityTabEnum.PPMs]: {
    displayName: 'PPMs',
    services: [ServiceTypeCodeEnum.PPM],
    filters: [
      UnscheduledActivityFilterFieldsEnum.PostcodeAreas,
      UnscheduledActivityFilterFieldsEnum.PpmTypes,
      UnscheduledActivityFilterFieldsEnum.UpdateDates,
      UnscheduledActivityFilterFieldsEnum.SlaDates,
      UnscheduledActivityFilterFieldsEnum.OrderDates,
      UnscheduledActivityFilterFieldsEnum.ActivitySpeeds,
        UnscheduledActivityFilterFieldsEnum.ReasonCodes,
        UnscheduledActivityFilterFieldsEnum.EventTypes
    ]
  },
  [ActivityTabEnum.TwoPerson]: { displayName: 'Two person jobs' },
  [ActivityTabEnum.PostalAndCounter]: { displayName: 'Postal and Counter' }
};

export const defaultSchedulingTableFilters: ISearchPagination = {
  pageSize: NUMBER_OF_ACTIVITIES_PER_PAGE,
  pageIndex: 1,
  sortColumn: 'dateCreated',
  sortOrder: 'desc'
};

/* types */
export interface ScheduleActivityRequest {
  orderId: ApiId;
  date: IsoDate;
  reasonCodeId?: ApiId;
}

// BE just return the ServiceResponseModel but no data
export type ScheduleActivityResponse = IApiResponse<null>;

/* state */
interface SchedulingState {
  viewingScheduleActivity: boolean;
  activityId?: ApiId;
  activity?: ActivitySearchResponseModel;
  scheduled: boolean;
  serviceCentre?: IServiceCentreSummary;
  searchData: ActivitySearch;
}

const initialState: SchedulingState = {
  viewingScheduleActivity: false,
  scheduled: false,
  searchData: { serviceCentreId: '', currentServiceTab: ActivityTabEnum.Delivery, ...defaultSchedulingTableFilters } // put defaults here
};

/* slice */
const schedulingSlice = createSlice({
  name: 'scheduling',
  initialState,
  reducers: {
    setSchedulingActivityId(state, action: PayloadAction<ApiId | undefined>) {
      state.activityId = action.payload;
    },
    setSchedulingActivity(state, action: PayloadAction<ActivitySearchResponseModel>) {
      state.activity = action.payload;
      state.scheduled = false;
    },
    setViewScheduling(state, action: PayloadAction<boolean>) {
      state.viewingScheduleActivity = action.payload;
    },
    setScheduledActivity(state) {
      state.scheduled = true;
      state.viewingScheduleActivity = false;
    },
    unsetSchedulingActivity(state) {
      state.activity = undefined;
      state.activityId = undefined;
      state.serviceCentre = undefined;
      state.scheduled = false;
      state.viewingScheduleActivity = false;
    },
    setSchedulingSearchData(state, action: PayloadAction<ActivitySearch>) {
      state.searchData = action.payload;
    },
    updateSchedulingSearchData(state, action: PayloadAction<ActivitySearch>) {
      state.searchData = { ...state.searchData, ...action.payload };
    },
    resetSchedulingSearchData(state) {
      state.searchData = initialState.searchData;
    }
  },
  extraReducers: (builder) => {
    // when a new activity is set, reset all scheduling activity info
    builder.addCase(setActivityDetails, (state, action) => {
      state = { ...initialState };
    });
  }
});

/* thunks */
export const initSchedulingActivity = (): AppThunk => async (dispatch, getState) => {
  const activityId = getState().activities.scheduling.activityId;

  if (activityId) {
    return dispatch<any>(fetchActivitySummary(activityId)).then(
      (response: ActivitySearchResponseModel) => {
        dispatch(setSchedulingActivity(response));
      },
      (response: any) => {
        const error = mapApiErrors(response);
        console.warn(error);
        // throw new Error(error);
        // FIX - show the error on the schedule drawer rather than just throw an error
        // BE - PPM order details are giving an error which breaks this
      }
    );
  }
};

export const createActivitySchedule =
  (schedule: ScheduleDateFormData, orderId: ApiId): AppThunk =>
  async (dispatch) => {
    return postItems<ScheduleActivityRequest, ScheduleActivityResponse>(
      `${ENDPOINTS.ACTIVITIES.ACTIVITY}/${orderId}/Schedule`,
      {
        date: format(new Date(schedule.deliveryDate), SHORT_UTC_DATE_FORMAT),
        reasonCodeId: schedule.reasonCode,
        orderId
      }
    ).then(
      async () => {
        try {
          const updatedActivity = await dispatch<any>(fetchActivitySummary(orderId));

          await dispatch(setSchedulingActivity(updatedActivity));
          dispatch(setScheduledActivity());
        } catch (response: any) {
          const error = mapApiErrors(response);
          throw new Error(error);
        }
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const unscheduleActivity =
  (orderId: ApiId): AppThunk =>
  async (dispatch) => {
    return postItems<ApiId, boolean>(`${ENDPOINTS.ACTIVITIES.ACTIVITY}/${orderId}/Unschedule`, orderId).then(
      async (response) => {
        await dispatch<any>(fetchAllActivityDetails(orderId));
      }
    );
  };

/* actions */
export const {
  setSchedulingActivity,
  setSchedulingActivityId,
  setViewScheduling,
  setScheduledActivity,
  unsetSchedulingActivity,
  setSchedulingSearchData,
  updateSchedulingSearchData,
  resetSchedulingSearchData
} = schedulingSlice.actions;

/* selectors */
export const selectViewingScheduling = (state: RootState) => state.activities.scheduling.viewingScheduleActivity;
export const selectSchedulingActivity = (state: RootState) => state.activities.scheduling.activity;
export const selectSchedulingActivityServiceCentre = (state: RootState) =>
  state.activities.scheduling.activity?.serviceCentre;
export const selectSchedulingStatus = (state: RootState) => state.activities.scheduling.scheduled;

export const selectSchedulingActivityServiceTypeIds = (state: RootState) =>
  state.activities.scheduling.activity?.serviceTypeIds;

export const selectScheduleActivityReasonCodes = createSelector(
  [selectReasonCodes, selectSchedulingActivityServiceTypeIds],
  (reasonCodes, activityServiceTypeIds) => {
    return mapReasonCodes(
      reasonCodes,
      ReasonCodeLevelEnum.Service,
      activityServiceTypeIds,
      ReasonCodeUserTypeEnum.CSA,
      ServiceActionEnum.None
    );
  }
);

const searchData = (state: RootState) => state.activities.scheduling.searchData;

export const selectSchedulingSearchData = createSelector(
  [searchData, selectActivityMasterServiceTypes, selectUserServiceCentres],
  (searchData, serviceTypes, userServiceCentres) => {
    const { serviceCentreId } = searchData;

    const today = new Date();

    let currentServiceTab = searchData.currentServiceTab || ActivityTabEnum.Delivery;

    const activityTabData = ActivityTabs[currentServiceTab!];

    const currentServiceTypeCodes = activityTabData.services;

    const filtersToShow = mapArrayFlagsToEnum(activityTabData.filters);

    const serviceTypeIds = serviceTypes
      .filter((service) => currentServiceTypeCodes && currentServiceTypeCodes.includes(service.serviceTypeCode))
      ?.map((service) => service.id);

    const searchOverrides: ActivitySearch = {
      unscheduledOrdersOnly: true,
      toDate: addDays(endOfDay(today), 28).toISOString(),
      dateSearchType: ActivitySearchDateTypeEnum.TargetDate,
      includeProductStatus: true,
      serviceTypeIds
    };

    // update search criteria for non default service type tabs
    switch (currentServiceTab) {
      case ActivityTabEnum.TwoPerson:
        searchOverrides.isTwoPerson = true;
        break;
      case ActivityTabEnum.PostalAndCounter:
        searchOverrides.isPostalCounterOrder = true;
        break;
    }

    return {
      ...searchData,
      ...searchOverrides,
      filtersToShow,
      currentServiceTab,
      // search data service centre will override last used, but the theory is that they should be the same most of the time.
      serviceCentreId: serviceCentreId || userServiceCentres.lastServiceCentre?.id
    };
  }
);

/* reducers */
export default schedulingSlice.reducer;
