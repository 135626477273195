import React, { useState } from 'react';

interface ReserveProductDialog {
  reserveDialogIsOpen: boolean;
  reserving: boolean;
  handleStartReserveItem: (e?: React.MouseEvent) => void;
  handleStartUnreserveItem: (e?: React.MouseEvent) => void;
  handleCloseReserveItem: () => void;
}

export const useReserveProductDialog = (opened: boolean = false): ReserveProductDialog => {
  const [reserveDialogIsOpen, setReserveDialogIsOpen] = useState<boolean>(opened);
  const [reserving, setReserving] = useState<boolean>(false);

  const handleStartReserveItem = (e?: React.MouseEvent) => {
    e?.preventDefault();

    setReserving(true);
    setReserveDialogIsOpen(true);
  };

  const handleStartUnreserveItem = (e?: React.MouseEvent) => {
    e?.preventDefault();

    setReserving(false);
    setReserveDialogIsOpen(true);
  };

  const handleCloseReserveItem = () => {
    setReserveDialogIsOpen(false);
  };

  return {
    reserveDialogIsOpen,
    reserving,
    handleStartReserveItem,
    handleStartUnreserveItem,
    handleCloseReserveItem
  };
};
