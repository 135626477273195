import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, IApiResponse, QuoteStatusEnum, Quote, QuoteListItem } from 'millbrook-core';
import { getItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';

/* types */
interface UpdateQuoteStatusRequest {
  quoteStatus: QuoteStatusEnum;
}

/* state */
interface State {
  quoteList: QuoteListItem[];
  selectedQuote: Quote | undefined;
}

const initialState: State = {
  quoteList: [],
  selectedQuote: undefined
};

/* slice */
const slice = createSlice({
  name: 'quotes',
  initialState,
  reducers: {
    setQuoteList(state, action: PayloadAction<QuoteListItem[]>) {
      state.quoteList = action.payload;
    },
    setSelectedQuote(state, action: PayloadAction<Quote | undefined>) {
      state.selectedQuote = action.payload;
    }
  }
});

/* selectors */
export const getQuoteList = (state: RootState) => state.quotes.quoteList;
export const getSelectedQuote = (state: RootState) => state.quotes.selectedQuote;

/* actions */
export const { setQuoteList, setSelectedQuote } = slice.actions;

/* thunks */
export const fetchQuoteList = (): AppThunk => async (dispatch) => {
  return getItems<IApiResponse<QuoteListItem[]>>(ENDPOINTS.QUOTES.FetchQuoteList, {
    enableGlobalErrorDialog: true
  }).then(
    (response) => {
      dispatch(setQuoteList(response.result || initialState.quoteList));
    },
    () => {
      // handled with global error handler
    }
  );
};

export const fetchSelectedQuote =
  (quoteId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<IApiResponse<any>>(ENDPOINTS.QUOTES.FetchQuoteDetails(quoteId), {
      enableGlobalErrorDialog: true
    }).then(
      (response) => {
        const quote = response.result?.data || initialState.selectedQuote;

        const mappedQuote = {
          ...quote,
          serviceUser: {
            ...quote.serviceUser,
            id: quote.serviceUserId,
            address: quote.serviceUserAddress?.address
          }
        };

        dispatch(setSelectedQuote(mappedQuote));
      },
      () => {
        // handled with global error handler
      }
    );
  };

export const updateQuoteStatus =
  (quoteId: ApiId, status: QuoteStatusEnum): AppThunk =>
  async (dispatch) => {
    const requestData: UpdateQuoteStatusRequest = {
      quoteStatus: status
    };

    return putItem<UpdateQuoteStatusRequest, IApiResponse<Quote>>(
      ENDPOINTS.QUOTES.UpdateQuoteStatus,
      requestData,
      quoteId,
      {
        enableGlobalErrorDialog: true
      }
    );
  };

export default slice.reducer;
