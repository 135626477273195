import { makeStyles, Theme } from '@material-ui/core';
import millbrookLogo from 'assets/svg/millbrook-logo-white.svg';
import Header from 'components/Header/Header';
import { Footer, useUiDimensions } from 'millbrook-core';
import { Fragment } from 'react';
import versionData from "../../version.json";

interface StyleProps {
  headerHeight: number;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  paper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: ({ headerHeight }) => headerHeight,
    minHeight: ({ headerHeight }) => `calc(100vh - ${headerHeight}px)`
  }
}));

interface MainLayoutProps {
  showBreadcrumb?: boolean;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { headerHeight } = useUiDimensions();
  const classes = useStyles({ headerHeight });

  return (
    <Fragment>
      <Header />
      <div className={classes.paper}>{children}</div>
      <Footer
        // TODO: Can't work out how to put raw svg into core. It still seems to reference the parent app asset folder
        logo={millbrookLogo}
        twitterUrl="https://twitter.com/MB_Healthcare"
        facebookUrl="https://en-gb.facebook.com/MillbrookHealthcare/"
        linkedInUrl="https://www.linkedin.com/company/millbrook-healthcare"
        copyright={'©' + new Date().getFullYear() + ' Millbrook Healthcare ltd. All rights reserved.'}
        addressInfo="Nutsey Lane<br />Calmore Industrial Park<br />Totton, Hants<br />SO40 3XJ"
        emailAddress="enquiries@millbrookhealthcare.co.uk"
        telephoneNumber="0800 988 2210"
        website="https://www.millbrook-healthcare.co.uk/"
        caresVersion={versionData.caresVersion}
      />
    </Fragment>
  );
};

export default MainLayout;
