import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { selectSelectedPinUserId } from 'features/auth-pin/auth-pin.slice';
import { ApiId, IsoDate } from 'millbrook-core';
import { AppThunk, RootState } from 'store/store';

export interface ProfessionalAssessmentTermsAgreedStore {
  dateAgreed?: IsoDate;
}

interface UserSettings {
  professionalAssessmentTermsAgreedStore?: ProfessionalAssessmentTermsAgreedStore;
}

interface UserSettingsState {
  [key: string]: UserSettings;
}

const initialState: UserSettingsState = {};

const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    setUserSettings(state, action: PayloadAction<{ pinUserId: ApiId; userSettings: Partial<UserSettings> }>) {
      const { pinUserId, userSettings } = action.payload;
      const oldState = state[pinUserId] || {};
      state[pinUserId] = { ...oldState, ...userSettings };
    }
  }
});

const allUserSettings = (state: RootState) => state.userSettings;

export const selectUserSettings = createSelector([allUserSettings, selectSelectedPinUserId], (settings, pinUserId) => {
  return settings[pinUserId || ''] || {};
});

/**
 * Thunks
 */

export const setUserSettings =
  (userSettings: Partial<UserSettings>): AppThunk =>
  async (dispatch, getState) => {
    const app = getState();

    const pinUserId = selectSelectedPinUserId(app);

    pinUserId && dispatch(userSettingsSlice.actions.setUserSettings({ pinUserId, userSettings }));
  };

export default userSettingsSlice.reducer;
